/* -------------  CAROUSEL QUOTES ---------------------- */
.testimonial.style-4 .owl-dots .owl-dot:hover, .testimonial.style-4 .owl-dots .owl-dot.active {

    -webkit-box-shadow: 0 0 0 1px #9fce4e;
    box-shadow: 0 0 0 1px #9fce4e;
    background: #9fce4e;
	
}
.testimonial.style-4 .owl-dots .owl-dot {

    height: 16px;
    width: 16px;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: 2px solid #fff;
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
    background: rgba(0, 0, 0, 0.3);
	margin: 4px;
	display: inline-block;
	transition: background-color 0.3s, border-color 0.3s, text-color 0.3s;
	
}

.owl-dots {

    text-align: center;
	margin-top: 15px;
	
}


/* --------------------------- HOME CAROUSEL ------------------------- */

.homeCarousel .owl-dots {
	left: 50%;
	top: 90%;
	text-align: center;
	position: absolute;
}

.homeCarousel .owl-carousel .owl-item {
	list-style-type: none;
}

.homeCarousel .owl-dots .owl-dot {
	left: -50%;
	display: inline-block;
	background: transparent;
	border: 1px solid #fff;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	width: 10px;
	height: 10px;
	margin: 3px;
	position: relative;
	cursor: pointer;
}

.homeCarousel .owl-dots .owl-dot.selected, .homeCarousel .owl-dots .owl-dot.active, .homeCarousel .owl-dots .owl-dot:hover {
	background: #fff;
}

.owl-dot {
	transition: all .3s ease-in-out;
}

.homeCarousel:hover .tparrows, .homeCarousel:hover .owl-dot {
	opacity: 1 !important;
}



/* -------------  CAROUSEL BANNER PARTNERS ---------------------- */

.client-slider .owl-carousel {
	text-align: center;
	overflow:hidden;
}

.client-slider .owl-carousel .owl-stage-outer {
	display:inline-block;
}

.client-slider .owl-nav .owl-prev1::before {
	content: "\44";
	position: relative;
	display: inline-block;
	font-family: 'ElegantIcons';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	text-decoration: inherit;
}

.client-slider .owl-nav .owl-prev1 {
	display: block;
	left: 20px; 
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	transition: all .3s ease-out 0s;
	width: 40px;
	height: 40px;
	line-height: 44px;
	font-size: 20px;
	background-color: #9fce4e;
	border: 1px solid #9fce4e;
	text-align: center;
	z-index: 100;
	color: #fff;
	text-decoration: none;
	
	
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	-o-transform: translate(0, -50%);
	transform: translate(0, -50%);
	-webkit-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s;
	transition: all .3s ease-out 0s;
}

.client-slider .owl-nav .owl-next1::before {
	content: "\45";
	position: relative;
	display: inline-block;
	font-family: 'ElegantIcons';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	text-decoration: inherit;
}

.client-slider .owl-nav .owl-next1 {
	display: block;
	right: 20px;
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	transition: all .3s ease-out 0s;
	width: 40px;
	height: 40px;
	line-height: 44px;
	font-size: 20px;
	background-color: #9fce4e;
	border: 1px solid #9fce4e;
	text-align: center;
	z-index: 100;
	color: #fff;
	text-decoration: none;
	
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	-o-transform: translate(0, -50%);
	transform: translate(0, -50%);
	-webkit-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s;
	transition: all .3s ease-out 0s;
}

.client-slider .owl-nav .owl-next1:hover,  .client-slider .owl-nav .owl-prev1:hover {
	background: #fff;
	color: #9fce4e;
}

/* 
.client-slider .owl-stage {
	text-align: left;
	float: none;
	position: absolute;
	top: 0px;
	right: auto;
	bottom: auto;
	left: 0px;
	margin: 0px;
	width: 6930px;
	height: 65px;
	z-index: auto;
	padding: 0;
	list-style: none;
} */

.client-slider li {
	width: 295px;
	text-align: center;
	float: left;
	padding: 0;
	list-style: none;
	margin: 0 10px;
	
}

.client-slider li a {
	color: #333;
	text-decoration: none;
	background: transparent;
	display: inline-block;
}

.client-slider li a img {
	width: auto		!important;
}


