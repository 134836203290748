.mapContainer { 
    height: 600px;
    width: 90%;
    margin:auto;
    display:flex;
    flex-direction: row;
    margin-top: 50px
}

.mapElement {
    width:100%;
    height: 100%;
    order: 2;
    flex-grow: 1;
}

.searchInput {
    display: flex; 
    flex-direction: row;
    border: 1px solid #000;
    width: 350px;
    box-sizing: border-box;
    height: 40px;
    margin-top: 11px;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;
}

.storesListContainer{
    height: 600px;
    overflow-y: auto;
}

@media screen and (max-width: 768px) {
    .mapContainer{
        flex-direction: column-reverse;
    }
    .mapElement{
        height: 500px;
    }
    .searchInput{
       left: 20px !important;
       margin-top: 60px;
       width: 80%; 
    }
    .storesListContainer{
        height: unset;
        max-height: 140px;
    }
    .storesListContainer > div{
        width: 100% !important;
    }
}