@media screen and (max-width: 320px) {
  .mobile_view {
    height: 92vh !important; }
    .mobile_view .col-md-7.c-l.text-cn.mobile_view_txt {
      position: absolute;
      width: 100%;
      height: auto;
      display: inline-block;
      padding-top: 116%; }
      .mobile_view .col-md-7.c-l.text-cn.mobile_view_txt > div {
        padding: 0px; }
        .mobile_view .col-md-7.c-l.text-cn.mobile_view_txt > div p {
          text-align: left; }
    .mobile_view .col-md-5.c-r.img-cn.mobile_view_img {
      position: absolute;
      width: 100%;
      height: auto;
      display: inline-block;
      padding-top: 14%; }

  .history .col-md-7.c-r.type-num {
    padding-top: 2px; }
}
@media screen and (min-width: 321px) and (max-width: 375px) {
  .mobile_view {
    height: 95vh; }
    .mobile_view .col-md-7.c-l.text-cn.mobile_view_txt {
      padding-top: 107%; }
    .mobile_view .col-md-5.c-r.img-cn.mobile_view_img {
      position: absolute;
      width: 100%;
      height: auto;
      display: inline-block;
      padding-top: 12%; }

  .carousel_custom_product_7 .summary .meta-cn p strong {
    width: 170px !important; }

  .carousel_custom_product_7 .summary .meta-cn p span {
    width: 120px !important; }
}
@media screen and (max-width: 425px) {
  .main-hd .tool-hd {
    text-align: center; }
    .main-hd .tool-hd .col-md-12 {
      text-align: center; }
    .main-hd .tool-hd a {
      margin-left: 20px !important; }
      .main-hd .tool-hd a:first-child {
        margin-left: 0px !important; }
  .main-hd .nav-hd .slicknav_menu {
    display: block !important; }
  .main-hd .nav-hd .logo {
    max-width: 200px; }
  .main-hd .nav-hd .nav-cn {
    display: none; }

  .wrapper {
    margin: unset; }

  #main_custom h2 {
    font-size: 1.5625rem;
    margin: unset; }
  #main_custom .all_category_product {
    margin-top: 6px; }
    #main_custom .all_category_product h1 {
      padding-left: 4px; }
    #main_custom .all_category_product h2 {
      font-size: 4.6875rem !important; }
  #main_custom .search__custom h1 {
    font-size: 1.875rem; }
  #main_custom .section2 .portfolio-caption,
  #main_custom .section3 .portfolio-caption,
  #main_custom .section4 .portfolio-caption {
    margin-bottom: 0; }
    #main_custom .section2 .portfolio-caption h4,
    #main_custom .section3 .portfolio-caption h4,
    #main_custom .section4 .portfolio-caption h4 {
      font-weight: bold;
      font-size: 1.125rem; }
    #main_custom .section2 .portfolio-caption p,
    #main_custom .section3 .portfolio-caption p,
    #main_custom .section4 .portfolio-caption p {
      font-size: 1rem;
      margin-bottom: 0; }
  #main_custom .btn_custom {
    padding: 8px 20px 4px !important;
    width: 244px !important; }
  #main_custom .line_btn {
    padding: 0px; }

  .display_filter_prod {
    width: 9%; }

  .carousel_custom_product_7 .summary .meta-cn p span {
    width: 300px !important; }

  .row.box-1 .pad {
    padding: 20px !important; }
    .row.box-1 .pad blockquote {
      font-size: 1rem; }
      .row.box-1 .pad blockquote p {
        font-size: 1rem; }

  .custom_list h2,
  .row.box-1 h1 {
    font-size: 1.875rem !important; }

  .related__product__custom .portfolio-link {
    padding: 20px; }

  .carousel_custom_product_7 .summary h1 {
    font-size: 1.5rem; }
  .carousel_custom_product_7 .summary .meta-vol,
  .carousel_custom_product_7 .summary .product-excerpt p {
    font-size: 1rem; }

  .section2 h2 {
    font-size: 1.5625rem !important; }
  .section2 .portfolio-caption h4 {
    font-size: 1.125rem;
    font-weight: 600; }
  .section2 .portfolio-caption p {
    font-size: 1rem;
    height: 100px;
    margin: 0 1.8rem; }

  .related__product__custom .portfolio-link {
    margin-bottom: -62px; }
    .related__product__custom .portfolio-link .portfolio-caption p {
      margin-top: -2px; }

  .display_filter_prod {
    display: block;
    padding-bottom: 0;
    text-align: right;
    width: 6%;
    position: absolute;
    font-size: 1.25rem;
    right: 18px;
    top: 11%; }

  .sidebar-wrap {
    display: none;
    position: absolute;
    top: 22%;
    bottom: 0;
    padding-right: 0;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(198, 198, 198, 0.7);
    overflow: hidden;
    overflow-y: hidden;
    overflow-x: hidden;
    height: auto;
    transition: all .3s ease;
    z-index: 99999; }

  .filter-bg-finder {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99998;
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.9);
    transition: all .3s ease; }

  .custom_row_block .cl.text-cn > div {
    background: #fff; }

  .display_my_img_txt .text-cn > div {
    position: unset;
    text-align: left;
    margin-top: -40px;
    padding: unset; }

  .featured_posts_custom_heading h1 {
    font-size: 2.8125rem;
    margin-bottom: 26px; }
  .featured_posts_custom_heading blockquote {
    font-size: 1.125rem; }

  .featured_posts_custom h3 {
    font-size: 1.0625rem;
    line-height: 24px; }
  .featured_posts_custom p {
    font-size: .875rem;
    line-height: 24px;
    min-height: unset; }
  .featured_posts_custom .arrow {
    height: 75px; }

  .list_cn .modal-xl .modal-content .popup-title {
    margin-top: 0px;
    font-size: 1.125rem; }

  .modal.fade.show {
    background: rgba(255, 255, 255, 0.9); }

  .custom__all_prods .list__product__item .Producto-item {
    height: 550px !important; }

  .list__product__item .Producto-item {
    height: 550px !important; }

  .item__row__custom.inverse__custom,
  .display_my_img_txt.display__img__text {
    display: flex;
    flex-direction: column-reverse; }

  .item__row__custom .c-l.text-cn > div {
    background-color: unset;
    text-align: left; }

  .cat__custom__article ul {
    display: unset; }
    .cat__custom__article ul li {
      width: 100%;
      margin-bottom: 20px; }
      .cat__custom__article ul li a {
        display: flex;
        align-items: center; }

    .all-articles__custom .item__article__only h2 {
      font-size: 1.25rem !important; }
    .all-articles__custom .item__article__only time {
      color: #bebebe;
      display: block;
      margin: 12px 0 -4px; }
    .all-articles__custom .item__article__only span {
      font-size: 16.5px !important; }
    .all-articles__custom .item__article__only button {
      font-size: 1.125rem; }

  .cat__custom__article ul li a span {
    margin: 24% 0 24% 0%; }

  .changeHamburger {
    float: right;
    margin: 0; }
    .changeHamburger .slicknav_icon-bar {
      transform: rotate(-45deg);
      transform-origin: 30% 50%;
      width: 30px;
      margin-top: 5px;
      display: block;
      margin-bottom: -1px !important; }
      .changeHamburger .slicknav_icon-bar:first-child {
        transform: rotate(45deg); }


  .slicknav_menu #navigation .producto-nav a:before {
    font-family: "FontAwesome";
    content: "\f067";
    padding-right: 8px;
    font-size: 10px;
    vertical-align: middle; }
  .slicknav_menu #navigation .producto-nav .slicknav_nav .sub-menu {
    background-color: #f5f5f5 !important; }
  .slicknav_menu #navigation .producto-nav .child-nav {
    position: relative;
    padding: 12px 30px; }
    .slicknav_menu #navigation .producto-nav .child-nav:first-child {
      padding-top: 0;
      border-top: none; }
  .slicknav_menu #navigation .producto-nav .sub-menu .tiret__custom {
    padding: 5px 0; }
    .slicknav_menu #navigation .producto-nav .sub-menu .tiret__custom:first-child {
      padding-top: 15px; }
    .slicknav_menu #navigation .producto-nav .sub-menu .tiret__custom > a {
      font-size: .85rem; }


  #navigation .producto-nav:nth-child(2) li:after {
    display: none; }

  #reviews h2 {
    font-size: 2.625rem; }
  #reviews p {
    font-size: 1rem; }
  #reviews .Legal-form {
    margin-bottom: 60px; }

  .category__summary__custom h1 {
    font-size: 3.125rem; }
  .category__summary__custom p {
    font-size: 1.125rem; }

  .history:before {
    display: none; }
  .history .c-l.type-num {
    text-align: left;
    padding-left: 20px; }
  .history .type-num {
    padding-left: 20px; }
  .history .c-l.type-txt {
    text-align: left; }
  .history .c-r.type-num p {
    margin-bottom: 0; }
  .history .changeOrder {
    display: flex;
    flex-direction: column-reverse; }

  .video__pad {
    height: auto; }

  .modal .modal-content {
    height: 100% !important; }

  .richtext_custom {
    padding: 0px !important;
    font-size: 1rem;
    line-height: 30px; } }
@media screen and (min-width: 426px) and (max-width: 769px) {
  .changeHamburger {
    float: right;
    margin: 0; }
    .changeHamburger .slicknav_icon-bar {
      transform: rotate(-45deg);
      transform-origin: 30% 50%;
      width: 30px;
      margin-top: 5px;
      display: block;
      margin-bottom: -1px !important; }
      .changeHamburger .slicknav_icon-bar:first-child {
        transform: rotate(45deg); }

  .slicknav_menu #navigation .producto-nav a:before {
    font-family: "FontAwesome";
    content: "\f067";
    padding-right: 8px;
    font-size: 10px;
    vertical-align: middle; }
  .slicknav_menu #navigation .producto-nav .slicknav_nav .sub-menu {
    background-color: #f5f5f5 !important; }
  .slicknav_menu #navigation .producto-nav .child-nav {
    position: relative;
    padding: 12px 30px; }
    .slicknav_menu #navigation .producto-nav .child-nav:first-child {
      padding-top: 0;
      border-top: none; }
  .slicknav_menu #navigation .producto-nav .sub-menu .tiret__custom {
    padding: 5px 0; }
    .slicknav_menu #navigation .producto-nav .sub-menu .tiret__custom:first-child {
      padding-top: 0; }
    .slicknav_menu #navigation .producto-nav .sub-menu .tiret__custom > a {
      font-size: .85rem; }
  .slicknav_menu #navigation li {
    max-width: 100%; }

  #navigation .producto-nav:nth-child(2) li:after {
    display: none; }

  .single-product-images .carousel-slider {
    height: auto; }

  .related__product__custom .portfolio-caption {
    height: 118px; }
    .related__product__custom .portfolio-caption p {
      margin-top: 0px; }

  .for_tab {
    padding: 0px; }
    .for_tab .sidebar-wrap {
      padding-left: 0px; }

  .all_category_product .list_cat_product__custom .item_product_category {
    height: 408px !important; }
    .all_category_product .list_cat_product__custom .item_product_category img {
      height: 248px !important;
      width: 248px !important; }

  footer {
    height: 497px; }
    footer .top-ft {
      height: 313px !important; }
      footer .top-ft .col-8 {
        height: 70px !important; }
      footer .top-ft .col-4 p {
        margin-bottom: 0px; }
        footer .top-ft .col-4 p .link_stext {
          font-size: 1.625rem !important; }


  .section2 .portfolio-caption h4 {
    font-size: 1.125rem !important; }
  .section2 .portfolio-caption p {
    font-size: 1rem !important; }

  .main-hd .nav-hd .slicknav_menu {
    display: block !important; }

  .main-hd .nav-hd .nav-cn {
    display: none; }

  .display_my_img_txt {
    height: 410px;
    padding-bottom: 10px; }
    .display_my_img_txt .text-cn p {
      margin-top: unset;
      margin-bottom: 30px; }

  .history:before {
    display: none; }
  .history .type-txt > div {
    width: unset;
    text-align: left; }
  .history .col-md-5.c-l.type-txt {
    padding-top: 22%; }
  .history .col-md-7.c-r.type-num {
    padding-left: 20px;
    position: absolute; }
    .history .col-md-7.c-r.type-num p {
      padding: unset;
      margin: unset;
      font-size: 2rem;
      padding-top: 0px; }
  .history .col-md-7.c-l.type-num {
    padding: 20px;
    text-align: left; }
    .history .col-md-7.c-l.type-num p {
      padding: unset;
      margin: unset;
      font-size: 2rem;
      padding-top: 0px; } }
@media screen and (min-width: 319px) and (max-width: 769px) {
  .commerce .commerce-tabs .nav-tabs>li>a {
    border-width: unset;
  }
  .commerce div.product.style-2 .commerce-tabs .nav-tabs li {
    width: 100%;
  }
  .product_meta .prodAd{
    display: unset;
  }
}
/*# sourceMappingURL=responsive.css.map */
