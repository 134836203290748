/*
    GLOBAL CSS
*/

@font-face {
    font-family: "Atlan-Regular";
    src: url(../fonts/Atlan-Regular.otf);
    font-style: normal;
}

@font-face {
    font-family: "Atlan-Semibold";
    src: url(../fonts/Atlan-SemiBold.otf);
    font-style: normal;
}

@font-face {
    font-family: "Atlan-Bold";
    src: url(../fonts/Atlan-Bold.otf);
    font-style: normal;
}

@font-face {
    font-family: "Camila-Bold";
    src: url(../fonts/Camila-Bold.otf); 
    font-style: normal;
}

@font-face {
    font-family: "Camila-Semibold";
    src: url(../fonts/Camila-Semibold.otf); 
    font-style: normal;
}

@font-face {
    font-family: "Camila-SemiboldIt";
    src: url(../fonts/Camila-SemiboldIt.otf); 
    font-style: normal;
}

@font-face {
    font-family: "Camila-Medium";
    src: url(../fonts/Camila-Medium.otf); 
    font-style: normal;
}

.fa-star{
    color: #dfcda3;
}

body{
    font-size: 16px;
    overflow-x: hidden !important;
    transform: none !important;
}

.nomargin > div {
    margin: 0;
}
/*** GREEN BORDERS ***/
header.main-hd{
    border: 20px solid #394A27;
    border-bottom: 0;
    position: fixed;
    width: 100%;
    background-color: #ffffff;
    z-index:15;
}

.content-container{
    border: 20px solid #394a27;
    border-top: 0;
    border-bottom: 0;
    padding-top: 174px;
}

footer{
    border: 20px solid #394A27;
    border-top: 0;
}

@media (max-width: 899px){
    header.main-hd{
        border-width: 10px; 
    }
    .content-container{
        border-width: 10px;
        padding-top: 73px;
    }
    footer{
        border-width: 10px;
    }
}

/*** Titles ***/
.decorated-title{
    font-family: "Camila-SemiboldIt", serif;
    padding-bottom: 54px;
    padding-top: 16px;
    background: url(../images/Theme/ornament-olive.png) no-repeat center bottom;
    background-size: contain;
    font-size: 36px;
    line-height: 36px;
    color: #21552F;
    font-weight: lighter;
    text-align: center;
}

.Reviews{
    width: 100%;
}

/*** Fix griditems **/
.masonry-products{
    padding: 0;
}

/*** fix RichTextCustom ***/
.richtext_custom{
    float: none;
}
/*** RichTextComponent for category page***/
.RichTextComponent.category-page{
    font-family: "Camila-SemiboldIt", serif;
    line-height: 34px;
    margin-top: 40px;
}

/*** RichTextComponent for la marca***/
.RichTextComponent.yellow-2columns{
    padding: 30px;
    background-color: #F7F2DF;
    background-image: url(../images/Theme/engagements_bg.png),url(../images/Theme/engagements_bg_bottom.png);
    background-repeat: no-repeat;
    background-position: left top,right 20px bottom 20px;
    min-height: 200px;
    margin-bottom: 80px;
    font-size: 15px;
}

.RichTextComponent.yellow-2columns .richtext_custom{
    columns: 2;
    column-gap: 60px;
}

@media (max-width: 768px){
    .RichTextComponent.yellow-2columns .richtext_custom{
        columns: 1;
    }
}

/*** General Product item style ***/
.RelatedProducts{
    margin-bottom: 40px;
}

.product-item{
    border: 1px solid white;
    padding: 20px 10px;
    margin-bottom: 15px;
    list-style: none;
}

.product-item h3{
    font-family: 'Camila-Semibold', serif;
    font-size: 22px;
    line-height: 22px;
    height: 45px;
}

.product-item .portfolio-link img{
    width: 200px !important;
    height: 200px !important;
}

.product-item .rating span{
    margin-left: 12px;
    color: #C3B37B;
}

.product-item .StarRating{
    display: inline-block;
}

.product-item .fa-star{
    color: #dfcda3;
    font-size: 18px;
}

.btn_custom_oro{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0px;
    z-index: 10;
    margin-top: 0;
    transition: all ease-in-out 0.3s;
    display: inline-block;
    width: 198px;
    text-align: center;
    border: 4px double #C3B37B;
    cursor: pointer;
    opacity: 0;
    color: black;
}

.btn_custom_oro span{
    display: block;
    background-color: #C3B37B;
    font-size: 16px;
    line-height: 18px;
    color: #FFF;
    padding: 6px;
    transition: background-color .3s,color .3s;
}

.btn_custom_oro i{
    margin-left: 10px;
    vertical-align: middle;
}

.product-item:hover .btn_custom_oro{
    opacity: 1;
    transform: translate(-50%,-40px);
} 

.product-item h3, .product-item .rating{
    transition: all .3s ease-in-out;
}

.product-item:hover h3, .product-item:hover .rating{
    opacity: 0;
}

.product-item:hover{
    border: 1px solid #A5C03F;
}

.product-item.Moon:before{
    content: "";
    position: absolute;
    width: 23px;
    height: 23px;
    background: url(../images/Theme/product-night.svg);
    top: 15px;
    right: 15px;
}

.product-item.Sun:before{
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background: url(../images/Theme/product-day.svg);
    top: 15px;
    right: 15px;
}

@media (max-width: 768px){
    .btn_custom_oro{
        opacity: 1;
    }
    .product-item .rating{
        margin: 20px 0;
    }
    .product-item h3{
        height: unset;
    }
}

/* RelatedProduct for product page */

.content-container.commerce .RelatedProducts{
    background: url(../images/Theme/routine-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0px;
}

.content-container.commerce .related_product_custom{
    background-color: rgba(255,255,255,0.8);
    padding: 0 20px 0 45px;
}

.content-container.commerce a .product-item{
    width: 31%;
    background-color: white;
    margin: 20px 10px;
    border: none;
}

.content-container.commerce .RelatedProducts h2{
    font-size: 45px;
    line-height: 45px;
    background: none;
}

.content-container.commerce .RelatedProducts h2:before{
    content: "";
    height: 40px;
    width: 1px;
    background: #4C5A1A;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}


@media (max-width: 768px){
    .content-container.commerce a .product-item{
        width: unset;
    }

    .content-container.commerce .RelatedProducts{
        padding: 0 15px;
    }

    .content-container.commerce .related_product_custom{
        padding: 0 20px;
    }
    .content-container.commerce .RelatedProducts h2{
        font-size: 35px;
        line-height: 35px;
    }
}

/* Masonry page */

.masonry_ctn{
    font-size: 16px;
}

.RichTextComponent.introduction{
    font-family: "Camila-SemiboldIt",serif;
    font-size: 24px;
    line-height: 34px;
    max-width: 850px;
    padding: 85px 0;
}

.RichTextComponent.introduction:after{
    content: "";
    height: 80px;
    width: 1px;
    background: #DFCDA3;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}

/*** Blog page ***/

.cat__custom__article{
    padding-left: 30px;
    padding-right: 30px;
}

.cat__custom__article .item{
    margin-bottom: 40px;
    height: 0;
    padding-top: 16%;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.cat__custom__article .item a{
    font-family: 'Camila-Medium', serif;
    display: inline-block;
    background-color: rgba(255,255,255,0.8);
    position: absolute;
    padding: 13px 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.3rem;
    width: 75%;
    text-align: center;
}

.all-articles__custom .item__article__only h2{
    text-align: center;
    font-size: 1.3em;
}

.all-articles__custom .item__article__only button{
    margin: 0 auto;
    display: block;
    position: initial;
    font-size: 15px;
}

.all-articles__custom .item__article__only .imgContainer{
    width: 100%;
    height: 0;
    padding-top: 65%;
    background-size: cover;
    background-position: center;
}

@media (max-width: 768px){
    .all-articles__custom .item__article__only .imgContainer{
        height: 47vw;
    }
    
    .cat__custom__article .item{
        padding-top: 45%;
        margin-bottom: 20px;
    }
}

/*** Single Article page ***/

.row-article-layout .cat__custom__article{
    padding: 0;
}

.row-article-layout .cat__custom__article .item{
    width: 100%;
    padding-top: 30%;
    margin-bottom: 20px;
}

.custom__title_article h1{
    margin-left: 15px;
    margin-bottom: 40px;
    font-family: "Camila-Semibold", serif;
    color: #C3B37B;
}

.all-articles__custom {
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
}

/*** Footer ***/

footer .menu_ctn{
    display: flex;
    width: 75%;
}

footer .logo_ctn{
    padding: 0 20px;
}

footer .item-col{
    width: calc(100% / 6);
    padding-right: 20px !important;
}

@media (max-width: 1439px){
    footer .item-col{
        width: calc(100% / 3);
        margin-bottom: 30px;
    }
}

footer ul li{
    list-style: none;
}

footer .top_level{
    font-family: "Atlan-Bold";
    text-transform: uppercase;
    font-size: 14px;
}

footer .sub-menu{
    padding: 0;
}

footer .sub-menu li a{
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: #4A4A4A;
}

footer .rightMenuLinks{
    margin-bottom: 15px;
}
footer .rightMenuLinks a{
    display: block;
    text-transform: uppercase;
}

footer .bottom-ft{
    background: #394a27;
    color: white;
    padding: 25px 0 10px;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.1rem;
}

footer .bottom-ft p{
    margin: 0;
}

footer .bottom-ft .helv_rom{
    margin-right: 10px;
    color: white;
}

@media (max-width: 990px){
    footer .menu_ctn{
        display: none;
    }

    footer{
        height: unset !important;
    }
    footer .top-ft{
        height: unset !important;
    }
    footer .rgt{
        text-align: center;
        font-size: 1.2rem;
    }
}

/*** Error pages 404 & 500 ***/

.BlockImageRight.error-page{
    text-align: center;
    background-repeat: no-repeat;
    background-image: url(../images/Theme/feuille-left.png),url(../images/Theme/feuille-right.png) !important;
    background-position: top left,top right;
    background-size: auto 100%,auto 100%;
}

.BlockImageRight.error-page .colLeft{
    width: 100% !important;
}

.BlockImageRight.error-page .colRight{
    display: none;
}

.BlockImageRight.error-page h2{
    font-family: "Camila-Semibold", serif;
    color: #4C5A1A !important;
    font-size: calc(30px + 17vw);
}

.BlockImageRight.error-page p {
    font-family: "Camila-Semibold", serif;
    color: #4C5A1A !important;
    text-align: center;
    font-size: calc(13px + 2vw);
    max-width: 500px;
    margin: auto;
    margin-top: 0 !important;
}

.BlockImageRight.error-page .btn{
    border: 4px double #C3B37B;
    padding: 0 !important;
    width: 200px;
}

.BlockImageRight.error-page .btn span{
    padding: 6px;
    background-color: #C3B37B;
    display: block;
}

.BlockImageRight.error-page .btn i{
    display: none !important;
}

.BlockImageRight.error-page .btn:hover{
    background-color: initial;
}

.BlockImageRight.error-page .btn:hover span{
    left: 0;
    color: #394A27;
}

@media (max-width: 768px){
    .BlockImageRight.error-page p {
        background-color: #ffffffc2;
    }
}

/*** Forms ***/

form[method="post"]{
    max-width: 800px;
    padding: 40px 20px;
    margin: auto;
}

form[method="post"] .form-control:not(.submit){
    border: 1px solid #DFCDA3;
    background-color: white;
    padding: 10px 20px;
    line-height: 20px;
    font-size: 16px;
}

form[method="post"] label{
    margin-top: 20px;
    font-family: "Camila-Semibold";
    font-size: 1.3em;
    display: block;
}
form[method="post"] .invalid{
    color: red;
}

form[method="post"] fieldset{
    margin-top: 50px;
}

form[method="post"] button[type="submit"]{
    background-color: #C3B37B;
    border: none;
    color: white;
    padding: 10px 30px;
    font-size: 1.3em;
}

form[method="post"] button[type="submit"]:hover{
    background-color: #4C5A1A;
}
