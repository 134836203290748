.go-to-top {
    background-color: rgba(255,255,255,0.8) !important;
    border-radius: 100%;
    transition: all ease-in 0.3s;
}

.go-to-top i{
    color: #A5C03F;
    font-size: 32px;
    font-weight: bold;
}
