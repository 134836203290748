.ProductBannerGallery {
    font-family: "Poppins", Helvetica, Arial, sans-serif;
}

.ProductBannerGallery .imgContainer {
    width: 100%;
    height: 720px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background-color: #d2d2d2;
    /*transition: all .3s ease-in-out;*/
}

.ProductBannerGallery .thumbnailsContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
}

.ProductBannerGallery .thumb {
    width: 50px;
    height: 50px;
    border: 1px solid white;
    background-size: cover;
    background-position: center;
    margin-bottom: 10px;
    cursor: pointer;
    opacity: .6;
}

.ProductBannerGallery .thumb:hover,
.ProductBannerGallery .thumb.active {
    opacity: 1;
}

.ProductBannerGallery .textContainer {
    position: absolute;
    width: 350px;
    right: 9%;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255,255,255,.8);
    padding: 29px 45px 34px 45px;
    text-align: center;
}

.ProductBannerGallery .textContainer h1 {
    margin-top: 0;
}

.ProductBannerGallery .textContainer .star-rating {
    float: none;
    display: inline-block;
    margin-top: 20px;
} 

 .ProductBannerGallery .textContainer .link{
    display: block;
    padding: 8px 20px;
    background-color: #9fce4e;
    margin-top: 20px;
    color: white;
    font-size: 17px;
} 

@media (max-width: 899px) {
    .ProductBannerGallery .imgContainer {
        height: 100vw;
        background-attachment: scroll;
    }
    .ProductBannerGallery .thumbnailsContainer {
        display: none;
    }
    .ProductBannerGallery .textContainer {
        position: initial;
        width: 100%;
        transform: none;
        
    }
}
