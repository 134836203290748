/**
 Table of Contents:

1. RESET
2. DEFAULT CLASSES
3. ELEMENTS
  3.1.  MENUS
  3.2.  HEADERS
  3.3.  BREADCRUMBS
  3.4.  PAGINATION
  3.5.  BUTTONS
  3.6.  CAROUSEL
  3.7.  CAROUSELFRED
  3.8.  TESTIMONIALS
  3.9.  COUNTER
  3.10. COUNTDOWN TIMER
  3.11. INSTAGRAM
  3.12. TYPED TEXT
  3.13. PIE CHART
  3.14. PRICING TABLE
  3.15. TEAM MEMBER
  3.16. FOOTER
  3.17. BLOG POST
  3.18. SIDEBAR
  3.19. CONTACT FORM
  3.20. ACCORDION
  3.21. MESSAGE BOX
  3.22. TEXT BOX
  3.23. TEXT BLOCK
  3.24. TABS
  3.25. CALL TO ACTION
  3.26. PORTFOLIO
  3.27. BLOCK ICON
  3.28. PROGRESSBARS
4. COMMERCE
  4.1.  GENERAL
  4.2.  SINGLE PRODUCT
  4.3.  CART
  4.4.  OTHER

*
* -----------------------------------------------------------------------------
*/

/* --------------------------------------------------------------------- */
/* 1. RESET
/* --------------------------------------------------------------------- */

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0;
    text-rendering: optimizelegibility;
    letter-spacing: 0.5px;
    word-wrap: break-word;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden
}

@media (max-width:900px) {
    body {
        overflow-x: hidden
    }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline
}

audio:not([controls]) {
    display: none;
    height: 0
}

[hidden],
template {
    display: none
}

a {
    background: transparent;
    -webkit-transition: background-color 0.3s, border-color 0.3s, text-color 0.3s;
    -o-transition: background-color 0.3s, border-color 0.3s, text-color 0.3s;
    transition: background-color 0.3s, border-color 0.3s, text-color 0.3s
}

a:active,
a:hover {
    outline: 0
}

abbr[title] {
    border-bottom: 1px dotted
}

b,
strong {
    font-weight: bold
}

dfn {
    font-style: italic
}

h1 {
    font-size: 2em;
    margin: 0.67em 0
}

mark {
    background: #ff0;
    color: #000
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -0.5em
}

sub {
    bottom: -0.25em
}

img {
    border: 0
}

svg:not(:root) {
    overflow: hidden
}

figure {
    margin: 1em 2.857142857142857em
}

hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0
}

pre {
    overflow: auto
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0
}

button {
    overflow: visible
}

button,
select {
    text-transform: none
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer
}

button[disabled],
html input[disabled] {
    cursor: default
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0
}

input {
    line-height: normal
}

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto
}

input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none
}

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 .14285714285714285em;
    padding: 0.35em 0.625em 0.75em
}

legend {
    border: 0;
    padding: 0
}

textarea {
    overflow: auto
}

optgroup {
    font-weight: bold
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

.main-content table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 2em
}

.main-content table>thead>tr>th,
.main-content table>tbody>tr>th,
.main-content table>tfoot>tr>th,
.main-content table>thead>tr>td,
.main-content table>tbody>tr>td,
.main-content table>tfoot>tr>td {
    padding: .7142857142857143em .7142857142857143em;
    line-height: 1.6;
    vertical-align: middle;
    border-top: 1px solid #e5e5e5
}

.main-content table>thead>tr>th {
    text-transform: uppercase;
    font-weight: 600
}

.main-content table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 0 solid #e5e5e5
}

.main-content table>caption+thead>tr:first-child>th,
.main-content table>colgroup+thead>tr:first-child>th,
.main-content table>thead:first-child>tr:first-child>th,
.main-content table>caption+thead>tr:first-child>td,
.main-content table>colgroup+thead>tr:first-child>td,
.main-content table>thead:first-child>tr:first-child>td {
    border-top: 0
}

.main-content table>tbody+tbody {
    border-top: 1px solid #e5e5e5
}

td,
th {
    padding: 0
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

html {
    font-size: 14px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

body {
    font-family: "Atlan-Regular", Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 1.6;
    color: #444;
    background: #fff;
    font-style: normal;
    font-weight: 300
}

input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

a {
    color: #333;
    text-decoration: none
}

a:hover,
a:focus {
    color: initial;
    text-decoration: none
}

a:focus {
    outline: none
}

figure {
    margin: 0
}

img {
    vertical-align: middle;
    max-width: 100%;
    height: auto
}

hr {
    margin-top: 2em;
    margin-bottom: 2em;
    border: 0;
    border-top: 1px solid #aaa
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto
}

code,
kbd,
pre,
samp {
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace
}

code {
    padding: .14285714285714285em .2857142857142857em;
    font-size: 90%;
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 0
}

kbd {
    padding: .14285714285714285em .2857142857142857em;
    font-size: 90%;
    color: #fff;
    background-color: #333;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25)
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    box-shadow: none
}

pre {
    display: block;
    padding: .5em;
    margin: 0 0 1em;
    font-size: 12px;
    line-height: 1.6;
    word-break: break-all;
    word-wrap: break-word;
    color: #333;
    background-color: #f3f3f3;
    border: 1px solid #e1e1e1;
    border-radius: 0
}

pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0
}

/* --------------------------------------------------------------------- */
/* 2. COMMON CLASSES
/* --------------------------------------------------------------------- */

.pre-scrollable {
    max-height: 24.285714285714285em;
    overflow-y: scroll
}

/*.fade {*/
/*    opacity: 0;*/
/*    -webkit-transition: opacity .15s linear;*/
/*    -o-transition: opacity .15s linear;*/
/*    transition: opacity .15s linear*/
/*}*/

.fade.in {
    opacity: 1
}

.collapse {
    display: none
}

.collapse.in {
    display: block
}

tr.collapse.in {
    display: table-row
}

tbody.collapse.in {
    display: table-row-group
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height .35s ease;
    -o-transition: height .35s ease;
    transition: height .35s ease
}

.linear {
    transition-timing-function: cubic-bezier(.25, .25, .75, .75);
    -webkit-transition-timing-function: cubic-bezier(.25, .25, .75, .75)
}

.ease {
    transition-timing-function: cubic-bezier(.25, .1, .25, 1);
    -webkit-transition-timing-function: cubic-bezier(.25, .1, .25, 1)
}

.easeIn {
    transition-timing-function: cubic-bezier(.42, 0, 1, 1);
    -webkit-transition-timing-function: cubic-bezier(.42, 0, 1, 1)
}

.easeOut {
    transition-timing-function: cubic-bezier(0, 0, .58, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 0, .58, 1)
}

.easeInOut {
    transition-timing-function: cubic-bezier(.42, 0, .58, 1);
    -webkit-transition-timing-function: cubic-bezier(.42, 0, .58, 1)
}

.easeInQuad {
    transition-timing-function: cubic-bezier(.55, .085, .68, .53);
    -webkit-transition-timing-function: cubic-bezier(.55, .085, .68, .53)
}

.easeInCubic {
    transition-timing-function: cubic-bezier(.55, .055, .675, .19);
    -webkit-transition-timing-function: cubic-bezier(.55, .055, .675, .19)
}

.easeInQuart {
    transition-timing-function: cubic-bezier(.895, .03, .685, .22);
    -webkit-transition-timing-function: cubic-bezier(.895, .03, .685, .22)
}

.easeInQuint {
    transition-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transition-timing-function: cubic-bezier(.755, .05, .855, .06)
}

.easeInSine {
    transition-timing-function: cubic-bezier(.47, 0, .745, .715);
    -webkit-transition-timing-function: cubic-bezier(.47, 0, .745, .715)
}

.easeInExpo {
    transition-timing-function: cubic-bezier(.95, .05, .795, .035);
    -webkit-transition-timing-function: cubic-bezier(.95, .05, .795, .035)
}

.easeInCirc {
    transition-timing-function: cubic-bezier(.6, .04, .98, .335);
    -webkit-transition-timing-function: cubic-bezier(.6, .04, .98, .335)
}

.easeInBack {
    transition-timing-function: cubic-bezier(.6, -0.28, .735, .045);
    -webkit-transition-timing-function: cubic-bezier(.6, -0.28, .735, .045)
}

.easeOutQuad {
    transition-timing-function: cubic-bezier(.25, .46, .45, .94);
    -webkit-transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

.easeOutCubic {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1)
}

.easeOutQuart {
    transition-timing-function: cubic-bezier(.165, .84, .44, 1);
    -webkit-transition-timing-function: cubic-bezier(.165, .84, .44, 1)
}

.easeOutQuint {
    transition-timing-function: cubic-bezier(.23, 1, .32, 1);
    -webkit-transition-timing-function: cubic-bezier(.23, 1, .32, 1)
}

.easeOutSine {
    transition-timing-function: cubic-bezier(.39, .575, .565, 1);
    -webkit-transition-timing-function: cubic-bezier(.39, .575, .565, 1)
}

.easeOutExpo {
    transition-timing-function: cubic-bezier(.19, 1, .22, 1);
    -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1)
}

.easeOutCirc {
    transition-timing-function: cubic-bezier(.075, .82, .165, 1);
    -webkit-transition-timing-function: cubic-bezier(.075, .82, .165, 1)
}

.easeOutBack {
    transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
    -webkit-transition-timing-function: cubic-bezier(.175, .885, .32, 1.275)
}

.easeInOutQuad {
    transition-timing-function: cubic-bezier(.455, .03, .515, .955);
    -webkit-transition-timing-function: cubic-bezier(.455, .03, .515, .955)
}

.easeInOutCubic {
    transition-timing-function: cubic-bezier(.645, .045, .355, 1);
    -webkit-transition-timing-function: cubic-bezier(.645, .045, .355, 1)
}

.easeInOutQuart {
    transition-timing-function: cubic-bezier(.77, 0, .175, 1);
    -webkit-transition-timing-function: cubic-bezier(.77, 0, .175, 1)
}

.easeInOutQuint {
    transition-timing-function: cubic-bezier(.86, 0, .07, 1);
    -webkit-transition-timing-function: cubic-bezier(.86, 0, .07, 1)
}

.easeInOutSine {
    transition-timing-function: cubic-bezier(.445, .05, .55, .95);
    -webkit-transition-timing-function: cubic-bezier(.445, .05, .55, .95)
}

.easeInOutExpo {
    transition-timing-function: cubic-bezier(1, 0, 0, 1);
    -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1)
}

.easeInOutCirc {
    transition-timing-function: cubic-bezier(.785, .135, .15, .86);
    -webkit-transition-timing-function: cubic-bezier(.785, .135, .15, .86)
}

.easeInOutBack {
    transition-timing-function: cubic-bezier(.68, -0.55, .265, 1.55);
    -webkit-transition-timing-function: cubic-bezier(.68, -0.55, .265, 1.55)
}

@-webkit-keyframes flash {
    0%,
    50%,
    100% {
        opacity: 1
    }
    25%,
    75% {
        opacity: 0
    }
}

@-moz-keyframes flash {
    0%,
    50%,
    100% {
        opacity: 1
    }
    25%,
    75% {
        opacity: 0
    }
}

@-o-keyframes flash {
    0%,
    50%,
    100% {
        opacity: 1
    }
    25%,
    75% {
        opacity: 0
    }
}

@keyframes flash {
    0%,
    50%,
    100% {
        opacity: 1
    }
    25%,
    75% {
        opacity: 0
    }
}

@-webkit-keyframes shake {
    0%,
    100% {
        -webkit-transform: translateX(0)
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translateX(-10px)
    }
    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translateX(10px)
    }
}

@-moz-keyframes shake {
    0%,
    100% {
        -moz-transform: translateX(0)
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        -moz-transform: translateX(-10px)
    }
    20%,
    40%,
    60%,
    80% {
        -moz-transform: translateX(10px)
    }
}

@-o-keyframes shake {
    0%,
    100% {
        -o-transform: translateX(0)
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        -o-transform: translateX(-10px)
    }
    20%,
    40%,
    60%,
    80% {
        -o-transform: translateX(10px)
    }
}

@keyframes shake {
    0%,
    100% {
        transform: translateX(0)
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-10px)
    }
    20%,
    40%,
    60%,
    80% {
        transform: translateX(10px)
    }
}

@-webkit-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0)
    }
    40% {
        -webkit-transform: translateY(-30px)
    }
    60% {
        -webkit-transform: translateY(-15px)
    }
}

@-moz-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -moz-transform: translateY(0)
    }
    40% {
        -moz-transform: translateY(-30px)
    }
    60% {
        -moz-transform: translateY(-15px)
    }
}

@-o-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -o-transform: translateY(0)
    }
    40% {
        -o-transform: translateY(-30px)
    }
    60% {
        -o-transform: translateY(-15px)
    }
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0)
    }
    40% {
        transform: translateY(-30px)
    }
    60% {
        transform: translateY(-15px)
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0)
    }
}

@-moz-keyframes fadeInUp {
    0% {
        opacity: 0;
        -moz-transform: translateY(20px)
    }
    100% {
        opacity: 1;
        -moz-transform: translateY(0)
    }
}

@-o-keyframes fadeInUp {
    0% {
        opacity: 0;
        -o-transform: translateY(20px)
    }
    100% {
        opacity: 1;
        -o-transform: translateY(0)
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0)
    }
}

@-moz-keyframes fadeInDown {
    0% {
        opacity: 0;
        -moz-transform: translateY(-20px)
    }
    100% {
        opacity: 1;
        -moz-transform: translateY(0)
    }
}

@-o-keyframes fadeInDown {
    0% {
        opacity: 0;
        -o-transform: translateY(-20px)
    }
    100% {
        opacity: 1;
        -o-transform: translateY(0)
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0)
    }
}

@-moz-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -moz-transform: translateX(-20px)
    }
    100% {
        opacity: 1;
        -moz-transform: translateX(0)
    }
}

@-o-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -o-transform: translateX(-20px)
    }
    100% {
        opacity: 1;
        -o-transform: translateX(0)
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-20px)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0)
    }
}

@-moz-keyframes fadeInRight {
    0% {
        opacity: 0;
        -moz-transform: translateX(20px)
    }
    100% {
        opacity: 1;
        -moz-transform: translateX(0)
    }
}

@-o-keyframes fadeInRight {
    0% {
        opacity: 0;
        -o-transform: translateX(20px)
    }
    100% {
        opacity: 1;
        -o-transform: translateX(0)
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(20px)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

@-webkit-keyframes fadeInUpBig {
    0% {
        opacity: 0;
        -webkit-transform: translateY(2000px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0)
    }
}

@-moz-keyframes fadeInUpBig {
    0% {
        opacity: 0;
        -moz-transform: translateY(2000px)
    }
    100% {
        opacity: 1;
        -moz-transform: translateY(0)
    }
}

@-o-keyframes fadeInUpBig {
    0% {
        opacity: 0;
        -o-transform: translateY(2000px)
    }
    100% {
        opacity: 1;
        -o-transform: translateY(0)
    }
}

@keyframes fadeInUpBig {
    0% {
        opacity: 0;
        transform: translateY(2000px)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@-webkit-keyframes fadeInDownBig {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0)
    }
}

@-moz-keyframes fadeInDownBig {
    0% {
        opacity: 0;
        -moz-transform: translateY(-2000px)
    }
    100% {
        opacity: 1;
        -moz-transform: translateY(0)
    }
}

@-o-keyframes fadeInDownBig {
    0% {
        opacity: 0;
        -o-transform: translateY(-2000px)
    }
    100% {
        opacity: 1;
        -o-transform: translateY(0)
    }
}

@keyframes fadeInDownBig {
    0% {
        opacity: 0;
        transform: translateY(-2000px)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@-webkit-keyframes fadeInLeftBig {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0)
    }
}

@-moz-keyframes fadeInLeftBig {
    0% {
        opacity: 0;
        -moz-transform: translateX(-2000px)
    }
    100% {
        opacity: 1;
        -moz-transform: translateX(0)
    }
}

@-o-keyframes fadeInLeftBig {
    0% {
        opacity: 0;
        -o-transform: translateX(-2000px)
    }
    100% {
        opacity: 1;
        -o-transform: translateX(0)
    }
}

@keyframes fadeInLeftBig {
    0% {
        opacity: 0;
        transform: translateX(-2000px)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

@-webkit-keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0)
    }
}

@-moz-keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -moz-transform: translateX(2000px)
    }
    100% {
        opacity: 1;
        -moz-transform: translateX(0)
    }
}

@-o-keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -o-transform: translateX(2000px)
    }
    100% {
        opacity: 1;
        -o-transform: translateX(0)
    }
}

@keyframes fadeInRightBig {
    0% {
        opacity: 0;
        transform: translateX(2000px)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@-webkit-keyframes fadeOutUp {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(-20px)
    }
}

@-moz-keyframes fadeOutUp {
    0% {
        opacity: 1;
        -moz-transform: translateY(0)
    }
    100% {
        opacity: 0;
        -moz-transform: translateY(-20px)
    }
}

@-o-keyframes fadeOutUp {
    0% {
        opacity: 1;
        -o-transform: translateY(0)
    }
    100% {
        opacity: 0;
        -o-transform: translateY(-20px)
    }
}

@keyframes fadeOutUp {
    0% {
        opacity: 1;
        transform: translateY(0)
    }
    100% {
        opacity: 0;
        transform: translateY(-20px)
    }
}

@-webkit-keyframes fadeOutDown {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(20px)
    }
}

@-moz-keyframes fadeOutDown {
    0% {
        opacity: 1;
        -moz-transform: translateY(0)
    }
    100% {
        opacity: 0;
        -moz-transform: translateY(20px)
    }
}

@-o-keyframes fadeOutDown {
    0% {
        opacity: 1;
        -o-transform: translateY(0)
    }
    100% {
        opacity: 0;
        -o-transform: translateY(20px)
    }
}

@keyframes fadeOutDown {
    0% {
        opacity: 1;
        transform: translateY(0)
    }
    100% {
        opacity: 0;
        transform: translateY(20px)
    }
}

@-webkit-keyframes fadeOutLeft {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(-20px)
    }
}

@-moz-keyframes fadeOutLeft {
    0% {
        opacity: 1;
        -moz-transform: translateX(0)
    }
    100% {
        opacity: 0;
        -moz-transform: translateX(-20px)
    }
}

@-o-keyframes fadeOutLeft {
    0% {
        opacity: 1;
        -o-transform: translateX(0)
    }
    100% {
        opacity: 0;
        -o-transform: translateX(-20px)
    }
}

@keyframes fadeOutLeft {
    0% {
        opacity: 1;
        transform: translateX(0)
    }
    100% {
        opacity: 0;
        transform: translateX(-20px)
    }
}

@-webkit-keyframes fadeOutRight {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(20px)
    }
}

@-moz-keyframes fadeOutRight {
    0% {
        opacity: 1;
        -moz-transform: translateX(0)
    }
    100% {
        opacity: 0;
        -moz-transform: translateX(20px)
    }
}

@-o-keyframes fadeOutRight {
    0% {
        opacity: 1;
        -o-transform: translateX(0)
    }
    100% {
        opacity: 0;
        -o-transform: translateX(20px)
    }
}

@keyframes fadeOutRight {
    0% {
        opacity: 1;
        transform: translateX(0)
    }
    100% {
        opacity: 0;
        transform: translateX(20px)
    }
}

@-webkit-keyframes fadeOutUpBig {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(-2000px)
    }
}

@-moz-keyframes fadeOutUpBig {
    0% {
        opacity: 1;
        -moz-transform: translateY(0)
    }
    100% {
        opacity: 0;
        -moz-transform: translateY(-2000px)
    }
}

@-o-keyframes fadeOutUpBig {
    0% {
        opacity: 1;
        -o-transform: translateY(0)
    }
    100% {
        opacity: 0;
        -o-transform: translateY(-2000px)
    }
}

@keyframes fadeOutUpBig {
    0% {
        opacity: 1;
        transform: translateY(0)
    }
    100% {
        opacity: 0;
        transform: translateY(-2000px)
    }
}

@-webkit-keyframes fadeOutDownBig {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px)
    }
}

@-moz-keyframes fadeOutDownBig {
    0% {
        opacity: 1;
        -moz-transform: translateY(0)
    }
    100% {
        opacity: 0;
        -moz-transform: translateY(2000px)
    }
}

@-o-keyframes fadeOutDownBig {
    0% {
        opacity: 1;
        -o-transform: translateY(0)
    }
    100% {
        opacity: 0;
        -o-transform: translateY(2000px)
    }
}

@keyframes fadeOutDownBig {
    0% {
        opacity: 1;
        transform: translateY(0)
    }
    100% {
        opacity: 0;
        transform: translateY(2000px)
    }
}

@-webkit-keyframes fadeOutLeftBig {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px)
    }
}

@-moz-keyframes fadeOutLeftBig {
    0% {
        opacity: 1;
        -moz-transform: translateX(0)
    }
    100% {
        opacity: 0;
        -moz-transform: translateX(-2000px)
    }
}

@-o-keyframes fadeOutLeftBig {
    0% {
        opacity: 1;
        -o-transform: translateX(0)
    }
    100% {
        opacity: 0;
        -o-transform: translateX(-2000px)
    }
}

@keyframes fadeOutLeftBig {
    0% {
        opacity: 1;
        transform: translateX(0)
    }
    100% {
        opacity: 0;
        transform: translateX(-2000px)
    }
}

@-webkit-keyframes fadeOutRightBig {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px)
    }
}

@-moz-keyframes fadeOutRightBig {
    0% {
        opacity: 1;
        -moz-transform: translateX(0)
    }
    100% {
        opacity: 0;
        -moz-transform: translateX(2000px)
    }
}

@-o-keyframes fadeOutRightBig {
    0% {
        opacity: 1;
        -o-transform: translateX(0)
    }
    100% {
        opacity: 0;
        -o-transform: translateX(2000px)
    }
}

@keyframes fadeOutRightBig {
    0% {
        opacity: 1;
        transform: translateX(0)
    }
    100% {
        opacity: 0;
        transform: translateX(2000px)
    }
}

@-webkit-keyframes slideInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px)
    }
    100% {
        -webkit-transform: translateY(0)
    }
}

@-moz-keyframes slideInDown {
    0% {
        opacity: 0;
        -moz-transform: translateY(-2000px)
    }
    100% {
        -moz-transform: translateY(0)
    }
}

@-o-keyframes slideInDown {
    0% {
        opacity: 0;
        -o-transform: translateY(-2000px)
    }
    100% {
        -o-transform: translateY(0)
    }
}

@keyframes slideInDown {
    0% {
        opacity: 0;
        transform: translateY(-2000px)
    }
    100% {
        transform: translateY(0)
    }
}

@-webkit-keyframes slideInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px)
    }
    100% {
        -webkit-transform: translateX(0)
    }
}

@-moz-keyframes slideInLeft {
    0% {
        opacity: 0;
        -moz-transform: translateX(-2000px)
    }
    100% {
        -moz-transform: translateX(0)
    }
}

@-o-keyframes slideInLeft {
    0% {
        opacity: 0;
        -o-transform: translateX(-2000px)
    }
    100% {
        -o-transform: translateX(0)
    }
}

@keyframes slideInLeft {
    0% {
        opacity: 0;
        transform: translateX(-2000px)
    }
    100% {
        transform: translateX(0)
    }
}

@-webkit-keyframes slideInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px)
    }
    100% {
        -webkit-transform: translateX(0)
    }
}

@-moz-keyframes slideInRight {
    0% {
        opacity: 0;
        -moz-transform: translateX(2000px)
    }
    100% {
        -moz-transform: translateX(0)
    }
}

@-o-keyframes slideInRight {
    0% {
        opacity: 0;
        -o-transform: translateX(2000px)
    }
    100% {
        -o-transform: translateX(0)
    }
}

@keyframes slideInRight {
    0% {
        opacity: 0;
        transform: translateX(2000px)
    }
    100% {
        transform: translateX(0)
    }
}

@-webkit-keyframes slideOutUp {
    0% {
        -webkit-transform: translateY(0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(-2000px)
    }
}

@-moz-keyframes slideOutUp {
    0% {
        -moz-transform: translateY(0)
    }
    100% {
        opacity: 0;
        -moz-transform: translateY(-2000px)
    }
}

@-o-keyframes slideOutUp {
    0% {
        -o-transform: translateY(0)
    }
    100% {
        opacity: 0;
        -o-transform: translateY(-2000px)
    }
}

@keyframes slideOutUp {
    0% {
        transform: translateY(0)
    }
    100% {
        opacity: 0;
        transform: translateY(-2000px)
    }
}

@-webkit-keyframes slideOutLeft {
    0% {
        -webkit-transform: translateX(0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px)
    }
}

@-moz-keyframes slideOutLeft {
    0% {
        -moz-transform: translateX(0)
    }
    100% {
        opacity: 0;
        -moz-transform: translateX(-2000px)
    }
}

@-o-keyframes slideOutLeft {
    0% {
        -o-transform: translateX(0)
    }
    100% {
        opacity: 0;
        -o-transform: translateX(-2000px)
    }
}

@keyframes slideOutLeft {
    0% {
        transform: translateX(0)
    }
    100% {
        opacity: 0;
        transform: translateX(-2000px)
    }
}

@-webkit-keyframes slideOutRight {
    0% {
        -webkit-transform: translateX(0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px)
    }
}

@-moz-keyframes slideOutRight {
    0% {
        -moz-transform: translateX(0)
    }
    100% {
        opacity: 0;
        -moz-transform: translateX(2000px)
    }
}

@-o-keyframes slideOutRight {
    0% {
        -o-transform: translateX(0)
    }
    100% {
        opacity: 0;
        -o-transform: translateX(2000px)
    }
}

@keyframes slideOutRight {
    0% {
        transform: translateX(0)
    }
    100% {
        opacity: 0;
        transform: translateX(2000px)
    }
}

@-webkit-keyframes bounceIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(.3)
    }
    50% {
        opacity: 1;
        -webkit-transform: scale(1.05)
    }
    70% {
        -webkit-transform: scale(.9)
    }
    100% {
        -webkit-transform: scale(1)
    }
}

@-moz-keyframes bounceIn {
    0% {
        opacity: 0;
        -moz-transform: scale(.3)
    }
    50% {
        opacity: 1;
        -moz-transform: scale(1.05)
    }
    70% {
        -moz-transform: scale(.9)
    }
    100% {
        -moz-transform: scale(1)
    }
}

@-o-keyframes bounceIn {
    0% {
        opacity: 0;
        -o-transform: scale(.3)
    }
    50% {
        opacity: 1;
        -o-transform: scale(1.05)
    }
    70% {
        -o-transform: scale(.9)
    }
    100% {
        -o-transform: scale(1)
    }
}

@keyframes bounceIn {
    0% {
        opacity: 0;
        transform: scale(.3)
    }
    50% {
        opacity: 1;
        transform: scale(1.05)
    }
    70% {
        transform: scale(.9)
    }
    100% {
        transform: scale(1)
    }
}

@-webkit-keyframes bounceInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(2000px)
    }
    60% {
        opacity: 1;
        -webkit-transform: translateY(-30px)
    }
    80% {
        -webkit-transform: translateY(10px)
    }
    100% {
        -webkit-transform: translateY(0)
    }
}

@-moz-keyframes bounceInUp {
    0% {
        opacity: 0;
        -moz-transform: translateY(2000px)
    }
    60% {
        opacity: 1;
        -moz-transform: translateY(-30px)
    }
    80% {
        -moz-transform: translateY(10px)
    }
    100% {
        -moz-transform: translateY(0)
    }
}

@-o-keyframes bounceInUp {
    0% {
        opacity: 0;
        -o-transform: translateY(2000px)
    }
    60% {
        opacity: 1;
        -o-transform: translateY(-30px)
    }
    80% {
        -o-transform: translateY(10px)
    }
    100% {
        -o-transform: translateY(0)
    }
}

@keyframes bounceInUp {
    0% {
        opacity: 0;
        transform: translateY(2000px)
    }
    60% {
        opacity: 1;
        transform: translateY(-30px)
    }
    80% {
        transform: translateY(10px)
    }
    100% {
        transform: translateY(0)
    }
}

@-webkit-keyframes bounceInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px)
    }
    60% {
        opacity: 1;
        -webkit-transform: translateY(30px)
    }
    80% {
        -webkit-transform: translateY(-10px)
    }
    100% {
        -webkit-transform: translateY(0)
    }
}

@-moz-keyframes bounceInDown {
    0% {
        opacity: 0;
        -moz-transform: translateY(-2000px)
    }
    60% {
        opacity: 1;
        -moz-transform: translateY(30px)
    }
    80% {
        -moz-transform: translateY(-10px)
    }
    100% {
        -moz-transform: translateY(0)
    }
}

@-o-keyframes bounceInDown {
    0% {
        opacity: 0;
        -o-transform: translateY(-2000px)
    }
    60% {
        opacity: 1;
        -o-transform: translateY(30px)
    }
    80% {
        -o-transform: translateY(-10px)
    }
    100% {
        -o-transform: translateY(0)
    }
}

@keyframes bounceInDown {
    0% {
        opacity: 0;
        transform: translateY(-2000px)
    }
    60% {
        opacity: 1;
        transform: translateY(30px)
    }
    80% {
        transform: translateY(-10px)
    }
    100% {
        transform: translateY(0)
    }
}

@-webkit-keyframes bounceInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px)
    }
    60% {
        opacity: 1;
        -webkit-transform: translateX(30px)
    }
    80% {
        -webkit-transform: translateX(-10px)
    }
    100% {
        -webkit-transform: translateX(0)
    }
}

@-moz-keyframes bounceInLeft {
    0% {
        opacity: 0;
        -moz-transform: translateX(-2000px)
    }
    60% {
        opacity: 1;
        -moz-transform: translateX(30px)
    }
    80% {
        -moz-transform: translateX(-10px)
    }
    100% {
        -moz-transform: translateX(0)
    }
}

@-o-keyframes bounceInLeft {
    0% {
        opacity: 0;
        -o-transform: translateX(-2000px)
    }
    60% {
        opacity: 1;
        -o-transform: translateX(30px)
    }
    80% {
        -o-transform: translateX(-10px)
    }
    100% {
        -o-transform: translateX(0)
    }
}

@keyframes bounceInLeft {
    0% {
        opacity: 0;
        transform: translateX(-2000px)
    }
    60% {
        opacity: 1;
        transform: translateX(30px)
    }
    80% {
        transform: translateX(-10px)
    }
    100% {
        transform: translateX(0)
    }
}

@-webkit-keyframes bounceInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px)
    }
    60% {
        opacity: 1;
        -webkit-transform: translateX(-30px)
    }
    80% {
        -webkit-transform: translateX(10px)
    }
    100% {
        -webkit-transform: translateX(0)
    }
}

@-moz-keyframes bounceInRight {
    0% {
        opacity: 0;
        -moz-transform: translateX(2000px)
    }
    60% {
        opacity: 1;
        -moz-transform: translateX(-30px)
    }
    80% {
        -moz-transform: translateX(10px)
    }
    100% {
        -moz-transform: translateX(0)
    }
}

@-o-keyframes bounceInRight {
    0% {
        opacity: 0;
        -o-transform: translateX(2000px)
    }
    60% {
        opacity: 1;
        -o-transform: translateX(-30px)
    }
    80% {
        -o-transform: translateX(10px)
    }
    100% {
        -o-transform: translateX(0)
    }
}

@keyframes bounceInRight {
    0% {
        opacity: 0;
        transform: translateX(2000px)
    }
    60% {
        opacity: 1;
        transform: translateX(-30px)
    }
    80% {
        transform: translateX(10px)
    }
    100% {
        transform: translateX(0)
    }
}

@-webkit-keyframes bounceOut {
    0% {
        -webkit-transform: scale(1)
    }
    25% {
        -webkit-transform: scale(.95)
    }
    50% {
        opacity: 1;
        -webkit-transform: scale(1.1)
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(.3)
    }
}

@-moz-keyframes bounceOut {
    0% {
        -moz-transform: scale(1)
    }
    25% {
        -moz-transform: scale(.95)
    }
    50% {
        opacity: 1;
        -moz-transform: scale(1.1)
    }
    100% {
        opacity: 0;
        -moz-transform: scale(.3)
    }
}

@-o-keyframes bounceOut {
    0% {
        -o-transform: scale(1)
    }
    25% {
        -o-transform: scale(.95)
    }
    50% {
        opacity: 1;
        -o-transform: scale(1.1)
    }
    100% {
        opacity: 0;
        -o-transform: scale(.3)
    }
}

@keyframes bounceOut {
    0% {
        transform: scale(1)
    }
    25% {
        transform: scale(.95)
    }
    50% {
        opacity: 1;
        transform: scale(1.1)
    }
    100% {
        opacity: 0;
        transform: scale(.3)
    }
}

@-webkit-keyframes bounceOutUp {
    0% {
        -webkit-transform: translateY(0)
    }
    20% {
        opacity: 1;
        -webkit-transform: translateY(20px)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(-2000px)
    }
}

@-moz-keyframes bounceOutUp {
    0% {
        -moz-transform: translateY(0)
    }
    20% {
        opacity: 1;
        -moz-transform: translateY(20px)
    }
    100% {
        opacity: 0;
        -moz-transform: translateY(-2000px)
    }
}

@-o-keyframes bounceOutUp {
    0% {
        -o-transform: translateY(0)
    }
    20% {
        opacity: 1;
        -o-transform: translateY(20px)
    }
    100% {
        opacity: 0;
        -o-transform: translateY(-2000px)
    }
}

@keyframes bounceOutUp {
    0% {
        transform: translateY(0)
    }
    20% {
        opacity: 1;
        transform: translateY(20px)
    }
    100% {
        opacity: 0;
        transform: translateY(-2000px)
    }
}

@-webkit-keyframes bounceOutDown {
    0% {
        -webkit-transform: translateY(0)
    }
    20% {
        opacity: 1;
        -webkit-transform: translateY(-20px)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px)
    }
}

@-moz-keyframes bounceOutDown {
    0% {
        -moz-transform: translateY(0)
    }
    20% {
        opacity: 1;
        -moz-transform: translateY(-20px)
    }
    100% {
        opacity: 0;
        -moz-transform: translateY(2000px)
    }
}

@-o-keyframes bounceOutDown {
    0% {
        -o-transform: translateY(0)
    }
    20% {
        opacity: 1;
        -o-transform: translateY(-20px)
    }
    100% {
        opacity: 0;
        -o-transform: translateY(2000px)
    }
}

@keyframes bounceOutDown {
    0% {
        transform: translateY(0)
    }
    20% {
        opacity: 1;
        transform: translateY(-20px)
    }
    100% {
        opacity: 0;
        transform: translateY(2000px)
    }
}

@-webkit-keyframes bounceOutLeft {
    0% {
        -webkit-transform: translateX(0)
    }
    20% {
        opacity: 1;
        -webkit-transform: translateX(20px)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px)
    }
}

@-moz-keyframes bounceOutLeft {
    0% {
        -moz-transform: translateX(0)
    }
    20% {
        opacity: 1;
        -moz-transform: translateX(20px)
    }
    100% {
        opacity: 0;
        -moz-transform: translateX(-2000px)
    }
}

@-o-keyframes bounceOutLeft {
    0% {
        -o-transform: translateX(0)
    }
    20% {
        opacity: 1;
        -o-transform: translateX(20px)
    }
    100% {
        opacity: 0;
        -o-transform: translateX(-2000px)
    }
}

@keyframes bounceOutLeft {
    0% {
        transform: translateX(0)
    }
    20% {
        opacity: 1;
        transform: translateX(20px)
    }
    100% {
        opacity: 0;
        transform: translateX(-2000px)
    }
}

@-webkit-keyframes bounceOutRight {
    0% {
        -webkit-transform: translateX(0)
    }
    20% {
        opacity: 1;
        -webkit-transform: translateX(-20px)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px)
    }
}

@-moz-keyframes bounceOutRight {
    0% {
        -moz-transform: translateX(0)
    }
    20% {
        opacity: 1;
        -moz-transform: translateX(-20px)
    }
    100% {
        opacity: 0;
        -moz-transform: translateX(2000px)
    }
}

@-o-keyframes bounceOutRight {
    0% {
        -o-transform: translateX(0)
    }
    20% {
        opacity: 1;
        -o-transform: translateX(-20px)
    }
    100% {
        opacity: 0;
        -o-transform: translateX(2000px)
    }
}

@keyframes bounceOutRight {
    0% {
        transform: translateX(0)
    }
    20% {
        opacity: 1;
        transform: translateX(-20px)
    }
    100% {
        opacity: 0;
        transform: translateX(2000px)
    }
}

@-webkit-keyframes lightSpeedIn {
    0% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        opacity: 0
    }
    60% {
        -webkit-transform: translateX(-20%) skewX(30deg);
        opacity: 1
    }
    80% {
        -webkit-transform: translateX(0) skewX(-15deg);
        opacity: 1
    }
    100% {
        -webkit-transform: translateX(0) skewX(0deg);
        opacity: 1
    }
}

@-moz-keyframes lightSpeedIn {
    0% {
        -moz-transform: translateX(100%) skewX(-30deg);
        opacity: 0
    }
    60% {
        -moz-transform: translateX(-20%) skewX(30deg);
        opacity: 1
    }
    80% {
        -moz-transform: translateX(0) skewX(-15deg);
        opacity: 1
    }
    100% {
        -moz-transform: translateX(0) skewX(0deg);
        opacity: 1
    }
}

@-o-keyframes lightSpeedIn {
    0% {
        -o-transform: translateX(100%) skewX(-30deg);
        opacity: 0
    }
    60% {
        -o-transform: translateX(-20%) skewX(30deg);
        opacity: 1
    }
    80% {
        -o-transform: translateX(0) skewX(-15deg);
        opacity: 1
    }
    100% {
        -o-transform: translateX(0) skewX(0deg);
        opacity: 1
    }
}

@keyframes lightSpeedIn {
    0% {
        transform: translateX(100%) skewX(-30deg);
        opacity: 0
    }
    60% {
        transform: translateX(-20%) skewX(30deg);
        opacity: 1
    }
    80% {
        transform: translateX(0) skewX(-15deg);
        opacity: 1
    }
    100% {
        transform: translateX(0) skewX(0deg);
        opacity: 1
    }
}

@-webkit-keyframes lightSpeedOut {
    0% {
        -webkit-transform: translateX(0) skewX(0deg);
        opacity: 1
    }
    100% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        opacity: 0
    }
}

@-moz-keyframes lightSpeedOut {
    0% {
        -moz-transform: translateX(0) skewX(0deg);
        opacity: 1
    }
    100% {
        -moz-transform: translateX(100%) skewX(-30deg);
        opacity: 0
    }
}

@-o-keyframes lightSpeedOut {
    0% {
        -o-transform: translateX(0) skewX(0deg);
        opacity: 1
    }
    100% {
        -o-transform: translateX(100%) skewX(-30deg);
        opacity: 0
    }
}

@keyframes lightSpeedOut {
    0% {
        transform: translateX(0) skewX(0deg);
        opacity: 1
    }
    100% {
        transform: translateX(100%) skewX(-30deg);
        opacity: 0
    }
}

@-webkit-keyframes appear {
    0% {
        -webkit-transform: scale(.5);
        opacity: .1
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 1
    }
}

@-moz-keyframes appear {
    0% {
        -moz-transform: scale(.5);
        opacity: .1
    }
    100% {
        -moz-transform: scale(1);
        opacity: 1
    }
}

@-o-keyframes appear {
    0% {
        -o-transform: scale(.5);
        opacity: .1
    }
    100% {
        -o-transform: scale(1);
        opacity: 1
    }
}

@keyframes appear {
    0% {
        transform: scale(.5);
        opacity: .1
    }
    100% {
        transform: scale(1);
        opacity: 1
    }
}

@media (min-width:768px) {
    .el-appear {
        opacity: .1;
        filter: alpha(opacity=10);
        -webkit-transform: scale(.5);
        -ms-transform: scale(.5);
        -o-transform: scale(.5);
        transform: scale(.5)
    }
    .el-appear.animate-appear {
        -webkit-animation: appear 1s 1 ease;
        -o-animation: appear 1s 1 ease;
        animation: appear 1s 1 ease;
        opacity: 1;
        filter: alpha(opacity=100);
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@-webkit-keyframes loadingFade {
    0% {
        opacity: 0
    }
    50% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@-moz-keyframes loadingFade {
    0% {
        opacity: 0
    }
    50% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@keyframes loadingFade {
    0% {
        opacity: 0;
        filter: alpha(opacity=0)
    }
    50% {
        opacity: 1;
        filter: alpha(opacity=100)
    }
    100% {
        opacity: 0;
        filter: alpha(opacity=0)
    }
}

.fade-loading {
    text-align: center
}

.fade-loading i {
    -webkit-animation: 1.5s ease 0s normal none infinite loadingFade;
    -o-animation: 1.5s ease 0s normal none infinite loadingFade;
    animation: 1.5s ease 0s normal none infinite loadingFade;
    background: none repeat scroll 0 0 #9fce4e;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin: 0 4px;
    opacity: 0;
    filter: alpha(opacity=0);
    width: 10px
}

.fade-loading i:nth-child(0) {
    -webkit-animation-delay: 0;
    animation-delay: 0
}

.fade-loading i:nth-child(1) {
    -webkit-animation-delay: .2s;
    animation-delay: .2s
}

.fade-loading i:nth-child(2) {
    -webkit-animation-delay: .4s;
    animation-delay: .4s
}

.fade-loading i:nth-child(3) {
    -webkit-animation-delay: .6s;
    animation-delay: .6s
}

.fade-loading i:nth-child(4) {
    -webkit-animation-delay: .8s;
    animation-delay: .8s
}

.spinner-loading {
    -webkit-animation: fa-spin 1s infinite linear;
    -o-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear;
    -webkit-animation-timing-function: steps(8, start);
    animation-timing-function: steps(8, start)
}

@keyframes loadmoreCube {
    0% {
        transform: translateX(-120px)
    }
    50% {
        transform: translateX(0) rotate(180deg) scale(2.2)
    }
    100% {
        transform: translateX(120px)
    }
}

@-o-keyframes loadmoreCube {
    0% {
        -o-transform: translateX(-120px)
    }
    50% {
        -o-transform: translateX(0) rotate(180deg) scale(2.2)
    }
    100% {
        -o-transform: translateX(120px)
    }
}

@-moz-keyframes loadmoreCube {
    0% {
        -moz-transform: translateX(-120px)
    }
    50% {
        -moz-transform: translateX(0) rotate(180deg) scale(2.2)
    }
    100% {
        -moz-transform: translateX(120px)
    }
}

@-webkit-keyframes loadmoreCube {
    0% {
        -webkit-transform: translateX(-120px)
    }
    50% {
        -webkit-transform: translateX(0) rotate(180deg) scale(2.2)
    }
    100% {
        -webkit-transform: translateX(120px)
    }
}

.loadmore-action {
    text-align: center;
    margin-top: 2em;
    margin-bottom: 2em
}

.loadmore-action .loadmore-loading {
    display: none
}

.loadmore-action .loadmore-loading span {
    -webkit-animation: 2.5s ease-in-out 0s normal none infinite loadmoreCube;
    -o-animation: 2.5s ease-in-out 0s normal none infinite loadmoreCube;
    animation: 2.5s ease-in-out 0s normal none infinite loadmoreCube;
    background: #9fce4e;
    height: 8px;
    width: 8px;
    display: inline-block
}

.loadmore-action .loadmore-loading span:nth-child(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s
}

.loadmore-action .loadmore-loading span:nth-child(2) {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s
}

.loadmore-action .loadmore-loading span:nth-child(3) {
    -webkit-animation-delay: -1s;
    animation-delay: -1s
}

.loadmore-action .loadmore-loading span:nth-child(4) {
    -webkit-animation-delay: -1.5s;
    animation-delay: -1.5s
}

.loadmore-action .loadmore-loading span:nth-child(5) {
    -webkit-animation-delay: -2s;
    animation-delay: -2s
}

.loadmore-action .btn-loadmore {
    font-weight: 600;
    text-transform: uppercase;
    background: none;
    display: table;
    margin: 0 auto;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0
}

.loadmore-action .btn-loadmore:after {
    content: "\f067";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.loadmore-action .btn-loadmore:after {
    margin-left: 5px
}

.loadmore-action .btn-loadmore:hover {
    color: #9fce4e
}

.animate-box.animated {
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -ms-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s
}

.animate-box.animated.hinge {
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -ms-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s
}

.animate-box.animated {
    visibility: hidden
}

.go.animated {
    visibility: visible
}

.go.flash {
    -webkit-animation-name: flash;
    animation-name: flash
}

.go.shake {
    -webkit-animation-name: shake;
    animation-name: shake
}

.go.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce
}

.go.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn
}

.go.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp
}

.go.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown
}

.go.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft
}

.go.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight
}

.go.fadeInUpBig {
    -webkit-animation-name: fadeInUpBig;
    animation-name: fadeInUpBig
}

.go.fadeInDownBig {
    -webkit-animation-name: fadeInDownBig;
    animation-name: fadeInDownBig
}

.go.fadeInLeftBig {
    -webkit-animation-name: fadeInLeftBig;
    animation-name: fadeInLeftBig
}

.go.fadeInRightBig {
    -webkit-animation-name: fadeInRightBig;
    animation-name: fadeInRightBig
}

.go.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut
}

.go.fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp
}

.go.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown
}

.go.fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft
}

.go.fadeOutRight {
    -webkit-animation-name: fadeOutRight;
    animation-name: fadeOutRight
}

.go.fadeOutUpBig {
    -webkit-animation-name: fadeOutUpBig;
    animation-name: fadeOutUpBig
}

.go.fadeOutDownBig {
    -webkit-animation-name: fadeOutDownBig;
    animation-name: fadeOutDownBig
}

.go.fadeOutLeftBig {
    -webkit-animation-name: fadeOutLeftBig;
    animation-name: fadeOutLeftBig
}

.go.fadeOutRightBig {
    -webkit-animation-name: fadeOutRightBig;
    animation-name: fadeOutRightBig
}

.go.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown
}

.go.slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft
}

.go.slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight
}

.go.slideOutUp {
    -webkit-animation-name: slideOutUp;
    animation-name: slideOutUp
}

.go.slideOutLeft {
    -webkit-animation-name: slideOutLeft;
    animation-name: slideOutLeft
}

.go.slideOutRight {
    -webkit-animation-name: slideOutRight;
    animation-name: slideOutRight
}

.go.bounceIn {
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn
}

.go.bounceInUp {
    -webkit-animation-name: bounceInUp;
    animation-name: bounceInUp
}

.go.bounceInDown {
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown
}

.go.bounceInLeft {
    -webkit-animation-name: bounceInLeft;
    animation-name: bounceInLeft
}

.go.bounceInRight {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight
}

.go.bounceOut {
    -webkit-animation-name: bounceOut;
    animation-name: bounceOut
}

.go.bounceOutUp {
    -webkit-animation-name: bounceOutUp;
    animation-name: bounceOutUp
}

.go.bounceOutDown {
    -webkit-animation-name: bounceOutDown;
    animation-name: bounceOutDown
}

.go.bounceOutLeft {
    -webkit-animation-name: bounceOutLeft;
    animation-name: bounceOutLeft
}

.go.bounceOutRight {
    -webkit-animation-name: bounceOutRight;
    animation-name: bounceOutRight
}

.go.lightSpeedIn {
    -webkit-animation-name: lightSpeedIn;
    animation-name: lightSpeedIn;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out
}

.go.lightSpeedOut {
    -webkit-animation-name: lightSpeedOut;
    animation-name: lightSpeedOut;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: "Camila-SemiboldIt", Helvetica, Arial, sans-serif;
    font-weight: 600;
    line-height: 1.2;
    /* color: #333 */
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
    font-weight: normal;
    line-height: 1.2;
    color: #999
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
    margin-top: 1.5rem;
    margin-bottom: 1rem
}

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
    font-size: 65%
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin-top: 1rem;
    margin-bottom: 1rem
}

h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
    font-size: 75%
}

h1,
.h1 {
    font-family: "Camila-SemiboldIt", Helvetica, Arial, sans-serif;
    font-size: 2.5714285714285716em;
    font-style: normal;
}

h2,
.h2 {
    font-family: "Camila-SemiboldIt", Helvetica, Arial, sans-serif;
    font-size: 2.142857142857143em;
    font-style: normal;
}

h3,
.h3 {
    font-family: "Camila-SemiboldIt", Helvetica, Arial, sans-serif;
    font-size: 1.7142857142857142em;
    font-style: normal;
}

h4,
.h4 {
    font-family: "Camila-SemiboldIt", Helvetica, Arial, sans-serif;
    font-size: 1.2857142857142858em;
    font-style: normal;
}

h5,
.h5 {
    font-family: "Camila-SemiboldIt", Helvetica, Arial, sans-serif;
    font-size: 1em;
    font-style: normal;
}

h6,
.h6 {
    font-family: "Camila-SemiboldIt", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-style: normal;
}

p {
    margin: 0 0 1em
}

.el-heading {
    margin-top: 0;
    font-size: 1.7142857142857142em;
    margin-bottom: 1rem
}

.lead {
    margin-bottom: 2em;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.4
}

@media (min-width:768px) {
    .lead {
        font-size: 19.5px
    }
}

.heading-bold-first-word,
.heading-typed {
    margin-top: 0;
    font-weight: lighter
}

.heading-bold-first-word>span {
    font-weight: bold
}

h2.heading-custom-font {
    font-size: 3em
}

.heading-typed .nth-typed {
    display: inline-block;
    min-width: 1px;
    min-height: 1px
}

small,
.small {
    font-size: 85%
}

cite {
    font-style: normal
}

mark,
.mark {
    background-color: rgba(255, 224, 199, 0.3);
    padding: .2em
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.text-center {
    text-align: center
}

.text-justify {
    text-align: justify
}

.text-nowrap {
    white-space: nowrap
}

.text-lowercase {
    text-transform: lowercase
}

.text-uppercase {
    text-transform: uppercase
}

.text-capitalize {
    text-transform: capitalize
}

.text-muted {
    color: #999
}

.text-primary {
    color: #9fce4e
}

/* a.text-primary:hover {
    color: #86b633
} */

.text-success {
    color: #a0ce4e
}

a.text-success:hover {
    color: #87b633
}

.text-info {
    color: #63b5fd
}

a.text-info:hover {
    color: #319dfc
}

.text-warning {
    color: #feab62
}

a.text-warning:hover {
    color: #fe902f
}

.text-danger {
    color: #fe252c
}

a.text-danger:hover {
    color: #ef0109
}

.font-italic {
    font-style: italic
}

.bg-primary {
    color: #fff;
    background-color: #9fce4e
}

a.bg-primary:hover {
    background-color: #86b633
}

.bg-success {
    background-color: rgba(204, 228, 158, 0.3)
}

a.bg-success:hover {
    background-color: rgba(183, 217, 118, 0.3)
}

.bg-info {
    background-color: rgba(200, 230, 254, 0.3)
}

a.bg-info:hover {
    background-color: rgba(149, 207, 254, 0.3)
}

.bg-warning {
    background-color: rgba(255, 224, 199, 0.3)
}

a.bg-warning:hover {
    background-color: rgba(254, 196, 149, 0.3)
}

.bg-danger {
    background-color: rgba(254, 139, 144, 0.3)
}

a.bg-danger:hover {
    background-color: rgba(254, 88, 96, 0.3)
}

.page-header {
    padding-bottom: 0;
    margin: 0 0 2em;
    border-bottom: 1px solid #e1e1e1;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    -webkit-background-attachment: fixed;
    background-repeat: no-repeat;
}

ul,
ol {
    margin-top: 0;
    margin-bottom: 1em
}

ul ul,
ol ul,
ul ol,
ol ol {
    margin-bottom: 0
}

dl {
    margin-top: 0;
    margin-bottom: 2em
}

dt,
dd {
    line-height: 1.6
}

dt {
    font-weight: bold
}

dd {
    margin-left: 0
}

abbr[title],
abbr[data-original-title] {
    cursor: help;
    border-bottom: 1px dotted #999
}

blockquote {
    padding: 0 0 0 1.4285714285714286em;
    margin: 2em 0 2em;
    font-size: 13px;
    border-left: 3px solid #e1e1e1;
    font-style: italic;
    font-weight: normal;
    position: relative
}

blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
    margin-bottom: 0
}

blockquote p {
    font-size: 1.1em
}

blockquote footer,
blockquote small,
blockquote .small {
    display: block;
    font-size: 80%;
    line-height: 1.6;
    color: #999;
    text-transform: uppercase
}

blockquote footer:before,
blockquote small:before,
blockquote .small:before {
    content: '\2014 \00A0'
}

blockquote:after {
    content: ""
}

address {
    margin-bottom: 2em;
    font-style: normal;
    line-height: 1.6
}

iframe,
embed,
object {
    max-height: 100%;
    max-width: 100%;
    border: 0
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px
}

@media (min-width:768px) {
    .container {
        width: 750px
    }
}

@media (min-width:992px) {
    .container {
        width: 970px
    }
}

@media (min-width:1300px) {
    .container {
        /*width: 1170px*/
        width: 1254px;
    }
}

#wrapper {
    -webkit-transition: transform .5s ease 0s;
    -o-transition: transform .5s ease 0s;
    transition: transform .5s ease 0s;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden
}

.full-wrap .container {
    width: 100%;
    max-width: 1280px
}

.wide-wrap {
    position: relative
}

.boxed-wrap {
    background: #fff;
    background-attachment: scroll;
    background-position: center center;
    background-repeat: repeat;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    margin: 0 auto;
    position: relative;
    z-index: 1;
	overflow-x: hidden;
}

@media (min-width:768px) {
    .boxed-wrap {
        width: 750px
    }
}

@media (min-width:992px) {
    .boxed-wrap {
        width: 970px
    }
}

@media (min-width:1200px) {
    .boxed-wrap {
        width: 1170px
    }
}

@media (min-width:768px) {
    .boxed-wrap .container {
        width: 720px
    }
}

@media (min-width:992px) {
    .boxed-wrap .container {
        width: 940px
    }
}

@media (min-width:1200px) {
    .boxed-wrap .container {
        width: 1140px
    }
}

.boxed-wrap .header-container .container {
    padding-left: 30px;
    padding-right: 30px
}

@media (min-width:768px) {
    .boxed-wrap .header-container .container {
        width: 750px
    }
}

@media (min-width:992px) {
    .boxed-wrap .header-container .container {
        width: 970px
    }
}

@media (min-width:1200px) {
    .boxed-wrap .header-container .container {
        width: 1170px
    }
}

@media (min-width:1200px) {
    .page-header-toggle-offcanvas-always-show .container {
        padding-left: 30px;
        padding-right: 30px
    }
}

.wrapper-container {
    position: relative;
    z-index: 10;
    background: #fff
}

.content-container {
    padding-top: 80px;
    padding-bottom: 80px;
    /*min-height: 500px;*/
    background: #fff;
    position: relative;
    z-index: 2
}

@media (max-width:767px) {
    .content-container {
        padding-top: 30px;
        padding-bottom: 30px
    }
}

.content-container.no-padding {
    padding-top: 0;
    padding-bottom: 0
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px
}

.container-full {
    padding-left: 0;
    padding-right: 0;
    overflow: hidden
}

.row {
    margin-left: -15px;
    margin-right: -15px
}

.row {
    position: relative
}

.row.row-custom-margin {
    z-index: 100
}

.main-sidebar>div:first-child .widget-title {
    margin-top: 0
}

@media (min-width:769px) {
    .page-layout-left-sidebar .main-wrap {
        float: right
    }
    .page-layout-border.page-layout-left-sidebar .content-container>.container>.row {
        background-attachment: scroll;
        background-image: url("../images/Theme/main-border.jpg");
        background-position: 25% top;
        background-repeat: repeat-y
    }
    .page-layout-border.page-layout-left-sidebar .main-wrap {
        padding-left: 30px
    }
    .page-layout-border.page-layout-left-sidebar .sidebar-wrap {
        padding-right: 30px
    }
    .page-layout-border.page-layout-right-sidebar .content-container>.container>.row {
        background-attachment: scroll;
        background-image: url("../images/Theme/main-border.jpg");
        background-position: 75% top;
        background-repeat: repeat-y
    }
    .page-layout-border.page-layout-right-sidebar .main-wrap {
        padding-right: 30px
    }
    .page-layout-border.page-layout-right-sidebar .sidebar-wrap {
        padding-left: 30px
    }
    .page-layout-border .content-container:not(.no-padding) {
        padding: 0
    }
    .page-layout-border .content-container:not(.no-padding) .main-wrap {
        padding-top: 80px;
        padding-bottom: 80px
    }
    .page-layout-border .content-container:not(.no-padding) .sidebar-wrap {
        padding-top: 80px;
        padding-bottom: 80px
    }
}

@media (max-width:991px) and (min-width:769px) {
    .container {
        width: 97%
    }
    .main-wrap {
        float: left;
        width: 66.66666666666666%
    }
    .col-md-12.main-wrap {
        width: inherit;
        float: none
    }
    .sidebar-wrap {
        float: left;
        width: 33.33333333333333%
    }
}

@media (max-width:767px) {
    .sidebar-wrap {
        border-top: 1px solid #e1e1e1;
        clear: both;
        margin-top: 60px;
        padding-top: 30px
    }
	.main-wrap {
		float: left;
    	margin-bottom: 30px;
	}
}

.column {
    position: relative
}

table {
    background-color: #fff
}

th {
    text-align: left
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 2em
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
    padding: .7142857142857143em .7142857142857143em;
    line-height: 1.6;
    vertical-align: middle;
    border-top: 1px solid #e5e5e5
}

.table>thead>tr>th {
    text-transform: uppercase;
    font-weight: 600
}

.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 0 solid #e5e5e5
}

.table>caption+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>th,
.table>thead:first-child>tr:first-child>th,
.table>caption+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>td,
.table>thead:first-child>tr:first-child>td {
    border-top: 0
}

.table>tbody+tbody {
    border-top: 1px solid #e5e5e5
}

.table-bordered {
    border: 1px solid #e5e5e5
}

.table-bordered>thead>tr>th,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>tbody>tr>td,
.table-bordered>tfoot>tr>td {
    border: 1px solid #e5e5e5
}

.table-bordered>thead>tr>th,
.table-bordered>thead>tr>td {
    border-bottom-width: 2px
}

table col[class*="col-"] {
    position: static;
    float: none;
    display: table-column
}

table td[class*="col-"],
table th[class*="col-"] {
    position: static;
    float: none;
    display: table-cell
}

::-webkit-input-placeholder {
    color: #444
}

:-moz-placeholder {
    color: #444
}

::-moz-placeholder {
    color: #444
}

:-ms-input-placeholder {
    color: #444
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 2em;
    line-height: inherit;
    color: #333;
    border: 0;
    border-bottom: 1px solid #e1e1e1
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px
}

input[type="search"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

input[type="radio"],
input[type="checkbox"] {
    margin: .2857142857142857em 0 0;
    margin-top: 1px \9;
    line-height: normal
}

input[type="file"] {
    display: block
}

input[type="range"] {
    display: block;
    width: 100%
}

select[multiple],
select[size] {
    height: auto
}

select {
    -webkit-appearance: select;
    -webkit-border-radius: 0px;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
    outline: none
}

output {
    display: block;
    padding-top: 1.5714285714285714rem;
    font-size: 13px;
    line-height: 1.6;
    color: #444
}

.form-control {
    display: block;
    width: 100%;
    height: 40px;
    padding: .5714285714285714rem 1rem;
    line-height: 1.6;
    color: #444;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e1e1e1;
    -webkit-border-radius: 0;
    border-radius: 0;
    outline: none;
    -webkit-transition: border-color 0.3s, box-shadow 0.3s;
    -o-transition: border-color 0.3s, box-shadow 0.3s;
    transition: border-color 0.3s, box-shadow 0.3s
}

.form-control:focus {
    border-color: #333;
    outline: none
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    cursor: not-allowed;
    background-color: #aaa;
    opacity: 1;
    filter: alpha(opacity=100)
}

textarea.form-control {
    height: auto
}

input[type="search"] {
    -webkit-appearance: none
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
    line-height: 40px;
    line-height: 1.6 \0
}

input[type="date"].input-sm,
input[type="time"].input-sm,
input[type="datetime-local"].input-sm,
input[type="month"].input-sm {
    line-height: 137.325%
}

input[type="date"].input-lg,
input[type="time"].input-lg,
input[type="datetime-local"].input-lg,
input[type="month"].input-lg {
    line-height: 4.842857142857143em
}

.form-group {
    margin-bottom: 15px
}

.radio,
.checkbox {
    position: relative;
    display: block;
    min-height: 2em;
    margin-top: .7142857142857143em;
    margin-bottom: .7142857142857143em
}

.radio label,
.checkbox label {
    padding-left: 1.4285714285714286em;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    position: absolute;
    margin-left: -1.4285714285714286em;
    margin-top: 4px \9
}

.dh-ie .radio input[type="radio"],
.dh-ie .radio-inline input[type="radio"],
.dh-ie .checkbox input[type="checkbox"],
.dh-ie .checkbox-inline input[type="checkbox"] {
    position: relative \9
}

.radio+.radio,
.checkbox+.checkbox {
    margin-top: -0.35714285714285715em
}

input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="checkbox"].disabled,
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"] {
    cursor: not-allowed
}

.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
    cursor: not-allowed
}

.input-sm {
    height: 137.325%;
    padding: .5rem .8571428571428571rem;
    font-size: 85%;
    line-height: 1.6;
    -webkit-border-radius: 0;
    border-radius: 0
}

select.input-sm {
    height: 137.325%;
    line-height: 137.325%
}

textarea.input-sm,
select[multiple].input-sm {
    height: auto
}

.input-lg {
    height: 4.842857142857143em;
    padding: .8571428571428571rem 2rem;
    font-size: 1.2857142857142858em;
    line-height: 2;
    -webkit-border-radius: 0;
    border-radius: 0
}

select.input-lg {
    height: 4.842857142857143em;
    line-height: 4.842857142857143em
}

textarea.input-lg,
select[multiple].input-lg {
    height: auto
}

.form-flat-radio,
.form-flat-checkbox {
    margin: 0;
    cursor: pointer;
    display: block;
    position: relative;
    padding: 0 0 0 30px
}

.dh-ie .form-flat-radio,
.dh-ie .form-flat-checkbox {
    padding: 0
}

.form-flat-radio input,
.form-flat-checkbox input {
    left: -9999px;
    position: absolute
}

.dh-ie .form-flat-radio input,
.dh-ie .form-flat-checkbox input {
    position: relative;
    left: auto
}

.form-flat-radio i,
.form-flat-checkbox i {
    background: none repeat scroll 0 0 #fff;
    border-style: solid;
    border-width: 1px;
    display: block;
    height: 18px;
    left: 0;
    outline: medium none;
    position: absolute;
    top: 5px;
    border-color: #e1e1e1;
    width: 18px;
    top: 50%;
    margin-top: -9px;
    color: #333
}

.dh-ie .form-flat-radio i,
.dh-ie .form-flat-checkbox i {
    display: none
}

.form-flat-radio i:after,
.form-flat-checkbox i:after {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity .1s ease 0s;
    -o-transition: opacity .1s ease 0s;
    transition: opacity .1s ease 0s
}

.form-flat-radio input:checked+i:after,
.form-flat-checkbox input:checked+i:after {
    opacity: 1;
    filter: alpha(opacity=100)
}

.form-flat-radio i {
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.form-flat-radio i:after {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    content: "";
    height: 8px;
    left: 4px;
    top: 4px;
    line-height: 1;
    width: 8px;
    position: absolute;
    background: #333
}

.form-flat-checkbox i:after {
    content: "\f00c";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.form-flat-checkbox i:after {
    font-size: 12px;
    height: 17px;
    left: -1px;
    text-align: center;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    margin-top: 2px;
    width: 17px;
    opacity: 0;
    filter: alpha(opacity=0);
    line-height: 1
}

.form-flat-select {
    position: relative;
    display: block
}

.form-flat-select i {
    height: 100%;
    line-height: 40px;
    padding: 0 1em;
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: none;
    color: #333
}

.dh-ie .form-flat-select i {
    display: none
}

.form-flat-select select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    padding: 0.5em 3em 0.5em 1.5em;
    padding-right: 2em \9;
    border: 1px solid #e1e1e1;
    outline: none;
    background: none;
    position: relative;
    z-index: 1;
    height: 40px;
    text-transform: uppercase;
    font-size: 85%
}

.form-flat-select select option {
    outline: none
}

.btn {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: .5714285714285714rem 2rem;
    font-size: 13px;
    line-height: 1.6;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    text-decoration: none
}

.btn i,
.btn span {
    z-index: 2;
    position: relative
}

.btn.btn-icon-left i {
    margin-right: 5px
}

.btn.btn-icon-right i {
    margin-left: 5px
}

.btn.btn-icon-right.btn-icon-slide-in {
    padding-left: 40px;
    padding-right: 40px;
    position: relative
}

.btn.btn-icon-right.btn-icon-slide-in span,
.btn.btn-icon-right.btn-icon-slide-in i {
    -webkit-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out
}

.btn.btn-icon-right.btn-icon-slide-in i {
    position: absolute;
    right: 40px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    opacity: 0;
    filter: alpha(opacity=0)
}

.btn.btn-icon-right.btn-icon-slide-in span {
    position: relative;
    left: 0
}

.btn.btn-icon-right.btn-icon-slide-in:hover span {
    left: -15px
}

.btn.btn-icon-right.btn-icon-slide-in:hover i {
    opacity: 1;
    filter: alpha(opacity=100);
    right: 25px
}

.btn.btn-round {
    -webkit-border-radius: 40px;
    border-radius: 40px
}

.btn-default {
    color: #444;
    background-color: #f3f3f3;
    border-color: #f3f3f3
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active {
    color: #444
}

.btn-default:active,
.btn-default.active {
    background-image: none
}

.btn-primary {
    color: #fff;
    background-color: #9fce4e;
    border-color: #9fce4e
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
    color: #fff
}

.btn-primary:active,
.btn-primary.active {
    background-image: none
}

.btn-primary:hover,
.btn-primary:focus {
    background: #000;
    border-color: #000
}

.btn-success {
    color: #fff;
    background-color: #a0ce4e;
    border-color: #a0ce4e
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active {
    color: #fff
}

.btn-success:active,
.btn-success.active {
    background-image: none
}

.btn-info {
    color: #fff;
    background-color: #63b5fd;
    border-color: #63b5fd
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active {
    color: #fff
}

.btn-info:active,
.btn-info.active {
    background-image: none
}

.btn-warning {
    color: #fff;
    background-color: #feab62;
    border-color: #feab62
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active {
    color: #fff
}

.btn-warning:active,
.btn-warning.active {
    background-image: none
}

.btn-danger {
    color: #fff;
    background-color: #fe252c;
    border-color: #fe252c
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active {
    color: #fff
}

.btn-danger:active,
.btn-danger.active {
    background-image: none
}

.btn-black {
    color: #fff;
    background-color: #333;
    border-color: #333
}

.btn-black:hover,
.btn-black:focus,
.btn-black:active,
.btn-black.active {
    color: #fff
}

.btn-black:active,
.btn-black.active {
    background-image: none
}

.btn-black:hover,
.btn-black:focus,
.btn-black:active,
.btn-black.active {
    background-color: #666;
    border-color: #666
}

.btn-white {
    color: #333;
    background-color: #fff;
    border-color: #fff
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active {
    color: #333
}

.btn-white:active,
.btn-white.active {
    background-image: none
}

.btn-outline {
    background: transparent;
    border: 2px solid #333;
    text-transform: uppercase;
    color: #333;
    position: relative;
    font-weight: 600
}

.btn-outline:hover,
.btn-outline:focus {
    color: #fff;
    border-color: #9fce4e;
    background: #9fce4e
}

.btn-primary-outline {
    background: transparent;
    border: 2px solid #333;
    border-color: #9fce4e;
    text-transform: uppercase;
    color: #9fce4e;
    position: relative;
    font-weight: 600
}

.btn-primary-outline:hover {
    color: #fff;
    border-color: #9fce4e;
    background: #9fce4e
}

.btn-success-outline {
    background: transparent;
    border: 2px solid #a0ce4e;
    text-transform: uppercase;
    color: #a0ce4e;
    position: relative;
    font-weight: 600
}

.btn-success-outline:hover {
    color: #fff;
    border-color: #a0ce4e;
    background: #a0ce4e
}

.btn-info-outline {
    background: transparent;
    border: 2px solid #63b5fd;
    text-transform: uppercase;
    color: #63b5fd;
    position: relative;
    font-weight: 600
}

.btn-info-outline:hover {
    color: #fff;
    border-color: #63b5fd;
    background: #63b5fd
}

.btn-warning-outline {
    background: transparent;
    border: 1px solid #feab62;
    text-transform: uppercase;
    color: #feab62;
    font-weight: 600;
    position: relative
}

.btn-warning-outline:hover {
    color: #fff;
    border-color: #feab62;
    background: #feab62
}

.btn-danger-outline {
    background: transparent;
    border: 2px solid #fe252c;
    text-transform: uppercase;
    color: #fe252c;
    font-weight: 600;
    position: relative
}

.btn-danger-outline:hover {
    color: #fff;
    border-color: #fe252c;
    background: #fe252c
}

.btn-white-outline {
    background: transparent;
    border: 2px solid #fff;
    text-transform: uppercase;
    color: #fff;
    position: relative;
    font-weight: 600
}

.btn-white-outline:hover {
    color: #fff;
    border-color: #9fce4e;
    background: #9fce4e
}

.btn-black-outline {
    background: transparent;
    font-weight: 600;
    border: 2px solid #333;
    text-transform: uppercase;
    color: #333;
    position: relative
}

.btn-black-outline:hover {
    color: #fff;
    border-color: #9fce4e;
    background: #9fce4e
}

.btn-lg {
    padding: .8571428571428571rem 2rem;
    font-size: 1.2857142857142858em;
    line-height: 1.6;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-border-radius: 0;
    border-radius: 0
}

.btn-sm {
    padding: .5rem .8571428571428571rem;
    font-size: 85%;
    line-height: 1.6;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-border-radius: 0;
    border-radius: 0
}

.btn-xs {
    padding: .21428571428571427rem .5rem;
    font-size: 85%;
    line-height: 1.6;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-border-radius: 0;
    border-radius: 0
}

.btn-align-center {
    display: table;
    margin-left: auto;
    margin-right: auto
}

@media (min-width:480px) {
    .btn-align-right {
        float: right
    }
}

.btn-uppercase {
    text-transform: uppercase
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: 5px
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
    width: 100%
}

a.go-to-top,
a.go-to-top:hover a.go-to-top:focus {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: -50px;
    opacity: 0;
    filter: alpha(opacity=0);
    right: 20px;
    -webkit-transition: all .2s ease-in-out 0s;
    -o-transition: all .2s ease-in-out 0s;
    transition: all .2s ease-in-out 0s;
    color: #fff;
    font-size: 24px;
    line-height: 40px;
    width: 40px;
    height: 40px;
    position: fixed;
    text-align: center;
    z-index: 100;
    visibility: hidden
}

a.go-to-top.on,
a.go-to-top:hover a.go-to-top:focus.on {
    opacity: .8;
    filter: alpha(opacity=80);
    visibility: visible;
    bottom: 20px
}

a.go-to-top.on:hover,
a.go-to-top:hover a.go-to-top:focus.on:hover {
    opacity: 1;
    filter: alpha(opacity=100)
}

.address {
    line-height: 2
}

.address li i {
    float: left;
    font-size: 1.1em;
    line-height: 1.7
}

.address li p {
    margin-left: 30px
}

.open-time {
    line-height: 2
}

.open-time li {
    overflow: hidden
}

.open-time li span {
    float: left
}

.open-time li span+span {
    float: right
}

.clear:before,
.clearfix:before,
.clear:after,
.clearfix:after,
.container:before,
.container:after,
.boxed-wrap:before,
.boxed-wrap:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after,
.navbar-wrap:before,
.navbar-wrap:after,
.primary-nav .dropdown-menu:before,
.primary-nav .dropdown-menu:after,
.paginate:before,
.paginate:after,
.modal-footer:before,
.modal-footer:after,
.pricing-table:before,
.pricing-table:after,
.searchform-result .searchform-result-list .search-entry:before,
.searchform-result .searchform-result-list .search-entry:after,
.posts .posts-wrap:before,
.posts .posts-wrap:after,
.hentry-wrap:before,
.hentry-wrap:after,
.author-info:before,
.author-info:after,
.comment-notes:before,
.comment-notes:after,
.widget_text:before,
.widget_text:after,
.widget_tag_cloud .tagcloud:before,
.widget_tag_cloud .tagcloud:after,
.widget_product_tag_cloud .tagcloud:before,
.widget_product_tag_cloud .tagcloud:after,
.posts-thumbnail-list>li:before,
.posts-thumbnail-list>li:after,
.masonry-filter:before,
.masonry-filter:after,
.masonry-filter .filter-action:before,
.masonry-filter .filter-action:after {
    content: " ";
    display: table
}

.clear:after,
.clearfix:after,
.container:after,
.boxed-wrap:after,
.container-fluid:after,
.row:after,
.nav:after,
.navbar:after,
.navbar-header:after,
.navbar-collapse:after,
.navbar-wrap:after,
.primary-nav .dropdown-menu:after,
.paginate:after,
.modal-footer:after,
.pricing-table:after,
.searchform-result .searchform-result-list .search-entry:after,
.posts .posts-wrap:after,
.hentry-wrap:after,
.author-info:after,
.comment-notes:after,
.widget_text:after,
.widget_tag_cloud .tagcloud:after,
.widget_product_tag_cloud .tagcloud:after,
.posts-thumbnail-list>li:after,
.masonry-filter:after,
.masonry-filter .filter-action:after {
    clear: both
}

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto
}

.pull-right {
    float: right !important
}

.pull-left {
    float: left !important
}

.transform-uppercase {
    text-transform: uppercase
}

.hide {
    display: none;
}

/*.show {*/
/*    display: block;*/
/*}*/

.invisible {
    visibility: hidden
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.no-padding {
    padding: 0
}

.no-margin {
    margin: 0
}

.hidden {
    display: none;
    visibility: hidden;
}

.affix {
    position: fixed;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.bypostauthor {
    margin: 0;
    padding: 0
}

#wpstats {
    display: none
}

.sticky {
    display: block
}

.alignleft {
    float: left;
    margin-right: 15px
}

.alignright {
    float: right;
    margin-left: 15px
}

.aligncenter {
    display: block;
    margin: 15px auto
}

.gallery-caption {
    max-width: 100%;
    padding: .35714285714285715em
}

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
    max-width: 100%;
    height: auto
}

.infinite-scroll-loading {
    text-align: center;
    margin-top: 2em;
    margin-bottom: 2em
}

[data-paginate="infinite_scroll"] .paginate,
[data-paginate="loadmore"] .paginate {
    display: none
}

.masonry-filter {
    margin-bottom: 30px;
    font-family: "Atlan-Semibold", Helvetica, Arial, sans-serif;
    display: table;
    margin-right: auto;
    margin-left: auto;
    width: 100%
}

.masonry-filter .filter-action {
    display: table-cell;
    vertical-align: middle;
    width: 80%;
    text-align: right
}

.masonry-filter .filter-action.filter-action-center {
    display: block;
    width: 100%;
    text-align: left;
    padding-bottom: 1em
}

.masonry-filter .filter-action.filter-action-center>ul {
    float: none;
    text-transform: uppercase;
    text-align: center
}

.masonry-filter .filter-action.filter-action-center>ul li {
    float: none;
    display: inline-block;
    position: relative;
    padding: 0
}

.masonry-filter .filter-action.filter-action-center>ul li:after {
    content: '';
    display: inline-block;
    right: 0;
    width: 2px;
    height: 15px;
    font-weight: bold;
    background: #999;
    top: 50%;
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    -o-transform: rotate(30deg);
    transform: rotate(30deg)
}

@media (max-width:767px) {
    .masonry-filter .filter-action.filter-action-center>ul li:after {
        display: none
    }
}

.masonry-filter .filter-action.filter-action-center>ul li:last-child:after {
    display: none
}

.masonry-filter .filter-action.filter-action-center>ul li a {
    border-bottom: none;
    font-weight: bold;
    color: #999;
    font-size: 1.4285714285714286em
}

.masonry-filter .filter-action.filter-action-center>ul li a.selected,
.masonry-filter .filter-action.filter-action-center>ul li a:hover {
    color: #000
}

@media (max-width:767px) {
    .masonry-filter .filter-action.filter-action-center>ul li a {
        font-size: 1em
    }
}

@media (max-width:767px) {
    .masonry-filter .filter-action {
        width: 100%;
        text-align: left;
        display: block
    }
}

.masonry-filter .filter-action>ul {
    float: right;
    list-style: none;
    padding: 0;
    margin: 0
}

.masonry-filter .filter-action>ul li {
    display: none;
    float: left;
    padding: .5714285714285714rem 1rem
}

@media (max-width:767px) {
    .masonry-filter .filter-action>ul li {
        display: block
    }
}

.masonry-filter .filter-action>ul li a {
    display: inline-block;
    color: #999;
    padding: 0.5em 1em
}

.masonry-filter .filter-action>ul li a.selected {
    color: #000
}

@media (max-width:991px) {
    .masonry-filter .filter-action>ul {
        margin-top: .8571428571428571rem;
        clear: both
    }
}

.not-found-wrapper {
    text-align: center;
    padding: 30px 0 100px
}

.not-found-wrapper .widget_search>p {
    font-size: 1.2em
}

.not-found-title {
    display: block;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px
}

.not-found-subtitle {
    display: block;
    font-size: 20px;
    font-weight: 600;
    line-height: 100%;
    margin-bottom: 20px;
    padding-bottom: 20px
}

.facebook-bg {
    border-color: transparent;
    background: #3b5998;
    color: #fff
}

.facebook-outlined {
    border-color: #3b5998;
    color: #3b5998
}

.facebook-bg-hover {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s
}

.facebook-bg-hover:hover {
    border-color: transparent;
    background: #3b5998;
    color: #fff
}

.twitter-bg {
    border-color: transparent;
    background: #00aced;
    color: #fff
}

.twitter-outlined {
    border-color: #00aced;
    color: #00aced
}

.twitter-bg-hover {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s
}

.twitter-bg-hover:hover {
    border-color: transparent;
    background: #00aced;
    color: #fff
}

.google-plus-bg {
    border-color: transparent;
    background: #dd4b39;
    color: #fff
}

.google-plus-outlined {
    border-color: #dd4b39;
    color: #dd4b39
}

.google-plus-bg-hover {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s
}

.google-plus-bg-hover:hover {
    border-color: transparent;
    background: #dd4b39;
    color: #fff
}

.pinterest-bg {
    border-color: transparent;
    background: #cb2027;
    color: #fff
}

.pinterest-outlined {
    border-color: #cb2027;
    color: #cb2027
}

.pinterest-bg-hover {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s
}

.pinterest-bg-hover:hover {
    border-color: transparent;
    background: #cb2027;
    color: #fff
}

.linkedin-bg {
    border-color: transparent;
    background: #007bb6;
    color: #fff
}

.linkedin-outlined {
    border-color: #007bb6;
    color: #007bb6
}

.linkedin-bg-hover {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s
}

.linkedin-bg-hover:hover {
    border-color: transparent;
    background: #007bb6;
    color: #fff
}

.rss-bg {
    border-color: transparent;
    background: #fab135;
    color: #fff
}

.rss-outlined {
    border-color: #fab135;
    color: #fab135
}

.rss-bg-hover {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s
}

.rss-bg-hover:hover {
    border-color: transparent;
    background: #fab135;
    color: #fff
}

.instagram-bg {
    border-color: transparent;
    background: #517fa4;
    color: #fff
}

.instagram-outlined {
    border-color: #517fa4;
    color: #517fa4
}

.instagram-bg-hover {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s
}

.instagram-bg-hover:hover {
    border-color: transparent;
    background: #517fa4;
    color: #fff
}

.youtube-bg {
    border-color: transparent;
    background: #cc181e;
    color: #fff
}

.youtube-outlined {
    border-color: #cc181e;
    color: #cc181e
}

.youtube-bg-hover {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s
}

.youtube-bg-hover:hover {
    border-color: transparent;
    background: #cc181e;
    color: #fff
}

.github-bg {
    border-color: transparent;
    background: #4183c4;
    color: #fff
}

.github-outlined {
    border-color: #4183c4;
    color: #4183c4
}

.github-bg-hover {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s
}

.github-bg-hover:hover {
    border-color: transparent;
    background: #4183c4;
    color: #fff
}

.behance-bg {
    border-color: transparent;
    background: #1769ff;
    color: #fff
}

.behance-outlined {
    border-color: #1769ff;
    color: #1769ff
}

.behance-bg-hover {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s
}

.behance-bg-hover:hover {
    border-color: transparent;
    background: #1769ff;
    color: #fff
}

.stack-exchange-bg {
    border-color: transparent;
    background: #366fb3;
    color: #fff
}

.stack-exchange-outlined {
    border-color: #366fb3;
    color: #366fb3
}

.stack-exchange-bg-hover {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s
}

.stack-exchange-bg-hover:hover {
    border-color: transparent;
    background: #366fb3;
    color: #fff
}

.tumblr-bg {
    border-color: transparent;
    background: #32506d;
    color: #fff
}

.tumblr-outlined {
    border-color: #32506d;
    color: #32506d
}

.tumblr-bg-hover {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s
}

.tumblr-bg-hover:hover {
    border-color: transparent;
    background: #32506d;
    color: #fff
}

.soundcloud-bg {
    border-color: transparent;
    background: #f50;
    color: #fff
}

.soundcloud-outlined {
    border-color: #f50;
    color: #f50
}

.soundcloud-bg-hover {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s
}

.soundcloud-bg-hover:hover {
    border-color: transparent;
    background: #f50;
    color: #fff
}

.dribbble-bg {
    border-color: transparent;
    background: #ea4c89;
    color: #fff
}

.dribbble-outlined {
    border-color: #ea4c89;
    color: #ea4c89
}

.dribbble-bg-hover {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s
}

.dribbble-bg-hover:hover {
    border-color: transparent;
    background: #ea4c89;
    color: #fff
}

.grayscale,
img.grayscale {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%)
}

img.grayscale:hover {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(0)
}

.nth-typed {
    font-weight: bold
}

.typed-cursor {
    opacity: 1;
    -webkit-animation: blink .7s infinite;
    -o-animation: blink .7s infinite;
    animation: blink .7s infinite;
    font-weight: lighter
}

@keyframes blink {
    0% {
        opacity: 1
    }
    50% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@-webkit-keyframes blink {
    0% {
        opacity: 1
    }
    50% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@-moz-keyframes blink {
    0% {
        opacity: 1
    }
    50% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@-ms-viewport {
    width: device-width
}

@media (max-width:992px) {
    .animate-box.animated {
        visibility: visible
    }
    .el-appear {
        opacity: 1;
        filter: alpha(opacity=100);
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

.heading-center-custom {
    font-size: 1.7142857142857142em;
    text-align: center;
    padding: 0.6em 1em;
    margin: 0 0 2em;
    position: relative;
    text-transform: uppercase
}

@media (max-width:480px) {
    .heading-center-custom {
        font-size: 1.2857142857142858em
    }
}

.heading-center-custom:after {
    content: '';
    width: 130px;
    height: 1px;
    background: #e1e1e1;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
}

.heading-center-custom:before {
    content: '';
    width: 9px;
    height: 9px;
    border: 1px solid #e1e1e1;
    background: #fff;
    position: absolute;
    bottom: -4px;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%) rotate(45deg);
    transform: -moz-translateX(-50%) rotate(45deg);
    transform: -webkit-translateX(-50%) rotate(45deg);
    transform: -ms-translateX(-50%) rotate(45deg);
    transform: -o-translateX(-50%) rotate(45deg)
}

@media (max-width:480px) {
    .heading-center-custom {
        font-size: 1.1428571428571428em
    }
}

.heading-center-custom-white {
    font-size: 1.7142857142857142em;
    text-align: center;
    padding: 0.6em 1em;
    margin: 0 0 2em;
    position: relative;
    text-transform: uppercase;
    color: #fff
}

@media (max-width:480px) {
    .heading-center-custom-white {
        font-size: 1.2857142857142858em
    }
}

.heading-center-custom-white:after {
    content: '';
    width: 130px;
    height: 1px;
    background: #e1e1e1;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
}

.heading-center-custom-white:before {
    content: '';
    width: 9px;
    height: 9px;
    border: 1px solid #e1e1e1;
    background: #fff;
    position: absolute;
    bottom: -4px;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%) rotate(45deg);
    transform: -moz-translateX(-50%) rotate(45deg);
    transform: -webkit-translateX(-50%) rotate(45deg);
    transform: -ms-translateX(-50%) rotate(45deg);
    transform: -o-translateX(-50%) rotate(45deg)
}

@media (max-width:480px) {
    .heading-center-custom-white {
        font-size: 1.1428571428571428em
    }
}

.heading-center-custom-white:after {
    background: #fff
}

.heading-center-custom-white:before {
    border-color: #fff
}

.heading-center-border {
    font-size: 1.7142857142857142em;
    display: table;
    margin: 0 auto 1em;
    color: #333;
    border: 2px solid #333;
    padding: 6.5px 10px;
    text-transform: uppercase
}

@media (max-width:480px) {
    .heading-center-border {
        font-size: 1.2857142857142858em
    }
}

.heading-center-border-white {
    font-size: 1.7142857142857142em;
    display: table;
    margin: 0 auto 1em;
    color: #fff;
    border: 2px solid #fff;
    padding: 6.5px 10px;
    text-transform: uppercase
}

@media (max-width:480px) {
    .heading-center-border-white {
        font-size: 1.2857142857142858em
    }
}

.separator-inherit-theme {
    position: relative
}

.separator-inherit-theme:before {
    content: '';
    width: 9px;
    height: 9px;
    border: 1px solid #e1e1e1;
    background: #fff;
    position: absolute;
    bottom: -5px;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%) rotate(45deg);
    transform: -moz-translateX(-50%) rotate(45deg);
    transform: -webkit-translateX(-50%) rotate(45deg);
    transform: -ms-translateX(-50%) rotate(45deg);
    transform: -o-translateX(-50%) rotate(45deg)
}

/* --------------------------------------------------------------------- */
/* 3. ELEMENTS
/* --------------------------------------------------------------------- */

/* === 3.1. MENUS === */

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .14285714285714285em;
    vertical-align: middle;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    cursor: pointer
}

.dropdown {
    position: relative
}

.dropdown-toggle:focus {
    outline: 0
}

.dropdown-menu {
    position: absolute;
    font-weight: 300;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 11.428571428571429em;
    padding: 10px 0;
    margin: 0px 0 0;
    list-style: none;
    text-align: left;
    background-color: #F7F2DF;
    -webkit-box-shadow: 0 .461538em .923077em rgba(0, 0, 0, 0.175);
    box-shadow: 0 .461538em .923077em rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
	font-size: 13px;
}

.dropdown-menu.pull-right {
    right: 0;
    left: auto
}

.dropdown-menu>li>a {
    display: block;
    padding: .35714285714285715em 1.4285714285714286em;
    clear: both;
    line-height: 1.6;
    color: #333;
    white-space: nowrap
}

.dropdown-menu>li>a:hover {
    text-decoration: none;
    color: #262626;
    background-color: #f5f5f5
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    background-color: #9fce4e
}

.open>.dropdown-menu {
    display: block
}

.open>a {
    outline: 0
}

.nav {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none
}

.nav>li {
    position: relative;
    display: block
}

.nav>li>a {
    position: relative;
    display: block;
    padding: 12px 24px
}

.nav>li>a:hover {
    text-decoration: none
}

.nav>li>a>img {
    max-width: none
}

.nav-tabs {
    border-bottom: 1px solid #e1e1e1
}

.nav-tabs>li {
    float: left;
    margin-bottom: -1px;
    font-family: "Atlan-Semibold", Helvetica, Arial, sans-serif
}

.nav-tabs>li>a {
    margin-right: 2px;
    line-height: 1.6;
    border: 1px solid transparent;
    -webkit-border-radius: 0 0 0 0;
    border-radius: 0 0 0 0;
    color: #777
}

.nav-tabs>li>a>i {
    margin-right: 0.5em
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
    background: #fff;
    color: #333;
    border: 1px solid #e1e1e1;
    border-bottom-color: transparent;
    cursor: default
}

.tabs-style-2 .nav-tabs {
    border: 0;
    text-align: center
}

.tabs-style-2 .nav-tabs>li {
    float: none;
    display: inline-block
}

.tabs-style-2 .nav-tabs>li:after {
    content: '';
    display: inline-block;
    right: 0;
    width: 2px;
    height: 15px;
    font-weight: 600;
    background: #999;
    top: 50%;
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    -o-transform: rotate(30deg);
    transform: rotate(30deg)
}

.tabs-style-2 .nav-tabs>li:last-child:after {
    display: none
}

.tabs-style-2 .nav-tabs>li>a {
    border: none;
    border-bottom: none;
    font-weight: 600;
    color: #999;
    font-size: 1.4285714285714286em;
    display: inline-block;
    text-transform: uppercase;
    padding: 0.5em 1em
}

.tabs-style-2 .nav-tabs>li>a:focus {
	border: none;
}

.tabs-style-2 .nav-tabs>li>a:hover {
    color: #000
}

.tabs-style-2 .nav-tabs>li.active>a {
    color: #000
}

.tabs-style-3 .nav-tabs {
    border: 1px solid #e1e1e1
}

.tabs-style-3 .nav-tabs>li {
    padding: 0 15px;
    margin: 0
}

.tabs-style-3 .nav-tabs>li>a {
    border: none;
    padding: 0;
    border-bottom: none;
    font-weight: 600;
    color: #777;
    display: inline-block;
    text-transform: uppercase;
    padding: 0.6em 0;
    position: relative
}

.tabs-style-3 .nav-tabs>li>a:focus {
	border: none;
}

.tabs-style-3 .nav-tabs>li>a:before {
    content: '';
    width: 100%;
    position: absolute;
    height: 3px;
    background: #9fce4e;
    left: 0;
    width: 0;
    bottom: -1px;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease
}

.tabs-style-3 .nav-tabs>li>a:hover {
    color: #000
}

.tabs-style-3 .nav-tabs>li.active>a {
    color: #000
}

.tabs-style-3 .nav-tabs>li.active>a:before {
    width: 100%
}

.tabs-style-3 .tab-content {
    padding: 15px;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    border-color: #e1e1e1
}

.tab-content {
    padding-top: 20px
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.tab-content .widget {
    margin-bottom: 0
}

.widget .tab-content {
    padding-bottom: 0
}

.navbar {
    margin-bottom: 2em;
    border-bottom: 0px solid transparent;
    position: relative
}

@media (min-width:900px) {
    .navbar-header {
        float: left
    }
}

.navbar-collapse {
    overflow-x: visible;
    -webkit-overflow-scrolling: touch
}

.navbar-collapse.in {
    overflow-y: auto
}

@media (min-width:900px) {
    .navbar-collapse {
        width: auto;
        border-top: 0;
        box-shadow: none
    }
    .navbar-collapse.collapse {
        display: block;
        height: auto;
        padding-bottom: 0;
        overflow: visible;
    }
    .navbar-collapse.in {
        overflow-y: visible
    }
}

.container>.navbar-wrap,
.container-fluid>.navbar-wrap,
.container>.navbar-header-left,
.container-fluid>.navbar-header-left,
.container>.navbar-header,
.container-fluid>.navbar-header,
.container>.navbar-collapse,
.container-fluid>.navbar-collapse {
    margin-right: 0;
    margin-left: 0
}

/* @media (min-width:900px) {
    .container>.navbar-wrap,
    .container-fluid>.navbar-wrap,
    .container>.navbar-header-left,
    .container-fluid>.navbar-header-left,
    .container>.navbar-header,
    .container-fluid>.navbar-header,
    .container>.navbar-collapse,
    .container-fluid>.navbar-collapse {
        margin-right: 0;
        margin-left: 0
    }
}

.navbar-fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
    -webkit-transform: translate3d(0, -60px, 0);
    transform: translate3d(0, -60px, 0)
}

@media (min-width:900px) {
    .navbar-fixed-top {
        border-radius: 0
    }
}

.boxed-wrap .navbar-fixed-top {
    left: auto;
    right: auto
} */

.navbar-fixed-top.fixed-transition {
    -webkit-transition: all .3s ease-in 0s;
    -o-transition: all .3s ease-in 0s;
    transition: all .3s ease-in 0s;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.navbar-fixed-top {
    top: 0
}

.navbar-brand {
    float: left;
    font-size: 0
}

@media (max-width:900px) {
    .navbar-brand {
        display: block;
        height: 74px;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        position: absolute;
        left: 50%
    }
    .page-header-overlay .navbar-brand {
        position: relative;
        left: auto;
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0)
    }
    .navbar-brand img {
        /*max-height: 60px;*/
        position: relative;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        transform: translate(0, -50%);
        top: 50%;
        left: 0
    }
}

.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none
}

.navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin: 19px 0;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 0;
    height: 35px;
    line-height: 35px
}

.navbar-toggle:focus {
    outline: 0
}

.navbar-toggle .icon-bar {
    display: block;
    width: 34px;
    height: 3px;
    -webkit-border-radius: 1px;
    border-radius: 1px;
    position: relative;
    transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
    -webkit-transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
    -o-transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
    -moz-transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
    background-color: #000 !important;
}

.navbar-toggle .bar-middle{
    width: 26px;
}

.navbar-toggle .icon-bar+.icon-bar {
    margin-top: 6px
}

.navbar-toggle.x .bar-top {
    top: 7px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: transform .4s;
    -o-transition: transform .4s;
    transition: transform .4s
}

.navbar-toggle.x .bar-middle {
    opacity: 0;
    -webkit-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s
}

.navbar-toggle.x .bar-bottom {
    top: -11px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: transform .4s;
    -o-transition: transform .4s;
    transition: transform .4s
}

@media (min-width:900px) {
    .navbar-toggle {
        display: none
    }
}

.navbar-header .navbar-toggle {
    border-color: transparent
}

.navbar-header .navbar-toggle:hover,
.navbar-header .navbar-toggle:focus {
    background-color: rgba(255, 255, 255, 0.2)
}

.navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 2em
}

@media (max-width:899px) {
    .navbar-nav>li>a {
        line-height: 2em;
        font-size: 13px;
        padding-left: 0;
        padding-right: 0
    }
}

@media (max-width:899px) {
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none
    }
    .navbar-nav .open .dropdown-menu>li .dropdown-menu>li>a {
        padding-left: 3.5714285714285716em
    }
    .navbar-nav .open .dropdown-menu>li>a {
        padding: .7142857142857143em 1.0714285714285714em .7142857142857143em 1.7857142857142858em
    }
    .navbar-nav .open .dropdown-menu>li>a {
        line-height: 2em
    }
    .navbar-nav .open .dropdown-menu>li>a:hover {
        background-image: none
    }
}

@media (min-width:900px) {
    .navbar-nav {
        float: right;
        margin: 0
    }
    .navbar-nav>li {
        display: inline-block
    }
    .navbar-nav.navbar-right:last-child {
        margin-right: -0.7142857142857143em
    }
}

.header-type-classic .navbar-fixed-top,
.header-type-center .navbar-fixed-top {
    -webkit-box-shadow: 0 1px 5px rgba(190, 190, 190, 0.46);
    box-shadow: 0 1px 5px rgba(190, 190, 190, 0.46)
}

@media (min-width:900px) {
    .header-type-classic .navbar-fixed-top .navbar-nav>li>.dropdown-menu,
    .header-type-center .navbar-fixed-top .navbar-nav>li>.dropdown-menu {
        top: 60px
    }
}

.header-type-classic .navbar-nav>li>.dropdown-menu {
    margin-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

@media (min-width:900px) {
    .header-type-classic .navbar-nav>li>.dropdown-menu {
        top: 56px
    }
}

.navbar-default {
    background-color: transparent;
    border-color: #333
}

@media (min-width:900px) {
    .header-type-center .navbar-fixed-top.navbar-default {
        height: 60px
    }
}

.navbar-default .navbar-brand {
    color: #333
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
    color: #1a1a1a;
    background-color: transparent
}

.navbar-default .navbar-nav>li>a {
    color: #333
}
.navbar-default .navbar-nav>.open>a:focus {
	background: none;
}
.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
	background: none;
}

/* === 3.2. HEADERS === */

.header-type-market .navbar-default .navbar-nav>li>a {
    color: #fff
}

.navbar-default .navbar-nav>li>a i {
    margin-right: .35714285714285715em
}

.header-transparent:not(.header-navbar-fixed):not(.header-type-classic-right) .navbar-default .navbar-nav>li>a {
    color: #fff
}

/* .navbar-default .navbar-nav>li>a:hover {
    color: #9fce4e;
    background-color: transparent
} */

.header-type-market .navbar-default .navbar-nav>li>a:hover {
    color: #fff
}

.navbar-default .navbar-nav .current-menu-item>a,
.navbar-default .navbar-nav .current-menu-item>a:focus{
    color: #9fce4e;
    background-color: transparent
}

.header-transparent:not(.header-navbar-fixed) .navbar-default .navbar-nav .active>a,
.header-transparent:not(.header-navbar-fixed) .navbar-default .navbar-nav .open>a {
    color: #9fce4e
}

.navbar-default .navbar-nav>.current-menu-ancestor>a,
.navbar-default .navbar-nav>.current-menu-parent>a,
.navbar-default .navbar-nav>.current-menu-ancestor>a:hover,
.navbar-default .navbar-nav>.current-menu-parent>a:hover {
    color: #9fce4e;
    background-color: transparent
}

.navbar-default .navbar-nav>.current-menu-ancestor>a:before,
.navbar-default .navbar-nav>.current-menu-parent>a:before,
.navbar-default .navbar-nav>.current-menu-ancestor>a:hover:before,
.navbar-default .navbar-nav>.current-menu-parent>a:hover:before {
    display: block;
}

.navbar-default .navbar-collapse {
    border-color: #333
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:hover {
    background-color: transparent
}

@media (max-width:899px) {
    .navbar-default .navbar-nav .open .dropdown-menu>li>a {
        color: #333
    }
    .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover {
        color: #9fce4e;
        background-color: transparent
    }
}

.navbar-brand {
    padding: 0
}

@media (min-width:900px) {
    .header-type-classic .navbar-brand {
        line-height: 90px
    }
}

.navbar-fixed-top .navbar-brand {
    line-height: 60px
}


.navbar-brand .logo-fixed {
    display: none
}

@media (max-width:899px) {
    .navbar-brand .logo {
        display: block;
        max-width: 200px
    }
    .navbar-brand .logo-fixed {
        display: none
    }
}

.navbar-fixed-top .logo {
    display: none
}

.navbar-fixed-top .logo-fixed {
    display: inline-block;
    max-height: 60px;
}

.header-type-classic .navbar-fixed-top .logo-fixed {
	max-height: 60px;
}

.navbar-brand .logo-mobile {
   /* max-height: 45px;*/
    display: none;
   /* max-width: 120px;*/
    vertical-align: top
}

@media (max-width:899px) {
    .navbar-brand .logo,
    .navbar-brand .logo-fixed {
        display: none
    }
    .navbar-brand .logo-mobile {
        display: inline-block
    }
}

@media (max-width:899px) {
    .header-type-market .navbar-header {
        padding: 0 10px
    }
}

.header-type-market .navbar-default .dh-menu {
    float: left;
    width: 25%;
    padding: 0 15px;
    text-align: left
}

.header-type-market .navbar-default .dh-menu .navbar-nav {
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: scale(1, .7);
    -ms-transform: scale(1, .7);
    -o-transform: scale(1, .7);
    transform: scale(1, .7);
    visibility: hidden;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    transform-origin: 0 0 0;
    -webkit-transform-origin: 0 0 0;
    -moz-transform-origin: 0 0 0;
    -ms-transform-origin: 0 0 0
}

.always-show-categories:not(.header-navbar-fixed).header-type-market .navbar-default .dh-menu .navbar-nav {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
    visibility: visible
}

.always-show-categories.header-type-market .navbar-default .dh-menu .navbar-nav {
    -webkit-transition: all 0s ease;
    -o-transition: all 0s ease;
    transition: all 0s ease
}

.header-type-market .navbar-default .dh-menu .navbar-nav>li>a {
    color: #333
}

.header-type-market .navbar-default .dh-menu .navbar-nav>li>a:hover {
    color: #9fce4e;
    background-color: transparent
}

.header-type-market .navbar-default .dh-menu .navbar-nav>li.active>a,
.header-type-market .navbar-default .dh-menu .navbar-nav>li.open>a {
    color: #9fce4e;
    background-color: transparent
}

.header-type-market .dh-menu-wrap {
    position: relative
}

.header-type-market .dh-menu-wrap .dh-menu-title {
    cursor: pointer
}

.header-type-market .dh-menu-wrap:hover .navbar-nav {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
    visibility: visible
}

.header-type-market .navbar-nav {
    float: none
}

.header-type-market .dh-menu~.navbar-nav {
    float: left
}

.header-type-market .navbar-fixed-top .navbar-header {
    padding-top: 0!important;
    padding-bottom: 0!important;
    min-height: 0 !important
}

.header-type-market .navbar-fixed-top .navbar-header .navbar-header-center {
    display: none
}

.header-type-market .navbar-fixed-top .navbar-header .navbar-header-left {
    display: none
}

.header-type-market .navbar-fixed-top .navbar-header .navbar-header-right {
    position: absolute;
    width: auto;
    top: 50%;
    margin: 0;
    z-index: 11
}

.header-type-market .navbar-fixed-top .navbar-header .navbar-header-right {
    right: 15px
}

.header-type-market .navbar-fixed-top .navbar-header .navbar-header-right>div>a {
    line-height: 50px
}

.header-type-market~.heading-container .page-breadcrumb {
    text-align: inherit;
    padding: 10px 0;
}

.header-type-market~.heading-container .page-breadcrumb .breadcrumb>li+li::before {
    padding: 0 5px
}

.navbar-default {
    margin-bottom: 0;
    z-index: 999;
	border: none;
	border-radius: 0;
}

@media (min-width:900px) {
    .navbar-default .primary-navbar-collapse {
        text-align: center
    }
}

@media (max-width:899px) {
    .navbar-default .primary-navbar-collapse {
        float: none;
        padding: 0;
        width: auto
    }
}

@media (min-width:900px) {
    .header-type-center .navbar-default .navbar-header {
        float: none
    }
}

@media (min-width:900px) {
    .header-type-market .navbar-default .navbar-header {
        float: none;
        padding-top: 30px;
        padding-bottom: 30px
    }
}

.navbar-default .navbar-collapse {
    border: none
}

.primary-nav .menu-item-navbar-brand {
    display: none
}

@media (min-width:900px) {
    .header-type-center .navbar-wrap>.navbar-header {
        padding: 30px 0 20px
    }
    .header-type-center .navbar-wrap>.navbar-header .navbar-brand {
        float: none
    }
    .header-type-center .navbar-fixed-top .navbar-header {
        padding: 0
    }
    .header-type-center .navbar-fixed-top .navbar-header .navbar-header-left {
        display: none
    }
    .header-type-center .navbar-fixed-top .navbar-header .navbar-header-center,
    .header-type-center .navbar-fixed-top .navbar-header .navbar-header-right {
        position: absolute;
        width: auto;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        transform: translate(0, -50%);
        z-index: 11
    }
    .header-type-center .navbar-fixed-top .navbar-header .navbar-header-center {
        left: 0
    }
    .header-type-center .navbar-fixed-top .navbar-header .navbar-header-right {
        right: 0
    }
    .header-type-center .navbar-default .primary-navbar-collapse {
        float: none;
        padding: 0
    }
    .header-type-center.header-type-center .primary-nav {
        float: none;
        text-align: center;
        width: 100%;
        margin: 0
    }
    .header-type-center.header-type-center .primary-nav>li {
        display: inline-block;
        float: none
    }
}

.navbar-collapse {
    -webkit-box-shadow: none;
    box-shadow: none
}

.header-type-market .navbar-collapse {
    background: #9fce4e
}

.navbar-wrap {
    position: relative
}

.navicon {
    margin-right: .5714285714285714rem;
    font-size: 13px
}

@media (max-width:899px) {
    .navicon {
        display: none
    }
}

.megamenu-title {
    font-size: 1em;
    margin: 0;
    padding: 30px 0 5px;
    text-transform: uppercase
}

.megamenu-title a {
    background: transparent;
}

.megamenu-title .caret {
    display: none
}

.primary-nav,
.offcanvas-nav {
    font-family: "Atlan-Semibold", Helvetica, Arial, sans-serif
}

.primary-nav .dropdown-menu,
.offcanvas-nav .dropdown-menu {
    font-weight: 300
}

.primary-nav {
    margin: 0 0;
    font-size: 13px;
    font-style: normal;
    font-weight: 600
}

.primary-nav .menu-item-badge {
    margin-right: 1.2em;
    width: 15px;
    display: inline-block;
    text-align: center
}

.primary-nav .menu-item-badge img {
    max-height: 20px
}

@media (min-width:900px) {
    .primary-nav>.megamenu>.dropdown-menu {
        background: #fff;
        border-top: 2px solid #9fce4e;
        padding: 0
    }
    .primary-nav>.megamenu.megamenu-fullwidth {
        position: static;
    }
    .primary-nav>.megamenu.megamenu-fullwidth>.dropdown-menu {
        width: 100%
    }
    .primary-nav>.megamenu>.dropdown-menu .widget {
        margin-bottom: 0
    }
    .primary-nav>.megamenu>.dropdown-menu .megamenu-sidebar {
        padding: 15px
    }
    .primary-nav>.megamenu>.dropdown-menu .megamenu-sidebar .products {
        margin: 0
    }
    .primary-nav>.megamenu>.dropdown-menu .megamenu-sidebar .products li {
        margin: 0
    }
    .primary-nav>.megamenu>.dropdown-menu .megamenu-sidebar .products li img {
        width: 100%
    }
    .primary-nav>.megamenu>.dropdown-menu .megamenu-sidebar a {
        margin: 0;
        padding: 0
    }
    .primary-nav>.megamenu>.dropdown-menu>li {
        display: table-cell;
        vertical-align: top;
        border-bottom: none;
        border-top: none;
        padding: 8px 30px 8px 0;
        min-width: 200px
    }
    .primary-nav>.megamenu>.dropdown-menu>li:first-child {
        padding-left: 30px
    }
    .primary-nav>.megamenu>.dropdown-menu>li.mega-col-12 {
        width: 100%
    }
    .primary-nav>.megamenu>.dropdown-menu>li.mega-col-3 {
        width: 25%
    }
    .primary-nav>.megamenu>.dropdown-menu>li.mega-col-6 {
        width: 50%
    }
    .primary-nav>.megamenu>.dropdown-menu>li.mega-col-4 {
        width: 33.3333%
    }
    .primary-nav>.megamenu>.dropdown-menu>li>a {
        background: #fff
    }
    .primary-nav>.megamenu>.dropdown-menu>li .dropdown-menu {
        display: block !important;
        position: relative;
        -webkit-box-shadow: none;
        box-shadow: none;
        float: none;
        left: 0;
        top: 0;
        opacity: 1 !important;
        padding: 0;
    }
    .primary-nav>.megamenu>.dropdown-menu>li .dropdown-menu a {
        background: #fff
    }
    .primary-nav>.megamenu>.dropdown-menu>li .dropdown-menu a:hover {
        color: #9fce4e
    }
    .primary-nav>.megamenu.open>.dropdown-menu {
        display: table !important
    }
}

.primary-nav .dropdown-menu {
    min-width: 200px
}

.primary-nav .dropdown-menu li {
    border-bottom: none
}

.primary-nav .dropdown-menu li.menu-item {
    border-top: 1px solid #e1e1e1
}

.primary-nav .dropdown-menu li:first-child {
    border-top: none;
}

.primary-nav .dropdown-menu li.menu-item-has-children>.dropdown-menu {
    margin: 0;
    left: 100%;
    top: 0;
    padding: 8px 22px
}

@media (max-width:899px) {
    .primary-nav .dropdown-menu li .megamenu-title {
        font-size: 13px;
        font-weight: 600;
        font-family: "Atlan-Semibold", Helvetica, Arial, sans-serif;
        margin: 0;
        padding: .7142857142857143em 1.0714285714285714em .7142857142857143em 1.7857142857142858em;
        position: relative;
        color: #333
    }
    .primary-nav .dropdown-menu li .megamenu-title .caret {
        cursor: pointer
    }
}

.primary-nav .dropdown-menu a {
    color: #4a4a4a;
    padding: 12px 0;
    font-size: 15px;
    font-family: "Atlan-Regular", Helvetica, Arial, sans-serif;
}


@media (min-width:900px) {
    .primary-nav .dropdown-menu a:hover {
        color: #21552F;
        background: transparent
    }
}

.primary-nav .dropdown-menu .open>a {
    color: #21552F
}

.primary-nav li.menu-item-has-children {
    position: relative
}

@media (max-width:899px) {
    .primary-nav li.menu-item-has-children.open>.megamenu-title>.caret:before,
    .primary-nav li.menu-item-has-children.open>a>.caret:before {
        content: "\f068";
        position: relative;
        display: inline-block;
        font-family: "FontAwesome";
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        text-decoration: inherit;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        speak: none
    }
}

@media (max-width:899px) {
    .primary-nav li {
        border-bottom: 1px solid #e1e1e1
    }
    .primary-nav li:last-child {
        border-bottom: none
    }
}

.primary-nav>li:not(.megamenu)>.dropdown-menu {
    padding: 8px 22px
}

.primary-nav>li>a {
    position: relative;
    text-transform: uppercase
}

@media (min-width:900px) {
    .header-type-classic .primary-nav>li>a {
        padding: 0 20px;
        line-height: 90px
    }
    .header-type-center .primary-nav>li>a {
        line-height: 70px;
        vertical-align: middle;
        text-align: center;
        display: block;
        position: relative;
        padding-top: 0;
        padding-bottom: 0
    }
    .header-type-market .primary-nav>li>a {
        padding-top: 0;
        padding-bottom: 0;
        line-height: 50px
    }
}

.primary-nav>li.menu-item-has-children {
    position: relative
}

.primary-nav>li.menu-item-has-children .caret {
    border: none;
    vertical-align: baseline;
    margin-left: .14285714285714285em;
    font-size: 1em
}

@media (min-width:900px) {
    .primary-nav>li.menu-item-has-children .caret:before {
        content: "\f107";
        position: relative;
        display: inline-block;
        font-family: "FontAwesome";
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        text-decoration: inherit;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        speak: none
    }
}

@media (max-width:899px) {
    .primary-nav>li.menu-item-has-children .caret {
        height: 100%;
        padding: 10px;
        position: absolute;
        right: 0px;
        top: 0;
        width: 40px;
        text-align: center
    }
    .primary-nav>li.menu-item-has-children .caret:before {
        content: "\f067";
        position: relative;
        display: inline-block;
        font-family: "FontAwesome";
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        text-decoration: inherit;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        speak: none
    }
}

@media (min-width:900px) {
    .primary-nav>li.menu-item-has-children li.menu-item-has-children .caret {
        position: absolute;
        right: 1rem
    }
    .primary-nav>li.menu-item-has-children li.menu-item-has-children .caret:before {
        content: "\f105";
        position: relative;
        display: inline-block;
        font-family: "FontAwesome";
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        text-decoration: inherit;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        speak: none
    }
}

.primary-nav>li.current-menu-parent>a,
.primary-nav>li.current-menu-parent>a:hover {
    color: #9fce4e;
    background-color: transparent
}

@media (max-width:899px) {
    .primary-nav .dropdown-menu {
        position: relative;
        float: none
    }
    .primary-nav>li>a {
        font-size: 13px
    }
}

.navbar-fixed-top {
    height: 60px;
    z-index: 1001
}

.navbar-fixed-top .navbar-default-container {
    background: #fff
}

.header-type-market .navbar-fixed-top {
    height: 50px;
    -webkit-box-shadow: 0 1px 5px rgba(190, 190, 190, 0.46);
    box-shadow: 0 1px 5px rgba(190, 190, 190, 0.46)
}

.navbar-fixed-top .navbar-nav.primary-nav>li>a {
    line-height: 60px
}

.header-type-market .navbar-fixed-top .navbar-nav.primary-nav>li>a {
    line-height: 50px
}

.navbar-fixed-top .navbar-nav.primary-nav>li>a .underline::after {
    background: #333
}

@-webkit-keyframes search_pop {
    0% {
        -webkit-transform: scale(.8)
    }
    100% {
        -webkit-transform: scale(1)
    }
}

@-moz-keyframes search_pop {
    0% {
        -moz-transform: scale(.8)
    }
    100% {
        -moz-transform: scale(1)
    }
}

@-o-keyframes search_pop {
    0% {
        -moz-transform: scale(.8)
    }
    100% {
        -moz-transform: scale(1)
    }
}

@keyframes search_pop {
    0% {
        transform: scale(.8)
    }
    100% {
        transform: scale(1)
    }
}

.navbar-search {
    position: relative
}

.navbar-search .search-form-wrap {
    color: #444
}

.navbar-search .search-form-wrap.show-popup {
    position: absolute;
    top: 100%;
    right: 0;
    border: 1px solid #e1e1e1;
    z-index: 1000;
    background: #fff;
    margin: -1px 0 0 0;
    display: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2)
}

@media (max-width:899px) {
    .navbar-search .search-form-wrap.show-popup {
        background: rgba(0, 0, 0, 0);
        margin: 0;
        border: none;
    }
}

.navbar-search .search-form-wrap.show-popup.show {
    -webkit-animation: .3s cubic-bezier(.175, .885, .32, 1.275) 0s normal none 1 search_pop;
    -o-animation: .3s cubic-bezier(.175, .885, .32, 1.275) 0s normal none 1 search_pop;
    animation: .3s cubic-bezier(.175, .885, .32, 1.275) 0s normal none 1 search_pop
}

.navbar-search .search-form-wrap.show-popup:before {
    border-color: #e1e1e1;
    border-style: solid none none solid;
    border-width: 1px 0 0 1px;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    right: 20px;
    top: -5px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 9px;
    z-index: 1010;
    background: #fff
}

.navbar-search .search-form-wrap.show-popup .searchform-loading {
    display: none
}

.navbar-search .search-form-wrap.show-popup .searchform {
    position: relative;
    margin: 15px
}

.navbar-search .search-form-wrap.show-popup .searchform:after {
    content: "\f002";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.navbar-search .search-form-wrap.show-popup .searchform.loading:after {
    content: "\f110";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.navbar-search .search-form-wrap.show-popup .searchform.loading:after {
    -webkit-animation: fa-spin 1s infinite linear;
    -o-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear;
    -webkit-animation-timing-function: steps(8, start);
    animation-timing-function: steps(8, start)
}

.navbar-search .search-form-wrap.show-popup .searchform:after {
    color: #fff;
    position: absolute;
    top: 8px;
    right: 10px;
    font-size: 16px;
}

.navbar-search .search-form-wrap.show-popup .searchform:before {
    content: "";
    width: 34px;
    height: 34px;
    position: absolute;
    right: 0;
    text-align: center;
    background: #9fce4e;
    line-height: 34px;
    color: #fff
}

@media (max-width:899px) {
    .navbar-search .search-form-wrap.show-popup .searchform:before {
        background: rgba(0, 0, 0, 0)
    }
}

.navbar-search .search-form-wrap.show-popup .searchinput {
    width: 250px;
    height: 34px;
    border: 1px solid #e1e1e1;
    padding: 5px;
    padding-right: 36px;
    background: #f8f8f8;
    font-size: 13px;
    outline: none
}

.header-type-classic .navbar-default-wrap:not(.container)>.row {
    margin-left: 0;
    margin-right: 0
}

@media (max-width:899px) {
    .navbar-default {
        height: 74px
    }
    .navbar-default-col {
        padding: 0 10px
    }
    .navbar-toggle {
        float: right;
        position: relative
    }
    .header-container {
        position: relative;
        background: #fff
    }
    .navbar-brand {
        float: none;
        text-align: center
    }
}

.navbar-minicart {
    position: relative
}

@media (max-width:899px) {
    .navbar-minicart {
        display: none;
    }
}

.search-icon-mobile {
    display: none;
    position: relative;
    float: right;
    padding: 9px 10px;
    margin: 19px 0;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 0;
    height: 35px;
    line-height: 35px;
    line-height: 1
}

.search-icon-mobile:focus {
    outline: 0
}

.search-icon-mobile .icon-bar {
    display: block;
    width: 32px;
    height: 3px;
    -webkit-border-radius: 1px;
    border-radius: 1px;
    position: relative;
    transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
    -webkit-transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
    -o-transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
    -moz-transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
    background-color: #000
}

.search-icon-mobile .icon-bar+.icon-bar {
    margin-top: 6px
}

.search-icon-mobile.x .bar-top {
    top: 7px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: transform .4s;
    -o-transition: transform .4s;
    transition: transform .4s
}

.search-icon-mobile.x .bar-middle {
    width: 28px;
    opacity: 0;
    -webkit-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s
}

.search-icon-mobile.x .bar-bottom {
    top: -5px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: transform .4s;
    -o-transition: transform .4s;
    transition: transform .4s
}

@media (min-width:900px) {
    .search-icon-mobile {
        display: none
    }
}

.search-icon-mobile i {
    font-size: 16px;
    height: auto
}

@media (max-width:899px) {
    .search-icon-mobile {
        display: block
    }
}

.cart-icon-mobile {
    display: none;
    margin: 19px 0;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 0;
    height: 35px;
    line-height: 35px;
    float: right;
    line-height: 1;
    padding: 9px 10px;
    font-size: 16px;
    color: #000;
    position: relative
}

.cart-icon-mobile:focus {
    outline: 0
}

.cart-icon-mobile .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    -webkit-border-radius: 1px;
    border-radius: 1px;
    position: relative;
    transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
    -webkit-transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
    -o-transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
    -moz-transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
    background-color: #000
}

.cart-icon-mobile .icon-bar+.icon-bar {
    margin-top: 4px
}

.cart-icon-mobile.x .bar-top {
    top: 7px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: transform .4s;
    -o-transition: transform .4s;
    transition: transform .4s
}

.cart-icon-mobile.x .bar-middle {
    opacity: 0;
    -webkit-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s
}

.cart-icon-mobile.x .bar-bottom {
    top: -5px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: transform .4s;
    -o-transition: transform .4s;
    transition: transform .4s
}

@media (min-width:900px) {
    .cart-icon-mobile {
        display: none
    }
}

.cart-icon-mobile:hover {
    color: #000;
    background: rgba(255, 255, 255, 0.2)
}

.cart-icon-mobile span {
    background: none repeat scroll 0 0 #9fce4e;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    color: #fff;
    font-size: 85%;
    height: 1.7em;
    line-height: 1.7em;
    position: absolute;
    right: 1px;
    text-align: center;
    top: -2px;
    width: 1.7em;
    font-size: 12px
}

.cart-icon-mobile .minicart-icon-svg {
    font-size: 16px;
    width: 20px
}

@media (max-width:899px) {
    .cart-icon-mobile {
        display: block
    }
}

.header-type-toggle-offcanvas {
    background: #fff
}

.header-type-toggle-offcanvas .navbar-default-wrap {
    text-align: center
}

@media (min-width:900px) {
    .header-type-toggle-offcanvas.header-logo-position-left .navbar-default-wrap {
        text-align: left
    }
    .header-type-toggle-offcanvas.header-logo-position-left .navbar-toggle-fixed {
        left: auto;
        right: 15px
    }
    .header-type-toggle-offcanvas.header-logo-position-left .navbar-toggle-right {
        right: 65px
    }
}

.header-type-toggle-offcanvas .navbar-default-wrap {
    position: relative
}

.header-type-toggle-offcanvas .navbar-default.navbar-fixed-top .navbar-header {
    line-height: 60px
}

.header-type-toggle-offcanvas .navbar-default.navbar-fixed-top .navbar-header img {
    max-height: 60px
}

.header-type-toggle-offcanvas .navbar-header {
    float: none;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    z-index: 1031;
    padding: 0
}

@media (min-width:900px) {
    .header-type-toggle-offcanvas .navbar-header {
        line-height: 74px
    }
}

.header-type-toggle-offcanvas .navbar-header .navbar-brand {
    padding: 0
}

@media (max-width:899px) {
    .header-type-toggle-offcanvas .navbar-header .navbar-brand {
        position: relative;
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        left: auto
    }
}

.header-type-toggle-offcanvas .navbar-toggle-right {
    position: absolute;
    top: 50%;
    right: 20px;
    z-index: 1031;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.header-type-toggle-offcanvas .navbar-toggle-right>div {
    display: inline-block;
    margin-left: 30px
}

@media (max-width:899px) {
    .header-type-toggle-offcanvas .navbar-toggle-right>div {
        margin-left: 15px
    }
}

.header-type-toggle-offcanvas .navbar-toggle-right>div .minicart {
    right: 0;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0)
}

.header-type-toggle-offcanvas .navbar-toggle-right>div .minicart:before {
    display: none
}

@media (max-width:899px) {
    .header-type-toggle-offcanvas .navbar-toggle-right>div.navbar-user {
        display: none
    }
}

.header-type-toggle-offcanvas .navbar-toggle-right .navbar-user {
    position: relative;
    line-height: 74px
}

.header-type-toggle-offcanvas .navbar-toggle-right .navbar-user .dropdown-menu {
    padding: 10px;
    left: auto;
    right: 0;
    font-size: 85%
}

.header-type-toggle-offcanvas .navbar-toggle-right .navbar-user:hover .dropdown-menu {
    display: block
}

@media (max-width:900px) {
    .header-type-toggle-offcanvas .navbar-toggle-fixed {
        display: block
    }
    .header-type-toggle-offcanvas .navbar-minicart,
    .header-type-toggle-offcanvas .navbar-search>a {
        display: block;
    }
}

.header-type-toggle-offcanvas .navbar:not(.navbar-fixed-top) {
    position: relative
}

.navbar-toggle-fixed {
    position: absolute;
    top: 50%;
    left: 20px;
    overflow: hidden;
    height: 35px;
    z-index: 1031;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.navbar-toggle-fixed .navbar-toggle {
    padding: 0;
    margin: 0;
    float: none;
    display: block
}

.navbar-toggle-fixed .navbar-toggle .icon-bar {
    border-color: #333;
    background: #333
}

@media (min-width:900px) {
    .header-transparent:not(.header-navbar-fixed) .navbar-toggle-fixed .navbar-toggle .icon-bar {
        border-color: #fff;
        background: #fff
    }
}

.offcanvas-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    position: fixed;
    top: 0;
    -webkit-transition: all 400ms cubic-bezier(.645, .045, .355, 1) 0s;
    -o-transition: all 400ms cubic-bezier(.645, .045, .355, 1) 0s;
    transition: all 400ms cubic-bezier(.645, .045, .355, 1) 0s;
    width: 100%;
    z-index: 1030;
    visibility: hidden
}

.offcanvas {
    -webkit-transition: transform .5s;
    -o-transition: transform .5s;
    transition: transform .5s;
    background: none repeat scroll 0 0 #F7F2DF;
    bottom: 0;
    position: fixed;
    z-index: 1031;
    right: 0;
    -webkit-transform: translate3d(150%, 0, 0);
    transform: translate3d(150%, 0, 0);
    top: 84px;
    width: 80%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 40px;
}

/* @media (max-width:320px) {
    .offcanvas {
        width: 260px;
        -webkit-transform: translate3d(-260px, 0, 0);
        transform: translate3d(-260px, 0, 0)
    }
} */

@media (min-width:900px) {
    .offcanvas.navbar-offcanvas {
        left: auto;
        right: 0;
        -webkit-transform: translate3d(150%, 0, 0);
        transform: translate3d(150%, 0, 0)
    }
}

.offcanvas.navbar-offcanvas .navbar-toggle-fixed .navbar-toggle {
    float: right
}

.offcanvas-wrap {
    position: relative;
    padding: 0 20px
}

@media (max-width:782px) {
    body.admin-bar .offcanvas-wrap {
        margin-top: 46px
    }
}

.offcanvas-wrap .widget {
    margin-bottom: 2rem
}

.offcanvas-user {
    margin-left: -20px;
    margin-right: -20px;
    background: #333;
    padding: 0 15px;
    margin-bottom: 20px
}

.offcanvas-user a {
    height: 74px;
    line-height: 74px;
    color: #fff;
    width: 100%;
    float: left;
    text-align: center;
    display: block;
    text-transform: uppercase;
    font-size: 85%
}

@media (max-width:320px) {
    .offcanvas-user a {
        font-size: 75%
    }
}

.offcanvas-user a.offcanvas-user-wishlist-link {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    width: 50%
}

.offcanvas-user a.offcanvas-user-wishlist-link~.offcanvas-user-account-link {
    width: 50%
}

@media (max-width:899px) {
    .navbar-toggle-right .navbar-user,
    .offcanvas-sidebar-wrap,
    .navbar-toggle-fixed {
        display: none
    }
    .open-offcanvas #wrapper {
        height: 100%;
        overflow: hidden;
        /* position: fixed; */
        width: 100%
    }
    .open-offcanvas[data-spy="scroll"] #wrapper {
        position: inherit;
        width: auto;
        height: auto
    }
    .open-offcanvas .offcanvas {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    .open-offcanvas .offcanvas-overlay {
        opacity: 0;
        visibility: visible
    }
}

.offcanvas-user,
.mobile-offcanvas-navbar {
    display: block
}

.header-offcanvas-topbar {
    display: none
}

@media (min-width:1200px) {
    .header-offcanvas.header-offcanvas-always-show {
        -webkit-box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.17);
        box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.17);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    .header-offcanvas.header-offcanvas-always-show .navbar-toggle-fixed {
        display: none
    }
    .header-offcanvas.header-offcanvas-always-show .header-offcanvas-topbar {
        display: block;
        text-align: center
    }
    .header-offcanvas.header-offcanvas-always-show .header-offcanvas-topbar .header-offcanvas-topbar-icons {
        background: #f3f3f3;
        margin: 0 -20px
    }
    .header-offcanvas.header-offcanvas-always-show .header-offcanvas-topbar .header-offcanvas-topbar-icons>.navcart {
        float: left;
        width: 50%
    }
    .header-offcanvas.header-offcanvas-always-show .header-offcanvas-topbar .header-offcanvas-topbar-icons .navcart~.navbar-user {
        float: left;
        width: 50%
    }
    .header-offcanvas.header-offcanvas-always-show .header-offcanvas-topbar .header-offcanvas-topbar-icons .navcart~.navbar-user a span {
        display: none
    }
    .header-offcanvas.header-offcanvas-always-show .header-offcanvas-topbar .header-offcanvas-topbar-icons>div {
        position: relative;
        padding-bottom: 20px;
        padding-top: 20px
    }
    .header-offcanvas.header-offcanvas-always-show .header-offcanvas-topbar .header-offcanvas-topbar-icons>div:nth-child(2):before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 100%;
        width: 1px;
        background: #e1e1e1
    }
    .header-offcanvas.header-offcanvas-always-show .header-offcanvas-topbar .navbar-header {
        float: none;
        margin: 30px 0
    }
    .header-offcanvas.header-offcanvas-always-show .header-offcanvas-topbar .navbar-header .navbar-brand {
        float: none
    }
    .header-offcanvas.header-offcanvas-always-show~#wrapper {
        -webkit-transform: translate3d(300px, 0, 0);
        transform: translate3d(300px, 0, 0);
        width: calc(100% - 300px);
        width: -webkit-calc(100% - 300px);
        width: -moz-calc(100% - 300px)
    }
    .header-offcanvas.header-offcanvas-always-show~#wrapper .commerce h3[class^="woo-cart"] {
        font-size: 1em
    }
    .header-offcanvas.header-offcanvas-always-show~#wrapper .commerce .commerce-shipping-calculator h3 a:after {
        margin-top: 1px
    }
    .header-offcanvas.header-offcanvas-always-show~#wrapper #header {
        display: none
    }
    .header-offcanvas.header-offcanvas-always-show~#wrapper .navbar-toggle-fixed {
        display: none
    }
    .header-offcanvas.header-offcanvas-always-show~#wrapper>.offcanvas-overlay {
        display: none
    }
    .header-offcanvas.header-offcanvas-always-show~#wrapper .container,
    .header-offcanvas.header-offcanvas-always-show~#wrapper .container-fluid {
        width: 100%
    }
}

.header-offcanvas .navbar-toggle-fixed {
    position: relative;
    left: auto;
    top: auto;
    height: auto;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    padding: 20px 0;
    text-align: right
}

.header-offcanvas .navbar-toggle-fixed .navbar-toggle {
    height: 30px;
    line-height: 30px
}

.header-offcanvas .navbar-toggle-fixed .navbar-toggle .bar-top {
    top: 7px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: transform .4s;
    -o-transition: transform .4s;
    transition: transform .4s
}

.header-offcanvas .navbar-toggle-fixed .navbar-toggle .bar-middle {
    opacity: 0;
    -webkit-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s
}

.header-offcanvas .navbar-toggle-fixed .navbar-toggle .bar-bottom {
    top: -5px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: transform .4s;
    -o-transition: transform .4s;
    transition: transform .4s
}

.header-offcanvas .offcanvas-widget {
    margin-top: 2rem
}

@media (max-width:480px) {
    .header-offcanvas .widget .widget-title {
        font-size: 1em
    }
}

.header-offcanvas .language-switcher,
.header-offcanvas .currencies-switcher {
    margin: 0 0 20px
}

.header-offcanvas .language-switcher label,
.header-offcanvas .currencies-switcher label {
    font-size: 85%;
    margin: 0;
    width: 35%;
    text-transform: uppercase
}

.header-offcanvas .language-switcher .form-flat-select,
.header-offcanvas .currencies-switcher .form-flat-select {
    display: inline-block;
    width: 62%
}

.header-offcanvas .language-switcher .form-flat-select select,
.header-offcanvas .currencies-switcher .form-flat-select select {
    width: 100%;
    padding: 0.5em 2em 0.5em 1em
}

@media (min-width:900px) {
    .header-offcanvas .offcanvas-user,
    .header-offcanvas .mobile-offcanvas-navbar {
        display: none
    }
    .open-offcanvas .header-offcanvas {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    .open-offcanvas .offcanvas-overlay {
        opacity: 0;
        visibility: visible
    }
}

.offcanvas-nav {
    margin: 0;
    padding: 0
}

.offcanvas-nav li {
    display: block;
    position: relative
}

.offcanvas-nav li.menu-item-has-children {
    position: relative
}

.offcanvas-nav li.menu-item-has-children .caret {
    border: none;
    vertical-align: baseline;
    margin-left: .14285714285714285em;
    font-size: 2em;
    height: 100%;
    padding: 10px;
    position: absolute;
    right: 10px;
    top: 0;
    width: 40px;
    text-align: center
}

.offcanvas-nav li.menu-item-has-children .caret:before {
    content: "\f107";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.offcanvas-nav li.menu-item-has-children.open>.megamenu-title>.caret:before,
.offcanvas-nav li.menu-item-has-children.open>a>.caret:before,
.offcanvas-nav li.menu-item-has-children.open>.dropdown-menu>.dropdown-submenu.open>a>.caret:before {
    content: "\f068";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.offcanvas-nav li.menu-item-has-children.open .dropdown-menu {
    background-color: transparent;
    border: 0 none;
    box-shadow: none;
    float: none;
    margin-top: 0;
    position: static;
    width: auto;
    padding: 0
}

.offcanvas-nav li.menu-item-has-children.open .dropdown-menu>li>a {
    line-height: 1.65517em;
    padding: 0.689655em 1.03448em 0.689655em 1.72414em
}

.offcanvas-nav li.menu-item-has-children.open .dropdown-menu .dropdown-menu>li>a {
    padding-left: 3.44828em
}

.offcanvas-nav li.active>a,
.offcanvas-nav li.open>a,
.offcanvas-nav a:hover {
    color: #9fce4e
}

.offcanvas-nav li.active>a:before,
.offcanvas-nav li.open>a:before,
.offcanvas-nav a:hover:before {
    background: #9fce4e
}

.offcanvas-nav>li>a {
    padding: 0.689655em 0;
    padding-left: 20px;
    font-size: 15px;
}

.offcanvas-nav a {
    display: block;
    line-height: 1.65517em;
    padding: 0.689655em 0;
    text-transform: uppercase;
    position: relative;
    color: #333
}

/* .offcanvas-nav a:before {
    content: '';
    width: 10px;
    height: 1px;
    background: #777;
    display: inline-block;
    margin-right: 20px;
    vertical-align: middle;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s
} */

.offcanvas-nav .dropdown-menu>li>a:hover,
.offcanvas-nav .dropdown-menu>li>a:focus {
    background: transparent
}

.offcanvas-nav .dropdown-menu>li.active>a,
.offcanvas-nav .dropdown-menu>li.active>a:hover,
.offcanvas-nav .dropdown-menu>li.active>a:focus {
    background: transparent
}

.offcanvas-nav .dropdown-menu a {
    color: #333;
	font-size: 13px;
}

.offcanvas-nav .dropdown-menu a:hover {
    color: #9fce4e
}

.offcanvas-nav .navbar-search {
    display: none
}

@media (min-width:900px) {
    .page-header-overlay.open-offcanvas {
        overflow: hidden
    }
    .page-header-overlay .offcanvas-wrap {
        padding-bottom: 20px
    }
    .page-header-overlay .padding-wrap .navbar-offcanvas-overlay,
    .page-header-overlay .padding-wrap .offcanvas-overlay {
        left: -40px;
        right: -40px
    }
    .page-header-overlay .padding-wrap .offcanvas-overlay {
        width: auto
    }
    .navbar-offcanvas-overlay {
        padding-bottom: 60px;
        padding-top: 60px;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        width: auto;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        background: rgba(26, 26, 26, 0.9);
        -webkit-transform-origin: center center 0;
        -moz-transform-origin: center center 0;
        -ms-transform-origin: center center 0;
        transform-origin: center center 0;
        -webkit-transition-delay: .3s;
        transition-delay: .3s
    }
    .navbar-offcanvas-overlay .offcanvas-widget,
    .navbar-offcanvas-overlay .language-switcher,
    .navbar-offcanvas-overlay .currencies-switcher {
        display: none
    }
    .navbar-offcanvas-overlay .navbar-toggle .icon-bar {
        width: 50px
    }
    .navbar-offcanvas-overlay .offcanvas-wrap {
        max-width: 90%;
        width: 1170px;
        margin: 0 auto
    }
    .open-offcanvas .navbar-offcanvas-overlay {
        opacity: 1;
        filter: alpha(opacity=100);
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
    .navbar-offcanvas-overlay .navbar-toggle-fixed .navbar-toggle {
        display: inline-block
    }
    .navbar-offcanvas-overlay .navbar-toggle-fixed .navbar-toggle .icon-bar {
        border-color: #fff;
        background: #fff
    }
    .navbar-offcanvas-overlay .offcanvas-nav li {
        text-align: center
    }
    .navbar-offcanvas-overlay .offcanvas-nav li .megamenu-title {
        color: #fff
    }
    .navbar-offcanvas-overlay .offcanvas-nav li a {
        color: #fff;
        text-align: center;
        display: inline-block;
        padding-top: .8571428571428571rem;
        padding-bottom: .8571428571428571rem;
        overflow: hidden;
        font-size: 1.2em
    }
    .navbar-offcanvas-overlay .offcanvas-nav li a:before {
        display: none
    }
    .navbar-offcanvas-overlay .offcanvas-nav li.menu-item-has-children .dropdown-menu>li>a {
        padding-left: 0;
        padding-right: 0
    }
    .navbar-offcanvas-overlay .offcanvas-nav li.menu-item-has-children .caret {
        display: inline-block;
        position: static;
        width: auto;
        padding: 0 0 0 15px
    }
    .navbar-offcanvas-overlay .offcanvas-nav li.menu-item-has-children .caret:before {
        content: "\f0d7"
    }
    .navbar-offcanvas-overlay .offcanvas-nav>li>a {
        padding-top: 15px;
        padding-bottom: 5px;
        font-size: 2em;
        padding-left: 10px;
        padding-right: 10px
    }
    .navbar-offcanvas-overlay .offcanvas-nav>li>a:before {
        background: #ffffff none repeat scroll 0 0;
        bottom: -20px;
        content: "";
        display: block;
        height: 2px;
        left: -10px;
        margin: 0;
        position: absolute;
        right: -10px;
        width: auto;
        -webkit-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease
    }
    .navbar-offcanvas-overlay .offcanvas-nav>li>a:hover:before {
        bottom: 0
    }
    .navbar-offcanvas-overlay .offcanvas-nav>li.active>a:before {
        bottom: 0
    }
    .navbar-offcanvas-overlay .searchform {
        text-align: center;
        margin-top: 30px
    }
    .navbar-offcanvas-overlay .searchform input {
        display: inline-block;
        background: transparent;
        border: 0;
        outline: 0;
        padding: .8571428571428571rem;
        text-align: center;
        font-size: 1.7142857142857142em;
        color: #fff
    }
    .navbar-offcanvas-overlay .searchform::-webkit-input-placeholder {
        color: #777
    }
    .navbar-offcanvas-overlay .searchform:-moz-placeholder {
        color: #777
    }
    .navbar-offcanvas-overlay .searchform::-moz-placeholder {
        color: #777
    }
    .navbar-offcanvas-overlay .searchform:-ms-input-placeholder {
        color: #777
    }
    .navbar-offcanvas-overlay .overlay-bottom {
        padding: 30px 0 0;
        overflow: hidden;
        border-top: 1px solid #e1e1e1
    }
    .navbar-offcanvas-overlay .overlay-bottom .copyright {
        float: left;
        width: 50%;
        color: #fff;
        line-height: 30px
    }
    .navbar-offcanvas-overlay .overlay-bottom .social {
        float: right;
        width: 50%;
        text-align: right
    }
    .navbar-offcanvas-overlay .overlay-bottom .social a {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.9);
        color: #444;
        margin-left: 7px
    }
    .navbar-offcanvas-overlay .overlay-bottom .social a i {
        background: none;
        color: #444
    }
    .navbar-offcanvas-overlay .overlay-bottom .social a:hover {
        background: rgba(255, 255, 255, 0.6)
    }
    .navbar-offcanvas-overlay~#wrapper .offcanvas-overlay {
        background: rgba(0, 0, 0, 0.15)
    }
    .padding-wrap {
        margin: 40px;
        overflow: hidden;
        position: relative
    }
    .padding-wrap .container {
        width: 100%;
        max-width: 1200px
    }
    .padding-wrap .content-container {
        padding-top: 40px;
        padding-bottom: 60px
    }
    .padding-wrap .footer-copyright {
        background: transparent
    }
    .padding-wrap .navbar-default {
        -webkit-box-shadow: none;
        box-shadow: none
    }
    .padding-wrap .navbar-fixed-top {
        left: 40px;
        right: 40px
    }
    .padding-wrap .heading-container {
        background: transparent
    }
}

@media (max-width:899px) {
    .navbar-offcanvas-overlay .searchform,
    .navbar-offcanvas-overlay .overlay-bottom {
        display: none
    }
}

.topbar {
    background: #f3f3f3;
    color: #444;
    font-size: 85%
}

@media (max-width:899px) {
    .topbar {
        display: none
    }
}

.topbar .left-topbar {
    line-height: 40px;
    height: 40px
}

.topbar .right-topbar {
    text-align: right;
    line-height: 40px;
    height: 40px
}

.topbar .right-topbar .language-switcher {
    float: right;
    padding-left: .5714285714285714em
}

.topbar .right-topbar .language-switcher a img {
    margin-top: -3px;
    margin-right: 3px
}

.topbar .right-topbar .currencies-switcher {
    float: right;
    padding: 0 .5714285714285714em;
    position: relative
}

.topbar .right-topbar .currencies-switcher .dropdown-menu {
    left: auto;
    right: 0
}

.topbar .right-topbar .currencies-switcher:hover ul.dropdown-menu {
    display: block
}

.topbar .right-topbar .wpml-languages {
    position: relative
}

.topbar .right-topbar .wpml-languages .dropdown-menu {
    left: auto;
    right: 0
}

.topbar .right-topbar .wpml-languages:hover .dropdown-menu {
    display: block
}

.topbar .right-topbar .wpml-languages>a {
    padding: 0
}

.topbar a {
    color: #444
}

.topbar a:hover,
.topbar a:focus {
    color: #444
}

.topbar .dropdown-menu>li>a {
    padding-bottom: 10px;
    padding-top: 10px;
    text-transform: none;
    color: #777
}

.topbar .dropdown-menu>li>a i {
    color: #777
}

.topbar-info a {
    margin-right: 1.1em;
    color: #444
}

.topbar-info a:hover {
    text-decoration: underline
}

.topbar-info a i {
    margin-right: 5px
}

.topbar-social a {
    display: inline-block;
    width: 2.5em;
    text-align: center;
    font-size: 1em;
    color: #444
}

.topbar-social a i {
    border: 1px solid;
    border-radius: 100%;
    font-size: 1em;
    height: 1.7em;
    line-height: 1.7em;
    text-align: center;
    width: 1.7em
}

.topbar-social a:hover {
    color: #9fce4e
}

@media (min-width:900px) {
    .navbar-header-left,
    .navbar-header-right {
        width: calc(25% - 4px);
        width: -webkit-calc(25% - 4px);
        width: -moz-calc(25% - 4px);
        display: inline-block;
        vertical-align: middle
    }
    .header-type-market .navbar-header-left,
    .header-type-market .navbar-header-right {
        margin: 3px 0
    }
}

@media (min-width:900px) {
    .navbar-header-center {
        width: calc(50% - 4px);
        width: -webkit-calc(50% - 4px);
        width: -moz-calc(50% - 4px);
        display: inline-block;
        vertical-align: middle
    }
}

@media (max-width:899px) {
    .navbar-header-left {
        display: none
    }
    .header-type-market .navbar-header-left {
        display: block
    }
}

.navbar-header-left .social a {
    font-size: 15px;
    margin: 0 15px 0 0
}

.header-transparent .navbar-header-left .social a {
    color: #fff
}

.navbar-header-left .social a i {
    background: transparent
}

.navbar-header-left .social a i:hover {
    color: #9fce4e
}

.navbar-header-center {
    text-align: center
}

@media (max-width:899px) {
    .header-type-market .navbar-header-center {
        display: none
    }
}

.navbar-search .searchinput-wrap {
    position: relative
}

.navbar-search .searchinput-wrap .searchsubmit {
    border: 0;
    outline: 0;
    float: left;
    width: 10%;
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    background: #9fce4e;
    -webkit-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
    font-size: 1.2857142857142858em
}

.navbar-search .searchinput-wrap .searchinput {
    display: block;
    width: 100%;
    height: 40px;
    padding: .5714285714285714rem 1rem;
    line-height: 1.6;
    color: #444;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e1e1e1;
    -webkit-border-radius: 0;
    border-radius: 0;
    outline: none;
    -webkit-transition: border-color 0.3s, box-shadow 0.3s;
    -o-transition: border-color 0.3s, box-shadow 0.3s;
    transition: border-color 0.3s, box-shadow 0.3s;
    border-width: 2px;
    border-color: #9fce4e;
    border-right: 0;
    width: 55%;
    padding-right: 45px;
    float: left;
    -webkit-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px
}

.navbar-search .searchinput-wrap .searchinput:focus {
    border-color: #333;
    outline: none
}

.navbar-search .searchinput-wrap .searchinput[disabled],
.navbar-search .searchinput-wrap .searchinput[readonly],
fieldset[disabled] .navbar-search .searchinput-wrap .searchinput {
    cursor: not-allowed;
    background-color: #aaa;
    opacity: 1;
    filter: alpha(opacity=100)
}

textarea.navbar-search .searchinput-wrap .searchinput {
    height: auto
}

.navbar-search .searchinput-wrap .searchinput:focus {
    border-color: #9fce4e
}

@media (max-width:1199px) {
    .navbar-search .searchinput-wrap .searchinput {
        width: 50%
    }
}

.navbar-search .searchinput-wrap .search-product-category {
    float: left;
    width: 35%
}

@media (max-width:1199px) {
    .navbar-search .searchinput-wrap .search-product-category {
        width: 40%
    }
}

.navbar-search .searchinput-wrap .search-product-category select {
    width: 100%;
    border-color: #9fce4e;
    border-width: 2px 2px 2px 1px;
    background: #fff
}

.navbar-search .searchinput-wrap .search-product-category i {
    z-index: 1
}

.navbar-header-right {
    text-align: right
}

@media (max-width:899px) {
    .navbar-header-right {
        display: none
    }
}

.navbar-header-right>div {
    display: inline-block;
    margin-left: 30px
}

.navbar-header-right>div a {
    color: #000
}

.navbar-header-right>div a:hover {
    color: #9fce4e
}

.navbar-header-right>div i {
    font-size: 1.2857142857142858em
}

.navbar-header-right .navbar-minicart {
    padding: 3px 0
}

.navbar-header-right .navbar-user {
    position: relative
}

.navbar-header-right .navbar-user .dropdown-menu {
    left: auto;
    right: 0
}

.navbar-header-right .navbar-user:hover .dropdown-menu {
    display: block
}

.navbar-offcanvas svg,
.navbar-search-button svg,
.cart-icon-mobile svg,
.navbar-user svg,
.navbar-wishlist svg,
.navbar-search svg,
.navbar-minicart svg {
    height: 20px;
    vertical-align: baseline;
    width: 20px;
    display: inline-block;
    vertical-align: middle
}

.navbar-offcanvas svg path,
.navbar-search-button svg path,
.cart-icon-mobile svg path,
.navbar-user svg path,
.navbar-wishlist svg path,
.navbar-search svg path,
.navbar-minicart svg path {
    stroke-miterlimit: 10;
    stroke-width: 2;
    fill: #000;
    stroke: #000
}

@media (min-width:900px) {
    .header-type-market.header-navbar-fixed .navbar-offcanvas svg path,
    .header-type-market.header-navbar-fixed .navbar-search-button svg path,
    .header-type-market.header-navbar-fixed .cart-icon-mobile svg path,
    .header-type-market.header-navbar-fixed .navbar-user svg path,
    .header-type-market.header-navbar-fixed .navbar-wishlist svg path,
    .header-type-market.header-navbar-fixed .navbar-search svg path,
    .header-type-market.header-navbar-fixed .navbar-minicart svg path,
    .header-transparent:not(.header-navbar-fixed):not(.header-type-classic-right) .navbar-offcanvas svg path,
    .header-transparent:not(.header-navbar-fixed):not(.header-type-classic-right) .navbar-search-button svg path,
    .header-transparent:not(.header-navbar-fixed):not(.header-type-classic-right) .cart-icon-mobile svg path,
    .header-transparent:not(.header-navbar-fixed):not(.header-type-classic-right) .navbar-user svg path,
    .header-transparent:not(.header-navbar-fixed):not(.header-type-classic-right) .navbar-wishlist svg path,
    .header-transparent:not(.header-navbar-fixed):not(.header-type-classic-right) .navbar-search svg path,
    .header-transparent:not(.header-navbar-fixed):not(.header-type-classic-right) .navbar-minicart svg path {
        fill: #fff;
        stroke: #fff
    }
}

.header-type-center-logo .navbar-search,
.header-type-center-logo .navbar-minicart,
.header-type-center-logo .navbar-offcanvas {
    display: none
}

@media (min-width:900px) {
    .header-type-center-logo .header-left {
        width: 40%;
        float: left
    }
    .header-type-center-logo .header-left .navbar-collaps {
        float: right
    }
    .header-type-center-logo .navbar-header {
        width: 20%;
        text-align: center;
        padding-left: 15px;
        padding-right: 15px
    }
    .header-type-center-logo .navbar-header .navbar-brand {
        float: none;
        line-height: 90px
    }
    .header-type-center-logo .header-right {
        width: 40%;
        float: left;
        text-align: right
    }
    .header-type-center-logo .header-right .navbar-collapse {
        float: left
    }
    .header-type-center-logo .header-right .navbar-search,
    .header-type-center-logo .header-right .navbar-minicart,
    .header-type-center-logo .header-right .navbar-offcanvas {
        float: left;
        display: block
    }
    .header-type-center-logo .header-right .navbar-search-button,
    .header-type-center-logo .header-right .minicart-link,
    .header-type-center-logo .header-right .navbar-offcanvas-btn {
        line-height: 90px;
        display: block;
        padding: 0 15px
    }
    .header-type-center-logo .primary-nav>li>a {
        line-height: 90px;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 22px;
        padding-right: 22px
    }
    .header-type-center-logo .navbar-fixed-top {
        -webkit-box-shadow: 0 1px 5px rgba(190, 190, 190, 0.46);
        box-shadow: 0 1px 5px rgba(190, 190, 190, 0.46)
    }
    .header-type-center-logo .navbar-fixed-top .logo-fixed {
        max-height: 60px
    }
    .header-type-center-logo .navbar-fixed-top .navbar-brand {
        line-height: 60px
    }
    .header-type-center-logo .navbar-fixed-top .header-right .navbar-search-button,
    .header-type-center-logo .navbar-fixed-top .header-right .minicart-link,
    .header-type-center-logo .navbar-fixed-top .header-right .navbar-offcanvas-btn {
        line-height: 60px
    }
}

.header-type-classic {
    position: relative
}

@media (min-width:900px) {
    .header-type-classic .navbar-header {
        position: absolute;
        left: 0
    }
}

@media (min-width:900px) {
    .header-type-classic-right.header-type-classic .navbar-header {
        width: auto;
        padding: 0;
        position: relative;
        left: auto
    }
}

/* @media (max-width:1200px) {
    .header-type-classic .navbar-header {
        width: auto
    }
} */

.header-type-classic-right.header-type-classic .navbar-default .primary-navbar-collapse {
    width: auto;
    float: right
}

@media (max-width:1200px) {
    .header-type-classic .navbar-default .primary-navbar-collapse {
        width: auto
    }
}

.header-type-classic .navbar-default .primary-navbar-collapse .navbar-nav {
    float: none
}

.header-type-classic .header-right {
    position: absolute;
    right: 0;
    top: 0
}

.header-type-classic-right.header-type-classic .header-right {
    width: auto;
    position: relative;
    right: auto;
    top: auto;
    float: right
}

@media (max-width:1200px) {
    .header-type-classic .header-right {
        width: auto
    }
}

@media (max-width:899px) {
    .header-type-classic .header-right {
        display: none
    }
}

.header-type-classic .header-right>div {
    display: inline-block
}

@media (max-width:1200px) {
    .header-type-classic .header-right>div {
        border: 0
    }
}

.header-type-classic .header-right>div.navbar-offcanvas a,
.header-type-classic .header-right>div .minicart-link,
.header-type-classic .header-right>div a.wishlist,
.header-type-classic .header-right>div .navbar-search-button {
    text-align: center;
    color: #000;
    line-height: 90px;
    display: block;
    padding: 0 15px
}

.header-type-classic .header-right>div.navbar-offcanvas a:hover,
.header-type-classic .header-right>div .minicart-link:hover,
.header-type-classic .header-right>div a.wishlist:hover,
.header-type-classic .header-right>div .navbar-search-button:hover {
    color: #9fce4e
}

.header-type-classic .header-right>div.navbar-offcanvas a {
    padding-right: 0
}

.header-type-classic .header-right>div i {
    font-size: 1.2857142857142858em
}

.header-type-classic .navbar-fixed-top .header-right>div.navbar-offcanvas a,
.header-type-classic .navbar-fixed-top .header-right>div .minicart-link,
.header-type-classic .navbar-fixed-top .header-right>div a.wishlist,
.header-type-classic .navbar-fixed-top .header-right>div .navbar-search-button {
    line-height: 60px
}

.topbar-nav {
    text-align: right;
    float: right
}

.topbar-nav .top-nav {
    margin: 0;
    padding: 0
}

.topbar-nav .top-nav>li {
    display: inline-block;
    vertical-align: top
}

.topbar-nav .top-nav>li>a {
    padding: 0 .5714285714285714em;
    line-height: 40px;
    position: relative;
    color: #444
}

.topbar-nav .top-nav>li>a i {
    margin-right: 5px
}

.topbar-nav .top-nav>li>a:hover,
.topbar-nav .top-nav>li>a:focus {
    background: transparent;
    text-decoration: underline
}

.topbar-nav .top-nav>li:last-child>a:before {
    display: none
}

.topbar-nav .top-nav a {
    display: block;
    color: #444
}

.topbar-nav .top-nav a:hover,
.topbar-nav .top-nav a:focus {
    background: none
}

.topbar-nav .top-nav .dropdown-menu a {
    color: #777;
    padding: .5714285714285714rem 1rem
}

.topbar-nav .top-nav .dropdown-menu a:hover,
.topbar-nav .top-nav .dropdown-menu a:focus {
    color: #444
}

.topbar-nav .top-nav .dropdown-menu .active a {
    background: none;
    color: #444
}

.topbar-nav .top-nav .dropdown-menu .active a:hover,
.topbar-nav .top-nav .dropdown-menu .active a:focus {
    color: #111
}

.topbar-nav .top-nav .dropdown-menu li {
    border-bottom: 1px solid #e1e1e1
}

.topbar-nav .top-nav .dropdown-menu li.menu-item-has-children>.dropdown-menu {
    margin: 0;
    left: 100%;
    top: 0
}

.topbar-nav .top-nav .dropdown-menu li:last-child {
    border: none
}

.searchform-result .no-result {
    padding: .7142857142857143em .7142857142857143em
}

.searchform-result .searchform-result-list {
    padding: .21428571428571427em .7142857142857143em 0
}

.searchform-result .searchform-result-list .search-object {
    font-size: 110%;
    margin: 10px 0 0 0
}

.searchform-result .searchform-result-list .search-object span {
    padding: 0 .35714285714285715em;
    -webkit-border-radius: 0;
    border-radius: 0;
    background: #f3f3f3
}

.searchform-result .searchform-result-list .search-entry {
    border-bottom: 1px dotted #e1e1e1;
    padding: .7142857142857143em 0
}

.searchform-result .searchform-result-list .search-entry .search-image {
    float: left;
    width: 1.7857142857142858em;
    text-align: center;
    margin: 0 .7142857142857143em .35714285714285715em 0;
    font-size: 1.1428571428571428em
}

.searchform-result .searchform-result-list .search-entry .search-image img {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    height: auto
}

.searchform-result .searchform-result-list .search-entry .search-title {
    margin: 0;
    font-size: 13px
}

.searchform-result .searchform-result-list .search-entry .search-excerpt {
    color: #777;
    font-size: 85%
}

.searchform-result .search-view-all {
    padding: 1.4285714285714286em 0;
    text-align: center;
    background-color: #f3f3f3;
    margin: -0.07142857142857142em 0 0;
    border-top: 1px solid #e1e1e1
}

.searchform-result .search-view-all a {
    font-size: 85%;
    text-transform: uppercase
}

.searchform-result .search-view-all a:after {
    content: "\f178";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;

    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.searchform-result .search-view-all a:after {
    margin-left: .35714285714285715em
}

.header-container.header-navbar-fixed {
    padding: 0 0 60px 0
}

.header-search-overlay {
    left: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    z-index: 99999
}

.header-search-overlay>div {
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative
}

.header-search-overlay .header-search-overlay-wrap {
    position: relative;
    height: 100%
}

.header-search-overlay .searchform {
    width: 100%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding-left: 60px
}

@media (max-width:899px) {
    .header-search-overlay .searchform {
        padding-left: 25px
    }
}

.header-search-overlay .searchform:before {
    content: "\f002";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.header-search-overlay .searchform:before {
    font-size: 20px;
    left: 0;
    line-height: 2.77143em;
    position: absolute;
    text-align: right;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    width: 50px
}

@media (max-width:899px) {
    .header-search-overlay .searchform:before {
        width: auto;
        font-size: 16px
    }
}

.header-search-overlay .searchform .searchinput {
    border: medium none;
    font-size: 2.5714285714285716em;
    width: 100%;
    outline: none;
    font-style: italic
}

.navbar-fixed-top .header-search-overlay .searchform .searchinput {
    font-size: 1.7142857142857142em
}

.header-type-toggle-offcanvas .header-search-overlay .searchform .searchinput {
    font-size: 1.7142857142857142em
}

@media (max-width:899px) {
    .header-search-overlay .searchform .searchinput {
        font-size: 1.2857142857142858em
    }
}

.header-search-overlay .searchform.loading~.close {
    display: block
}

.header-search-overlay .searchform.loading~.close>span {
    display: none
}

.header-search-overlay .searchform.loading~.close:after {
    content: "\f110";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.header-search-overlay .searchform.loading~.close:after {
    -webkit-animation: fa-spin 1s infinite linear;
    -o-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear;
    -webkit-animation-timing-function: steps(8, start);
    animation-timing-function: steps(8, start)
}

.header-search-overlay .close {
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    height: 40px;
    position: absolute;
    right: 40px;
    width: 40px;
    opacity: 1;
    filter: alpha(opacity=100);
    font-size: 18px
}

@media (max-width:899px) {
    .header-search-overlay .close {
        right: 0;
        width: 20px
    }
}

.header-search-overlay .searchform-result {
    background: #dadada;
    position: absolute;
    top: 100%;
    width: 100%;
    -webkit-box-shadow: 0 5px 6px rgba(0, 0, 0, 0.51);
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.51)
}


.header-search-overlay .searchform-result .no-result {
    font-size: 1.1em
}

.header-search-overlay .searchform-result .searchform-result-list .search-entry {
    border-bottom-color: #fbfbfb
}

.header-search-overlay .searchform-result .searchform-result-list .search-entry .search-title {
    font-size: 1.1em
}

@media (min-width:900px) {
    .header-fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000
    }
}

@media (min-width:900px) {
    .header-absolute {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        background: transparent
    }
    .header-type-classic-right.header-absolute .navbar-container {
        margin-top: 30px
    }
    .header-type-classic-right.header-absolute .navbar-wrap {
        padding-left: 15px;
        padding-right: 15px;
        background: #fff
    }
    .header-type-classic-right.header-absolute .topbar {
        background: transparent;
        color: #fff
    }
    .header-type-classic-right.header-absolute .topbar~.navbar-container {
        margin-top: 0
    }
    .header-type-classic-right.header-absolute .topbar a,
    .header-type-classic-right.header-absolute .topbar .top-nav>li>a,
    .header-type-classic-right.header-absolute .topbar .top-nav a {
        color: #fff
    }
    .header-type-classic-right.header-absolute .topbar .dropdown-menu a {
        color: #777
    }
}

@media (min-width:900px) {
    .header-transparent .header-right>div {
        border: none;
    }
    .header-transparent .navbar-default:not(.navbar-fixed-top) {
        background: rgba(0, 0, 0, 0);
        -webkit-box-shadow: none;
        box-shadow: none
    }
    .header-transparent .navbar-default:not(.navbar-fixed-top).navbar-fixed-top {
        background: #fff;
        border-color: transparent
    }
    .header-transparent .navbar-default:not(.navbar-fixed-top) .dropdown-menu a {
        background: transparent
    }
    .header-transparent .navbar-default:not(.navbar-fixed-top) .megamenu>.dropdown-menu .dropdown-menu {
        background: transparent
    }
}

@media (min-width:900px) {
    .header-type-classic .header-container.page-heading-default .navbar-container {
        padding-top: 30px;
        padding-bottom: 30px;
        position: relative
    }
}

.heading-container {
    background: #f3f3f3;
    overflow: hidden;
    position: relative
}

.heading-container>div {
    height: 100%
}

.heading-container.heading-resize {
    width: 100%
}

.heading-container .page-breadcrumb {
    font-size: 85%;
    text-transform: uppercase;
    text-align: center
}

.heading-container .heading-standar {
    position: relative
}

.heading-container .heading-standar .page-breadcrumb {
    float: none;
    padding: 20px 0
}

.heading-container .heading-parallax {
    position: relative
}

.heading-container .heading-background {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative
}

@media (max-width:1199px) {
    .heading-container .heading-background {
        padding-top: 0;
    }
}

.heading-container .heading-background .heading-wrap {
    position: relative;
    text-align: center;
    padding: 30px 0
}

@media (min-width:900px) {
    .heading-container .heading-background .heading-wrap {
        height: 200px
    }
    .header-transparent~.heading-container .heading-background .heading-wrap {
        height: 300px
    }
}

.heading-container .heading-background .heading-wrap:after {
    content: "";
    display: inline-block;
    height: 100%;
    margin-right: -0.3em;
    vertical-align: middle;
    width: 1px
}

.heading-container .heading-background .page-title {
    display: inline-block;
    vertical-align: middle
}

.heading-container .heading-background .page-title h1 {
    color: #fff;
    display: block;
    margin: 0;
    text-transform: uppercase
}

@media (max-width:767px) {
    .heading-container .heading-background .page-title h1 {
        font-size: 1.5em
    }
}

.heading-container .heading-background .page-title .subtitle {
    color: #fff;
    font-size: 1.2em;
    text-transform: uppercase;
    margin-top: 15px;
    display: block
}

@media (max-width:767px) {
    .heading-container .heading-background .page-title .subtitle {
        font-size: 1em;
        text-align: center
    }
}

.heading-container .heading-background .page-title .page-breadcrumb {
    margin-top: 15px
}

@media (max-width:767px) {
    .heading-container .heading-background .page-title .page-breadcrumb {
        display: none
    }
}

.heading-container .heading-background .page-title .page-breadcrumb .breadcrumb>li {
    color: #fff
}

.heading-container .heading-background .page-title .page-breadcrumb .breadcrumb>li+li::before {
    color: #fff
}

.heading-container .heading-background .page-title .page-breadcrumb a {
    color: #fff
}

@media (max-width:899px) {
    .heading-container .heading-background .heading-wrap {
        display: block;
        height: auto;
        margin: 0 auto
    }
    .heading-container .heading-background .page-title {
        display: inline-block;
        padding-bottom: 30px;
        padding-top: 30px
    }
}

@media (min-width:768px) {
    .heading-container.heading-button .heading-background .heading-wrap {
        text-align: left
    }
}

.heading-container.heading-button .heading-background .heading-wrap .heading-button-btn {
    margin-top: 30px;
    padding-right: 3rem;
    padding-left: 3rem
}

.navbar-default .navbar-toggle {
  border: none;
}
.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
  background: none;
}
.header-type-toggle-offcanvas .navbar-default-col,
.header-type-toggle-offcanvas .navbar-default-wrap {
  height: 70px;
}
.heading-container .heading-background {
  transform: translate3d(0px, 0px, 0px);
}
.heading-container .heading-background.bg-2 {
  padding-top: 90px; 
  transform: translate3d(0px, 0px, 0px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.heading-container .heading-background.bg-3 {
  padding-top: 110px; 
  transform: translate3d(0px, 0px, 0px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.heading-container .heading-background.bg-blog-minimal {
  transform: translate3d(0px, 0px, 0px);
}
.heading-container .heading-background.bg-construction {
  transform: translate3d(0px, 0px, 0px);
}

/* === 3.3. BREADCRUMBS === */

.breadcrumb {
    list-style: none;
    margin: 0;
    padding: 0
}

.breadcrumb>li {
    display: inline-block;
    color: #444
}

.breadcrumb>li+li:before {
    content: "\f105";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.breadcrumb>li+li:before {
    padding: 0 15px;
    color: #444
}

.breadcrumb>li a {
    color: #444
}

.breadcrumb>li a:hover {
    color: #9fce4e;
    text-decoration: underline
}

.breadcrumb>.active {
    color: #777
}

/* === 3.4. PAGINATION === */

.paginate {
    display: block;
    margin-top: 60px;
    padding: 0 0 .8571428571428571rem 0
}

.container-full .paginate {
    padding-left: 30px;
    padding-right: 30px
}

.paginate .paginate_links {
    display: table;
    margin: 0 auto
}

.paginate .paginate_links .pagination-meta,
.paginate .paginate_links .page-numbers {
    padding: 9px 5px;
    min-width: 40px;
    text-align: center;
    border: 1px solid #e1e1e1;
    display: inline-block;
    float: left;
    margin: 0 3px;
    color: #333
}

.paginate .paginate_links .pagination-meta.current,
.paginate .paginate_links .page-numbers.current {
    background: #333;
    border: 1px solid #333;
    color: #fff
}

.paginate .paginate_links .pagination-meta.dots,
.paginate .paginate_links .page-numbers.dots {
    border: none
}

.paginate .paginate_links a.page-numbers:hover,
.paginate .paginate_links a.page-numbers:focus {
    border: 1px solid #333
}

.paginate .paginate_links .pagination-meta {
    color: #444;
    border-color: transparent;
    margin-right: .7142857142857143em
}

@media (max-width:480px) {
    .paginate .paginate_links {
        float: none
    }
    .paginate .paginate_links .pagination-meta {
        display: none
    }
}

.paginate .paginate_next_prev {
    display: block
}

.paginate .paginate_next_prev .nav-next a,
.paginate .paginate_next_prev .nav-previous a {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: .5714285714285714rem 2rem;
    font-size: 13px;
    line-height: 1.6;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    text-decoration: none;
    background: transparent;
    border: 2px solid #333;
    text-transform: uppercase;
    color: #333;
    position: relative;
    font-weight: 600;
    border-width: 0 0 2px 0;
    color: #777;
    border-color: #777;
    padding-left: 0;
    padding-right: 0
}

.paginate .paginate_next_prev .nav-next a i,
.paginate .paginate_next_prev .nav-previous a i,
.paginate .paginate_next_prev .nav-next a span,
.paginate .paginate_next_prev .nav-previous a span {
    z-index: 2;
    position: relative
}

.paginate .paginate_next_prev .nav-next a.btn-icon-left i,
.paginate .paginate_next_prev .nav-previous a.btn-icon-left i {
    margin-right: 5px
}

.paginate .paginate_next_prev .nav-next a.btn-icon-right i,
.paginate .paginate_next_prev .nav-previous a.btn-icon-right i {
    margin-left: 5px
}

.paginate .paginate_next_prev .nav-next a.btn-icon-right.btn-icon-slide-in,
.paginate .paginate_next_prev .nav-previous a.btn-icon-right.btn-icon-slide-in {
    padding-left: 40px;
    padding-right: 40px;
    position: relative
}

.paginate .paginate_next_prev .nav-next a.btn-icon-right.btn-icon-slide-in span,
.paginate .paginate_next_prev .nav-previous a.btn-icon-right.btn-icon-slide-in span,
.paginate .paginate_next_prev .nav-next a.btn-icon-right.btn-icon-slide-in i,
.paginate .paginate_next_prev .nav-previous a.btn-icon-right.btn-icon-slide-in i {
    -webkit-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out
}

.paginate .paginate_next_prev .nav-next a.btn-icon-right.btn-icon-slide-in i,
.paginate .paginate_next_prev .nav-previous a.btn-icon-right.btn-icon-slide-in i {
    position: absolute;
    right: 40px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    opacity: 0;
    filter: alpha(opacity=0)
}

.paginate .paginate_next_prev .nav-next a.btn-icon-right.btn-icon-slide-in span,
.paginate .paginate_next_prev .nav-previous a.btn-icon-right.btn-icon-slide-in span {
    position: relative;
    left: 0
}

.paginate .paginate_next_prev .nav-next a.btn-icon-right.btn-icon-slide-in:hover span,
.paginate .paginate_next_prev .nav-previous a.btn-icon-right.btn-icon-slide-in:hover span {
    left: -15px
}

.paginate .paginate_next_prev .nav-next a.btn-icon-right.btn-icon-slide-in:hover i,
.paginate .paginate_next_prev .nav-previous a.btn-icon-right.btn-icon-slide-in:hover i {
    opacity: 1;
    filter: alpha(opacity=100);
    right: 25px
}

.paginate .paginate_next_prev .nav-next a.btn-round,
.paginate .paginate_next_prev .nav-previous a.btn-round {
    -webkit-border-radius: 40px;
    border-radius: 40px
}

.paginate .paginate_next_prev .nav-next a:hover,
.paginate .paginate_next_prev .nav-previous a:hover,
.paginate .paginate_next_prev .nav-next a:focus,
.paginate .paginate_next_prev .nav-previous a:focus {
    color: #fff;
    border-color: #9fce4e;
    background: #9fce4e
}

.paginate .paginate_next_prev .nav-next a:focus,
.paginate .paginate_next_prev .nav-previous a:focus,
.paginate .paginate_next_prev .nav-next a:hover,
.paginate .paginate_next_prev .nav-previous a:hover {
    background: transparent;
    color: #9fce4e
}

/* === 3.5. BUTTONS === */

.close {
    float: right;
    font-size: 19.5px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    opacity: .3;
    filter: alpha(opacity=30)
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: .5;
    filter: alpha(opacity=50)
}

button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none
}

/* === 3.6. CAROUSEL === */

.carousel {
    position: relative
}

.carousel.fade {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transition: none;
    -o-transition: none;
    transition: none
}

.carousel-inner {
    position: relative;
    overflow: hidden;
    width: 100%
}

.carousel-inner>.item {
    display: none;
    position: relative;
    -webkit-transition: .6s ease-in-out left;
    -o-transition: .6s ease-in-out left;
    transition: .6s ease-in-out left
}

.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
    line-height: 1
}

.carousel-inner>.active,
.carousel-inner>.next,
.carousel-inner>.prev {
    display: block
}

.carousel-inner>.active {
    left: 0
}

.carousel-inner>.next,
.carousel-inner>.prev {
    position: absolute;
    top: 0;
    width: 100%
}

.carousel-inner>.next {
    left: 100%
}

.carousel-inner>.prev {
    left: -100%
}

.carousel-inner>.next.left,
.carousel-inner>.prev.right {
    left: 0
}

.carousel-inner>.active.left {
    left: -100%
}

.carousel-inner>.active.right {
    left: 100%
}

.carousel.fade {
    opacity: 1;
    filter: alpha(opacity=100)
}

.carousel.fade .carousel-inner .item {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition-property: opacity;
    width: 100%;
    height: 100%
}

.carousel.fade .carousel-inner .active {
    opacity: 1;
    z-index: 1
}

.carousel.fade .carousel-inner .active.left,
.carousel.fade .carousel-inner .active.right {
    left: 0;
    opacity: 0;
    z-index: 1
}

.carousel.fade .carousel-inner .next.left,
.carousel.fade .carousel-inner .prev.right {
    opacity: 1
}

.carousel-control {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 15%;
    opacity: .5;
    filter: alpha(opacity=50);
    font-size: 20px;
    color: #fff;
    text-align: center;
    z-index: 10
}

.carousel-control.right {
    left: auto;
    right: 0
}

.carousel-control:hover,
.carousel-control:focus {
    outline: 0;
    color: #fff;
    text-decoration: none;
    opacity: .9;
    filter: alpha(opacity=90)
}

.carousel-control .carousel-icon-prev,
.carousel-control .carousel-icon-next {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block
}

.carousel-control .carousel-icon-prev {
    left: 50%;
    margin-left: -10px
}

.carousel-control .carousel-icon-next {
    right: 50%;
    margin-right: -10px
}

.carousel-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 15;
    width: 60%;
    margin-left: -30%;
    padding-left: 0;
    list-style: none;
    text-align: center
}

.carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    text-indent: -999px;
    border: 1px solid #fff;
    border-radius: 10px;
    cursor: pointer;
    margin: 0px 3px;
    background-color: #000 \9;
    background-color: rgba(0, 0, 0, 0)
}

.carousel-indicators .active {
    background-color: #fff
}

@media screen and (min-width:768px) {
    .carousel-control .carousel-icon-prev,
    .carousel-control .carousel-icon-next {
        width: 30px;
        height: 30px;
        margin-top: -15px;
        font-size: 30px
    }
    .carousel-control .carousel-icon-prev {
        margin-left: -15px
    }
    .carousel-control .carousel-icon-next {
        margin-right: -15px
    }
    .carousel-indicators {
        bottom: 20px
    }
}

.custom .tp-bullet {
    background: transparent;
    border: 1px solid #fff;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    margin: 3px
}

.custom .tp-bullet:hover,
.custom .tp-bullet.selected {
    background: #fff
}

.custom.tparrows {
    background: transparent;
    border: 1px solid #fff;
    color: #fff
}

.custom.tparrows:hover {
    background: #9fce4e;
    border-color: #9fce4e
}

.custom.tparrows.tp-leftarrow:before {
    content: "\44";
    font-family: 'ElegantIcons'
}

.custom.tparrows.tp-rightarrow:before {
    content: "\45";
    font-family: 'ElegantIcons'
}

@-webkit-keyframes text-from-bottom {
    0% {
        top: 70px;
        opacity: 0;
        filter: alpha(opacity=0)
    }
    50% {
        top: 45px;
        opacity: 0;
        filter: alpha(opacity=0)
    }
    100% {
        top: 0px;
        opacity: 1;
        filter: alpha(opacity=100)
    }
}

@-moz-keyframes text-from-bottom {
    0% {
        top: 70px;
        opacity: 0;
        filter: alpha(opacity=0)
    }
    50% {
        top: 45px;
        opacity: 0;
        filter: alpha(opacity=0)
    }
    100% {
        top: 0px;
        opacity: 1;
        filter: alpha(opacity=100)
    }
}

@-o-keyframes text-from-bottom {
    0% {
        top: 70px;
        opacity: 0;
        filter: alpha(opacity=0)
    }
    50% {
        top: 45px;
        opacity: 0;
        filter: alpha(opacity=0)
    }
    100% {
        top: 0px;
        opacity: 1;
        filter: alpha(opacity=100)
    }
}

@keyframes text-from-bottom {
    0% {
        top: 70px;
        opacity: 0;
        filter: alpha(opacity=0)
    }
    50% {
        top: 45px;
        opacity: 0;
        filter: alpha(opacity=0)
    }
    100% {
        top: 0px;
        opacity: 1;
        filter: alpha(opacity=100)
    }
}

/* === 3.7. CAROUSELFRED === */

@media (max-width:899px) {
    .animate-box.animated {
        visibility: visible
    }
}

.caroufredsel {
    position: relative;
    overflow: hidden;
    padding: 0;
    opacity: 0;
    filter: alpha(opacity=0)
}

.caroufredsel .caroufredsel_wrapper {
    margin: 0;
}

.caroufredsel.product-slider .caroufredsel-wrap {
    overflow: visible
}

.caroufredsel.product-slider.nav-position-right .product-slider-title {
    padding-right: 100px
}

.caroufredsel.product-slider.nav-position-right .product-slider-title h3 {
    padding-right: 30px;
    margin: 0;
    line-height: 40px;
    text-transform: uppercase
}

@media (max-width:991px) {
    .caroufredsel.product-slider.nav-position-right .product-slider-title h3 {
        padding: 0
    }
    .caroufredsel.product-slider.nav-position-right .product-slider-title h3:after {
        display: none
    }
}

.caroufredsel.product-slider.nav-position-right .product-slider-title~.caroufredsel-wrap .caroufredsel-next {
    right: 0;
    top: -70px;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    margin: 0
}

.caroufredsel.product-slider.nav-position-right .product-slider-title~.caroufredsel-wrap .caroufredsel-prev {
    left: auto;
    right: 50px;
    top: -70px;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    margin: 0
}

.caroufredsel.product-slider.nav-position-center .product-slider-title h3 {
    font-size: 1.7142857142857142em;
    text-align: center;
    padding: 0.6em 1em;
    margin: 0 0 2em;
    position: relative;
    text-transform: uppercase;
    display: table;
    margin: 0 auto;
    margin-bottom: 40px
}

@media (max-width:480px) {
    .caroufredsel.product-slider.nav-position-center .product-slider-title h3 {
        font-size: 1.2857142857142858em
    }
}

.caroufredsel.product-slider.nav-position-center .product-slider-title h3:after {
    content: '';
    width: 130px;
    height: 1px;
    background: #e1e1e1;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
}

.caroufredsel.product-slider.nav-position-center .product-slider-title h3:before {
    content: '';
    width: 9px;
    height: 9px;
    border: 1px solid #e1e1e1;
    background: #fff;
    position: absolute;
    bottom: -4px;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%) rotate(45deg);
    transform: -moz-translateX(-50%) rotate(45deg);
    transform: -webkit-translateX(-50%) rotate(45deg);
    transform: -ms-translateX(-50%) rotate(45deg);
    transform: -o-translateX(-50%) rotate(45deg)
}

@media (max-width:480px) {
    .caroufredsel.product-slider.nav-position-center .product-slider-title h3 {
        font-size: 1.1428571428571428em
    }
}

.caroufredsel.product-slider.nav-position-center .product-slider-title~.caroufredsel-wrap .caroufredsel-next:after,
.caroufredsel.product-slider.nav-position-center .product-slider-title~.caroufredsel-wrap .caroufredsel-prev:after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 0;
    width: 100%;
    height: 3px;
    background: #999;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.caroufredsel.product-slider.nav-position-center .product-slider-title~.caroufredsel-wrap .caroufredsel-next:hover:after,
.caroufredsel.product-slider.nav-position-center .product-slider-title~.caroufredsel-wrap .caroufredsel-prev:hover:after {
    background: #9fce4e
}

.caroufredsel.product-slider.nav-position-center .product-slider-title~.caroufredsel-wrap .caroufredsel-next {
    right: 10px;
    top: -65px;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0)
}

.caroufredsel.product-slider.nav-position-center .product-slider-title~.caroufredsel-wrap .caroufredsel-prev {
    left: 10px;
    top: -65px;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0)
}

.caroufredsel .caroufredsel-wrap {
    position: relative;
    overflow: hidden;
    height: 100%;
    margin: 0 -10px
}

.caroufredsel-item-no-padding.caroufredsel .caroufredsel-wrap {
    margin: 0
}

.caroufredsel .caroufredsel-wrap .caroufredsel-items {
    margin: 0;
    padding: 0;
    list-style: none
}

.caroufredsel .caroufredsel-wrap .caroufredsel-items>li {
    float: left
}

.caroufredsel .caroufredsel-wrap .caroufredsel-item {
    float: left;
    padding: 0;
    list-style: none;
    margin: 0 10px
}

.caroufredsel-item-no-padding.caroufredsel .caroufredsel-wrap .caroufredsel-item {
    margin: 0
}

.client .caroufredsel .caroufredsel-wrap .caroufredsel-item {
    text-align: center
}

.caroufredsel .caroufredsel-wrap .caroufredsel-item>a>img,
.caroufredsel .caroufredsel-wrap .caroufredsel-item>img {
    width: 100%
}

.client .caroufredsel .caroufredsel-wrap .caroufredsel-item>a>img,
.client .caroufredsel .caroufredsel-wrap .caroufredsel-item>img {
    width: auto
}

.caroufredsel .caroufredsel-wrap .caroufredsel-next,
.caroufredsel .caroufredsel-wrap .caroufredsel-prev {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    -webkit-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    width: 40px;
    height: 40px;
    line-height: 44px;
    font-size: 20px;
    background-color: #9fce4e;
    border: 1px solid #9fce4e;
    text-align: center;
    z-index: 100;
    color: #fff
}

.caroufredsel .caroufredsel-wrap .caroufredsel-next:hover,
.caroufredsel .caroufredsel-wrap .caroufredsel-prev:hover {
    background-color: #fff;
    color: #9fce4e
}

.caroufredsel .caroufredsel-wrap .caroufredsel-next.disabled,
.caroufredsel .caroufredsel-wrap .caroufredsel-prev.disabled {
    display: none;
}

.caroufredsel .caroufredsel-wrap .caroufredsel-prev {
    left: -45px
}

.caroufredsel .caroufredsel-wrap .caroufredsel-prev:before {
    content: "\44";
    position: relative;
    display: inline-block;
    font-family: 'ElegantIcons';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.caroufredsel .caroufredsel-wrap .caroufredsel-next {
    right: -45px
}

.caroufredsel .caroufredsel-wrap .caroufredsel-next:before {
    content: "\45";
    position: relative;
    display: inline-block;
    font-family: 'ElegantIcons';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.caroufredsel .caroufredsel-wrap:hover>.caroufredsel-prev {
    left: 20px
}

.caroufredsel .caroufredsel-wrap:hover>.caroufredsel-next {
    right: 20px
}

.caroufredsel .caroufredsel-pagination {
    text-align: center;
    margin-top: 15px
}

.testimonial.style-1 .caroufredsel .caroufredsel-pagination {
    margin-top: 50px
}

.testimonial.style-4 .caroufredsel .caroufredsel-pagination a,
.testimonial.style-3 .caroufredsel .caroufredsel-pagination a {
    height: 16px;
    width: 16px;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: 2px solid #fff;
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
    background: rgba(0, 0, 0, 0.3)
}

.testimonial.style-4 .caroufredsel .caroufredsel-pagination a:hover,
.testimonial.style-3 .caroufredsel .caroufredsel-pagination a:hover,
.testimonial.style-4 .caroufredsel .caroufredsel-pagination a.selected,
.testimonial.style-3 .caroufredsel .caroufredsel-pagination a.selected {
    -webkit-box-shadow: 0 0 0 1px #9fce4e;
    box-shadow: 0 0 0 1px #9fce4e;
    background: #9fce4e
}

.caroufredsel .caroufredsel-pagination a {
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.5);
    display: inline-block;
    height: 10px;
    margin: 4px;
    text-indent: -9999em;
    width: 10px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg)
}

.caroufredsel .caroufredsel-pagination a:hover,
.caroufredsel .caroufredsel-pagination a.selected {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(0, 0, 0, 0)
}

/* === 3.8. TESTIMONIALS === */

.testimonial.style-1 .caroufredsel .caroufredsel-pagination a:hover,
.testimonial.style-1 .caroufredsel .caroufredsel-pagination a.selected {
    border-color: #fff;
    background: #fff
}

.testimonial.style-1 .caroufredsel .caroufredsel-pagination a {
    border: 1px solid rgba(255, 255, 255, 0.5)
}

@media (min-width:1200px) {
    .testimonial .testimonial-wrap {
        padding: 0 60px
    }
    .style-4.testimonial .testimonial-wrap {
        padding: 0
    }
}

.style-1.testimonial .testimonial-wrap {
    text-align: center
}

.style-3.testimonial .testimonial-wrap {
    text-align: center
}

.testimonial .testimonial-text {
    padding: 10px 30px 45px;
    position: relative;
    font-style: italic
}

.testimonial .testimonial-text>span {
    font-size: 60px;
    line-height: 1;
    position: absolute;
    top: 0;
    left: 0;
    font-style: normal
}

.testimonial.bg-transparent .testimonial-text>span:last-child,
.testimonial.bg-transparent .testimonial-text>span:first-child,
.testimonial.bg-transparent .caroufredsel .caroufredsel-wrap .caroufredsel-next,
.testimonial.bg-transparent .caroufredsel .caroufredsel-wrap .caroufredsel-prev,
.testimonial.bg-transparent .testimonial-company,
.testimonial.bg-transparent .testimonial-author,
.testimonial.bg-transparent .testimonial-text {
    color: #fff
}

.testimonial.bg-transparent .testimonial-text {
    background: rgba(0, 0, 0, 0)
}

.testimonial.bg-transparent .testimonial-text:before {
    display: none
}

.testimonial .testimonial-avatar {
    position: relative;
    float: left;
    margin-right: 20px
}

.style-1.testimonial .testimonial-avatar {
    float: none;
    margin: 0 0 10px
}

.style-3.testimonial .testimonial-avatar {
    float: none;
    margin: 0 0 15px
}

.style-4.testimonial .testimonial-avatar {
    display: inline-block;
    float: none;
    vertical-align: middle
}

.testimonial .testimonial-avatar img {
    width: 50px;
    height: 50px;
    -webkit-border-radius: 100%;
    border-radius: 100%
}

.testimonial .testimonial-author {
    text-transform: uppercase;
    display: block
}

.style-1.testimonial .testimonial-author {
    color: #9fce4e
}

.style-3.testimonial .testimonial-author {
    padding: 0 15px;
    font-weight: 600;
    display: inline-block;
    line-height: 1.2;
    width: 48%;
    text-align: right
}

.style-4.testimonial .testimonial-author {
    padding: 0 15px;
    font-weight: 600;
    display: inline-block;
    line-height: 1.2
}

.testimonial .testimonial-company {
    display: block;
    font-style: italic
}

.style-3.testimonial .testimonial-company {
    padding: 0 14px;
    display: inline-block;
    border-left: 1px solid #e1e1e1;
    line-height: 1.2;
    font-style: inherit;
    width: 48%;
    text-align: left
}

.style-4.testimonial .testimonial-company {
    padding: 0 14px;
    display: inline-block;
    border-left: 1px solid #e1e1e1;
    line-height: 1.2;
    font-style: inherit
}

.testimonial .caroufredsel .caroufredsel-wrap .caroufredsel-next {
    font-size: 50px;
    right: 50%;
    margin-right: -124px;
    border: none;
    color: #444;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    background: none
}

.testimonial .caroufredsel .caroufredsel-wrap .caroufredsel-prev {
    font-size: 50px;
    left: 50%;
    margin-left: -124px;
    border: none;
    color: #444;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    background: none
}

.testimonial .caroufredsel .caroufredsel-wrap .caroufredsel-item:hover .caroufredsel-next,
.testimonial .caroufredsel .caroufredsel-wrap .caroufredsel-item:hover .caroufredsel-prev {
    opacity: 1;
    filter: alpha(opacity=100)
}

/* === 3.9. COUNTER === */

.counter {
    margin-bottom: 15px;
    text-align: center
}

.counter.counter-icon-top .counter-icon {
    display: block;
    margin-bottom: 15px
}

.counter.counter-icon-left .counter-icon {
    margin-right: 10px;
    line-height: 1;
    display: inline-block
}

.counter .counter-icon i {
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    border-width: 2px;
    border-style: solid;
    display: inline-block;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.counter .counter-count {
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 1em
}

.counter .counter-unit {
    display: inline-block
}

.counter .counter-number {
    margin-top: 0;
    display: inline-block
}

.counter .counter-text {
    display: block;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 0;
    letter-spacing: 3px;
    margin-top: 10px;
    margin-bottom: 30px
}

.counter.construction {
    text-align: left
}

.counter.construction .counter-count {
    display: inline-block
}

.counter.construction .counter-count .counter-number {
    line-height: 1;
    display: inline-block;
    text-align: right
}

.counter.construction .counter-text {
    margin-left: -30px;
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 30px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0
}

@media (max-width:320px) {
    .counter.construction .counter-text {
        display: block;
        margin-left: 0
    }
}
.counter-block {
  padding-top: 90px;
  padding-bottom: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.counter-block-3 {
  padding-top: 35px;
  padding-bottom: 30px;
}
.counter-icon {
  font-size:24px;
  color:#ffffff;
}
.counter-block-3 .counter-icon {
  color:#000;
}
.counter-number {
  font-size:24px;
  color:#9fce4e;
}
.counter-block-2 .counter-number {
  color:#fff;
}
.counter-block-4 .counter-number {
  font-size: 50px;
  color: #9fce4e;
  font-family: "Atlan-Semibold",Helvetica,Arial,sans-serif;
}
.counter-unit {
  font-size:24px;
  color:#9fce4e;
}
.counter-block-2 .counter-unit {
  color:#fff;
}
.counter-text {
  font-size:16px;
  color:#ffffff;
}
.counter-block-3 .counter-text {
  color:#000;
}
.counter-block-4 .counter-text {
  font-size: 14px;
  color: #444;
}

/* === 3.10. COUNTDOWN TIMER === */

.countdown {
    width: 100%
}

.countdown-item {
    float: left;
    width: 25%;
    text-align: center;
    border-right: 1px solid;
    text-transform: uppercase
}

.countdown-item:last-child {
    border-right: none
}

@media (max-width:767px) {
    .countdown-item {
        border: none;
        width: 50%
    }
}

.countdown-item .countdown-item-value {
    font-size: 2.57em;
    font-weight: 600
}

.countdown-white .countdown-item {
    color: #fff
}

/* === 3.11. INSTAGRAM === */

.instagram .instagram-wrap .row a {
    display: block;
    margin-bottom: 30px
}

@media (min-width:992px) {
    .instagram .instagram-wrap .row:last-child a {
        margin-bottom: 0
    }
}

@media (max-width:320px) {
    .instagram .instagram-wrap .row>div {
        width: 100%;
        float: none
    }
}

.instagram .instagram-item {
    display: block;
    position: relative
}

.instagram .instagram-item:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background: rgba(1, 1, 1, 0.3);
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden
}

.instagram .instagram-item:hover:after {
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible
}

/* === 3.12. TYPED TEXT === */

.dh-typed .dh-typed-text-wrap {
    display: inline-block;
    padding: 5px 10px
}

.dh-typed .dh-typed-text {
    display: inline-block
}

.dh-typed-text-wrap {
	background-color:#000000;
	color:#ffffff;
}

@-webkit-keyframes mymove {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
    50% {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        -o-transform: scale(1.05);
        transform: scale(1.05)
    }
    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes mymove {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
    50% {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        -o-transform: scale(1.05);
        transform: scale(1.05)
    }
    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

/* === 3.13. PIE CHART === */
.piechart {
  max-width: 210px;
  margin-left: auto;
  margin-right: auto;
}
.piechart path:first-child {
  stroke: #e2f0ca;
}
.piechart path:last-child {
  stroke: #9fce4e;
}
.piechart .progressbar-text {
  font-family: "Atlan-Semibold",Helvetica,Arial,sans-serif;
  display: block;
  color: #000 !important;
  font-size: 30px;
}
.block-piechart.skin-dark h4,
.block-piechart.skin-dark p { 
  color: #999;
}
.block-piechart.skin-dark .piechart .progressbar-text { 
  color: #fff !important;
}

/* === 3.14. PRICING TABLE === */

.pricing-table .pricing-column {
    float: left;
    position: relative
}

.pricing-table .pricing-column:first-child .pricing-header,
.pricing-table .pricing-column:first-child .pricing-footer,
.pricing-table .pricing-column:first-child .pricing-body {
    border-left: 1px solid #e1e1e1
}

@media (min-width:992px) {
    .pricing-table .pricing-column.pricing-recommend {
        margin: -20px -1px 0;
        z-index: 1;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15)
    }
    .pricing-table .pricing-column.pricing-recommend .pricing-title {
        padding: 15.714285714285714px 0
    }
    .pricing-table .pricing-column.pricing-recommend .pricing-footer {
        padding: 30.714285714285715px
    }
    .pricing-table .pricing-column.pricing-recommend .pricing-price,
    .pricing-table .pricing-column.pricing-recommend .pricing-title,
    .pricing-table .pricing-column.pricing-recommend .pricing-footer,
    .pricing-table .pricing-column.pricing-recommend .pricing-body {
        border-left-width: 1px
    }
    .pricing-table .pricing-column.pricing-recommend~.pricing-column .pricing-footer {
        border-width: 0px 1px 1px 1px
    }
}

.pricing-table.pricing-1-column .pricing-column {
    width: 100%
}

.pricing-table.pricing-2-column .pricing-column {
    width: 50%
}

.pricing-table.pricing-3-column .pricing-column {
    width: 33.33333333333333%
}

.pricing-table.pricing-4-column .pricing-column {
    width: 25%
}

.pricing-table.pricing-5-column .pricing-column {
    width: 20%
}

@media (max-width:991px) {
    .pricing-table.pricing-4-column .pricing-column,
    .pricing-table.pricing-5-column .pricing-column {
        width: 50%
    }
}

@media (max-width:767px) {
    .pricing-table.pricing-4-column .pricing-column, 
	.pricing-table.pricing-5-column .pricing-column {
        width: 100%;
    }
}

.pricing-table .pricing-default .pricing-header {
    color: #444
}

.pricing-table .pricing-default .pricing-title {
    background: #e1e1e1;
    border-color: #e1e1e1
}

.pricing-table .pricing-default .pricing-price {
    background: #f3f3f3;
    border-color: #f3f3f3
}

.pricing-table .pricing-primary {
    background: #fff
}

.pricing-table .pricing-primary .pricing-header {
    color: #fff
}

.pricing-table .pricing-primary .pricing-title {
    background: #9fce4e;
    border-color: #9fce4e
}

.pricing-table .pricing-primary .pricing-price {
    background: #b5d976;
    border-color: #b5d976
}

.pricing-table .pricing-success .pricing-header {
    color: #fff
}

.pricing-table .pricing-success .pricing-title {
    background: #a0ce4e;
    border-color: #a0ce4e
}

.pricing-table .pricing-success .pricing-price {
    background: #b5d976;
    border-color: #b5d976
}

.pricing-table .pricing-info .pricing-header {
    color: #fff
}

.pricing-table .pricing-info .pricing-title {
    background: #63b5fd;
    border-color: #63b5fd
}

.pricing-table .pricing-info .pricing-price {
    background: #95cdfe;
    border-color: #95cdfe
}

.pricing-table .pricing-warning .pricing-header {
    color: #fff
}

.pricing-table .pricing-warning .pricing-title {
    background: #feab62;
    border-color: #feab62
}

.pricing-table .pricing-warning .pricing-price {
    background: #fec695;
    border-color: #fec695
}

.pricing-table .pricing-danger .pricing-header {
    color: #fff
}

.pricing-table .pricing-danger .pricing-title {
    background: #fe252c;
    border-color: #fe252c
}

.pricing-table .pricing-danger .pricing-price {
    background: #fe585d;
    border-color: #fe585d
}

.pricing-table .pricing-white {
    background: #fff
}

.pricing-table .pricing-title {
    padding: 1.15385em 0;
    border-style: solid;
    border-width: 1px 0 1px 0px;
    border-color: #e1e1e1
}

.pricing-table .pricing-title h3 {
    margin: 0;
    padding: 0;
    text-align: center;
    color: inherit
}

.pricing-table .pricing-price {
    padding: 1.15385rem 0;
    text-align: center;
    font-size: 4em;
    border-style: solid;
    border-width: 0px 0 1px 0px;
    border-color: #e1e1e1
}

.pricing-table .pricing-price .price-value sub {
    font-size: 40%;
    position: relative;
    vertical-align: text-top
}

.pricing-table .pricing-price .price-unit {
    font-size: 25%
}

.pricing-table .pricing-header {
    border-style: solid;
    border-color: #e1e1e1;
    border-width: 0px 1px 0px 0px
}

.pricing-table .pricing-body {
    border-style: solid;
    border-width: 0px 1px 0px 0px;
    border-color: #e1e1e1
}

.pricing-table .pricing-features {
    list-style: none;
    margin: 0;
    padding: 0
}

.pricing-table .pricing-features li {
    padding: .8571428571428571rem 2rem;
    border-bottom: 1px solid #e1e1e1
}

.pricing-table .pricing-features li:nth-child(2n) {
    background: #f3f3f3
}

.pricing-table .pricing-footer {
    border-style: solid;
    border-width: 0px 1px 1px 0px;
    border-color: #e1e1e1;
    text-align: center;
    padding: 30px
}

/* === 3.15. TEAM MEMBER === */

.team-member {
    position: relative
}

.team-member .member-avatar {
    margin-bottom: 1em;
    position: relative
}

.team-member .member-avatar img {
    width: 100%
}

.team-member .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s
}

.team-member.team-member-default {
    padding-left: 55px;
    position: relative
}

.team-member.team-member-default .member-name {
    display: inline-block;
    padding-right: 10px
}

.team-member.team-member-default .member-name h4 {
    font-size: 1em
}

.team-member.team-member-default .member-name~.member-job:before {
    content: '-';
    margin-right: 10px
}

.team-member.team-member-default .member-job {
    font-size: 1em;
    display: inline-block;
    margin: 0;
    padding: 0
}

.team-member.team-member-default .member-meta {
    position: absolute;
    top: 0;
    left: 0
}

.team-member.team-member-default .member-meta>span {
    display: block;
    text-align: center;
    width: 44px;
    height: 44px;
    line-height: 44px;
    margin: 0 0 10px
}

.team-member.team-member-default .member-meta>span a {
    display: block;
    background: #f3f3f3;
    font-size: 1.1428571428571428em
}

.team-member.team-member-right {
    display: table;
    width: 100%;
    height: 100%;
    margin-bottom: 1em
}

.team-member.team-member-right .member-avatar {
    width: 40%;
    display: table-cell;
    vertical-align: top;
    overflow: hidden;
    height: 100%;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: auto 100%;
    -webkit-transition: all .9s;
    -o-transition: all .9s;
    transition: all .9s
}

@media (max-width:900px) {
    .team-member.team-member-right .member-avatar {
        display: block;
        height: 0;
        padding-bottom: 100%;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-position: center center;
        width: 100%
    }
}

.team-member.team-member-right:hover .member-avatar {
    background-position: center top
}

.team-member.team-member-right .member-info {
    height: 100%;
    display: table-cell;
    vertical-align: top;
    width: 60%
}

@media (max-width:900px) {
    .team-member.team-member-right .member-info {
        display: block;
        width: 100%
    }
}

.team-member.team-member-right .member-info-wrap {
    padding: 15px
}

.team-member.team-member-right .member-meta span a {
    width: 30px;
    height: 30px;
    display: inline-block;
    border: 1px solid;
    font-size: 15px;
    text-align: center;
    line-height: 30px;
    margin: 0 5px;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.team-member.team-member-below .member-meta {
    z-index: 2;
    position: absolute;
    bottom: 0;
    padding: 1em 0;
    left: 0;
    width: 100%;
    text-align: center;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    background: -moz-linear-gradient(top, rgba(25, 35, 45, 0) 0, #19232d 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(25, 35, 45, 0)), color-stop(100%, #19232d));
    background: -webkit-linear-gradient(top, rgba(25, 35, 45, 0) 0, #19232d 100%);
    background: -o-linear-gradient(top, rgba(25, 35, 45, 0) 0, #19232d 100%);
    background: -ms-linear-gradient(top, rgba(25, 35, 45, 0) 0, #19232d 100%);
    background: linear-gradient(to bottom, rgba(25, 35, 45, 0) 0, #19232d 100%)
}

.team-member.team-member-below .member-meta span a {
    width: 30px;
    height: 30px;
    display: inline-block;
    border: 1px solid #fff;
    color: #fff;
    font-size: 18px;
    text-align: center;
    line-height: 30px;
    margin: 0 5px;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.team-member.team-member-below:hover .overlay,
.team-member.team-member-below:hover .member-meta {
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible
}

.team-member .member-name h4 {
    margin: 0;
    font-size: 1.3em
}

.team-member .member-job {
    margin-top: .35714285714285715em;
    font-size: 85%;
    margin-bottom: 1em;
    position: relative
}

.team-member .member-desc {
    padding-top: 1em;
    margin-bottom: 1em;
    position: relative
}

.team-member .member-desc:before {
    border-top: 1px solid #e1e1e1;
    content: "";
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%
}

.team-member .member-meta span {
    display: inline-block
}

.team-member.team-member-overlay .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s
}

.team-member.team-member-overlay .member-info {
    z-index: 2;
    bottom: 1em;
    color: #fff;
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%
}

.team-member.team-member-overlay .member-name h4 {
    color: #fff
}

.team-member.team-member-overlay .member-job {
    color: rgba(255, 255, 255, 0.9)
}

.team-member.team-member-overlay .member-job:before {
    width: 20%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    border-color: rgba(255, 255, 255, 0.9)
}

.team-member.team-member-overlay .member-meta span a {
    width: 2em;
    height: 2em;
    display: inline-block;
    border: 1px solid rgba(255, 255, 255, 0.9);
    color: rgba(255, 255, 255, 0.8);
    font-size: 85%;
    text-align: center;
    line-height: 2em;
    margin: 0 .35714285714285715em;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.team-member.team-member-overlay:hover .overlay {
    opacity: .5;
    filter: alpha(opacity=50);
    visibility: visible
}

/* === 3.16. FOOTER === */

.footer-widget {
    font-size: 0.9em;
    background: #212121;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.footer-widget .footer-widget-wrap {
    padding: 60px 0
}

@media (max-width:991px) {
    .footer-widget .footer-widget-col {
        margin-bottom: 30px
    }
}

.footer-widget .widget {
    margin-bottom: 0;
}

.footer-widget .widget:first-child {
    padding-top: 0
}

.footer-widget .widget-title {
    margin-top: 0;
    color: #fff;
    margin-bottom: 2em
}

.footer-widget li {
    border-bottom: #727272;
    padding-left: 0;
    padding-bottom: 10px;
    position: relative
}

.footer-widget li .post-date {
    display: block
}

.footer-widget li:last-child {
    border: none
}

.footer-widget .social-widget-wrap.social-widget-none a:hover i {
    color: #9fce4e;
}

.footer-widget .social-widget-wrap.social-widget-square i {
    margin-right: 4px;
    background: #fff;
    color: #333
}

.footer-widget .social-widget-wrap.social-widget-square a:hover i {
    color: #9fce4e;
}

.footer-widget .posts-thumbnail-image img {
    width: 60px
}

.footer-widget .posts-thumbnail-content {
    border-bottom: none;
    margin-left: 73px;
    min-height: 45px
}

.footer-widget .posts-thumbnail-content h4 a {
    color: #ccc;
    font-family: "Atlan-Semibold", Helvetica, Arial, sans-serif
}

.footer-widget .posts-thumbnail-content h4 a:hover {
    color: #fff
}

.footer-widget .posts-thumbnail-content .posts-thumbnail-meta {
    color: #7c7c7c
}

.footer-widget .posts-thumbnail-image {
    float: left;
    height: auto;
    width: 60px
}

.footer-widget .recent-tweets ul,
.footer-widget .posts-thumbnail-list {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none
}

.footer-widget .posts-thumbnail-list li {
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: none
}

.footer-widget a {
    color: #ccc;
    font-weight: 300
}

.footer-widget a:hover {
    color: #fff
}

.dh-enable-animation .footer-space {
    display: none
}

@media (min-width:992px) {
    .dh-enable-animation .footer-space {
        display: block
    }
}

.footer {
    color: #959595
}

@media (min-width:992px) {
    .dh-enable-animation .footer.footer-fixed {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        margin-left: 0;
        margin-right: 0;
    }
}

.footer a {
    color: #ccc
}

.footer a:hover {
    color: #fff
}

.footer-info {
    background: #161616;
    font-size: 85%
}

.footer-menu,
.footer-copyright {
    padding: 30px 0
}

.footer-menu {
    text-align: right
}

@media (max-width:767px) {
    .footer-menu {
        text-align: center
    }
}

.footer-menu .footer-nav {
    margin: 0;
    padding: 0;
    list-style: none
}

.footer-menu .footer-nav li {
    padding: 0 .5714285714285714em;
    line-height: .7142857142857143em;
    display: inline-block;
    position: relative
}

.footer-menu .footer-nav li:last-child:before {
    display: none
}

.footer-menu .footer-nav li a {
    color: #959595;
    border-bottom: 1px solid transparent
}

.footer-menu .footer-nav li a:hover,
.footer-menu .footer-nav li a:focus {
    color: #fff;
    border-bottom: 1px solid
}

.footer-social {
    background: #161616;
    text-align: center;
    padding-top: 30px
}

.footer-social a {
    width: 40px;
    height: 40px;
    display: inline-block;
    border: 1px solid #959595;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    line-height: 38px;
    font-size: 14px;
    margin: 0 10px;
    color: #959595
}

.footer-social a:hover {
    border-color: #9fce4e
}

.footer-social a:hover i {
    background: none;
    color: #9fce4e
}

.mailchimp-form-content {
    position: relative
}

.mailchimp-form-content input.form-control {
    width: 100%;
    display: inline-block;
    height: 50px
}

.mailchimp-form-content button {
    position: absolute;
    width: 50px;
    height: 50px;
    line-height: 50px;
    padding: 0;
    background: #333;
    color: #fff;
    top: 0;
    right: 0
}

.mailchimp-form-content button:hover {
	color: #fff;
}

/* === 3.17. BLOG POST === */

.posts.infinite-scroll .paginate,
.posts.loadmore .paginate {
    display: none
}

.posts .entry-title {
    text-transform: uppercase
}

@media (max-width:1199px) {
    .posts .entry-title {
        font-size: 1.7142857142857142em
    }
}

@media (max-width:767px) {
    .posts .entry-title {
        font-size: 1.4285714285714286em
    }
}

.posts .posts-wrap.posts-layout-zigzag .hentry {
    margin: 0
}

.posts .posts-wrap.posts-layout-zigzag .hentry .hentry-wrap {
    position: relative
}

.posts .posts-wrap.posts-layout-zigzag .hentry .entry-featured {
    width: 50%;
    float: left;
    margin: 0
}

@media (max-width:767px) {
    .posts .posts-wrap.posts-layout-zigzag .hentry .entry-featured {
        width: 100%;
        float: none
    }
}

.posts .posts-wrap.posts-layout-zigzag .hentry .entry-info {
    width: 50%;
    float: left;
    padding-left: 30px
}

@media (min-width:992px) {
    .posts .posts-wrap.posts-layout-zigzag .hentry .entry-info {
        position: absolute;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        transform: translate(0, -50%);
        right: 0
    }
}

@media (max-width:991px) {
    .posts .posts-wrap.posts-layout-zigzag .hentry .entry-info {
        padding-top: 30px
    }
}

@media (max-width:767px) {
    .posts .posts-wrap.posts-layout-zigzag .hentry .entry-info {
        width: 100%;
        float: none;
        padding-left: 0;
        padding-bottom: 30px
    }
}

.posts .posts-wrap.posts-layout-zigzag .hentry:nth-child(even) .entry-featured {
    float: right
}

@media (max-width:767px) {
    .posts .posts-wrap.posts-layout-zigzag .hentry:nth-child(even) .entry-featured {
        float: none
    }
}

.posts .posts-wrap.posts-layout-zigzag .hentry:nth-child(even) .entry-info {
    left: 0;
    padding-left: 0;
    padding-right: 30px
}

.posts .posts-wrap.posts-layout-zigzag .hentry .entry-meta {
    margin-bottom: 1.5em
}

.posts .posts-wrap.posts-layout-masonry .entry-format,
.posts .posts-wrap.posts-layout-grid .entry-format {
    display: none
}

.posts .posts-wrap.posts-layout-masonry .entry-title,
.posts .posts-wrap.posts-layout-grid .entry-title {
    font-size: 1.2em
}

.posts .posts-wrap.posts-layout-masonry .hentry,
.posts .posts-wrap.posts-layout-grid .hentry {
    border-bottom: none;
    padding-bottom: 0
}

.posts .posts-wrap.posts-layout-masonry.no-gap.row,
.posts .posts-wrap.posts-layout-grid.no-gap.row {
    margin-left: 0;
    margin-right: 0
}

.posts .posts-wrap.posts-layout-masonry.no-gap.row .hentry,
.posts .posts-wrap.posts-layout-grid.no-gap.row .hentry {
    padding-left: 0;
    padding-right: 0
}

.posts .posts-wrap.posts-layout-masonry .entry-featured,
.posts .posts-wrap.posts-layout-grid .entry-featured {
    margin: 0
}

.posts .posts-wrap.posts-layout-masonry .entry-info,
.posts .posts-wrap.posts-layout-grid .entry-info {
    padding: 30px;
    border-style: solid;
    border-color: #e1e1e1;
    border-width: 1px 1px 1px 1px
}

.posts .posts-wrap.posts-layout-masonry .has-post-thumbnail .entry-info,
.posts .posts-wrap.posts-layout-grid .has-post-thumbnail .entry-info {
    border-top: none
}

.posts .posts-wrap.posts-layout-masonry .entry-content,
.posts .posts-wrap.posts-layout-grid .entry-content {
    margin-bottom: 1em
}

.posts .posts-wrap.posts-layout-masonry .entry-meta,
.posts .posts-wrap.posts-layout-grid .entry-meta {
    float: left;
    margin: 15px 0 0
}

@media (max-width:320px) {
    .posts .posts-wrap.posts-layout-masonry .entry-meta,
    .posts .posts-wrap.posts-layout-grid .entry-meta {
        float: none
    }
}

.posts .posts-wrap.posts-layout-masonry .readmore-link,
.posts .posts-wrap.posts-layout-grid .readmore-link {
    float: right;
    font-weight: 600;
    margin: 15px 0 0
}

@media (max-width:320px) {
    .posts .posts-wrap.posts-layout-masonry .readmore-link,
    .posts .posts-wrap.posts-layout-grid .readmore-link {
        float: none
    }
}

.posts .posts-wrap.posts-layout-grid {
    position: relative;
    margin-left: -15px;
    margin-right: -15px
}

.posts .posts-wrap.posts-layout-grid .no-border .entry-info {
    padding-left: 0;
    padding-right: 0;
    border: none
}

.posts .posts-wrap.posts-layout-grid .grid-full.no-border .entry-info {
    border-bottom: 1px solid #e1e1e1
}

.posts .posts-wrap.posts-layout-grid.no-padding .hentry {
    padding: 0
}

.posts .posts-wrap.posts-layout-grid.no-padding .hentry .entry-info {
    border: 0;
    background: #f4f4f4
}

.posts .posts-wrap.posts-layout-grid.no-padding .hentry:hover .entry-info {
    background: #e9e9e9
}

.posts .posts-wrap.posts-layout-grid.col-2 .hentry:nth-child(2n+1) {
    clear: both
}

.posts .posts-wrap.posts-layout-grid.col-3 .hentry:nth-child(3n+1) {
    clear: both
}

@media (max-width:991px) {
    .posts .posts-wrap.posts-layout-grid.col-3 .hentry:nth-child(3n+1) {
        clear: none
    }
}

@media (max-width:991px) {
    .posts .posts-wrap.posts-layout-grid.col-3 .hentry:nth-child(2n+1) {
        clear: both
    }
}

.posts .posts-wrap.posts-layout-grid.col-4 .hentry:nth-child(4n+1) {
    clear: both
}

@media (max-width:991px) {
    .posts .posts-wrap.posts-layout-grid.col-4 .hentry:nth-child(4n+1) {
        clear: none
    }
}

@media (max-width:991px) {
    .posts .posts-wrap.posts-layout-grid.col-4 .hentry:nth-child(2n+1) {
        clear: both
    }
}

.posts .posts-wrap.posts-layout-center .hentry-wrap {
    text-align: center
}

.posts .posts-wrap.posts-layout-center .entry-format {
    display: none
}

.hentry {
    margin-bottom: 60px;
    background: transparent
}

.entry-header {
    margin-bottom: 15px;
    position: relative
}

.entry-header .entry-meta {
    margin-top: 8px
}

.entry-content {
    margin-bottom: 2em
}

.entry-featured {
    margin-bottom: 30px
}

.entry-featured img {
    width: 100%;
    height: auto
}

.entry-featured.video-featured:not(.col-md-8) {
    width: 100%;
    max-width: 100%
}

.entry-featured.video-featured a {
    position: relative;
    display: block
}

.entry-featured.video-featured a:before {
    background: rgba(0, 0, 0, 0.14) none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1
}

.entry-featured.video-featured a i {
    color: #ffffff;
    display: block;
    font-size: 50px;
    line-height: 1;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2
}

.entry-format {
    width: 65px;
    height: 65px;
    display: inline-block;
    text-align: center;
    line-height: 65px;
    font-size: 30px;
    color: #9fce4e;
    float: left;
    margin-right: 20px
}

@media (max-width:767px) {
    .entry-format {
        display: none
    }
}

.entry-title {
    margin-top: 0;
    font-weight: 600;
    margin: 0
}

.sticky .entry-title a {
    color: #9fce4e
}

.sticky .entry-title:before {
    content: "\f08d";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.sticky .entry-title:before {
    line-height: 1;
    opacity: 1;
    -webkit-transform: rotate(-36deg);
    -ms-transform: rotate(-36deg);
    -o-transform: rotate(-36deg);
    transform: rotate(-36deg);
    color: #9fce4e;
    font-size: 1.2857142857142858em
}

.entry-meta {
    margin-bottom: 1.5em;
    text-transform: uppercase;
    color: #777;
    font-size: 85%
}

.entry-meta.icon-meta i {
    margin-right: 10px
}

.entry-meta.icon-meta>span {
    margin-right: 20px;
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
}

.entry-meta a {
    color: #777
}

.entry-meta a:hover {
    color: #9fce4e
}

.readmore-link a {
    display: inline-block;
    vertical-align: top;
    font-size: 85%;
    text-transform: uppercase
}

.readmore-link a:after {
    content: "\f0da";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.readmore-link a:after {
    margin-left: 5px
}

.readmore-link a:hover {
    color: #9fce4e
}

.posts-layout-default .readmore-link a,
.posts-layout-zigzag .readmore-link a,
.posts-layout-center .readmore-link a {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: .5714285714285714rem 2rem;
    font-size: 13px;
    line-height: 1.6;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    text-decoration: none;
    background: transparent;
    border: 2px solid #333;
    text-transform: uppercase;
    color: #333;
    position: relative;
    font-weight: 600
}

.posts-layout-default .readmore-link a i,
.posts-layout-zigzag .readmore-link a i,
.posts-layout-center .readmore-link a i,
.posts-layout-default .readmore-link a span,
.posts-layout-zigzag .readmore-link a span,
.posts-layout-center .readmore-link a span {
    z-index: 2;
    position: relative
}

.posts-layout-default .readmore-link a.btn-icon-left i,
.posts-layout-zigzag .readmore-link a.btn-icon-left i,
.posts-layout-center .readmore-link a.btn-icon-left i {
    margin-right: 5px
}

.posts-layout-default .readmore-link a.btn-icon-right i,
.posts-layout-zigzag .readmore-link a.btn-icon-right i,
.posts-layout-center .readmore-link a.btn-icon-right i {
    margin-left: 5px
}

.posts-layout-default .readmore-link a.btn-icon-right.btn-icon-slide-in,
.posts-layout-zigzag .readmore-link a.btn-icon-right.btn-icon-slide-in,
.posts-layout-center .readmore-link a.btn-icon-right.btn-icon-slide-in {
    padding-left: 40px;
    padding-right: 40px;
    position: relative
}

.posts-layout-default .readmore-link a.btn-icon-right.btn-icon-slide-in span,
.posts-layout-zigzag .readmore-link a.btn-icon-right.btn-icon-slide-in span,
.posts-layout-center .readmore-link a.btn-icon-right.btn-icon-slide-in span,
.posts-layout-default .readmore-link a.btn-icon-right.btn-icon-slide-in i,
.posts-layout-zigzag .readmore-link a.btn-icon-right.btn-icon-slide-in i,
.posts-layout-center .readmore-link a.btn-icon-right.btn-icon-slide-in i {
    -webkit-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out
}

.posts-layout-default .readmore-link a.btn-icon-right.btn-icon-slide-in i,
.posts-layout-zigzag .readmore-link a.btn-icon-right.btn-icon-slide-in i,
.posts-layout-center .readmore-link a.btn-icon-right.btn-icon-slide-in i {
    position: absolute;
    right: 40px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    opacity: 0;
    filter: alpha(opacity=0)
}

.posts-layout-default .readmore-link a.btn-icon-right.btn-icon-slide-in span,
.posts-layout-zigzag .readmore-link a.btn-icon-right.btn-icon-slide-in span,
.posts-layout-center .readmore-link a.btn-icon-right.btn-icon-slide-in span {
    position: relative;
    left: 0
}

.posts-layout-default .readmore-link a.btn-icon-right.btn-icon-slide-in:hover span,
.posts-layout-zigzag .readmore-link a.btn-icon-right.btn-icon-slide-in:hover span,
.posts-layout-center .readmore-link a.btn-icon-right.btn-icon-slide-in:hover span {
    left: -15px
}

.posts-layout-default .readmore-link a.btn-icon-right.btn-icon-slide-in:hover i,
.posts-layout-zigzag .readmore-link a.btn-icon-right.btn-icon-slide-in:hover i,
.posts-layout-center .readmore-link a.btn-icon-right.btn-icon-slide-in:hover i {
    opacity: 1;
    filter: alpha(opacity=100);
    right: 25px
}

.posts-layout-default .readmore-link a.btn-round,
.posts-layout-zigzag .readmore-link a.btn-round,
.posts-layout-center .readmore-link a.btn-round {
    -webkit-border-radius: 40px;
    border-radius: 40px
}

.posts-layout-default .readmore-link a:hover,
.posts-layout-zigzag .readmore-link a:hover,
.posts-layout-center .readmore-link a:hover,
.posts-layout-default .readmore-link a:focus,
.posts-layout-zigzag .readmore-link a:focus,
.posts-layout-center .readmore-link a:focus {
    color: #fff;
    border-color: #9fce4e;
    background: #9fce4e
}

.posts-layout-default .readmore-link a:before,
.posts-layout-zigzag .readmore-link a:before,
.posts-layout-center .readmore-link a:before {
    display: none
}

.post-navigation {
    padding: 30px 0;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1
}

.post-navigation .prev-post>span,
.post-navigation .next-post>span {
    font-size: 85%;
    font-weight: 600;
    font-family: "Atlan-Semibold", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    color: #777
}

.post-navigation .prev-post>span:before,
.post-navigation .next-post>span:before,
.post-navigation .prev-post>span:after,
.post-navigation .next-post>span:after {
    font-size: 110%
}

@media (max-width:767px) {
    .post-navigation .prev-post,
    .post-navigation .next-post {
        text-align: center
    }
    .post-navigation .prev-post:before,
    .post-navigation .next-post:before,
    .post-navigation .prev-post:after,
    .post-navigation .next-post:after {
        display: none
    }
}

.post-navigation .prev-post>span:before {
    content: "\f104";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.post-navigation .prev-post>span:before {
    margin-right: 5px
}

@media (max-width:767px) {
    .post-navigation .prev-post {
        margin-bottom: 2em
    }
}

.post-navigation .next-post {
    text-align: right
}

.post-navigation .next-post>span:after {
    content: "\f105";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.post-navigation .next-post>span:after {
    margin-left: 5px
}

.post-navigation a {
    display: block;
    font-weight: 600;
    color: #333
}

.post-navigation a:hover {
    color: #9fce4e
}

.author-info {
    padding: 30px 0;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1
}

.author-info .author-avatar {
    float: left;
    margin-right: 30px
}

.author-info .author-avatar img {
    max-width: 8.571428571428571em
}

@media (max-width:767px) {
    .author-info .author-avatar {
        float: none;
        text-align: center;
        margin-bottom: 10px
    }
}

.author-info .author-description {
    margin-left: 8.571428571428571em
}

@media (max-width:767px) {
    .author-info .author-description {
        margin-left: 0
    }
}

.author-info .author-description .author-title {
    font-size: 1.1em;
    margin-top: 0
}

@media (max-width:767px) {
    .author-info .author-description .author-title {
        text-align: center
    }
}

.author-info .author-description .author-title small {
    font-size: 85%
}

@media (max-width:767px) {
    .author-info .author-description .author-title small {
        display: none
    }
}

.author-info .author-description .author-link {
    display: block;
    font-weight: 600;
    margin-top: 2em
}

.author-info .author-social {
    text-align: left
}

.author-info .author-social a {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    margin-right: 0.821429em;
    text-align: center;
    background: none repeat scroll 0 0 #707070;
    font-size: 13px;
    height: 2em;
    line-height: 2em;
    width: 2em
}

.author-info .author-social a:hover {
    background: #9fce4e
}

.entry-tags {
    margin-top: 15px;
    text-transform: uppercase;
    display: inline-block
}

.entry-tags>span {
    color: #777
}

.entry-tags a {
    position: relative;
    display: inline-block;
    color: #333
}

.entry-tags a:hover {
    text-decoration: underline
}

@media (min-width:992px) {
    .single.single-post.page-layout-full-width .entry-info,
    .single.single-post.page-layout-full-width .post-navigation,
    .single.single-post.page-layout-full-width .related-post,
    .single.single-post.page-layout-full-width .author-info,
    .single.single-post.page-layout-full-width .comments-area {
        width: 66.66666666666666%;
        margin-left: auto;
        margin-right: auto
    }
    .single.single-post.page-layout-full-width .entry-header {
        text-align: center
    }
}

.single .hentry {
    margin-bottom: 30px
}

@media (min-width:768px) {
    .single .hentry {
        margin-left: auto;
        margin-right: auto
    }
}

.single .entry-title {
    text-transform: uppercase
}

@media (max-width:1199px) {
    .single .entry-title {
        font-size: 1.2em
    }
}

@media (max-width:767px) {
    .single .entry-title {
        font-size: 1.5em
    }
}

.single .entry-featured {
    margin-bottom: 2.5em
}

.single .entry-info .entry-meta {
    margin-top: 1.5em;
    margin-bottom: 2em
}

.main-content .single .entry-footer {
    margin-top: 45px
}

@media (max-width:767px) {
    .main-content .single .entry-footer {
        text-align: center
    }
}

.main-content .single .entry-tags {
    margin: 0
}

.main-content .single .share-links {
    text-align: right;
    margin: 0
}

.main-content .single .share-links>span {
    color: #777
}

@media (max-width:767px) {
    .main-content .single .share-links {
        text-align: center;
        margin: 10px 0 0
    }
}

.main-content .single .share-links .share-icons a {
    margin: 0 10px
}

.single .comments-area {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #e1e1e1
}

.single .title-sep-text {
    font-size: 1.7142857142857142em;
    padding: 0.6em 0;
    margin: 0 0 0 0;
    position: relative
}

@media (max-width:480px) {
    .single .title-sep-text {
        font-size: 1.2857142857142858em
    }
}

.single .below-content {
    margin-top: 1em
}

.share-links {
    margin-top: 30px
}

.share-links>span {
    display: inline-block;
    padding-right: 10px;
    text-transform: uppercase
}

.share-links .share-icons {
    display: inline-block
}

.share-links .share-icons a {
    font-size: 14px;
    text-align: center;
    display: inline-block;
    color: #333
}

.share-links .share-icons a:hover,
.share-links .share-icons a:focus {
    color: #9fce4e
}

@media (max-width:767px) {
    .share-links {
        display: block
    }
    .share-links h4 {
        width: auto
    }
}

.related-post {
    margin-top: 30px
}

.related-post .related-post-title h3 {
    font-size: 1.7142857142857142em;
    padding: 0.6em 0;
    margin: 0 0 0 0;
    position: relative
}

@media (max-width:480px) {
    .related-post .related-post-title h3 {
        font-size: 1.2857142857142858em
    }
}

.related-post .entry-featured {
    margin-bottom: 15px
}

.related-post .related-post-items {
    margin-top: 20px
}

@media (max-width:767px) {
    .related-post .related-post-item {
        margin-bottom: 30px
    }
}

.related-post .post-title {
    font-size: 1.2em;
    margin-top: 0;
    text-transform: uppercase
}

.related-post .entry-meta .meta-date {
    display: inline-block
}

.related-post .excerpt {
    margin-top: 1em
}

.related-post .readmore-link {
    font-weight: 600
}

.post-slider-wrap .hentry .entry-featured {
    margin: 0
}

.post-slider-wrap .hentry .entry-info {
    padding: 20px 25px;
    border-style: solid;
    border-color: #e1e1e1;
    border-width: 0 1px 1px 1px
}

.post-slider-wrap .hentry .entry-info .entry-title {
    font-size: 1.4285714285714286em
}

.post-slider-wrap .hentry .entry-info .entry-meta {
    float: left
}

@media (max-width:320px) {
    .post-slider-wrap .hentry .entry-info .entry-meta {
        float: none;
        margin-bottom: 15px
    }
}

.post-slider-wrap .hentry .entry-info .readmore-link {
    float: right
}

@media (max-width:320px) {
    .post-slider-wrap .hentry .entry-info .readmore-link {
        float: none
    }
}

ul.isotope-grid-post {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* === 3.18. SIDEBAR === */

.widget {
    margin-bottom: 3rem
}

.widget p:last-child,
.widget ul:last-child,
.widget ol:last-child {
    margin-bottom: 0
}

.widget ul,
.widget ol {
    margin-left: 0;
    padding: 0;
    list-style: none
}

.widget select {
    width: 100%
}

.main-sidebar>.widget {
    margin-bottom: 40px
}

.main-sidebar .widget_rss li,
.main-sidebar .widget_pages li,
.main-sidebar .widget_archive li,
.main-sidebar .widget_meta li,
.main-sidebar .widget_nav_menu li,
.main-sidebar .widget_categories li,
.main-sidebar .widget_recent_entries li,
.main-sidebar .widget_recent_comments li {
    padding-bottom: 10px;
    position: relative;
    padding-left: 1em
}

.main-sidebar .widget_rss li:before,
.main-sidebar .widget_pages li:before,
.main-sidebar .widget_archive li:before,
.main-sidebar .widget_meta li:before,
.main-sidebar .widget_nav_menu li:before,
.main-sidebar .widget_categories li:before,
.main-sidebar .widget_recent_entries li:before,
.main-sidebar .widget_recent_comments li:before {
    content: "\f105";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.main-sidebar .widget_rss li:before,
.main-sidebar .widget_pages li:before,
.main-sidebar .widget_archive li:before,
.main-sidebar .widget_meta li:before,
.main-sidebar .widget_nav_menu li:before,
.main-sidebar .widget_categories li:before,
.main-sidebar .widget_recent_entries li:before,
.main-sidebar .widget_recent_comments li:before {
    position: absolute;
    line-height: inherit;
    left: 0
}

.main-sidebar .widget_rss li:last-child,
.main-sidebar .widget_pages li:last-child,
.main-sidebar .widget_archive li:last-child,
.main-sidebar .widget_meta li:last-child,
.main-sidebar .widget_nav_menu li:last-child,
.main-sidebar .widget_categories li:last-child,
.main-sidebar .widget_recent_entries li:last-child,
.main-sidebar .widget_recent_comments li:last-child {
    border: none
}

.main-sidebar .widget_rss li .post-date,
.main-sidebar .widget_pages li .post-date,
.main-sidebar .widget_archive li .post-date,
.main-sidebar .widget_meta li .post-date,
.main-sidebar .widget_nav_menu li .post-date,
.main-sidebar .widget_categories li .post-date,
.main-sidebar .widget_recent_entries li .post-date,
.main-sidebar .widget_recent_comments li .post-date {
    display: block;
    font-size: 85%;
    color: #777
}

.main-sidebar .widget_rss li ul,
.main-sidebar .widget_pages li ul,
.main-sidebar .widget_archive li ul,
.main-sidebar .widget_meta li ul,
.main-sidebar .widget_nav_menu li ul,
.main-sidebar .widget_categories li ul,
.main-sidebar .widget_recent_entries li ul,
.main-sidebar .widget_recent_comments li ul {
    padding-top: 15px
}

.main-sidebar .widget_rss li ul li:last-child,
.main-sidebar .widget_pages li ul li:last-child,
.main-sidebar .widget_archive li ul li:last-child,
.main-sidebar .widget_meta li ul li:last-child,
.main-sidebar .widget_nav_menu li ul li:last-child,
.main-sidebar .widget_categories li ul li:last-child,
.main-sidebar .widget_recent_entries li ul li:last-child,
.main-sidebar .widget_recent_comments li ul li:last-child {
    padding-bottom: 0
}

.main-sidebar .widget_archive li {
    padding-left: 1.5em
}

.main-sidebar .widget_archive li:before {
    content: "\f133";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.main-sidebar .widget_categories li {
    padding-left: 1.5em
}

.main-sidebar .widget_categories li:before {
    content: "\f114";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.main-sidebar .widget_meta li {
    padding-left: 1.5em
}

.main-sidebar .widget_meta li:before {
    content: "\f013";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.main-sidebar .widget_recent_comments li {
    padding-left: 1.5em
}

.main-sidebar .widget_recent_comments li:before {
    content: "\f0e5";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.widgettitle,
.widget-title {
    font-size: 1.2em;
    margin-bottom: 1.5em;
    text-transform: uppercase
}

@media (min-width:1200px) {
    .main-sidebar {
        padding: 20px;
        border: 1px solid #e1e1e1
    }
    .main-sidebar.no-border {
        border: 0;
        padding: 0
    }
}

.widget_search form {
    position: relative
}

.widget_search form:before {
    content: "\f002";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.widget_search form:before {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    text-align: center;
    line-height: 40px;
    z-index: 1;
    -webkit-border-radius: 0 0 0 0;
    border-radius: 0 0 0 0
}

.widget_search form #searchsubmit {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    display: block!important;
    visibility: visible;
    border: 0;
    background: transparent;
    outline: 0;
    text-indent: -9999em
}

.widget_tag_cloud .tagcloud a,
.widget_product_tag_cloud .tagcloud a {
    float: left;
    margin: 0 .7142857142857143em .7142857142857143em 0;
    padding: 1em 1.5em;
    font-size: 13px;
    line-height: 1.6;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-border-radius: 0;
    border-radius: 0;
    text-align: center;
    vertical-align: middle;
    font-size: 85%;
    border: 1px solid #e1e1e1;
    color: #444
}

.widget_tag_cloud .tagcloud a:hover,
.widget_product_tag_cloud .tagcloud a:hover {
    border-color: #333;
    color: #333
}

.social-widget-wrap.social-widget-square i {
    color: #fff
}

.social-widget-wrap.social-widget-round i {
    -webkit-border-radius: 100%;
    border-radius: 100%;
    color: #fff
}

.social-widget-wrap.social-widget-outlined i {
    background: transparent;
    border: 1px solid
}

.social-widget-wrap a {
    margin: 0 9px 10px 0;
    display: inline-block
}

.social-widget-wrap a i {
    font-size: 16px;
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    line-height: 40px
}

.recent-tweets ul {
    list-style: none
}

.recent-tweets ul li {
    padding-bottom: 30px
}

.recent-tweets .twitter_time {
    margin-top: 8px;
    display: block;
    font-size: 85%
}

.recent-tweets .twitter_time:before {
    content: "\f099";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.recent-tweets .twitter_time:before {
    color: #00aced;
    font-size: 120%;
    margin-right: 5px
}

.posts-thumbnail-list>li {
    padding: 0 0 20px 0
}

.posts-thumbnail-list>li:last-child {
    padding-bottom: 0
}

.widget-post-thumbnail li .posts-thumbnail-image {
    float: left;
    width: 70px;
    margin-right: 1em
}

.widget-post-thumbnail li .posts-thumbnail-content h4 {
    font-size: 1.1em;
    margin-top: -3px;
    margin-bottom: 0.5em
}

.widget-post-thumbnail li .posts-thumbnail-content .posts-thumbnail-meta {
    color: #777;
    font-size: 85%
}

.widget-post-thumbnail li .posts-thumbnail-content .posts-thumbnail-meta a {
    color: #777
}

.widget-post-thumbnail li .posts-thumbnail-content .posts-thumbnail-meta a:hover {
    color: #9fce4e
}

/* === 3.19. CONTACT FORM === */

.form-control:not(.submit) {
    display: block;
    width: 100%;
    height: 40px;
    padding: .5714285714285714rem 1rem;
    line-height: 1.6;
    color: #444;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e1e1e1;
    -webkit-border-radius: 0;
    border-radius: 0;
    outline: none;
    -webkit-transition: border-color 0.3s, box-shadow 0.3s;
    -o-transition: border-color 0.3s, box-shadow 0.3s;
    transition: border-color 0.3s, box-shadow 0.3s
}

.form-control:not(.submit):focus {
    border-color: #333;
    outline: none
}

.form-control:not(.submit)[disabled],
.form-control:not(.submit)[readonly],
fieldset[disabled] .form-control:not(.submit) {
    cursor: not-allowed;
    background-color: #aaa;
    opacity: 1;
    filter: alpha(opacity=100)
}

textarea.form-control:not(.submit) {
    height: auto
}

textarea.form-control:not(.submit) {
    height: 130px
}

.form-control-wrap {
    display: block;
    width: 100%;
    margin-top: .35714285714285715em;
    margin-bottom: 1em
}

.submit {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: .5714285714285714rem 2rem;
    font-size: 13px;
    line-height: 1.6;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    text-decoration: none;
    color: #fff;
    background-color: #333;
    border-color: #333;
    padding: .8571428571428571rem 1.5em
}

.submit i,
.submit span {
    z-index: 2;
    position: relative
}

.submit.btn-icon-left i {
    margin-right: 5px
}

.submit.btn-icon-right i {
    margin-left: 5px
}

.submit.btn-icon-right.btn-icon-slide-in {
    padding-left: 40px;
    padding-right: 40px;
    position: relative
}

.submit.btn-icon-right.btn-icon-slide-in span,
.submit.btn-icon-right.btn-icon-slide-in i {
    -webkit-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out
}

.submit.btn-icon-right.btn-icon-slide-in i {
    position: absolute;
    right: 40px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    opacity: 0;
    filter: alpha(opacity=0)
}

.submit.btn-icon-right.btn-icon-slide-in span {
    position: relative;
    left: 0
}

.submit.btn-icon-right.btn-icon-slide-in:hover span {
    left: -15px
}

.submit.btn-icon-right.btn-icon-slide-in:hover i {
    opacity: 1;
    filter: alpha(opacity=100);
    right: 25px
}

.submit.btn-round {
    -webkit-border-radius: 40px;
    border-radius: 40px
}

.submit:hover,
.submit:focus,
.submit:active,
.submit.active {
    color: #fff
}

.submit:active,
.submit.active {
    background-image: none
}

.submit:hover,
.submit:focus,
.submit:active,
.submit.active {
    background-color: #666;
    border-color: #666
}

/* === 3.20. ACCORDION === */
.panel-group .panel+.panel {
  margin-top: 0;
}
.panel-group .panel {
  border-color: #f7f7f7;
  background-color: #FFF;
  border-radius: 0;
  border-bottom: none;
}
.panel-default>.panel-heading {
  background-color: #FFF;
  padding: 0;
  -webkit-transition: background .2s ease-in-out;
  transition: background .2s ease-in-out;
}
.panel-default>.panel-heading:hover {
  background-color: #f7f7f7;
}
.panel-default>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #f7f7f7;
}
.panel-title > a {
  padding: 14px 20px 14px 46px;
  color: #666;
  font-family: "Atlan-Semibold",Helvetica,Arial,sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
  position: relative;
}
.panel-title a:after {
  content: "\f106";
  font-family: FontAwesome;
  font-size: 23px;
  color: #333333;
  font-weight: 400;
  position: absolute;
  top: 45%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.panel-title a.collapsed:after {
  content: "\f107";
}

/* === 3.21. MESSAGE BOX === */
.message-box {
  border: 1px solid transparent;
  display: block;
  overflow: hidden;
  margin: 0 0 22px;
  padding: 14px 14px 14px 50px;
  position: relative;
  font-size: 14px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.message-box-icon {
  position: absolute;
  font-weight: 400;
  font-style: normal;
  bottom: 0;
  font-size: 14px;
  left: 0;
  top: 0;
  width: 50px;
  text-align: center;
}
.message-box-icon > i {
  -webkit-transform: translate(0%,50%);
  -ms-transform: translate(0%,50%);
  -o-transform: translate(0%,50%);
  transform: translate(0%,50%);
}
.message-box-icon > i, .message-box-icon > .fa {
  font-size: 24px;
  line-height: 1;
}
.message-box p {
  margin-bottom: 0;
}
.color-info.message-box {
  color: #5e7f96;
  border-color: #cfebfe;
  background-color: #dff2fe;
}
.color-info.message-box .message-box-icon {
  color: #56b0ee;
}
.color-danger.message-box {
  color: #a85959;
  border-color: #fedede;
  background-color: #fdeaea;
}
.color-danger.message-box .message-box-icon {
  color: #ff7877;
}
.color-success.message-box {
  color: #5e7f96;
  border-color: #cfebfe;
  background-color: #e6fdf8;
}
.color-success.message-box .message-box-icon {
  color: #1bbc9b;
}
.color-warning.message-box {
  color: #9d8967;
  border-color: #ffeccc;
  background-color: #fff4e2;
}
.color-warning.message-box .message-box-icon {
  color: #fcb53f;
}

/* === 3.22. TEXT BOX === */
.text-box {
  padding: 120px 60px;
}
.text-box h2 {
  font-size:48px; 
  margin: 0 10px 30px 0;
}
.text-box h3 {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 5px;
}
.text-box.dark {
  background-color: #3a3a3a;
}
.text-box.darker {
  background-color: #2d2d2d;
}
.text-box.darkest {
  background-color: #1f1f1f;
}
.text-box.dark h2,
.text-box.dark h3,
.text-box.darker h2,
.text-box.darker h3,
.text-box.darkest h2,
.text-box.darkest h3 {
  color: #fff;
}
.text-box.dark p,
.text-box.darker p,
.text-box.darkest p {
  color: #8d8d8d;
}

/* === 3.23. TEXT BLOCK === */
.text-block-default {
  padding: 35px 15px;
}
.text-block-border {
  border: 1px solid #e5e5e5;
  padding: 35px 15px;
}
.text-block-dark {
  padding: 35px 15px;
  background-color: #000;
  color: #fff;
}
.text-block-color {
  padding: 35px 15px;
  background-color: #9fce4e;
  color: #fff;
}

/* === 3.24. TABS === */
.nav-tabs {
  border-color: #f0f0f0;
}
.tabs .nav-tabs > li > a {
  border-color: #f7f7f7;
  background-color: #FFF;
  color: #666;
  border-bottom-color: #f0f0f0;
}
.tabs .nav-tabs > li > a:hover {
  background-color: #f0f0f0;
}
.tabs .nav-tabs > li.active > a {
  border-color: #f0f0f0;
  background-color: #f8f8f8;
  color: #666;
  border-bottom-color: transparent;
}
.tab-content {
  box-sizing: content-box;
  padding: 14px 20px;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  -webkit-transition: padding .2s ease-in-out;
  transition: padding .2s ease-in-out;
  background-color: #f8f8f8;
  border: 1px solid #f0f0f0;
  border-top: none;
}
.commerce-tabs .tab-content {
  border: none;
  background: none;
}
.tabs.style-2 .nav-tabs > li {
  float: none;
  display: inline-block;
  z-index: 1;
}
.tabs.style-2 .nav-tabs > li.active > a {
  margin-right: 0;
}
.tabs.style-2 .nav-tabs {
  text-align: center;
  border-bottom: none;
}
.tabs.style-2 .nav-tabs > li > a {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.tabs.style-2 .tab-content {
  border-radius: 30px;
  border-top: 1px solid #f0f0f0;
}
.tabs.style-3 .tab-content {
  border-top: 1px solid #f0f0f0;
}
.tabs.style-3 .nav-tabs {
  text-align: right;
  border-bottom: none;
}
.tabs.style-3 .nav-tabs > li {
  float: none;
  display: inline-block;
  z-index: 1;
  top: -1px;
}
.tabs.style-3 .nav-tabs > li > a {
  margin-right: 0;
  background-color: #f5b14b;
  border: none;
  color: #fff;
}
.tabs.style-3 .nav-tabs > li.active > a {
  background-color: #f8f8f8;
  color: #666;
  border: 1px solid #f0f0f0;
  border-bottom-color: #f0f0f0;
  border-top-color: #f8f8f8;
}
.tabs.vertical,
.tabs.vertical-2,
.tabs.vertical-3 {
  display: table;
  width: 100%;
}
.tabs.vertical .nav-tabs-wrapper,
.tabs.vertical-2 .nav-tabs-wrapper,
.tabs.vertical-3 .nav-tabs-wrapper {
  display: table-cell;
  width: 20%;
  vertical-align: top;
}
.tabs.vertical .tab-content,
.tabs.vertical-2 .tab-content,
.tabs.vertical-3 .tab-content {
  float: none;
  display: table-cell;
  vertical-align: top;
  width: 80%;
  padding: 14px 20px;
  border-radius: 5px;
  border-top-left-radius: 0;
  border: 1px solid #f0f0f0;
  background-color: #f8f8f8;
}
.tabs.vertical-2 .tab-content {
  border-radius: 0;
}
.tabs.vertical .nav-tabs,
.tabs.vertical-2 .nav-tabs,
.tabs.vertical-3 .nav-tabs {
  border-bottom: none;
}
.tabs.vertical .nav-tabs > li,
.tabs.vertical-2 .nav-tabs > li,
.tabs.vertical-3 .nav-tabs > li {
  float: none;
  display: block;
  z-index: 1;
  width: 100%;
  margin-bottom: 1px;
  left: 1px;
}
.tabs.vertical-2 .nav-tabs > li {
  left: -1px;
}
.tabs.vertical .nav-tabs > li a,
.tabs.vertical-2 .nav-tabs > li a,
.tabs.vertical-3 .nav-tabs > li a {
  margin-right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-color: #e3e3e3;
  background-color: #EBEBEB;
  color: #666;
}
.tabs.vertical-2 .nav-tabs > li a {
  border-radius: 0;
}
.tabs.vertical-3 .nav-tabs > li a {
  border-color: #f6b859;
  background-color: #F7BE68;
  color: #fff;
}
.tabs.vertical-3 .nav-tabs > li a:hover {
  background-color: #f5b14b;
}
.tabs.vertical-3 .nav-tabs > li.active > a {
  color: #666;
}
.tabs.vertical .nav-tabs > li.active > a,
.tabs.vertical-2 .nav-tabs > li.active > a,
.tabs.vertical-3 .nav-tabs > li.active > a {
  border-color: #f0f0f0;
  border-bottom-color: #f0f0f0;
  border-right-color: #f8f8f8;
  background-color: #f8f8f8;
}
.tabs.vertical-2 .nav-tabs > li.active > a {
  border-left-color: #f8f8f8;
  border-right-color: #f0f0f0;
}

/* === 3.25. CALL TO ACTION === */
.call-to-action .title.bold {
  font-weight: 600;
}
.call-to-action .fz-16 {
  font-size: 16px;
}
.call-to-action.style-1 {
  padding: 28px;
  border: 1px solid #F0F0F0;
}
.call-to-action.style-1 .btn {
  color: #6AB165;
  border: 2px solid #6AB165;
  font-size: 14px;
  background-color: transparent;
  padding: 13px 19px;
}
.call-to-action.style-1 .btn:hover {
  color: #fff;
  background-color: #6AB165;
}
.call-to-action.style-2 {
  color: #fff;
}
.call-to-action.style-2 .title {
  font-size: 22px;
  margin: 0 0 5px;
}
.call-to-action.style-2 .btn-icon {
  border-color:#ffffff;
  color:#ffffff;
}

/* === 3.26. PORTFOLIO === */
.grid-filter .filter-category li {
  color: #000;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  z-index: 2;
  padding: 1px 30px;
  border: none;
  line-height: 38px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  font-family: "Open Sans",sans-serif;
  display: inline-block;
  background: #fff;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.grid-filter .filter-category li a.active {
  color: #9fce4e;
}
.isotope-grid-post.style-5 > .grid-item {
  background-color: #2d3039;
}
.isotope-grid-post.style-1 .overlay-wrap,
.isotope-grid-post.style-3 .overlay-wrap,
.isotope-grid-post.style-4 .overlay-wrap,
.isotope-grid-post.style-5 .overlay-wrap,
.isotope-grid-post.style-6 .overlay-wrap {
  overflow: hidden;
  position: relative;
  text-align: center;
}
.isotope-grid-post.style-1 .overlay-wrap .overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 250ms linear 0ms;
  -khtml-transition: all 250ms linear 0ms;
  -moz-transition: all 250ms linear 0ms;
  -ms-transition: all 250ms linear 0ms;
  -o-transition: all 250ms linear 0ms;
  transition: all 250ms linear 0ms;
}
.isotope-grid-post.style-1 .overlay-wrap .overlay {
  visibility: hidden;
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  -ms-opacity: 0;
  -o-opacity: 0;
  opacity: 0;
  -webkit-transform: scale(1.5);
  -khtml-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transition: all 250ms linear 0ms;
  -khtml-transition: all 250ms linear 0ms;
  -moz-transition: all 250ms linear 0ms;
  -ms-transition: all 250ms linear 0ms;
  -o-transition: all 250ms linear 0ms;
  transition: all 250ms linear 0ms;
}
.isotope-grid-post.style-3 .overlay-wrap .overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 0px solid transparent;
  outline: 0px solid transparent;
  visibility: hidden;
  opacity: 0;
  transform-origin: 100% 50% 0px;
  transform-style: flat;
  transform: matrix3d(0, 0, 1, -0.000833333, 0, 1, 0, 0, -1, 0, 0, 0, 0, 0, 0, 1);
  background-color: rgba(137,175,114,1.00);
  -webkit-transition: all 250ms linear 0ms;
  -khtml-transition: all 250ms linear 0ms;
  -moz-transition: all 250ms linear 0ms;
  -ms-transition: all 250ms linear 0ms;
  -o-transition: all 250ms linear 0ms;
  transition: all 250ms linear 0ms;
}
.isotope-grid-post.style-3 .overlay-wrap > img {
  transform-style: flat;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -webkit-transition: all 250ms linear 0ms;
  -khtml-transition: all 250ms linear 0ms;
  -moz-transition: all 250ms linear 0ms;
  -ms-transition: all 250ms linear 0ms;
  -o-transition: all 250ms linear 0ms;
  transition: all 250ms linear 0ms;
}
.isotope-grid-post.style-3 .overlay-wrap:hover > img {
  transform-style: flat;
  transform: matrix3d(0.459627, 0, -0.385673, 0.000321394, 0, 0.6, 0, 0, 0.642788, 0, 0.766044, -0.00063837, -50, 0, 0.001, 0.999999);
}
.isotope-grid-post.style-4 .overlay-wrap .overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 0px solid transparent;
  outline: 0px solid transparent;
  visibility: hidden;
  opacity: 0;
  transform-origin: 50% 50% 0px;
  transform-style: flat;
  transform: matrix3d(1, 0, 0, 0, 0, -1, 0, 0, 0, 0, -1, 0.000833333, 0, 0, 0, 1);
  background-color: rgba(88,92,103,1.00);
  -webkit-transition: all 250ms linear 0ms;
  -khtml-transition: all 250ms linear 0ms;
  -moz-transition: all 250ms linear 0ms;
  -ms-transition: all 250ms linear 0ms;
  -o-transition: all 250ms linear 0ms;
  transition: all 250ms linear 0ms;
}
.isotope-grid-post.style-5 .overlay-wrap .overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 0px solid transparent;
  outline: 0px solid transparent;
  visibility: hidden;
  opacity: 0;
  transform-style: flat;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  background-color: rgba(45,48,57,0.75);
  -webkit-transition: all 250ms linear 0ms;
  -khtml-transition: all 250ms linear 0ms;
  -moz-transition: all 250ms linear 0ms;
  -ms-transition: all 250ms linear 0ms;
  -o-transition: all 250ms linear 0ms;
  transition: all 250ms linear 0ms;
}
.isotope-grid-post.style-5 .overlay-wrap > img {
  transform-origin: 50% 50% 0px;
  transform-style: flat;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -webkit-transition: all 250ms linear 0ms;
  -khtml-transition: all 250ms linear 0ms;
  -moz-transition: all 250ms linear 0ms;
  -ms-transition: all 250ms linear 0ms;
  -o-transition: all 250ms linear 0ms;
  transition: all 250ms linear 0ms;
}
.isotope-grid-post.style-5 .overlay-wrap:hover > img {
  transform: matrix3d(0.8, 0, 0, 0, 0, 0.8, 0, 0, 0, 0, 1, 0, 0, 0, 0.001, 0.999999);
}
.isotope-grid-post.style-6 .overlay-wrap .overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 0px solid transparent;
  outline: 0px solid transparent;
  visibility: hidden;
  opacity: 0;
  transform-origin: 50% 0% 0px;
  transform-style: flat;
  transform: matrix3d(1,0,0.00,0,0.00,0,1.00,0.008,0,-1,0,0,0,0,0,1);
  background-color: rgba(54,56,57,0.85);
  -webkit-transition: all 350ms cubic-bezier(0.855,1.650,0.625,0.680) 0ms;
  -khtml-transition: all 350ms cubic-bezier(0.855,1.650,0.625,0.680) 0ms;
  -moz-transition: all 350ms cubic-bezier(0.855,1.650,0.625,0.680) 0ms;
  -ms-transition: all 350ms cubic-bezier(0.855,1.650,0.625,0.680) 0ms;
  -o-transition: all 350ms cubic-bezier(0.855,1.650,0.625,0.680) 0ms;
  transition: all 350ms cubic-bezier(0.855,1.650,0.625,0.680) 0ms;
}
.isotope-grid-post.style-1 .overlay-wrap:hover .overlay {
  visibility: visible;
  filter: alpha(opacity=100);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  -ms-opacity: 1;
  -o-opacity: 1;
  opacity: 1;
  -webkit-transform: scale(1);
  -khtml-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 250ms linear 0ms;
  -khtml-transition: all 250ms linear 0ms;
  -moz-transition: all 250ms linear 0ms;
  -ms-transition: all 250ms linear 0ms;
  -o-transition: all 250ms linear 0ms;
  transition: all 250ms linear 0ms;
}
.isotope-grid-post.style-3 .overlay-wrap:hover .overlay,
.isotope-grid-post.style-4 .overlay-wrap:hover .overlay,
.isotope-grid-post.style-5 .overlay-wrap:hover .overlay,
.isotope-grid-post.style-6 .overlay-wrap:hover .overlay {
  visibility: visible;
  opacity: 1;
  transform: translate(0%, 0%);
}
.overlay .overlay-content,
.box-overlay .box-overlay-content {
  width: 100%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -khtml-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}
.overlay .overlay-content a.icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  display: inline-block;
  margin: 0 10px 0 0;
  background-color: rgba(255,255,255,0.15);
  color: #fff;
}
.isotope-grid-post.style-3 .overlay .overlay-content a.icon {
  background-color: rgba(255,255,255,0);
  border: 2px solid #fff;
  border-radius: 60px;
  font-size: 16px;
}
.isotope-grid-post.style-3 .overlay .overlay-content a.icon:hover {
  background-color: rgba(255,255,255,1.00);
  color: #000;
}
.isotope-grid-post.style-5 .overlay .overlay-content a.icon {
  background: none;
  border: none;
  border-radius: 0;
  font-size: 24px;
  margin-right: 0;
}
.overlay .overlay-content a.icon:hover {
  background-color: rgba(0,0,0,0.5);
}
.overlay .overlay-content a.icon.circle {
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}
.overlay .overlay-content h3 {
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
  font-weight: 700;
  display: inline-block;
  float: none;
  clear: both;
  margin: 15px 0px 0px 0px;
  padding: 5px 10px 5px 10px;
  border-radius: 0px 0px 0px 0px;
  background-color: rgba(255,255,255,0.15);
  position: relative;
  z-index: 2;
  font-family: "Open Sans";
  text-transform: uppercase;
}
.isotope-grid-post.style-6 .overlay .overlay-content h3 {
  background: none;
}
.grid-hover-dir {
  list-style: none;
  position: relative;
  padding: 0;
  margin: 0;
}
.grid-hover-dir li {
  background: #fff;
  position: relative;
}
.grid-hover-dir li .box-overlay-wrapper,
.grid-hover-dir li .box-overlay-wrapper img {
  display: block;
  position: relative;
}
.grid-hover-dir li .box-overlay-wrapper {
  overflow: hidden;
}
.grid-hover-dir li .box-overlay-wrapper .box-overlay {
  position: absolute;
  background: #333;
  background: rgba(159,206,78,0.75);
  width: 100%;
  height: 100%;
}
.box-overlay .box-overlay-content .title {
  font-size: 15px;
  line-height: 22px;
  color: #fff;
  font-weight: 900;
  display: inline-block;
  float: none;
  clear: both;
  margin: 0;
  padding: 0;
  border-radius: 0;
  background-color: rgba(255,255,255,0);
  position: relative;
  z-index: 2;
  font-family: "Raleway";
  text-transform: uppercase;
}
.box-overlay .box-overlay-content .sep {
  font-size: 0;
  text-indent: -9999px;
  display: inline-block;
  float: none;
  clear: both;
  margin: 20px 0;
  padding: 0;
  position: relative;
  z-index: 2;
  border-top: 2px solid #fff;
  width: 65px;
  zoom: 102%;
}
.box-overlay .box-overlay-content a.icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  display: inline-block;
  margin: 0 10px 0 0;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
}
.isotope-masonry-post .overlay-wrap {
  overflow: hidden;
  position: relative;
  text-align: center;
}
.isotope-masonry-post.style-1 .overlay-wrap .overlay {
  margin-top: -10px;
  position: absolute;
  top: 100%;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 0px solid transparent;
  outline: 0px solid transparent;
  opacity: 1;
  transform-origin: 50% 50% 0px;
  transform-style: flat;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  background-color: rgba(0,142,133,0.85);
  -webkit-transition: all 250ms linear 0ms;
  -khtml-transition: all 250ms linear 0ms;
  -moz-transition: all 250ms linear 0ms;
  -ms-transition: all 250ms linear 0ms;
  -o-transition: all 250ms linear 0ms;
  transition: all 250ms linear 0ms;
}
.isotope-masonry-post.style-2 .overlay-wrap .overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 0px solid transparent;
  outline: 0px solid transparent;
  visibility: hidden;
  opacity: 0;
  transform-origin: 50% 50% 0px;
  transform-style: flat;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  background-color: rgba(34,34,34,0.50);
  -webkit-transition: all 250ms linear 0ms;
  -khtml-transition: all 250ms linear 0ms;
  -moz-transition: all 250ms linear 0ms;
  -ms-transition: all 250ms linear 0ms;
  -o-transition: all 250ms linear 0ms;
  transition: all 250ms linear 0ms;
}
.isotope-masonry-post.style-3 .overlay-wrap .overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 0px solid transparent;
  outline: 0px solid transparent;
  visibility: hidden;
  opacity: 0;
  transform-origin: 50% 50% 0px;
  transform-style: flat;
  transform: matrix3d(0.2, 0, 0, 0, 0, 0.2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  background-color: rgba(255,255,255,1.00);
  -webkit-transition: all 250ms linear 0ms;
  -khtml-transition: all 250ms linear 0ms;
  -moz-transition: all 250ms linear 0ms;
  -ms-transition: all 250ms linear 0ms;
  -o-transition: all 250ms linear 0ms;
  transition: all 250ms linear 0ms;
}
.isotope-masonry-post.style-4 .overlay-wrap .overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 0px solid transparent;
  outline: 0px solid transparent;
  visibility: hidden;
  opacity: 0;
  transform-origin: 50% 50% 0px;
  transform-style: flat;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  background-color: rgba(54,56,57,0.85);
  -webkit-transition: all 250ms linear 0ms;
  -khtml-transition: all 250ms linear 0ms;
  -moz-transition: all 250ms linear 0ms;
  -ms-transition: all 250ms linear 0ms;
  -o-transition: all 250ms linear 0ms;
  transition: all 250ms linear 0ms;
}
.isotope-masonry-post.style-1 .overlay-wrap:hover .overlay {
  margin-top: 0;
  top: 0%;
  bottom: 0;
  transform: translate(0%, 0%);
}
.isotope-masonry-post.style-2 .overlay-wrap:hover .overlay,
.isotope-masonry-post.style-3 .overlay-wrap:hover .overlay,
.isotope-masonry-post.style-4 .overlay-wrap:hover .overlay {
  visibility: visible;
  opacity: 1;
  transform: translate(0%, 0%);
}
.isotope-masonry-post.style-1 .overlay .overlay-content h3 {
  background: none;
  font-size: 16px;
  font-family: "Raleway";
  font-weight: 800;
}
.isotope-masonry-post.style-1 .overlay .overlay-links {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.isotope-masonry-post.style-1 .overlay .overlay-links a {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  display: inline-block;
  margin: 0;
  background-color: rgba(255,255,255,0.15);
  color: #fff;
}
.isotope-masonry-post.style-1 .overlay .overlay-links a:hover {
  background-color: rgba(0,0,0,0.25);
}
.isotope-masonry-post.style-2 .overlay-wrap > img {
  width: 100%;
  height: auto;
  transform-origin: 50% 50% 0px;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -webkit-transition: all 250ms linear 0ms;
  -khtml-transition: all 250ms linear 0ms;
  -moz-transition: all 250ms linear 0ms;
  -ms-transition: all 250ms linear 0ms;
  -o-transition: all 250ms linear 0ms;
  transition: all 250ms linear 0ms;
}
.isotope-masonry-post.style-2 .overlay-wrap:hover > img {
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -60, 0, 1);
}
.isotope-masonry-post.style-2 .overlay .overlay-content {
  padding: 20px;
  text-align: center;
  bottom: 0;
  top: initial;
  background-color: rgba(51,51,51,1.00);
  -webkit-transform: translateY(0%);
  -khtml-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
}
.isotope-masonry-post.style-2 .overlay .overlay-content h3 {
  background: none;
  font-size: 16px;
  font-family: "Raleway";
  font-weight: 800;
  margin-top: 0;
  padding: 0;
}
.isotope-masonry-post.style-2 .overlay .overlay-links {
  width: 100%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -khtml-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}
.isotope-masonry-post.style-2 .overlay .overlay-links a.icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  display: inline-block;
  margin: 0 10px 0 0;
  background-color: rgba(0,0,0,0.50);
  color: #fff;
  border-radius: 50%;
}
.isotope-masonry-post.style-2 .overlay .overlay-links a.icon:hover {
  background-color: rgba(0,0,0,0.75);
}
.isotope-masonry-post.style-3 .grid-item .portfolio-wrapper {
  padding: 5px;
  background-color: #eeeeee;
}
.isotope-masonry-post.style-3 .overlay a.icon {
  background-color: rgba(245,245,245,1.00);
  color: #000;
}
.isotope-masonry-post.style-3 .overlay a.icon:hover {
  background-color: rgba(238,238,238,1.00);
  color: #000;
}
.isotope-masonry-post.style-3 .overlay .overlay-content h3 {
  background: none;
  color: #000;
  font-size: 18px;
  font-weight: 800;
  font-family: "Raleway";
  visibility: hidden;
  opacity: 0;
  transform-origin: 50% 50% 0px;
  transform-style: flat;
  transform: translate(-100%, 0%) matrix3d(1, 0, 0, 0, -0.866025, 0.5, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -webkit-transition: all 250ms linear 0ms;
  -khtml-transition: all 250ms linear 0ms;
  -moz-transition: all 250ms linear 0ms;
  -ms-transition: all 250ms linear 0ms;
  -o-transition: all 250ms linear 0ms;
  transition: all 250ms linear 0ms;
}
.isotope-masonry-post.style-3 .overlay-wrap:hover .overlay h3 {
  visibility: visible;
  opacity: 1;
  transform: translate(0%, 0%);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.isotope-masonry-post.style-4 .overlay a.icon {
  background-color: rgba(255,255,255,0);
  color: #fff;
  border-radius: 60px;
  border: 2px solid #fff;
  font-size: 16px;
}
.isotope-masonry-post.style-4 .overlay a.icon:hover {
  background-color: rgba(255,255,255,1.00);
  color: #000;
}
.isotope-masonry-post.style-4 .overlay .overlay-content h3 {
  background: none;
}
.single-portfolio .portfolio-link {
  margin-bottom: 30px;
}
.single-portfolio .portfolio-link a {
  width: 100%;
  border-radius: 5px;
}
.single-portfolio .portfolio-share .share-links {
  padding: 0;
  background: transparent;
  margin-bottom: 30px;
}
.single-portfolio .portfolio-share .share-links .share-icons {
  text-align: center;
  width: 100%;
}
.single-portfolio .portfolio-share .share-links .share-icons a {
  font-size: 16px;
  width: 40px;
  height: 40px;
  border: 1px solid #e5e5e5;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-right: 10px;
  color: #a9a9a9;
  border-radius: 50%;
  background: none;
}
.single-portfolio .portfolio-excerpt {
  margin-bottom: 30px;
}
.single-portfolio .portfolio-attributes .portfolio-attribute {
  display: block;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
  text-transform: capitalize;
}
.single-portfolio .portfolio-attributes .portfolio-attribute i {
  color: #9fce4e;
  margin-right: 10px;
}


/* === 3.27. BLOCK ICON === */
.block-icon-box-left-icon {
  margin-bottom: 35px;
}
.block-icon-box-left-icon .icon {
  text-align: center;
  display: inline-block;
  border: 2px solid transparent;
  width: 60px;
  height: 60px;
  box-sizing: content-box;
  position: relative;
  margin-top: -8px;
  margin-bottom: 35px;
}
.block-icon-box-left-icon.circle .icon {
  background-color: #9fce4e;
  border-radius: 30px;
}
.block-icon-box-left-icon .icon i {
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -o-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  color: #9fce4e;
}
.block-icon-box-left-icon.circle .icon i {
  color: #fff;
}
.block-icon-box-left-icon .icon i.fz-4 {
  font-size: 40px;
}
.block-icon-box-left-icon.style-2 .icon {
  margin-bottom: 0px;
}
.block-icon-box-left-icon .icon.icon-image {
  margin-right: 10px;
  margin-bottom: 0;
}
.block-icon-box-left-icon .heading {
  margin: 0;
  text-transform: uppercase;
}
.block-icon-box-left-icon .content {
  margin-top: 15px;
  margin-bottom: 50px;
}

/* === 3.28. PROGRESSBARS === */
.block-progressbar {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  z-index: 10;
}
.block-progressbar .progress-title {
  margin-bottom: 10px;
  line-height: 26px;
  font-weight: 400;
  font-size: 13px;
  margin-top: 0;
}
.block-progressbar.white .progress-title {
  color:#FFFFFF;
}
.block-progressbar .progressbar {
  transition: all 0.7s ease-in-out;
  background-color: #00B863;
  width: 0;
}
.block-progressbar .progressbar-back-text,
.block-progressbar .progressbar-front-text {
  display: none;
}
.block-progressbar .progressbar {
  background-color: rgb(159, 206, 78);
  height: 5px;
  width: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid transparent;
  transition: width 1s linear;
  -moz-transition: width 1s linear;
  -webkit-transition: width 1s linear;
  -o-transition: width 1s linear;
}
.block-progressbar .progressbar.striped {
  background-image: -webkit-gradient(linear,0 100%,100% 0,color-stop(.25,rgba(255,255,255,.15)),color-stop(.25,transparent),color-stop(.5,transparent),color-stop(.5,rgba(255,255,255,.15)),color-stop(.75,rgba(255,255,255,.15)),color-stop(.75,transparent),to(transparent));
  background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-image: -moz-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-image: -o-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  -webkit-background-size: 40px 40px;
  -moz-background-size: 40px 40px;
  -o-background-size: 40px 40px;
  background-size: 40px 40px;
}
.block-progressbar .progressbar.animated {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -moz-animation: progress-bar-stripes 2s linear infinite;
  -ms-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}
.block-progressbar .progressbar:before {
  content: '';
  bottom: 0;
  position: absolute;
  display: block;
  width: 100%;
  height: 5px;
  z-index: -1;
}
.style-tooltip .block-progressbar {
  margin-bottom: 30px;
  position: relative;
}
.style-tooltip .block-progressbar .progressbar {
  position: absolute;
  bottom: -5px;
}
.style-tooltip .block-progressbar .progressbar-back-text {
  position: absolute;
  display: block;
  width: 100%;
  height: 5px;
  bottom: -5px;
  font-size: 0;
  background-color: #f7f7f7;
  z-index: -1;
  -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.1) inset;
  box-shadow: 0 1px 2px rgba(0,0,0,.1) inset;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.style-tooltip .block-progressbar .progressbar-front-text {
  position: absolute;
  display: block;
  right: -25px;
  bottom: 5px;
  line-height: 1;
  font-family: "Atlan-Semibold",Helvetica,Arial,sans-serif;
  height: 23px;
  text-align: center;
  max-width: 50px;
  padding: 5px;
  border-radius: 3px;
  color: #000;
  font-size: 13px;
}
.style-tooltip .block-progressbar.white .progressbar-front-text {
  color: #fff;
}

/* --------------------------------------------------------------------- */
/* 4. COMMERCE
/* --------------------------------------------------------------------- */

/* === 4.1. GENERAL === */
@font-face {
  font-family: 'star';
  src: url('../fonts/star.eot');
  src: url('../fonts/star.eot?#iefix') format('embedded-opentype'), url('../fonts/star.woff') format('woff'), url('../fonts/star.ttf') format('truetype'), url('../fonts/star.svg#star') format('svg');
  font-weight: normal;
  font-style: normal;
}
.commerce .info-excerpt {
    display: none;
}
.commerce span.onsale {
    color: #fff;
    display: inline-block;
    position: absolute;
    left: 10px;
    text-align: center;
    text-transform: uppercase;
    top: 10px;
    z-index: 100;
    padding: 0 10px;
    line-height: 25px;
    font-size: .8571428571428571em;
    background: #9fce4e;
}
.commerce ul.products:not(.masonry-products) {
    margin-left: -15px;
    margin-right: -15px
}

.commerce ul.products:not(.masonry-products) li.product {
    float: left;
    margin: 0 0 30px;
    padding: 0 15px;
    position: relative;
    width: 25%;
    margin-left: 0;
    height: auto;
    display: inline-block;
    clear: none
}

@media (min-width:992px) {
    .commerce ul.products:not(.masonry-products) li.product.first {
        clear: both
    }
    .commerce ul.products:not(.masonry-products) li.product.last {
        margin-right: 0
    }
}

@media (min-width:992px) {
    .columns-6>ul.products:not(.masonry-products) li.product,
    ul.products:not(.masonry-products).columns-6 li.product {
        width: 16.666666666666664%
    }
    .columns-6>ul.products:not(.masonry-products) li.product.first,
    ul.products:not(.masonry-products).columns-6 li.product.first {
        clear: none
    }
    .columns-6>ul.products:not(.masonry-products) li.product:nth-of-type(6n + 1),
    ul.products:not(.masonry-products).columns-6 li.product:nth-of-type(6n + 1) {
        clear: both
    }
    .columns-5>ul.products:not(.masonry-products) li.product,
    ul.products:not(.masonry-products).columns-5 li.product {
        width: 20%
    }
    .columns-5>ul.products:not(.masonry-products) li.product.first,
    ul.products:not(.masonry-products).columns-5 li.product.first {
        clear: none
    }
    .columns-5>ul.products:not(.masonry-products) li.product:nth-of-type(5n + 1),
    ul.products:not(.masonry-products).columns-5 li.product:nth-of-type(5n + 1) {
        clear: both
    }
    .columns-4>ul.products:not(.masonry-products) li.product.first,
    ul.products:not(.masonry-products).columns-4 li.product.first {
        clear: none
    }
    .columns-4>ul.products:not(.masonry-products) li.product:nth-of-type(4n + 1),
    ul.products:not(.masonry-products).columns-4 li.product:nth-of-type(4n + 1) {
        clear: both
    }
    .columns-3>ul.products:not(.masonry-products) li.product,
    ul.products:not(.masonry-products).columns-3 li.product {
        width: 33.33333333333333%
    }
    .columns-3>ul.products:not(.masonry-products) li.product.first,
    ul.products:not(.masonry-products).columns-3 li.product.first {
        clear: none
    }
    .columns-3>ul.products:not(.masonry-products) li.product:nth-of-type(3n + 1),
    ul.products:not(.masonry-products).columns-3 li.product:nth-of-type(3n + 1) {
        clear: both
    }
    .columns-2>ul.products:not(.masonry-products) li.product,
    ul.products:not(.masonry-products).columns-2 li.product {
        width: 50%
    }
    .columns-2>ul.products:not(.masonry-products) li.product.first,
    ul.products:not(.masonry-products).columns-2 li.product.first {
        clear: none
    }
    .columns-2>ul.products:not(.masonry-products) li.product:nth-of-type(2n + 1),
    ul.products:not(.masonry-products).columns-2 li.product:nth-of-type(2n + 1) {
        clear: both
    }
}

.columns-1>ul.products:not(.masonry-products) li.product,
ul.products:not(.masonry-products).columns-1 li.product {
    width: 100%;
    clear: none
}

@media (max-width:991px) and (min-width:480px) {
    .commerce .yith-wcwl-add-to-wishlist {
        display: none;
    }
    .commerce ul.products:not(.masonry-products)>li.product:not(.product-category) {
        width: 33.33333333333333%;
        clear: none;
        margin-left: 0;
        height: auto;
        display: inline-block;
        position: relative
    }
    .commerce ul.products:not(.masonry-products)>li.product:not(.product-category):nth-of-type(3n + 1) {
        clear: both
    }
    .commerce ul.products:not(.masonry-products)>.product-category.last {
        margin-right: 0
    }
    .commerce-lookbok.commerce ul.products:not(.masonry-products)>li.product,
    .columns-4>.commerce ul.products:not(.masonry-products)>li.product,
    .commerce ul.products:not(.masonry-products).columns-4>li.product,
    .columns-2>.commerce ul.products:not(.masonry-products)>li.product,
    .commerce ul.products:not(.masonry-products).columns-2>li.product {
        width: 50%
    }
    .commerce-lookbok.commerce ul.products:not(.masonry-products)>li.product.first,
    .columns-4>.commerce ul.products:not(.masonry-products)>li.product.first,
    .commerce ul.products:not(.masonry-products).columns-4>li.product.first,
    .columns-2>.commerce ul.products:not(.masonry-products)>li.product.first,
    .commerce ul.products:not(.masonry-products).columns-2>li.product.first {
        clear: none
    }
    .commerce-lookbok.commerce ul.products:not(.masonry-products)>li.product:nth-of-type(3n + 1),
    .columns-4>.commerce ul.products:not(.masonry-products)>li.product:nth-of-type(3n + 1),
    .commerce ul.products:not(.masonry-products).columns-4>li.product:nth-of-type(3n + 1),
    .columns-2>.commerce ul.products:not(.masonry-products)>li.product:nth-of-type(3n + 1),
    .commerce ul.products:not(.masonry-products).columns-2>li.product:nth-of-type(3n + 1) {
        clear: none
    }
    .commerce-lookbok.commerce ul.products:not(.masonry-products)>li.product:nth-of-type(2n + 1),
    .columns-4>.commerce ul.products:not(.masonry-products)>li.product:nth-of-type(2n + 1),
    .commerce ul.products:not(.masonry-products).columns-4>li.product:nth-of-type(2n + 1),
    .columns-2>.commerce ul.products:not(.masonry-products)>li.product:nth-of-type(2n + 1),
    .commerce ul.products:not(.masonry-products).columns-2>li.product:nth-of-type(2n + 1) {
        clear: both
    }
    .col-md-9.main-wrap ul.products:not(.masonry-products) li.product:not(.product-category) {
        width: 33.33333333333333%;
        clear: none
    }
    .col-md-9.main-wrap ul.products:not(.masonry-products) li.product:not(.product-category).first {
        clear: none
    }
    .col-md-9.main-wrap ul.products:not(.masonry-products) li.product:not(.product-category):nth-of-type(3n + 1) {
        clear: both
    }
}

@media (max-width:480px) {
    .commerce ul.products:not(.masonry-products)>li.product,
    .commerce ul.products:not(.masonry-products)>li.product:not(.product-category) {
        width: 100%;
        float: none;
        clear: none
    }
}

.commerce ul.cart_list,
.commerce ul.product_list_widget {
    list-style: none outside;
    padding: 0;
    margin: 0
}

.commerce ul.cart_list li,
.commerce ul.product_list_widget li {
    padding-bottom: 15px;
    list-style: none
}

.commerce ul.cart_list li:before,
.commerce ul.product_list_widget li:before,
.commerce ul.cart_list li:after,
.commerce ul.product_list_widget li:after {
    content: " ";
    display: table
}

.commerce ul.cart_list li:after,
.commerce ul.product_list_widget li:after {
    clear: both
}

.commerce ul.cart_list li:last-child,
.commerce ul.product_list_widget li:last-child {
    border: none
}

.commerce ul.cart_list li a,
.commerce ul.product_list_widget li a {
    display: block;
    font-size: 0.9em;
    font-weight: 600;
    margin-bottom: 0.2em;
    color: #333;
    text-transform: uppercase
}

.commerce ul.cart_list li a:hover,
.commerce ul.product_list_widget li a:hover {
    color: #9fce4e
}

.commerce ul.cart_list li img,
.commerce ul.product_list_widget li img {
    float: left;
    margin-right: 1em;
    width: 60px;
    height: auto
}

.commerce ul.cart_list li .amount,
.commerce ul.product_list_widget li .amount {
    font-size: 100%
}

.commerce ul.cart_list li del,
.commerce ul.product_list_widget li del {
    font-size: 85%;
    color: #777;
    margin-left: .35714285714285715em
}

.commerce ul.cart_list li ins,
.commerce ul.product_list_widget li ins {
    background: none;
    text-decoration: none
}

.commerce .product-category {
    position: relative;
    text-align: center;
    background: none;
}

.commerce .product-category>a {
    position: relative;
    display: block
}

.commerce .product-category .product-category-image {
    display: block;
    position: relative;
    overflow: hidden
}

.commerce .product-category .product-category-image:before {
    z-index: 1;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 1s;
    -webkit-transition: background-color 1s
}

.commerce .product-category .product-category-image img {
    width: 100%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 2s;
    transition: transform 2s;
    transition: transform 2s, -webkit-transform 2s
}

.commerce .product-category:hover .product-category-image:before {
    background-color: rgba(0, 0, 0, 0.5)
}

.commerce .product-category:hover .product-category-image img {
    -webkit-transform: matrix(1.2, 0, 0, 1.2, 0, 0);
    transform: matrix(1.2, 0, 0, 1.2, 0, 0)
}

.commerce .product-category:hover h3 {
    background: rgba(0, 0, 0, 0.5)
}

.commerce .product-category h3 {
    font-size: 2em;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
    margin: 0;
    padding: 10px 0;
    background: rgba(0, 0, 0, 0.3);
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    z-index: 3
}

.commerce .product-category h3:after,
.commerce .product-category h3:before {
    content: '-'
}

@media (max-width:767px) {
    .commerce .product-category h3 {
        font-size: 1.1428571428571428em
    }
}

.commerce ul.products li.product {
    overflow: hidden;
    background-color: transparent;
    text-align: center
}
.commerce .products ul, .commerce ul.products {
    margin: 0;
    padding: 0;
    list-style: none outside;
    clear: both;
}
.commerce ul.products li.product figure {
    position: relative
}

.commerce ul.products li.product figure .product-wrap {
    position: relative;
    display: block
}

.commerce ul.products li.product figure .product-wrap .product-images {
    position: relative;
    overflow: hidden;
    border: 1px solid #e1e1e1
}

.commerce ul.products li.product figure .product-wrap .product-images>a {
    display: block
}

.commerce ul.products li.product figure .product-wrap .product-images .loop-action {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 30;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.commerce ul.products li.product figure .product-wrap .product-images .loop-action>div {
    display: inline-block;
    vertical-align: top
}

.commerce ul.products li.product figure .product-wrap .product-images .loop-add-to-wishlist .yith-wcwl-add-to-wishlist {
    margin: 0
}

.commerce ul.products li.product figure .product-wrap .product-images .shop-loop-thumbnail {
    left: 0;
    top: 0;
    width: 100%;
    z-index: 8
}

.commerce ul.products li.product figure .product-wrap .product-images .shop-loop-thumbnail img {
    display: block;
    height: auto;
    -webkit-transition: all .5s ease-in-out 0s;
    -o-transition: all .5s ease-in-out 0s;
    transition: all .5s ease-in-out 0s;
    width: 100%;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    opacity: 1;
    filter: alpha(opacity=100)
}

.commerce ul.products li.product figure .product-wrap .product-images .shop-loop-thumbnail.shop-loop-front-thumbnail {
    z-index: 10;
    position: relative
}

.commerce ul.products li.product figure .product-wrap .product-images .shop-loop-thumbnail.shop-loop-back-thumbnail {
    position: absolute
}

.commerce ul.products li.product figure:hover .product-wrap .product-images {
    border-color: #9fce4e
}

.commerce ul.products li.product figure:hover .product-wrap .product-images .shop-loop-front-thumbnail img {
    opacity: 0;
    filter: alpha(opacity=0)
}

.commerce ul.products li.product figure:hover .product-wrap .product-images .loop-action {
    opacity: 1;
    filter: alpha(opacity=100)
}

.commerce ul.products li.product figcaption {
    padding: 1em 0 0;
    position: relative;
    z-index: 50
}

.commerce ul.products li.product figcaption .shop-loop-product-info {
    display: block
}

.commerce ul.products li.product figcaption .shop-loop-product-info:before,
.commerce ul.products li.product figcaption .shop-loop-product-info:after {
    content: " ";
    display: table
}

.commerce ul.products li.product figcaption .shop-loop-product-info:after {
    clear: both
}

.commerce ul.products li.product figcaption .shop-loop-product-info .list-info-meta {
    display: none
}

.commerce ul.products li.product strong {
    display: block
}

.commerce ul.products li.product .star-rating {
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    font-size: 85%
}

.commerce ul.products li.product .info-price {
    margin-top: 15px;
}

.commerce ul.products li.product .info-price del {
    font-size: 85%
}

.commerce ul.products li.product .price {
    opacity: .7;
    filter: alpha(opacity=70);
    font-weight: 500;
}

.commerce ul.products li.product .price del {
    color: #999;
    float: none;
    margin: 0 5px
}

.commerce ul.products li.product .price ins {
    background: none;
    text-decoration: none;
    float: none
}
.commerce ul.products li.product .product_title {
    font-size: 1.2em;
    margin-top: 0.5em;
    margin-bottom: 10px
}
.commerce .star-rating {
    margin: auto;
    overflow: hidden;
    position: relative;
    height: 1em;
    line-height: 1em;
    font-size: 13px;
    width: 5.5em;
    font-family: 'star'
}

.commerce .star-rating:before {
    content: "\73\73\73\73\73";
    color: #9fce4e;
    float: left;
    top: 0;
    left: 0;
    position: absolute
}

.commerce .star-rating span {
    overflow: hidden;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    padding-top: 1.5em;
    color: #9fce4e
}

.commerce .star-rating span:before {
    content: "\E033\E033\E033\E033\E033";
    top: 0;
    position: absolute;
    left: 0;
    letter-spacing: 1.5px;
}

.commerce .products .star-rating {
    display: block;
    float: none
}

.commerce ul.products li.product .star-rating {
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    font-size: 85%
}

.commerce ul.products li.product figure .product-wrap .product-images .loop-action {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 30;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.commerce ul.products li.product figure .product-wrap .product-images .loop-action>div {
    display: inline-block;
    vertical-align: top
}

.commerce ul.products li.product figure .product-wrap .product-images .loop-add-to-wishlist .yith-wcwl-add-to-wishlist {
    margin: 0
}
.commerce ul.products li.product .shop-loop-quickview a {
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    height: 45px;
    width: 45px;
    line-height: 53px;
    text-align: center
}

.commerce ul.products li.product .shop-loop-quickview a i {
    font-size: 18px
}

.commerce ul.products li.product .shop-loop-quickview a.loading i:before {
    content: '\e02d';
    -webkit-animation: fa-spin .5s infinite linear;
    -o-animation: fa-spin .5s infinite linear;
    animation: fa-spin .5s infinite linear;
    -webkit-animation-timing-function: steps(8, start);
    animation-timing-function: steps(8, start)
}

.commerce ul.products li.product .loop-add-to-cart a {
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    height: 45px;
    width: 45px;
    line-height: 45px;
    position: relative;
    text-indent: -999em
}

.commerce ul.products li.product .loop-add-to-cart a:before {
    content: "\e013";
    position: relative;
    display: inline-block;
    font-family: 'ElegantIcons';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}
.commerce ul.products li.product .loop-add-to-cart a:before {
    display: inline-block;
    font-size: 18px;
    left: 0;
    position: absolute;
    text-indent: 0;
    top: 0;
    height: 45px;
    width: 45px;
    line-height: 45px
}
.commerce ul.products li.product .yith-wcwl-add-to-wishlist .add_to_wishlist {
    display: block;
    position: relative;
    text-indent: -999em;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    -webkit-border-radius: 0;
    border-radius: 0;
    color: #fff
}

.commerce ul.products li.product .yith-wcwl-add-to-wishlist .add_to_wishlist:before {
    content: "\e030";
    position: relative;
    display: inline-block;
    font-family: 'ElegantIcons';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.commerce ul.products li.product .yith-wcwl-add-to-wishlist .add_to_wishlist:before {
    display: block;
    font-size: 18px;
    left: 0;
    position: absolute;
    text-indent: 0;
    top: 0;
    height: 45px;
    width: 45px;
    line-height: 45px
}

.caroufredsel.product-slider .caroufredsel_wrapper {
    position: static;
}

.caroufredsel.product-slider .caroufredsel-wrap ul.products>li {
    float: left;
    list-style: none;
    margin: 0 10px;
    display: block;
    clear: none !important;
}

.caroufredsel-item-no-padding.caroufredsel.product-slider .caroufredsel-wrap ul.products>li {
    margin: 0;
}

.caroufredsel-item-no-padding.caroufredsel.product-slider .caroufredsel-wrap ul.products>li.columns-1 {
    padding: 0;
}

.caroufredsel-item-no-padding.caroufredsel.product-slider .caroufredsel-wrap ul.products {
    margin: 0;
}

.caroufredsel-item-no-padding.caroufredsel.product-slider .caroufredsel-wrap ul.products.columns-1>li {
    padding: 0;
}

.caroufredsel.product-slider .caroufredsel-prev,
.caroufredsel.product-slider .caroufredsel-next {
    margin-top: -50px
}

.product-categories-grid-wrap {
    position: relative
}

.product-categories-grid-wrap .wall-col {
    margin: 0;
    padding: 0;
    height: 30rem;
    position: relative;
    overflow: hidden
}

@media (max-width:991px) {
    .product-categories-grid-wrap .wall-col {
        height: auto;
        padding-bottom: 0;
    }
}

.product-categories-grid-wrap .wall-col .product-category-grid-featured {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 2s;
    transition: transform 2s;
    transition: transform 2s, -webkit-transform 2s
}

.product-categories-grid-wrap .wall-col .product-category-grid-item-wrap .product-category-grid-featured-wrap:before {
    z-index: 1;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 1s;
    -webkit-transition: background-color 1s
}

.product-categories-grid-wrap .wall-col:hover .product-category-grid-featured {
    -webkit-transform: matrix(1.2, 0, 0, 1.2, 0, 0);
    transform: matrix(1.2, 0, 0, 1.2, 0, 0)
}

.product-categories-grid-wrap .wall-col:hover .product-category-grid-item-wrap .product-category-grid-featured-wrap:before {
    background-color: rgba(0, 0, 0, 0.5)
}

.product-categories-grid-wrap .wall-col:hover .product-category-grid-featured-summary h3:before {
    bottom: -10px;
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible
}

.product-categories-grid-wrap .wall-row {
    width: 100%;
    display: table
}

.product-categories-grid-wrap .wall-row .wall-col {
    display: table-cell;
    float: none;
    position: relative;
    height: 100%
}

@media (max-width:767px) {
    .product-categories-grid-wrap .wall-row {
        display: block
    }
    .product-categories-grid-wrap .wall-row .wall-col {
        display: block
    }
}

.product-categories-grid-wrap .product-category-grid-item {
    display: block
}

.product-categories-grid-wrap .product-category-grid-item-wrap {
    overflow: hidden;
    position: relative;
    width: 100%
}

.grid-gutter .product-categories-grid-wrap .product-category-grid-item-wrap:before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border: 15px solid #fff;
    z-index: 3
}

.product-categories-grid-wrap .product-category-grid-featured {
    height: 0;
    padding-bottom: 100%;
    background-color: #f3f3f3;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 101% auto;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.product-categories-grid-wrap .product-category-grid-featured-wrap {
    overflow: hidden;
    position: relative
}

.product-categories-grid-wrap .title-in {
    height: 0;
    padding-bottom: 50%
}

@media (max-width:991px) {
    .product-categories-grid-wrap .title-in {
        height: auto;
        padding-bottom: 0;
    }
}

.product-categories-grid-wrap .title-in .product-category-grid-item-wrap {
    position: static
}

.product-categories-grid-wrap .title-in .product-category-grid-item-wrap .portfolio-featured {
    opacity: .6;
    filter: alpha(opacity=60)
}

@media (max-width:767px) {
    .product-categories-grid-wrap .pull-right {
        float: none !important;
    }
}

.product-categories-grid-wrap .title-out {
    height: 0;
    padding-bottom: 25%
}

.product-categories-grid-wrap .title-out .product-category-grid-featured {
    padding-bottom: 50%
}

@media (max-width:767px) {
    .product-categories-grid-wrap .title-out .product-category-grid-featured {
        padding-bottom: 100%
    }
}

.product-categories-grid-wrap .title-out.padding-100 .product-category-grid-featured {
    padding-bottom: 100%
}

.product-categories-grid-wrap .height-auto {
    height: auto;
    padding: 0
}

.product-categories-grid-wrap .height-auto .product-category-grid-featured {
    padding-bottom: 100%
}

@media (max-width:767px) {
    .product-categories-grid-wrap .height-auto .product-category-grid-featured {
        padding-bottom: 100%
    }
}

.product-categories-grid-wrap .product-category-grid-featured-summary {
    text-transform: uppercase;
    text-align: center;
    z-index: 12
}

.product-categories-grid-wrap .product-category-grid-featured-summary h3 {
    margin: 0;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    font-size: 2em;
    position: relative;
    line-height: 1.1;
    color: #fff
}

.product-categories-grid-wrap .product-category-grid-featured-summary h3 small {
    margin-top: 10px;
    margin-bottom: 10px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    font-size: 0.5em;
    display: block;
    font-weight: normal;
    color: #333;
    color: #fff
}

.product-categories-grid-wrap .product-category-grid-featured-summary .product-category-grid-btn {
    margin-top: 15px
}

.grid-style-1 .product-categories-grid-wrap .product-category-grid-item:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
    background: none;
    -webkit-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.grid-style-1 .product-categories-grid-wrap .product-category-grid-featured-summary {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 30px
}

.grid-style-1 .product-categories-grid-wrap .product-category-grid-featured-summary h3:before {
    width: 100px;
    height: 1px;
    content: "";
    position: absolute;
    bottom: -30px;
    -webkit-transition: bottom .3s, opacity .3s;
    -o-transition: bottom .3s, opacity .3s;
    transition: bottom .3s, opacity .3s;
    left: 50%;
    margin-left: -50px;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    background: #FFF
}

@media (max-width:767px) {
    .grid-style-2 .product-categories-grid-wrap .product-category-grid-item:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        opacity: 1;
        filter: alpha(opacity=100);
        visibility: visible;
        background: rgba(0, 0, 0, 0.5);
        -webkit-transition: all .3s ease-out 0s;
        -o-transition: all .3s ease-out 0s;
        transition: all .3s ease-out 0s
    }
    .grid-style-2 .product-categories-grid-wrap .product-category-grid-featured-summary {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%)
    }
    .grid-style-2 .product-categories-grid-wrap .product-category-grid-featured-summary h3:before {
        width: 100px;
        height: 1px;
        content: "";
        position: absolute;
        bottom: -30px;
        -webkit-transition: bottom .3s, opacity .3s;
        -o-transition: bottom .3s, opacity .3s;
        transition: bottom .3s, opacity .3s;
        left: 50%;
        margin-left: -50px;
        opacity: 0;
        filter: alpha(opacity=0);
        visibility: hidden;
        background: #FFF
    }
    .grid-style-2 .product-categories-grid-wrap .product-category-grid-featured-summary .product-category-grid-btn {
        display: none
    }
}

.grid-style-2 .product-categories-grid-wrap .title-out {
    background: #f3f3f3
}

@media (min-width:768px) {
    .grid-style-2 .product-categories-grid-wrap .title-in .product-category-grid-featured-summary {
        text-align: left
    }
    .grid-style-2 .product-categories-grid-wrap .title-in .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap {
        padding: 30px
    }
    .grid-style-2 .product-categories-grid-wrap .title-in .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap {
        top: 0;
        left: auto;
        right: 0;
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0)
    }
    .grid-gutter.grid-style-2 .product-categories-grid-wrap .title-in .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap {
        top: 15px;
        right: 15px
    }
    .grid-style-2 .product-categories-grid-wrap .title-in .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap h3 small {
        font-size: 0.6em;
        text-transform: none
    }
    .grid-style-2 .product-categories-grid-wrap .title-in .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap .product-category-grid-btn {
        border-color: #fff;
        color: #fff
    }
    .grid-style-2 .product-categories-grid-wrap .title-in .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap .product-category-grid-btn:hover {
        border-color: #9fce4e
    }
    .grid-style-2 .product-categories-grid-wrap .title-in.out-right .product-category-grid-featured-summary {
        left: 0;
        right: auto
    }
    .grid-style-2 .product-categories-grid-wrap .title-in.out-right .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap {
        top: 0;
        left: 0;
        right: auto;
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0)
    }
    .grid-gutter.grid-style-2 .product-categories-grid-wrap .title-in.out-right .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap {
        top: 15px;
        left: 15px
    }
    .grid-style-2 .product-categories-grid-wrap .title-out .product-category-grid-featured {
        padding-bottom: 100%
    }
    .grid-style-2 .product-categories-grid-wrap .title-out .product-category-grid-featured-wrap {
        width: 50%
    }
    .grid-style-2 .product-categories-grid-wrap .title-out .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap h3 {
        color: #333
    }
    .grid-style-2 .product-categories-grid-wrap .title-out .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap h3 small {
        color: #333
    }
    .grid-style-2 .product-categories-grid-wrap .product-category-grid-featured-summary {
        width: 50%;
        position: absolute;
        top: 0;
        height: 100%;
        right: 0
    }
    .grid-style-2 .product-categories-grid-wrap .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 15px
    }
    .grid-style-2 .product-categories-grid-wrap .title-out.last-right .product-category-grid-featured-wrap {
        float: right
    }
    .grid-style-2 .product-categories-grid-wrap .title-out.last-right .product-category-grid-featured-summary {
        right: auto;
        left: 0
    }
}

.grid-style-3 .product-categories-grid-wrap .title-in {
    padding-bottom: 100%
}

.grid-style-3 .product-categories-grid-wrap .title-out {
    padding-bottom: 50%
}

.grid-style-3 .product-categories-grid-wrap .wall-col .product-category-grid-featured-summary {
    text-align: left
}

.grid-style-3 .product-categories-grid-wrap .wall-col .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0)
}

.grid-style-3 .product-categories-grid-wrap .wall-col .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap h3 {
    padding: 30px
}

@media (max-width:991px) and (min-width:768px) {
    .grid-style-3 .product-categories-grid-wrap .wall-col .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap h3 {
        padding: 15px;
        font-size: 1.4285714285714286em
    }
}

.grid-style-3 .product-categories-grid-wrap .wall-col .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap h3 small {
    font-size: 0.5em;
    margin-bottom: 10px;
    text-transform: capitalize
}

.grid-style-3 .product-categories-grid-wrap .wall-col .product-category-grid-featured-summary {
    width: 100%;
    position: absolute;
    top: 15px;
    height: 100%;
    left: 15px
}

.grid-style-3 .product-categories-grid-wrap .wall-col .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%
}

.grid-style-4 .product-categories-grid-wrap .title-in {
    padding-bottom: 36.4%
}

.grid-style-4 .product-categories-grid-wrap .product-category-grid-featured {
    padding-bottom: 72.8%
}

.grid-style-4 .product-categories-grid-wrap .wall-col .product-category-grid-featured-summary {
    text-align: left
}

.grid-style-4 .product-categories-grid-wrap .wall-col .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0)
}

.grid-style-4 .product-categories-grid-wrap .wall-col .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap h3 {
    font-size: 2.142857142857143em;
    padding: 30px
}

@media (max-width:991px) and (min-width:768px) {
    .grid-style-4 .product-categories-grid-wrap .wall-col .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap h3 {
        padding: 15px;
        font-size: 1.4285714285714286em
    }
}

.grid-style-4 .product-categories-grid-wrap .wall-col .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap h3 small {
    font-size: 0.5em;
    margin-bottom: 10px;
    text-transform: capitalize
}

.grid-style-4 .product-categories-grid-wrap .wall-col .product-category-grid-featured-summary {
    right: 15px;
    position: absolute;
    top: 15px;
    height: 100%;
    left: 15px;
    z-index: 12
}

.grid-style-4 .product-categories-grid-wrap .wall-col .product-category-grid-featured-summary .product-category-grid-featured-summary-wrap {
    position: absolute;
    bottom: 30px;
    left: 0;
    z-index: 10;
    width: 100%
}

@media (max-width: 991px) {
	.product-categories-grid-wrap .title-out,
	.grid-style-3 .product-categories-grid-wrap .title-out,
	.grid-style-4 .product-categories-grid-wrap .title-out {
		height: auto;
		padding-bottom: 0;
	}
	.product-categories-grid-wrap .title-in,
	.grid-style-3 .product-categories-grid-wrap .title-in,
	.grid-style-4 .product-categories-grid-wrap .title-in {
		height: auto;
		padding-bottom: 0;
	}
}

ul.masonry-products li.product {
    padding-bottom: 2em;
    background: none;
    z-index: 51
}

.lookbooks .lookbook {
    margin-bottom: 20px
}

.lookbooks .lookbook:first-child {
    margin-top: 20px
}

@media (max-width:767px) {
    .lookbooks .lookbook {
        padding: 0 20px !important;
    }
}

.lookbooks .loobook-wrap {
    position: relative
}

.lookbooks .lookbook-info {
    float: right;
    width: 33.33333333333333%;
    min-height: 1px
}

@media (max-width:767px) {
    .lookbooks .lookbook-info {
        width: 100%;
    }
}

.lookbooks .lookbook-thumb {
    float: left;
    width: 66.66666666666666%;
    position: relative;
    background: #fff;
    padding: 0 20px
}

.lookbooks .lookbook-thumb img {
    width: 100%
}

@media (max-width:767px) {
    .lookbooks .lookbook-thumb {
        width: 100%;
        padding: 20px 0 0 0
    }
}

.lookbooks .lookbook-left {
    padding-right: 20px
}

@media (min-width:480px) {
    .lookbooks .lookbook-left .lookbook-info-wrap {
        left: auto;
        right: 0
    }
}

.lookbooks .lookbook-right {
    padding-left: 20px
}

.lookbooks .lookbook-right .lookbook-info {
    float: left
}

@media (min-width:480px) {
    .lookbooks .lookbook-right .lookbook-info .lookbook-info-wrap {
        left: 0
    }
}

.lookbooks .lookbook-info-wrap {
    padding: 30px;
    text-align: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: absolute;
    height: 100%;
    width: 33.33333333333333%;
    vertical-align: middle;
    top: 0
}

.lookbooks .lookbook-info-wrap:before {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    position: absolute;
    background: rgba(59, 62, 67, 0.7)
}

.lookbooks .lookbook-info-wrap:after {
    content: "";
    display: inline-block;
    height: 100%;
    margin-right: -1em;
    vertical-align: middle;
    width: 1px
}

.lookbooks .lookbook-info-wrap .lookbook-action {
    margin-top: 30px;
    padding-left: 3rem;
    padding-right: 3rem;
    text-transform: uppercase
}

@media (max-width:991px) {
    .lookbooks .lookbook-info-wrap .lookbook-description {
        display: none
    }
}

.lookbooks .lookbook-info-wrap .lookbook-info-sumary {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    z-index: 2
}

.lookbooks .lookbook-info-wrap .lookbook-small-title {
    font-size: 1.1em;
    text-transform: uppercase;
    color: #fff
}

.lookbooks .lookbook-info-wrap h3 {
    color: #fff;
    margin-top: 0
}

.lookbooks .lookbook-info-wrap h3 a {
    text-transform: uppercase;
    color: #fff;
    font-size: 1.5em
}

@media (max-width:991px) {
    .lookbooks .lookbook-info-wrap h3 a {
        font-size: 1.2em
    }
}

@media (max-width:767px) {
    .lookbooks-slider .lookbook-info-wrap,
    .lookbooks-slider .lookbook-info-wrap {
        position: relative;
        width: 100%
    }
}

@media (min-width:480px) {
    .lookbooks-grid .commerce-lookbok {
        padding: 30px
    }
}

.lookbooks-grid .lookbook-info-wrap,
.lookbooks-grid .lookbook-info {
    width: 50%
}

@media (max-width:991px) {
    .lookbooks-grid .lookbook-info-wrap,
    .lookbooks-grid .lookbook-info {
        width: 100%;
        position: relative
    }
}

@media (max-width:991px) {
    .lookbooks-grid .lookbook-info-wrap {
        background-size: contain;
    }
}

.lookbooks-grid .lookbook-info-wrap-border {
    display: none
}

.lookbooks-grid .lookbook-small-title {
    font-weight: 600;
    color: #9fce4e;
}

@media (min-width:992px) {
    .lookbooks-grid .lookbook-info-wrap-border {
        border: 1px solid #9fce4e;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        position: absolute;
        z-index: 2;
        width: auto;
        height: auto;
        display: block;
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
        opacity: 0;
        filter: alpha(opacity=0);
        visibility: hidden
    }
}

.lookbooks-grid .lookbook-thumb {
    width: 50%
}

@media (max-width:991px) {
    .lookbooks-grid .lookbook-thumb {
        width: 100%
    }
}

.lookbooks-grid .lookbook {
    padding: 0;
    margin: 0;
}

@media (min-width:992px) {
    .lookbooks-grid .lookbook-info-sumary {
        position: relative;
        padding: 20px
    }
    .lookbooks-grid .lookbook-info-sumary h3 {
        font-size: 2.5em;
        font-weight: 900
    }
    .lookbooks-grid .lookbook-info-sumary>* {
        position: relative;
        z-index: 2
    }
    .lookbooks-grid .lookbook-info:hover .lookbook-info-wrap-border {
        opacity: 1;
        filter: alpha(opacity=100);
        visibility: visible
    }
}

.commerce ul.products li.product.style-2 {
    text-align: left
}

.commerce ul.products li.product.style-2 figure .star-rating {
    margin-left: 0;
}

.commerce ul.products li.product.style-2 figure .product-wrap .product-images {
    border: 0
}

.commerce ul.products li.product.style-2 figure .product-wrap .product-images .loop-action {
    right: auto;
    opacity: 1;
    filter: alpha(opacity=100);
    background: none;
    width: 50px;
    z-index: 99
}

.commerce ul.products li.product.style-2 figure .product-wrap .product-images .loop-action>div {
    -webkit-transform: translate(-100%, 0);
    -ms-transform: translate(-100%, 0);
    -o-transform: translate(-100%, 0);
    transform: translate(-100%, 0)
}

.commerce ul.products li.product.style-2 figure .product-wrap .product-images .loop-action .shop-loop-quickview {
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.commerce ul.products li.product.style-2 figure .product-wrap .product-images .loop-action .shop-loop-quickview a {
    color: #000;
    width: 50px;
    height: 40px;
    line-height: 40px
}

.commerce ul.products li.product.style-2 figure .product-wrap .product-images .loop-action .loop-add-to-cart {
    -webkit-transition: all .3s ease .1s;
    -o-transition: all .3s ease .1s;
    transition: all .3s ease .1s
}

.commerce ul.products li.product.style-2 figure .product-wrap .product-images .loop-action .loop-add-to-cart a {
    color: #000;
    width: 50px;
    height: 40px;
    line-height: 40px
}

.commerce ul.products li.product.style-2 figure .product-wrap .product-images .loop-action .loop-add-to-cart a:before {
    width: 50px;
    height: 40px;
    line-height: 40px
}

.commerce ul.products li.product.style-2 figure .product-wrap .product-images .loop-action .loop-add-to-cart a.loading:before,
.commerce ul.products li.product.style-2 figure .product-wrap .product-images .loop-action .loop-add-to-cart a.added:before {
    color: #000;
    line-height: 40px;
    width: 50px;
    height: 40px
}

.commerce ul.products li.product.style-2 figure .product-wrap .product-images .loop-action .loop-add-to-wishlist {
    -webkit-transition: all .3s ease .1s;
    -o-transition: all .3s ease .1s;
    transition: all .3s ease .1s
}

.commerce ul.products li.product.style-2 figure .product-wrap .product-images .loop-action .loop-add-to-wishlist .add_to_wishlist {
    color: #000;
    width: 50px;
    height: 40px;
    line-height: 40px
}

.commerce ul.products li.product.style-2 figure .product-wrap .product-images .loop-action .loop-add-to-wishlist .add_to_wishlist:before {
    width: 50px;
    height: 40px;
    line-height: 40px
}

.commerce ul.products li.product.style-2 figure .product-wrap .product-images .loop-action .loop-add-to-wishlist .yith-wcwl-wishlistaddedbrowse a,
.commerce ul.products li.product.style-2 figure .product-wrap .product-images .loop-action .loop-add-to-wishlist .yith-wcwl-wishlistexistsbrowse a {
    color: #000;
    width: 50px;
    height: 40px;
    line-height: 40px
}

.commerce ul.products li.product.style-2 figure .product-wrap .product-images .loop-action .loop-add-to-wishlist .yith-wcwl-wishlistaddedbrowse a:before,
.commerce ul.products li.product.style-2 figure .product-wrap .product-images .loop-action .loop-add-to-wishlist .yith-wcwl-wishlistexistsbrowse a:before {
    width: 50px;
    height: 40px;
    line-height: 40px
}

.commerce ul.products li.product.style-2 figure:hover .product-wrap .product-images .loop-action>div {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0)
}

.commerce ul.products li.product.style-3 {
    text-align: left
}

.commerce ul.products li.product.style-3 figure .star-rating {
    margin-left: 0;
}

.commerce ul.products li.product.style-3 figure .product-wrap .product-images {
    border: 0
}

.commerce ul.products li.product.style-3 figure .product-wrap .product-images .loop-action {
    right: auto;
    left: 10px;
    opacity: 1;
    filter: alpha(opacity=100);
    background: none;
    width: 35px;
    z-index: 99;
    bottom: 10px
}

.commerce ul.products li.product.style-3 figure .product-wrap .product-images .loop-action>div {
    -webkit-transform: translate(-150%, 0);
    -ms-transform: translate(-150%, 0);
    -o-transform: translate(-150%, 0);
    transform: translate(-150%, 0)
}

.commerce ul.products li.product.style-3 figure .product-wrap .product-images .loop-action .shop-loop-quickview {
    margin: 0 0 5px;
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.commerce ul.products li.product.style-3 figure .product-wrap .product-images .loop-action .shop-loop-quickview a {
    background: #818181;
    color: #fff;
    width: 35px;
    height: 35px;
    line-height: 35px
}

.commerce ul.products li.product.style-3 figure .product-wrap .product-images .loop-action .shop-loop-quickview a i {
    font-size: 15px
}

.commerce ul.products li.product.style-3 figure .product-wrap .product-images .loop-action .shop-loop-quickview a:hover {
    background: #9fce4e
}

.commerce ul.products li.product.style-3 figure .product-wrap .product-images .loop-action .loop-add-to-cart {
    margin: 0 0 5px;
    -webkit-transition: all .3s ease .1s;
    -o-transition: all .3s ease .1s;
    transition: all .3s ease .1s
}

.commerce ul.products li.product.style-3 figure .product-wrap .product-images .loop-action .loop-add-to-cart a {
    background: #818181;
    color: #fff;
    width: 35px;
    height: 35px;
    line-height: 35px
}

.commerce ul.products li.product.style-3 figure .product-wrap .product-images .loop-action .loop-add-to-cart a:hover {
    background: #9fce4e
}

.commerce ul.products li.product.style-3 figure .product-wrap .product-images .loop-action .loop-add-to-cart a:before {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 15px
}

.commerce ul.products li.product.style-3 figure .product-wrap .product-images .loop-action .loop-add-to-cart a.loading:before,
.commerce ul.products li.product.style-3 figure .product-wrap .product-images .loop-action .loop-add-to-cart a.added:before {
    color: #fff;
    line-height: 35px;
    width: 35px;
    height: 35px
}

.commerce ul.products li.product.style-3 figure .product-wrap .product-images .loop-action .loop-add-to-wishlist {
    -webkit-transition: all .3s ease .1s;
    -o-transition: all .3s ease .1s;
    transition: all .3s ease .1s
}

.commerce ul.products li.product.style-3 figure .product-wrap .product-images .loop-action .loop-add-to-wishlist .add_to_wishlist {
    background: #818181;
    color: #fff;
    width: 35px;
    height: 35px;
    line-height: 35px
}

.commerce ul.products li.product.style-3 figure .product-wrap .product-images .loop-action .loop-add-to-wishlist .add_to_wishlist:hover {
    background: #9fce4e
}

.commerce ul.products li.product.style-3 figure .product-wrap .product-images .loop-action .loop-add-to-wishlist .add_to_wishlist:before {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 15px
}

.commerce ul.products li.product.style-3 figure .product-wrap .product-images .loop-action .loop-add-to-wishlist .yith-wcwl-wishlistaddedbrowse a,
.commerce ul.products li.product.style-3 figure .product-wrap .product-images .loop-action .loop-add-to-wishlist .yith-wcwl-wishlistexistsbrowse a {
    background: #818181;
    color: #fff;
    width: 35px;
    height: 35px;
    line-height: 35px
}

.commerce ul.products li.product.style-3 figure .product-wrap .product-images .loop-action .loop-add-to-wishlist .yith-wcwl-wishlistaddedbrowse a:before,
.commerce ul.products li.product.style-3 figure .product-wrap .product-images .loop-action .loop-add-to-wishlist .yith-wcwl-wishlistexistsbrowse a:before {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 15px
}

.commerce ul.products li.product.style-3 figure:hover .product-wrap .product-images .loop-action>div {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0)
}

.commerce .widget_price_filter .price_slider {
    margin-bottom: 1.3em
}

.commerce .widget_price_filter .price_slider_amount {
    text-align: right
}

.commerce .widget_price_filter .price_slider_amount:before,
.commerce .widget_price_filter .price_slider_amount:after {
    content: " ";
    display: table
}

.commerce .widget_price_filter .price_slider_amount:after {
    clear: both
}

.commerce .widget_price_filter .price_slider_amount #min_price,
.commerce .widget_price_filter .price_slider_amount #max_price {
    display: none
}

.commerce .widget_price_filter .price_slider_amount .button {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    color: #333;
    padding: .5714285714285714rem 2rem;
    font-size: 13px;
    line-height: 1.6;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    color: #fff;
    background-color: #333;
    border-color: #333;
    padding: .42857142857142855rem 2rem
}

.commerce .widget_price_filter .price_slider_amount .button:hover,
.commerce .widget_price_filter .price_slider_amount .button:focus {
    text-decoration: none
}

.commerce .widget_price_filter .price_slider_amount .button i,
.commerce .widget_price_filter .price_slider_amount .button span {
    z-index: 2;
    position: relative
}

.commerce .widget_price_filter .price_slider_amount .button:hover,
.commerce .widget_price_filter .price_slider_amount .button:focus,
.commerce .widget_price_filter .price_slider_amount .button:active,
.commerce .widget_price_filter .price_slider_amount .button.active {
    color: #fff
}

.commerce .widget_price_filter .price_slider_amount .button:active,
.commerce .widget_price_filter .price_slider_amount .button.active {
    background-image: none
}

.commerce .widget_price_filter .price_slider_amount .button:hover,
.commerce .widget_price_filter .price_slider_amount .button:focus {
    background-color: #666;
    border-color: #666
}

.commerce .widget_price_filter .price_label {
    text-transform: uppercase;
    float: left
}

.commerce .widget_price_filter .price_label span {
    color: #333
}

.commerce .widget_price_filter .ui-slider {
    position: relative;
    text-align: left
}

.commerce .widget_price_filter .ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 12px;
    height: 12px;
    cursor: pointer;
    outline: none;
    top: -4px;
    background: #333;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: 1px dotted #333
}

.commerce .widget_price_filter .ui-slider .ui-slider-handle:last-child {
    margin-left: -9px
}

.commerce .widget_price_filter .ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: .7em;
    display: block;
    border: 0;
    background: #999
}

.commerce .widget_price_filter .price_slider_wrapper .ui-widget-content {
    background: #e6e6e6
}

.commerce .widget_price_filter .ui-slider-horizontal {
    height: 4px
}

.commerce .widget_price_filter .ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 100%
}

.commerce .widget_price_filter .ui-slider-horizontal .ui-slider-range-min {
    left: -1px
}

.commerce .widget_price_filter .ui-slider-horizontal .ui-slider-range-max {
    right: -1px
}

.commerce.widget_product_categories ul.product-categories>li:first-child {
    border: none
}

.commerce.widget_product_categories ul.product-categories li {
    padding: 0;
    border-bottom: none
}

.commerce.widget_product_categories ul.product-categories li ul.children li {
    padding-left: 1.4285714285714286em
}

.commerce.widget_product_categories ul.product-categories a {
    padding: 0 0 15px 0;
    display: inline-block
}

.commerce.widget_product_categories ul.product-categories a:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 5px;
    height: 5px;
    border: 1px dotted #999;
    margin-right: .7142857142857143em;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg)
}

.commerce.widget_product_categories ul.product-categories a:hover {
    color: #9fce4e
}

.commerce.widget_product_categories ul.product-categories a:hover:before {
    background: #333;
    border-color: #333
}
.commerce .shop-toolbar {
    padding-bottom: 2em
}

.commerce .shop-toolbar:before,
.commerce .shop-toolbar:after {
    content: " ";
    display: table
}

.commerce .shop-toolbar:after {
    clear: both
}

.commerce .shop-toolbar .view-mode {
    float: right;
    border: 1px solid #e1e1e1;
    padding: 0 10px
}

@media (max-width:1199px) {
    .commerce .shop-toolbar .view-mode {
        display: none
    }
}

.commerce .shop-toolbar .view-mode a {
    font-size: 1.2857142857142858em;
    height: 40px;
    display: inline-block;
    line-height: 40px;
    text-align: center;
    float: left;
    padding: 0 10px;
    color: #aaa;
    cursor: pointer
}

.commerce .shop-toolbar .view-mode a:hover,
.commerce .shop-toolbar .view-mode a.active {
    color: #333
}

.commerce .shop-toolbar .view-mode a.list-mode {
    margin-right: 0
}

.commerce .shop-toolbar .filter-toggle-button {
    float: left;
    padding: 0 10px
}

.commerce .shop-toolbar .filter-toggle-button a {
    font-size: 1em;
    height: 40px;
    display: inline-block;
    line-height: 38px;
    text-align: center;
    float: left;
    color: #777;
    cursor: pointer
}

.commerce .shop-toolbar .filter-toggle-button a:hover,
.commerce .shop-toolbar .filter-toggle-button a.active {
    color: #333
}

.commerce .commerce-ordering {
    float: left
}

.commerce .commerce-ordering .commerce-ordering-select {
    float: left;
    line-height: 1;
    margin-right: 1em
}

.commerce .commerce-ordering .commerce-ordering-select label {
    margin: 0
}

.commerce .commerce-ordering .form-flat-select {
    display: inline-block
}

@media (max-width:767px) {
    .commerce .commerce-ordering {
        display: none
    }
}

@media (min-width:992px) {
    .commerce .shop-loop.list ul.products {
        margin: 0
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) {
        display: block;
        float: none;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        clear: none
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) .product-container {
        border: 0;
        padding: 0
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) .product-wrap {
        width: 30%
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figure:before,
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figure:after {
        content: " ";
        display: table
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figure:after {
        clear: both
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) .product-wrap {
        float: left
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) .product-wrap .product-images:after {
        display: none
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) .product-wrap .product-images .loop-action {
        display: none
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption {
        width: 70%;
        display: table;
        padding: 0 0 10px 30px;
        margin: 0
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .product_title {
        font-size: 1.5em;
        margin-top: 0;
        margin-bottom: 0.5em
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .shop-loop-product-info {
        text-align: left
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .info-meta .star-rating {
        display: block;
        margin: 0 0 0.5em
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .info-price .price {
        font-size: 1.2857142857142858em;
        color: #333
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .info-excerpt {
        display: block;
        margin-top: 1.5em
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .loop-add-to-wishlist {
        float: left;
        margin-left: 10px
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .loop-add-to-wishlist .yith-wcwl-add-to-wishlist {
        margin-top: 0
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .loop-add-to-wishlist .yith-wcwl-add-to-wishlist .add_to_wishlist,
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .loop-add-to-wishlist .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistaddedbrowse a,
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .loop-add-to-wishlist .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistexistsbrowse a {
        border: 1px solid #e1e1e1;
        color: #aaa;
        line-height: 43px
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .loop-add-to-wishlist .yith-wcwl-add-to-wishlist .add_to_wishlist:hover,
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .loop-add-to-wishlist .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistaddedbrowse a:hover,
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .loop-add-to-wishlist .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistexistsbrowse a:hover {
        color: #fff;
        background: #333;
        border-color: #333
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .shop-loop-quickview {
        float: left;
        margin-left: 10px
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .shop-loop-quickview a {
        border: 1px solid #e1e1e1;
        color: #aaa;
        line-height: 46px
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .shop-loop-quickview a:hover {
        color: #fff;
        background: #333;
        border-color: #333
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .list-info-meta {
        display: block;
        margin-top: 30px
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .list-info-meta .loop-add-to-cart {
        float: left
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .list-info-meta .loop-add-to-cart a {
        display: inline-block;
        margin-bottom: 0;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        white-space: nowrap;
        color: #333;
        padding: .5714285714285714rem 2rem;
        font-size: 13px;
        line-height: 1.6;
        -webkit-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        -webkit-border-radius: 0;
        border-radius: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        outline: none;
        color: #fff;
        background-color: #333;
        border-color: #333;
        border: 0;
        padding: 0 .8571428571428571rem;
        line-height: 45px;
        padding-left: 50px;
        padding-right: 1.5em;
        width: auto;
        text-indent: 0
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .list-info-meta .loop-add-to-cart a:hover,
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .list-info-meta .loop-add-to-cart a:focus {
        text-decoration: none
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .list-info-meta .loop-add-to-cart a i,
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .list-info-meta .loop-add-to-cart a span {
        z-index: 2;
        position: relative
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .list-info-meta .loop-add-to-cart a:hover,
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .list-info-meta .loop-add-to-cart a:focus,
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .list-info-meta .loop-add-to-cart a:active,
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .list-info-meta .loop-add-to-cart a.active {
        color: #fff
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .list-info-meta .loop-add-to-cart a:active,
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .list-info-meta .loop-add-to-cart a.active {
        background-image: none
    }
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .list-info-meta .loop-add-to-cart a:hover,
    .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .list-info-meta .loop-add-to-cart a:focus {
        background-color: #666;
        border-color: #666
    }
}

.commerce .commerce-pagination .commerce-result-count {
    float: left
}

.commerce .commerce-pagination .paginate {
    float: right;
    margin: 0;
    padding: 0;
}
.commerce .col2-set {
    width: 100%
}

.commerce .col2-set:before,
.commerce .col2-set:after {
    content: " ";
    display: table
}

.commerce .col2-set:after {
    clear: both
}

.commerce .col2-set .col-1 {
    float: left;
    width: 48%
}

.commerce .col2-set .col-2 {
    float: right;
    width: 48%
}
.commerce form.login .input-text,
.commerce form.register .input-text {
    border-color: #e1e1e1
}

.commerce form.login,
.commerce form.checkout_coupon,
.commerce form.register {
    padding: 0 0 80px;
    margin: 0;
    text-align: left;
    -webkit-border-radius: 0;
    border-radius: 0
}

.commerce form.login:before,
.commerce form.checkout_coupon:before,
.commerce form.register:before,
.commerce form.login:after,
.commerce form.checkout_coupon:after,
.commerce form.register:after {
    content: " ";
    display: table
}

.commerce form.login:after,
.commerce form.checkout_coupon:after,
.commerce form.register:after {
    clear: both
}
.commerce form .form-row .input-text {
    display: block;
    height: 40px;
    padding: .5714285714285714rem 1rem;
    line-height: 1.6;
    color: #444;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e1e1e1;
    -webkit-border-radius: 0;
    border-radius: 0;
    outline: none;
    -webkit-transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    -o-transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    width: 100%;
}
.commerce form .form-row .required {
    color: #fe252c;
    border: 0;
    text-decoration: none;
}
.commerce form .form-row-wide {
    clear: both;
}
.commerce form .form-row {
    margin-bottom: 15px;
}
.commerce form .form-row button.button, .commerce form .form-row input.button {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: .5714285714285714rem 2rem;
    font-size: 13px;
    line-height: 1.6;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    background: transparent;
    border: 2px solid #333;
    text-transform: uppercase;
    color: #333;
    position: relative;
    font-weight: 600;
}
.commerce form .form-row button.button:hover, 
.commerce form .form-row input.button:hover, 
.commerce form .form-row button.button:focus, 
.commerce form .form-row input.button:focus {
    color: #fff;
    border-color: #9fce4e;
    background: #9fce4e;
}
.commerce .widget_layered_nav ul {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none outside;
}
.commerce .widget_layered_nav ul li {
    padding-bottom: 1em;
    list-style: none;
}
.commerce .widget_layered_nav ul li:before, 
.commerce .widget_layered_nav ul li:after {
    content: " ";
    display: table;
}
.commerce .widget_layered_nav ul li:before, .commerce .widget_layered_nav ul li:after {
    content: " ";
    display: table;
}
.commerce .widget_layered_nav ul li:after {
    clear: both;
}
.commerce .widget_layered_nav ul li>a, 
.commerce .widget_layered_nav ul li>span {
    padding: 1px 0;
    float: left;
}
.commerce .widget_layered_nav ul li>a {
    position: relative;
}
.commerce .widget_layered_nav ul li>a, 
.commerce .widget_layered_nav ul li>span {
    padding: 1px 0;
    float: left;
	text-transform: uppercase;
}
.commerce .widget_layered_nav ul li>span {
    position: relative;
}
.commerce .widget_layered_nav ul li .count {
    margin-left: 5px;
}
.commerce .widget_layered_nav ul li>span.count {
    color: #888;
}
.commerce .widget_layered_nav ul li>a:before {
    content: "\f096";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none;
	width: 14px;
}
.commerce .widget_layered_nav ul li>a:hover:before {
    content: "\f14a";
    position: relative;
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none;
}
/* === 4.2. SINGLE PRODUCT === */

.commerce div.product-quickview-content,
.commerce div.product.style-1 {
    overflow: hidden
}

.commerce div.product-quickview-content div.single-product-images,
.commerce div.product.style-1 div.single-product-images {
    position: relative;
    margin-bottom: 2em
}

.commerce div.product-quickview-content div.single-product-images .product-images-slider,
.commerce div.product.style-1 div.single-product-images .product-images-slider {
    position: relative;
    overflow: hidden;
    border: 1px solid #e1e1e1
}

.commerce div.product-quickview-content div.single-product-images .product-images-slider .caroufredsel-wrap .caroufredsel-items li,
.commerce div.product.style-1 div.single-product-images .product-images-slider .caroufredsel-wrap .caroufredsel-items li {
    position: absolute
}

.commerce div.product-quickview-content div.single-product-images .product-images-slider .caroufredsel-wrap .caroufredsel-items li:first-child,
.commerce div.product.style-1 div.single-product-images .product-images-slider .caroufredsel-wrap .caroufredsel-items li:first-child {
    position: relative;
    z-index: 50
}

.commerce div.product-quickview-content div.single-product-images .product-images-slider .caroufredsel_wrapper .caroufredsel-items li,
.commerce div.product.style-1 div.single-product-images .product-images-slider .caroufredsel_wrapper .caroufredsel-items li {
    position: relative;
}

.commerce div.product-quickview-content div.summary .product_meta,
.commerce div.product div.summary .product_meta {
    clear: both;
    padding: 0;
    margin: 0
}

.commerce div.product-quickview-content div.summary .product_meta:before,
.commerce div.product div.summary .product_meta:before,
.commerce div.product-quickview-content div.summary .product_meta:after,
.commerce div.product div.summary .product_meta:after {
    content: " ";
    display: table
}

.commerce div.product-quickview-content div.summary .product_meta:after,
.commerce div.product div.summary .product_meta:after {
    clear: both
}

.commerce div.product-quickview-content div.summary .product_meta a,
.commerce div.product div.summary .product_meta a {
    font-weight: 600
}

.commerce div.product-quickview-content div.summary .product_meta a:hover,
.commerce div.product div.summary .product_meta a:hover {
    text-decoration: underline
}

.commerce div.product-quickview-content div.summary .product_meta>span,
.commerce div.product div.summary .product_meta>span {
    display: block
}

.commerce div.product-quickview-content .summary .yith-wcwl-add-to-wishlist,
.commerce div.product .summary .yith-wcwl-add-to-wishlist {
    margin: 0 0 15px;
    float: left;
    width: 100%
}

.commerce div.product-quickview-content .summary .yith-wcwl-add-to-wishlist a,
.commerce div.product .summary .yith-wcwl-add-to-wishlist a {
    font-weight: 600
}

.commerce div.product-quickview-content .content-share,
.commerce div.product .content-share {
    position: relative;
    margin-top: 1em
}

.commerce div.product .easyzoom {
    position: relative
}

.commerce div.product .easyzoom img {
    vertical-align: bottom;
    display: block
}

.commerce div.product .easyzoom.is-loading img {
    cursor: progress
}

.commerce div.product .easyzoom.is-ready img {
    cursor: crosshair
}

.commerce div.product .easyzoom.is-error img {
    cursor: not-allowed
}

.commerce div.product .easyzoom-notice {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 11;
    width: 10em;
    margin: -1em 0 0 -5em;
    line-height: 2em;
    text-align: center;
    color: #fff
}

.commerce div.product .easyzoom-flyout {
    background: #ffffff none repeat scroll 0 0;
    height: 100%;
    display: block;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10
}

.commerce div.product .easyzoom-flyout img {
    width: auto;
    max-width: none;
}

.commerce div.product div.single-product-images-slider .caroufredsel-item a {
    cursor: crosshair
}

@media (min-width:992px) {
    .commerce div.product.style-1 div.single-product-images-slider.product-has-thumbnails-gallery {
        padding-left: 95px
    }
}

.commerce div.product.style-1 div.single-product-images-slider img {
    width: 100%
}

.commerce div.product.style-1 div.single-product-thumbnails {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 80px
}

@media (max-width:991px) {
    .commerce div.product.style-1 div.single-product-thumbnails {
        display: none
    }
}

.commerce div.product.style-1 div.single-product-thumbnails .caroufredsel {
    height: 100%;
    width: 80px
}

.commerce div.product.style-1 div.single-product-thumbnails .caroufredsel .caroufredsel-wrap {
    margin: 0
}

.commerce div.product.style-1 div.single-product-thumbnails .caroufredsel-item {
    height: auto
}

.commerce div.product.style-1 div.single-product-thumbnails .caroufredsel-item .thumb {
    display: inline-block;
    border: 2px solid #e1e1e1;
    -webkit-transition: border-color .3s;
    -o-transition: border-color .3s;
    transition: border-color .3s;
    width: 100%;
    margin-bottom: 15px
}

.commerce div.product.style-1 div.single-product-thumbnails .caroufredsel-item.selected .thumb,
.commerce div.product.style-1 div.single-product-thumbnails .caroufredsel-item:hover .thumb {
    border-color: #333
}

.commerce div.product.style-1 div.single-product-thumbnails .caroufredsel-wrap .caroufredsel-items .caroufredsel-item {
    float: none;
    display: block;
    width: 80px;
    margin: 0;
    padding: 0
}

.commerce div.product.style-2 .single-product-images-slider {
    border: 1px solid #e1e1e1
}

.commerce div.product.style-2 .single-product-images-slider img {
    width: 100%
}

.commerce div.product.style-2 .caroufredsel-fixed .caroufredsel-items {
    width: 100%;
}

.commerce div.product.style-2 .caroufredsel-fixed .caroufredsel-items>li {
    width: 25%;
}

.commerce div.product.style-2 .single-product-thumbnails .caroufredsel-item {
    border: 2px solid rgba(0, 0, 0, 0)
}

.commerce div.product.style-2 .single-product-thumbnails .caroufredsel-item .thumb {
    border: 1px solid #e1e1e1;
    -webkit-transition: border-color .3s;
    -o-transition: border-color .3s;
    transition: border-color .3s;
    margin-bottom: 30px
}

.commerce div.product.style-2 .single-product-thumbnails .caroufredsel-item.selected .thumb,
.commerce div.product.style-2 .single-product-thumbnails .caroufredsel-item:hover .thumb {
    border-color: #333;
    -webkit-box-shadow: 0 0 0 1px #333;
    box-shadow: 0 0 0 1px #333
}

.commerce div.product.style-2 .single-product-thumbnails {
    margin-top: 20px
}

.commerce div.product.style-2 .single-product-thumbnails img {
    width: 100%
}

@media (max-width:1199px) {
    .commerce div.product.style-2 .single-product-thumbnails {
        margin-bottom: 60px
    }
}

.commerce div.product.style-2 div.summary .share-links .share-icons {
    text-align: left
}

.commerce div.product.style-2 .commerce-tabs .nav-tabs {
    border-bottom: 1px solid #e1e1e1
}

.commerce div.product.style-2 .commerce-tabs .nav-tabs li {
    margin-bottom: -1px
}
.commerce div.product {
    margin-bottom: 0;
    position: relative
}

.commerce div.product .summary .product_title {
    margin: 0.3em 0 0.7em;
    padding: 0;
    text-transform: uppercase;
    font-size: 1.7142857142857142em
}

.commerce div.product .summary .commerce-product-rating {
    display: block;
    margin-bottom: 15px
}

.commerce div.product .summary .commerce-product-rating .star-rating {
    margin: 3px 10px 0 0;
    float: left
}

.commerce div.product .summary .commerce-product-rating .commerce-review-link {
    font-size: 85%
}

.commerce div.product .summary .price {
    font-size: 2em;
    color: #333;
    font-family: "Atlan-Semibold", Helvetica, Arial, sans-serif;
    font-weight: 500;
    opacity: .7;
    filter: alpha(opacity=70)
}

.commerce div.product .summary .price del {
    color: #999;
    float: none;
    margin: 0 5px;
    font-size: 0.7em
}

.commerce div.product .summary .price ins {
    background: none;
    text-decoration: none;
    float: none
}

.commerce div.product .summary .product-excerpt {
    position: relative;
    margin-top: 2em
}

.commerce div.product .summary .product-excerpt p {
    margin: 0
}

.commerce div.product form.cart div.quantity {
    display: inline-block;
    vertical-align: top
}

.commerce div.product form.cart div.quantity input.qty {
    height: 50px;
    width: 80px
}

.commerce .quantity {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    zoom: 1
}

.commerce .quantity input.qty {
    display: block;
    width: 100%;
    height: 40px;
    padding: .5714285714285714rem 1rem;
    line-height: 1.6;
    color: #444;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e1e1e1;
    -webkit-border-radius: 0;
    border-radius: 0;
    outline: none;
    -webkit-transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    -o-transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    width: 70px;
    font-size: 1em
}

.commerce .quantity input.qty:focus {
    border-color: #333;
    outline: none
}

.commerce .quantity input.qty[disabled],
.commerce .quantity input.qty[readonly],
fieldset[disabled] .commerce .quantity input.qty {
    cursor: not-allowed;
    background-color: #aaa;
    opacity: 1;
    filter: alpha(opacity=100)
}

textarea.commerce .quantity input.qty {
    height: auto
}

.commerce .quantity input[type=number] {
    padding-right: 1.2em
}

.commerce .quantity input {
    display: inline-block;
    margin: 0;
    vertical-align: top
}

.commerce div.product form.cart .button,
.commerce div.product form.cart input.button {
    text-transform: uppercase;
    height: 50px;
    vertical-align: top;
    /* margin-left: 30px; */
    padding-left: 1.5em;
    padding-right: 1.5em;
    color: #fff;
    background-color: #333;
    border-color: #333
}

.commerce div.product form.cart .button:hover,
.commerce div.product form.cart input.button:hover,
.commerce div.product form.cart .button:focus,
.commerce div.product form.cart input.button:focus,
.commerce div.product form.cart .button:active,
.commerce div.product form.cart input.button:active,
.commerce div.product form.cart .button.active,
.commerce div.product form.cart input.button.active {
    color: #fff
}

.commerce div.product form.cart .button:active,
.commerce div.product form.cart input.button:active,
.commerce div.product form.cart .button.active,
.commerce div.product form.cart input.button.active {
    background-image: none
}

.commerce div.product form.cart .button:hover,
.commerce div.product form.cart input.button:hover,
.commerce div.product form.cart .button:focus,
.commerce div.product form.cart input.button:focus {
    background-color: #666;
    border-color: #666
}

.commerce div.product form.cart .single_add_to_cart_button:before {
    content: "\e013";
    position: relative;
    display: inline-block;
    font-family: 'ElegantIcons';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

.commerce div.product form.cart .single_add_to_cart_button:before {
    font-size: 1.2857142857142858em;
    margin-right: 0.5em
}

.commerce div.product form.cart {
    padding: 30px 0;
}

.commerce div.product div.summary .share-links {
    background: none;
    padding: 0;
    margin: 30px 0 0 0;
    text-align: left
}

.commerce div.product div.summary .share-links>span {
    padding-right: 20px
}

.commerce div.product div.summary .share-links .share-icons {
    text-align: left
}

.commerce div.product div.summary .share-links .share-icons a {
    background: none;
    width: 40px;
    height: 40px;
    line-height: 38px;
    color: #444;
    font-size: 1.2857142857142858em;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: 1px solid #e1e1e1;
    margin: 0 20px 0 0
}

.commerce div.product div.summary .share-links .share-icons a:hover {
    color: #9fce4e;
    border-color: #9fce4e
}

.commerce div.product div.social {
    text-align: right;
    margin: 0 0 1em
}

.commerce div.product div.social span {
    margin: 0 0 0 .14285714285714285em
}

.commerce div.product div.social span span {
    margin: 0
}

.commerce .commerce-tabs {
    margin: 30px 0
}

.commerce .commerce-tabs .nav-tabs {
    border: none;
    text-align: center;
    border-bottom: 1px solid #e1e1e1
}

.commerce .commerce-tabs .nav-tabs>li {
    float: none;
    margin: 0 0 -1px -4px;
    display: inline-block;
    /*border-bottom: 1px solid #e1e1e1*/
}

.commerce .commerce-tabs .nav-tabs>li>a {
    font-size: 1.1428571428571428em;
    border-bottom: none;
    text-transform: uppercase;
    font-weight: 600;
    background: rgba(0, 0, 0, 0);
    /*border-width: 0 0 1px 0;*/
    border-style: solid;
    margin: 0;
    border-color: rgba(0, 0, 0, 0);
    -webkit-transition: none;
    -o-transition: none;
    transition: none
}

.commerce .commerce-tabs .nav-tabs>li>a:hover {
    color: #333
}

/*.commerce .commerce-tabs .nav-tabs>li.active {*/
/*    border-bottom-color: #333*/
/*}*/

.commerce .commerce-tabs .nav-tabs>li>a.active {
    color: #333;
    border-bottom-color: #333;
    border-bottom: 4px solid;
}

.commerce .commerce-tabs .tab-content {
    background: transparent;
    padding: 20px 0 40px;
    /*border-bottom: 1px solid #e1e1e1*/
}

.commerce .commerce-tabs .tab-content .tab-pane h2 {
    display: none
}

@media (min-width:768px) {
    .page-layout-full-width.commerce .commerce-tabs .tab-content #tab-additional_information,
    .page-layout-full-width.commerce .commerce-tabs .tab-content #tab-reviews {
        max-width: 66%;
        margin: 0 auto
    }
}

.commerce .style-2 .commerce-tabs .nav-tabs {
    text-align: inherit
}

.product.style-3 .summary-container {
    background: #f5f5f5 none repeat scroll 0 0;
}
.product.style-3 .entry-summary {
    padding-bottom: 30px;
    padding-left: 0;
    padding-top: 30px;
}
.product.style-3 .caroufredsel .caroufredsel-pagination {
    bottom: 15px;
    left: 50px;
    position: absolute;
}

/* === 4.3. CART === */
.minicart-icon {
    position: relative
}

.minicart-icon span {
    background: none repeat scroll 0 0 #9fce4e;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    height: 1.6em;
    line-height: 16px;
    position: absolute;
    right: -9px;
    text-align: center;
    top: -3px;
    width: 16px
}

.header-type-market.header-navbar-fixed .minicart-icon span {
    background: #fff;
    color: #9fce4e
}

@media (max-width:899px) {
    .minicart {
        display: none;
    }
}

.header-container .minicart {
    display: none;
    position: absolute;
    padding: 18px 0;
    z-index: 9999;
    width: 300px;
    right: 0px;
    -webkit-box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.2);
    background: #fff;
    top: 100%;
    margin-top: 10px
}

.header-container .minicart:before {
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    right: 20px;
    top: -5px;
    left: auto;
    transform: translate(-50%, 0) rotate(45deg);
    -moz-transform: translate(-50%, 0) rotate(45deg);
    -webkit-transform: translate(-50%, 0) rotate(45deg);
    -ms-transform: translate(-50%, 0) rotate(45deg);
    -o-transform: translate(-50%, 0) rotate(45deg);
    width: 9px;
    z-index: 1010;
    background: #fff
}

.header-container .minicart .minicart-header {
    font-size: 85%;
    text-transform: uppercase;
    color: #444;
    padding-bottom: 20px;
    border-bottom: 1px solid #e1e1e1;
    display: none
}

.header-container .minicart .minicart-header.no-items {
    border: none;
    text-align: center
}

.header-container .minicart .minicart-body {
    max-height: 250px;
    overflow-y: auto;
    padding: 0 18px
}

.header-container .minicart .minicart-body .cart-product {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e1e1e1;
    -webkit-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
    text-align: left;
    overflow: hidden;
    position: relative
}

.header-container .minicart .minicart-body .cart-product.cart-product-remove {
    height: 0;
    padding: 0;
    margin: 0;
    border: none
}

.header-container .minicart .minicart-body .cart-product .cart-product-image {
    float: left;
    margin-right: 5px
}

.header-container .minicart .minicart-body .cart-product .cart-product-image img {
    max-width: 60px;
    max-height: 60px
}

.header-container .minicart .minicart-body .cart-product .cart-product-details {
    float: left;
    color: #444
}

.header-container .minicart .minicart-body .cart-product .cart-product-title {
    line-height: 1.6
}

.header-container .minicart .minicart-body .cart-product .cart-product-title a {
    color: #777;
	font-weight: 500;
}

.header-container .minicart .minicart-body .cart-product .cart-product-title a:hover {
    color: #9fce4e
}

.header-container .minicart .minicart-body .cart-product .cart-product-quantity-price {
    color: #333
}

.header-container .minicart .minicart-body .cart-product .remove {
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid #777;
    color: #777;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center
}

.header-container .minicart .minicart-body .cart-product .remove:hover {
    background: #f3f3f3
}

.header-container .minicart .minicart-footer {
    padding: 18px 18px 0
}

.header-container .minicart .minicart-footer .minicart-total {
    text-transform: uppercase;
    color: #444;
    padding: 8px 15px 15px;
    text-align: center;
    font-weight: normal
}

.header-container .minicart .minicart-footer .minicart-actions {
    text-align: center
}

.header-container .minicart .minicart-footer .minicart-actions.empty {
    text-align: left
}

.header-container .minicart .minicart-footer .minicart-actions .button {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: .5714285714285714rem 2rem;
    font-size: 13px;
    line-height: 1.6;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    background: transparent;
    border: 2px solid #333;
    text-transform: uppercase;
    color: #333;
    position: relative;
    font-weight: 600;
    display: block
}

.header-container .minicart .minicart-footer .minicart-actions .button.viewcart-button {
    display: none
}

.header-container .minicart .minicart-footer .minicart-actions .button:hover,
.header-container .minicart .minicart-footer .minicart-actions .button:focus {
    text-decoration: none
}

.header-container .minicart .minicart-footer .minicart-actions .button i,
.header-container .minicart .minicart-footer .minicart-actions .button span {
    z-index: 2;
    position: relative
}

.header-container .minicart .minicart-footer .minicart-actions .button:hover,
.header-container .minicart .minicart-footer .minicart-actions .button:focus {
    color: #fff;
    border-color: #9fce4e;
    background: #9fce4e
}
.commerce .cart-empty {
    font-size: 2em;
    font-weight: 600;
    color: #333;
    text-align: center
}

.commerce .return-to-shop {
    text-align: center
}

.commerce .return-to-shop .button {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    color: #333;
    padding: .5714285714285714rem 2rem;
    font-size: 13px;
    line-height: 1.6;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    color: #444;
    background-color: #f3f3f3;
    border-color: #f3f3f3
}

.commerce .return-to-shop .button:hover,
.commerce .return-to-shop .button:focus {
    text-decoration: none
}

.commerce .return-to-shop .button i,
.commerce .return-to-shop .button span {
    z-index: 2;
    position: relative
}

.commerce .return-to-shop .button:hover,
.commerce .return-to-shop .button:focus,
.commerce .return-to-shop .button:active,
.commerce .return-to-shop .button.active {
    color: #444
}

.commerce .return-to-shop .button:active,
.commerce .return-to-shop .button.active {
    background-image: none
}

.commerce table {
    width: 100%
}

.commerce table.cart .product-thumbnail img {
    width: 100%;
    max-width: 90px;
    border: 1px solid #e1e1e1
}

.commerce table.cart .amount,
.commerce table.cart .product-name a {
    text-transform: uppercase;
    font-weight: 600
}

@media (max-width:320px) {
    .commerce table.cart .amount,
    .commerce table.cart .product-name a {
        text-transform: none
    }
}

.commerce table.cart thead th {
    border-bottom: 0px;
    font-weight: 600;
    background: #f3f3f3;
    opacity: .8;
    filter: alpha(opacity=80)
}

.commerce table.cart th,
.commerce table.cart td {
    vertical-align: middle
}

.commerce table.cart .product-remove {
    text-align: center
}

.commerce table.cart .product-remove a {
    display: inline-block;
    text-align: center;
    width: 20px;
    line-height: 18px;
    font-size: 12px;
    border: 1px solid #e1e1e1;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.commerce table.cart td.actions {
    border-top: 1px solid #e1e1e1;
    font-size: 1em;
    padding: 25px 0
}

.commerce table.cart td.actions .button {
    float: right;
    background: transparent;
    border: 2px solid #333;
    text-transform: uppercase;
    color: #333;
    position: relative;
    font-weight: 600;
    margin-left: 1em
}

.commerce table.cart td.actions .button:hover,
.commerce table.cart td.actions .button:focus {
    color: #fff;
    border-color: #9fce4e;
    background: #9fce4e
}

@media (max-width:320px) {
    .commerce table.cart td.actions .button {
        font-size: 0.8em
    }
}

@media (max-width:480px) {
    .commerce table.cart td.actions .button {
        padding-left: 15px;
        padding-right: 15px
    }
    .commerce table.cart td.actions .button.commerce-contine-shoppong-btn {
        float: left;
        margin-left: 0
    }
}

.commerce table.cart input {
    margin: 0;
    vertical-align: middle;
    line-height: 1em
}

.commerce .cart .button {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: .5714285714285714rem 2rem;
    font-size: 13px;
    line-height: 1.6;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    background: transparent;
    border: 2px solid #333;
    text-transform: uppercase;
    color: #333;
    position: relative;
    font-weight: 600
}

.commerce .cart-collaterals {
    padding: 30px;
    border: 1px solid #e1e1e1;
    background: #f3f3f3;
    margin-bottom: 30px
}

.commerce .cart-collaterals h2 {
    font-size: 1.2857142857142858em;
    margin-top: 0;
    text-transform: uppercase
}

.commerce .cart-collaterals table {
    background: transparent;
    border-bottom: 1px solid #e5e5e5
}

.commerce .cart-collaterals table th,
.commerce .cart-collaterals table td {
    border: 0;
    padding-right: 0
}

.commerce .cart-collaterals table th p,
.commerce .cart-collaterals table td p {
    margin: 0
}

.commerce .cart-collaterals table tr {
    border-bottom: 1px solid #e1e1e1
}

.commerce .cart-collaterals table .cart-subtotal th,
.commerce .cart-collaterals table .cart-subtotal td {
    font-size: 1.2em
}

.commerce .cart-collaterals table .order-total th,
.commerce .cart-collaterals table .order-total td {
    font-size: 1.4em
}

.commerce .cart-collaterals .checkout-button {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    color: #333;
    padding: .5714285714285714rem 2rem;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    padding: .8571428571428571rem 2rem;
    font-size: 1.2857142857142858em;
    line-height: 1.6;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-border-radius: 0;
    border-radius: 0;
    color: #fff;
    background-color: #333;
    border-color: #333;
    width: 100%;
    font-size: 1.1428571428571428em;
    text-transform: uppercase;
    font-weight: 600;
    padding-left: 0.5rem;
    padding-right: 0.5rem
}

.commerce .cart-collaterals .checkout-button:hover,
.commerce .cart-collaterals .checkout-button:focus {
    text-decoration: none
}

.commerce .cart-collaterals .checkout-button i,
.commerce .cart-collaterals .checkout-button span {
    z-index: 2;
    position: relative
}

.commerce .cart-collaterals .checkout-button:hover,
.commerce .cart-collaterals .checkout-button:focus,
.commerce .cart-collaterals .checkout-button:active,
.commerce .cart-collaterals .checkout-button.active {
    color: #fff
}

.commerce .cart-collaterals .checkout-button:active,
.commerce .cart-collaterals .checkout-button.active {
    background-image: none
}

.commerce .cart-collaterals .checkout-button:hover,
.commerce .cart-collaterals .checkout-button:focus {
    background-color: #666;
    border-color: #666
}

.commerce .cart-collaterals .checkout-button:after {
    display: none
}

@media (max-width:1199px) and (min-width:992px) {
    .commerce .cart-collaterals .checkout-button {
        font-size: 1em
    }
}

@media (max-width:480px) {
    .commerce .cart-collaterals .checkout-button {
        font-size: 1em
    }
}

/* Fix for New ListOfPush Component and PushItem Components */

.counter-block .counter .counter-count .counter-number {
    width: 80%;
    height: 30px;
    overflow: hidden;
    word-break: break-all;
}

.counter-block .counter .counter-text .counter-message {
    width: 80%;
    height: 20px;
    overflow: hidden; 
    word-break: break-all;
    display: inline-block;
}

.counter-block .counter img {
    width: 2.5em;
    height: 2.5em;
}

/* Fix for Add to Cart FilteredProducts */
.shop-loop-quickview .cart .quantity {
    display: inline-block;
}

.shop-loop-quickview .cart .quantity input.qty {
    height: 45px;
}

.shop-loop-quickview .cart button.single_add_to_cart_button  {
    vertical-align: top;
    padding: 0.75rem 2rem;
    text-transform: uppercase;
    color: #fff;
    background-color: #333;
    border-color: #333;
}

.shop-loop-quickview .cart button.single_add_to_cart_button:before {
    content: "\E013";
    position: relative;
    display: inline-block;
    font-family: ElegantIcons;
    font-style: normal;
    font-weight: 400;
    line-height: 0;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none;
    font-size: 1.25em;
    margin-right: .5em;
}

/* Fix for Cart Message */
.woocommerce-message {
    display: none;
    border: 1px solid #a0ce4e;
    background: rgba(204,228,158,0.3);
}

.woocommerce-message, .woocommerce-error, .woocommerce-info {
    padding: 1em 1em 1em 3.5em !important;
    margin: 0 0 3em !important;
    position: relative;
    border: 2px solid #e8e8e8;
    list-style: none outside !important;
    width: auto;
}

.woocommerce-message:before {
    color: #a0ce4e;
    content: "\f058";
    font-family: "fontawesome";
}

.woocommerce-message:before, .woocommerce-error:before, .woocommerce-info:before {
    content: "";
    height: 1.5em;
    width: 1.5em;
    display: block;
    position: absolute;
    top: 0;
    left: 1em;
    font-family: sans-serif;
    font-size: 1em;
    line-height: 1.5;
    text-align: center;
    padding-top: 1em;
}

.woocommerce-message:before, .woocommerce-error:before, .woocommerce-info:before, .woocommerce-message:after, .woocommerce-error:after, .woocommerce-info:after {
    content: " ";
    display: table;
}

.woocommerce-message>a.button, .woocommerce-error>a.button, .woocommerce-info>a.button {
    border: medium none !important;
    float: right;
    padding: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.wc-forward:after, .wc-forward a:after {
    font-family: 'WooCommerce';
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    margin-left: .5em;
    content: "\e029";
    font-size: .7em;
}

.woocommerce-message:before {
    color: #a0ce4e;
    content: "\f058";
    font-family: "fontawesome";
}

.woocommerce-message .button:not(.checkout-button) {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: .5714285714285714rem 2rem;
    font-size: 13px;
    line-height: 1.6;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    background: transparent;
    border: 2px solid #333;
    text-transform: uppercase;
    color: #333;
    position: relative;
    font-weight: 600;
}

.woocommerce-message a.button:hover {
    color: #9fce4e !important;
    background: transparent !important;
    border: none !important;
}

.cartmessage {
    display: block;
}

/* Mini Cart */
.header-container .minicart:before {
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    right: 20px;
    top: -5px;
    left: auto;
    transform: translate(-50%, 0) rotate(45deg);
    -moz-transform: translate(-50%, 0) rotate(45deg);
    -webkit-transform: translate(-50%, 0) rotate(45deg);
    -ms-transform: translate(-50%, 0) rotate(45deg);
    -o-transform: translate(-50%, 0) rotate(45deg);
    width: 9px;
    z-index: 1010;
    background: #fff;
}

.minicart-display {
    display: block !important;
}


/* Root on load display */

#root{
    opacity: 0;
    transition: all .5s ease-in-out;
}

.is-loaded {
    opacity: 1 !important;
}

/* Update */
input.error {
    border-color: red !important;
}

.input-feedback {
    color: rgb(235, 54, 54);
    margin-bottom: -20px;
}

.form-row > .button {
    margin-top: 20px;
}

/* Fix IOS */
@supports (-webkit-touch-callout: none)  {
    #page-header {
        background-attachment: scroll !important; 	
        -webkit-background-attachment: scroll !important; 	
    }
    #page-header::after{
        content: "";
        position: absolute;
        background-color: red;
    }
}
@supports (-webkit-overflow-scrolling: touch)  {
    #page-header {
        background-attachment: scroll !important; 	
        -webkit-background-attachment: scroll !important; 	
    }
    #page-header::after{
        content: "";
        position: absolute;
        background-color: red;
    }
}
