.breadcrumb__custom{
    width: 100%;
    padding: 30px;
}

.breadcrumb__custom,
.breadcrumb__custom a{
    color: #575756;
    line-height: 20px;
    font-size: 10px;
    font-family: "Atlan-Regular",sans-serif;
    text-transform: uppercase;
}

.breadcrumb__custom a:hover{
    cursor: pointer;
}

.breadcrumb__custom div{
    display: inline;
}
