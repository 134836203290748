/**
Theme Name: Luxury
*/
/**
 * Table of Contents
 *
 * 1.0 - Padding, Margin, Font-size
 * 2.0 - Custom CSS
 */

/*--------------------------------------------------------------
1.0 - Padding, Margin, Font-size
--------------------------------------------------------------*/

/* Font style */
.fsi {
  font-style: italic !important;
}

.fsn {
  font-style: normal !important;
}

/* Font weight */
.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 500 !important;
}

.fw-bolder {
  font-weight: 700 !important;
}

.fz-5 {
  font-size: 50px;
}

.fz-2 {
  font-size: 20px;
}

.fz-35 {
  font-size: 35px;
}

/* Padding */
.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 10px !important;
}

.p-2 {
  padding: 20px !important;
}

.p-3 {
  padding: 30px !important;
}

.p-4 {
  padding: 40px !important;
}

.p-5 {
  padding: 50px !important;
}

.p-6 {
  padding: 60px !important;
}

.p-7 {
  padding: 70px !important;
}

.p-8 {
  padding: 80px !important;
}

.p-9 {
  padding: 90px !important;
}

.p-10 {
  padding: 100px !important;
}

.p-11 {
  padding: 110px !important;
}

.p-12 {
  padding: 120px !important;
}

.p-13 {
  padding: 130px !important;
}

.p-14 {
  padding: 140px !important;
}

.p-15 {
  padding: 150px !important;
}

.p-16 {
  padding: 160px !important;
}

.p-17 {
  padding: 170px !important;
}

.p-18 {
  padding: 180px !important;
}

.p-19 {
  padding: 190px !important;
}

.p-20 {
  padding: 200px !important;
}

/* Padding top */
.pt-0 {
  padding-top: 0px !important;
}

.pt-1 {
  padding-top: 10px !important;
}

.pt-2 {
  padding-top: 20px !important;
}

.pt-3 {
  padding-top: 30px !important;
}

.pt-4 {
  padding-top: 40px !important;
}

.pt-5 {
  padding-top: 50px !important;
}

.pt-6 {
  padding-top: 60px !important;
}

.pt-7 {
  padding-top: 70px !important;
}

.pt-8 {
  padding-top: 80px !important;
}

.pt-9 {
  padding-top: 90px !important;
}

.pt-10 {
  padding-top: 100px !important;
}

.pt-11 {
  padding-top: 110px !important;
}

.pt-12 {
  padding-top: 120px !important;
}

.pt-13 {
  padding-top: 130px !important;
}

.pt-14 {
  padding-top: 140px !important;
}

.pt-15 {
  padding-top: 150px !important;
}

.pt-16 {
  padding-top: 160px !important;
}

.pt-17 {
  padding-top: 170px !important;
}

.pt-18 {
  padding-top: 180px !important;
}

.pt-19 {
  padding-top: 190px !important;
}

.pt-20 {
  padding-top: 200px !important;
}

/* Padding right */
.pr-0 {
  padding-right: 0px !important;
}

.pr-1 {
  padding-right: 10px !important;
}

.pr-2 {
  padding-right: 20px !important;
}

.pr-3 {
  padding-right: 30px !important;
}

.pr-4 {
  padding-right: 40px !important;
}

.pr-5 {
  padding-right: 50px !important;
}

.pr-6 {
  padding-right: 60px !important;
}

.pr-7 {
  padding-right: 70px !important;
}

.pr-8 {
  padding-right: 80px !important;
}

.pr-9 {
  padding-right: 90px !important;
}

.pr-10 {
  padding-right: 100px !important;
}

.pr-11 {
  padding-right: 110px !important;
}

.pr-12 {
  padding-right: 120px !important;
}

.pr-13 {
  padding-right: 130px !important;
}

.pr-14 {
  padding-right: 140px !important;
}

.pr-15 {
  padding-right: 150px !important;
}

.pr-16 {
  padding-right: 160px !important;
}

.pr-17 {
  padding-right: 170px !important;
}

.pr-18 {
  padding-right: 180px !important;
}

.pr-19 {
  padding-right: 190px !important;
}

.pr-20 {
  padding-right: 200px !important;
}

/* Padding bottom */
.pb-0 {
  padding-bottom: 0px !important;
}

.pb-1 {
  padding-bottom: 10px !important;
}

.pb-2 {
  padding-bottom: 20px !important;
}

.pb-3 {
  padding-bottom: 30px !important;
}

.pb-4 {
  padding-bottom: 40px !important;
}

.pb-5 {
  padding-bottom: 50px !important;
}

.pb-6 {
  padding-bottom: 60px !important;
}

.pb-7 {
  padding-bottom: 70px !important;
}

.pb-8 {
  padding-bottom: 80px !important;
}

.pb-9 {
  padding-bottom: 90px !important;
}

.pb-10 {
  padding-bottom: 100px !important;
}

.pb-11 {
  padding-bottom: 110px !important;
}

.pb-12 {
  padding-bottom: 120px !important;
}

.pb-13 {
  padding-bottom: 130px !important;
}

.pb-14 {
  padding-bottom: 140px !important;
}

.pb-15 {
  padding-bottom: 150px !important;
}

.pb-16 {
  padding-bottom: 160px !important;
}

.pb-17 {
  padding-bottom: 170px !important;
}

.pb-18 {
  padding-bottom: 180px !important;
}

.pb-19 {
  padding-bottom: 190px !important;
}

.pb-20 {
  padding-bottom: 200px !important;
}

.pb-26 {
  padding-bottom: 260px !important;
}

/* Padding left */
.pl-0 {
  padding-left: 0px !important;
}

.pl-1 {
  padding-left: 10px !important;
}

.pl-2 {
  padding-left: 20px !important;
}

.pl-3 {
  padding-left: 30px !important;
}

.pl-4 {
  padding-left: 40px !important;
}

.pl-5 {
  padding-left: 50px !important;
}

.pl-6 {
  padding-left: 60px !important;
}

.pl-7 {
  padding-left: 70px !important;
}

.pl-8 {
  padding-left: 80px !important;
}

.pl-9 {
  padding-left: 90px !important;
}

.pl-10 {
  padding-left: 100px !important;
}

.pl-11 {
  padding-left: 110px !important;
}

.pl-12 {
  padding-left: 120px !important;
}

.pl-13 {
  padding-left: 130px !important;
}

.pl-14 {
  padding-left: 140px !important;
}

.pl-15 {
  padding-left: 150px !important;
}

.pl-16 {
  padding-left: 160px !important;
}

.pl-17 {
  padding-left: 170px !important;
}

.pl-18 {
  padding-left: 180px !important;
}

.pl-19 {
  padding-left: 190px !important;
}

.pl-20 {
  padding-left: 200px !important;
}

/* Margin */
.mlr-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.m-0 {
  margin: 0px !important;
}

.m-1 {
  margin: 10px !important;
}

.m-2 {
  margin: 20px !important;
}

.m-3 {
  margin: 30px !important;
}

.m-4 {
  margin: 40px !important;
}

.m-5 {
  margin: 50px !important;
}

.m-6 {
  margin: 60px !important;
}

.m-7 {
  margin: 70px !important;
}

.m-8 {
  margin: 80px !important;
}

.m-9 {
  margin: 90px !important;
}

.m-10 {
  margin: 100px !important;
}

.m-11 {
  margin: 110px !important;
}

.m-12 {
  margin: 120px !important;
}

.m-13 {
  margin: 130px !important;
}

.m-14 {
  margin: 140px !important;
}

.m-15 {
  margin: 150px !important;
}

.m-16 {
  margin: 160px !important;
}

.m-17 {
  margin: 170px !important;
}

.m-18 {
  margin: 180px !important;
}

.m-19 {
  margin: 190px !important;
}

.m-20 {
  margin: 200px !important;
}

/* Margin top */
.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 10px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mt-3 {
  margin-top: 30px !important;
}

.mt-4 {
  margin-top: 40px !important;
}

.mt-5 {
  margin-top: 50px !important;
}

.mt-6 {
  margin-top: 60px !important;
}

.mt-7 {
  margin-top: 70px !important;
}

.mt-8 {
  margin-top: 80px !important;
}

.mt-9 {
  margin-top: 90px !important;
}

.mt-10 {
  margin-top: 100px !important;
}

.mt-11 {
  margin-top: 110px !important;
}

.mt-12 {
  margin-top: 120px !important;
}

.mt-13 {
  margin-top: 130px !important;
}

.mt-14 {
  margin-top: 140px !important;
}

.mt-15 {
  margin-top: 150px !important;
}

.mt-16 {
  margin-top: 160px !important;
}

.mt-17 {
  margin-top: 170px !important;
}

.mt-18 {
  margin-top: 180px !important;
}

.mt-19 {
  margin-top: 190px !important;
}

.mt-20 {
  margin-top: 200px !important;
}

.mt-28 {
  margin-top: 280px !important;
}

/* Margin right */
.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 10px !important;
}

.mr-2 {
  margin-right: 20px !important;
}

.mr-3 {
  margin-right: 30px !important;
}

.mr-4 {
  margin-right: 40px !important;
}

.mr-5 {
  margin-right: 50px !important;
}

.mr-6 {
  margin-right: 60px !important;
}

.mr-7 {
  margin-right: 70px !important;
}

.mr-8 {
  margin-right: 80px !important;
}

.mr-9 {
  margin-right: 90px !important;
}

.mr-10 {
  margin-right: 100px !important;
}

.mr-11 {
  margin-right: 110px !important;
}

.mr-12 {
  margin-right: 120px !important;
}

.mr-13 {
  margin-right: 130px !important;
}

.mr-14 {
  margin-right: 140px !important;
}

.mr-15 {
  margin-right: 150px !important;
}

.mr-16 {
  margin-right: 160px !important;
}

.mr-17 {
  margin-right: 170px !important;
}

.mr-18 {
  margin-right: 180px !important;
}

.mr-19 {
  margin-right: 190px !important;
}

.mr-20 {
  margin-right: 200px !important;
}

/* Margin bottom */
.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.mb-3 {
  margin-bottom: 30px !important;
}

.mb-4 {
  margin-bottom: 40px !important;
}

.mb-5 {
  margin-bottom: 50px !important;
}

.mb-6 {
  margin-bottom: 60px !important;
}

.mb-7 {
  margin-bottom: 70px !important;
}

.mb-8 {
  margin-bottom: 80px !important;
}

.mb-9 {
  margin-bottom: 90px !important;
}

.mb-10 {
  margin-bottom: 100px !important;
}

.mb-11 {
  margin-bottom: 110px !important;
}

.mb-12 {
  margin-bottom: 120px !important;
}

.mb-13 {
  margin-bottom: 130px !important;
}

.mb-14 {
  margin-bottom: 140px !important;
}

.mb-15 {
  margin-bottom: 150px !important;
}

.mb-16 {
  margin-bottom: 160px !important;
}

.mb-17 {
  margin-bottom: 170px !important;
}

.mb-18 {
  margin-bottom: 180px !important;
}

.mb-19 {
  margin-bottom: 190px !important;
}

.mb-20 {
  margin-bottom: 200px !important;
}

.mb-35 {
  margin-bottom: 350px !important;
}

/* Margin left */
.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 10px !important;
}

.ml-2 {
  margin-left: 20px !important;
}

.ml-3 {
  margin-left: 30px !important;
}

.ml-4 {
  margin-left: 40px !important;
}

.ml-5 {
  margin-left: 50px !important;
}

.ml-6 {
  margin-left: 60px !important;
}

.ml-7 {
  margin-left: 70px !important;
}

.ml-8 {
  margin-left: 80px !important;
}

.ml-9 {
  margin-left: 90px !important;
}

.ml-10 {
  margin-left: 100px !important;
}

.ml-11 {
  margin-left: 110px !important;
}

.ml-12 {
  margin-left: 120px !important;
}

.ml-13 {
  margin-left: 130px !important;
}

.ml-14 {
  margin-left: 140px !important;
}

.ml-15 {
  margin-left: 150px !important;
}

.ml-16 {
  margin-left: 160px !important;
}

.ml-17 {
  margin-left: 170px !important;
}

.ml-18 {
  margin-left: 180px !important;
}

.ml-19 {
  margin-left: 190px !important;
}

.ml-20 {
  margin-left: 200px !important;
}

/* Margin top negative */
.mtn-0 {
  margin-top: 0px !important;
}

.mtn-1 {
  margin-top: -10px !important;
}

.mtn-1-5 {
  margin-top: -15px !important;
}

.mtn-2 {
  margin-top: -20px !important;
}

.mtn-3 {
  margin-top: -30px !important;
}

.mtn-4 {
  margin-top: -40px !important;
}

.mtn-5 {
  margin-top: -50px !important;
}

.mtn-6 {
  margin-top: -60px !important;
}

.mtn-7 {
  margin-top: -70px !important;
}

.mtn-8 {
  margin-top: -80px !important;
}

.mtn-9 {
  margin-top: -90px !important;
}

.mtn-10 {
  margin-top: -100px !important;
}

.mtn-11 {
  margin-top: -110px !important;
}

.mtn-12 {
  margin-top: -120px !important;
}

.mtn-13 {
  margin-top: -130px !important;
}

.mtn-14 {
  margin-top: -140px !important;
}

.mtn-15 {
  margin-top: -150px !important;
}

.mtn-16 {
  margin-top: -160px !important;
}

.mtn-17 {
  margin-top: -170px !important;
}

.mtn-18 {
  margin-top: -180px !important;
}

.mtn-19 {
  margin-top: -190px !important;
}

.mtn-20 {
  margin-top: -200px !important;
}

/* Margin right negative */
.mrn-0 {
  margin-right: 0px !important;
}

.mrn-1 {
  margin-right: -10px !important;
}

.mrn-2 {
  margin-right: -20px !important;
}

.mrn-3 {
  margin-right: -30px !important;
}

.mrn-4 {
  margin-right: -40px !important;
}

.mrn-5 {
  margin-right: -50px !important;
}

.mrn-6 {
  margin-right: -60px !important;
}

.mrn-7 {
  margin-right: -70px !important;
}

.mrn-8 {
  margin-right: -80px !important;
}

.mrn-9 {
  margin-right: -90px !important;
}

.mrn-10 {
  margin-right: -100px !important;
}



.mrn-11 {
  margin-right: -110px !important;
}

.mrn-12 {
  margin-right: -120px !important;
}

.mrn-13 {
  margin-right: -130px !important;
}

.mrn-14 {
  margin-right: -140px !important;
}

.mrn-15 {
  margin-right: -150px !important;
}

.mrn-16 {
  margin-right: -160px !important;
}

.mrn-17 {
  margin-right: -170px !important;
}

.mrn-18 {
  margin-right: -180px !important;
}

.mrn-19 {
  margin-right: -190px !important;
}

.mrn-20 {
  margin-right: -200px !important;
}

/* Margin bottom negative */
.mbn-0 {
  margin-bottom: 0px !important;
}

.mbn-1 {
  margin-bottom: -10px !important;
}

.mbn-2 {
  margin-bottom: -20px !important;
}

.mbn-3 {
  margin-bottom: -30px !important;
}

.mbn-4 {
  margin-bottom: -40px !important;
}

.mbn-5 {
  margin-bottom: -50px !important;
}

.mbn-6 {
  margin-bottom: -60px !important;
}

.mbn-7 {
  margin-bottom: -70px !important;
}

.mbn-8 {
  margin-bottom: -80px !important;
}

.mbn-9 {
  margin-bottom: -90px !important;
}

.mbn-10 {
  margin-bottom: -100px !important;
}

.mbn-11 {
  margin-bottom: -110px !important;
}

.mbn-12 {
  margin-bottom: -120px !important;
}

.mbn-13 {
  margin-bottom: -130px !important;
}

.mbn-14 {
  margin-bottom: -140px !important;
}

.mbn-15 {
  margin-bottom: -150px !important;
}

.mbn-16 {
  margin-bottom: -160px !important;
}

.mbn-17 {
  margin-bottom: -170px !important;
}

.mbn-18 {
  margin-bottom: -180px !important;
}

.mbn-19 {
  margin-bottom: -190px !important;
}

.mbn-20 {
  margin-bottom: -200px !important;
}

.mbn-35 {
  margin-bottom: -350px !important;
}

/* Margin left negative */
.mln-0 {
  margin-left: 0px !important;
}

.mln-1 {
  margin-left: -10px !important;
}

.mln-2 {
  margin-left: -20px !important;
}

.mln-3 {
  margin-left: -30px !important;
}

.mln-4 {
  margin-left: -40px !important;
}

.mln-5 {
  margin-left: -50px !important;
}

.mln-6 {
  margin-left: -60px !important;
}

.mln-7 {
  margin-left: -70px !important;
}

.mln-8 {
  margin-left: -80px !important;
}

.mln-9 {
  margin-left: -90px !important;
}

.mln-10 {
  margin-left: -100px !important;
}

.mln-11 {
  margin-left: -110px !important;
}

.mln-12 {
  margin-left: -120px !important;
}

.mln-13 {
  margin-left: -130px !important;
}

.mln-14 {
  margin-left: -140px !important;
}

.mln-15 {
  margin-left: -150px !important;
}

.mln-16 {
  margin-left: -160px !important;
}

.mln-17 {
  margin-left: -170px !important;
}

.mln-18 {
  margin-left: -180px !important;
}

.mln-19 {
  margin-left: -190px !important;
}

.mln-20 {
  margin-left: -200px !important;
}
.white {
  color: #fff !important;
}
.dark {
  color: #444 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-light {
  background-color: #f8f8f8 !important;
}
.bg-dark {
  background-color: #333333 !important;
}
.bg-darkest {
  background-color: #000000 !important;
}
.bg-gray {
  background-color: #efefef !important;
}
.bg-color {
  background-color: #9fce4e !important;
}

/*--------------------------------------------------------------
2.0 - Custom CSS
--------------------------------------------------------------*/
hr {
  border-top-color: #e5e5e5;
}
hr.dashed {
  border-top-style: dashed;
  border-top-width: 2px;
}
hr.dotted {
  border-top-style: dotted;
  border-top-width: 2px;
}
hr.double {
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e5e5e5;
  height: 1px;
}
.separator-inherit-theme.icon:before {
  text-align: center;
  display: inline-block;
  border: 2px solid transparent;
  width: 60px;
  height: 60px;
  box-sizing: content-box;
  transform: translateX(-50%) translateY(50%) rotate(0deg);
  font-family:'FontAwesome';
  content: "\f004";
  font-size: 30px;
  color: #9fce4e;
}
.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}
.breadcrumb {
  background: none;
}
.dropdown-menu>li>a {
  font-weight: 300;
}
.navbar-brand>img {
  display: inline;
}
.navbar-default {
  border: none;
}
.nav>li>a:focus, .nav>li>a:hover {
  background: none;
}
.dropdown-menu {
  border: none;
}
#footer .logo {
  max-width: 165px;
}
.deal {
  margin-bottom: 70px;
  padding-top: 120px;
  padding-bottom: 120px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.deal .text-block {
  padding-left: 100px;
}
.deal h3 {
  color: #9fce4e;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 80px;
}
.deal h2 {
  color: #fff;
  font-size: 50px;
  font-weight: 900;
  line-height: 52px;
}
.deal p {
  text-align: right;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
}
.deal a {
  line-height: 20px;
  border: 2px solid #fff;
  padding: 8px 28px;
  letter-spacing: 0px;
  font-weight: 600;
  font-size: 13px;
  color: #fff;
  float: right;
}
.deal a:hover {
  background-color: #9fce4e;
  border-color: #9fce4e;
}
.banner-ads {
  margin-right: 0px;
  margin-left: 0px;
  padding: 10px 15px;
  background-color: #9fce4e;
}
@media (max-width:768px) {
  .deal .text-block {
	padding-left: 0px;
	text-align: center;
  }
  .deal p {
    text-align: center;
  }
  .deal a {
    float: none;
  }
}
.banner-ads-inner {
  margin-bottom:0px;
  border-top-width:1px;
  border-right-width:1px;
  border-bottom-width:1px;
  border-left-width:1px;
  padding-top:6px;
  padding-right:6px;
  padding-bottom:6px;
  padding-left:6px;
  border-left-color:rgba(255,255,255,0.25);
  border-left-style:solid;
  border-right-color:rgba(255,255,255,0.25);
  border-right-style:solid;
  border-top-color:rgba(255,255,255,0.25);
  border-top-style:solid;
  border-bottom-color:rgba(255,255,255,0.25);
  border-bottom-style:solid;
  border-radius:1px;
}
.banner-ads-inner p {
  text-align: center; 
  color: #fff; 
  font-size: 24px; 
  text-transform: uppercase; 
  margin-bottom: 0;
}
.testimonial-bg {
  margin-bottom:70px;
  padding-top:85px;
  padding-bottom:70px;
  background-position:center;
  background-repeat:no-repeat;
  background-size:cover;
}
.brands {
  margin-bottom:70px;
  padding-top:85px;
  padding-bottom:85px;
  background-position:center;
  background-repeat:no-repeat;
  background-size:cover;
}
.privacy {
  margin-right:0px;
  margin-left:0px;
  margin-bottom: 80px;
  border: 1px solid #e5e5e5;
}
.privacy i {
  font-size: 5em;
  color:#353333;
}
.mini-sep {
  max-width: 115px;
  border-color: #EBEBEB;
}
.icon-circle {
  text-align: center;
}
.icon-circle .icon-circle-inner {
  width: 115px;
  height: 115px;
  -webkit-box-shadow: inset 0 0 0 4px #fff;
  box-shadow: inset 0 0 0 4px #fff;
  background-color: #9fce4e;
  box-sizing: content-box;
  position: relative;
  display: inline-block;
  border: 2px solid transparent;
  max-width: 100%;
  border-radius: 50%;
}
.icon-circle .icon-circle-inner i {
  font-style: normal;
  font-weight: 400;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -o-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  font-size: 28px;
  color: #FFF;
}
.parallax-background {
  padding-top: 150px;
  padding-bottom: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.video-embed-action i {
  font-size: 60px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  color: #fff;
  text-align: center;
  display: inline-block;
}
.parallax-background-2 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 35px;
}
.parallax-background-2 h2 {
  font-size: 36px;
}
.parallax-background-2 .image {
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  padding-top: 45px;
  text-align: right;
}
.parallax-background-3 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 70px;
}
.parallax-background-3 .image {
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
.parallax-background-4 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 70px;
}
.parallax-background-5 {
  padding-top: 25px;
  padding-bottom: 25px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax-background-6 {
  padding-top: 80px;
  padding-bottom: 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.modern-background {
  padding-top: 130px;
  padding-bottom: 130px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
	.thumbs.animated {
		/*CSS transitions*/
		-o-transition-property: none !important;
		-moz-transition-property: none !important;
		-ms-transition-property: none !important;
		-webkit-transition-property: none !important;
		transition-property: none !important;
		/*CSS transforms*/
		-o-transform: none !important;
		-moz-transform: none !important;
		-ms-transform: none !important;
		-webkit-transform: none !important;
		transform: none !important;
		/*CSS animations*/
		-webkit-animation: none !important;
		-moz-animation: none !important;
		-o-animation: none !important;
		-ms-animation: none !important;
		animation: none !important;
	}
}
.jss-check-out-video{
  width: 800px;
  height: 500px;
  position: absolute;
  left: 35%;
  top: 30%;
  margin: -75px 0 0 -135px;
}
.portfolio-lightbox-overlay div[class^='paginatedThumbnails_']{
  left: 50%;
  margin-top: -50px;
  position: absolute;
  z-index: 100000;
}
i.elegant_arrow_triangle-right_alt2.white:hover{
  cursor: pointer;
}
.portfolio-wrapper .icon.circle.prettyphoto:hover{
  cursor: pointer;
}
.ReactModal__Overlay--after-open{
  z-index: 1005 !important;

}
