.video-background-holder {
    position: relative;
    background-color: black;
    height: 520px;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
}

.video-background-holder video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: 520px;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.video-background-content {
    position: relative;
    z-index: 2;
    padding: 11% 0;
}

.btn.btn-icon-right.btn-icon-slide-in {
    margin-right: 25px;
}

.video-background-content h1 {
    color: #4f4f4f;
    font-size: 26px;
    letter-spacing: 2px;
    line-height: 1.3em;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 10px;
    text-align: left;
}

.video-background-content h1 p {
    margin: 0;
}

.video-background-content .lead {
    text-transform: uppercase;
    font-size: 10px;
    color: #404040;
    letter-spacing: 2px;
    -webkit-transition: color .2s;
    transition: color .2s;
    text-align: left;
    line-height: 1.7em;
    font-weight: 500;
}


@media only screen and (max-width: 767px) {
    .video-background-content {
        padding: 46% 10% !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 864px) {
    .video-background-content {
        padding: 22% 14% !important;
    }
}
