.row_custom_small{
    display: flex;
    flex-wrap: wrap;
}
/*.all-articles__custom:first-child{*/
/*    padding-top: 0 !important;*/
/*}*/
.all-articles__custom .item__article__only {
    height: auto;
    position: relative;
    color: #000;
    padding-bottom: 80px;
    margin-bottom: 30px; 
    min-height: 30vw;
    max-height: 610px;
}
/* .all-articles__custom .item__article__only:hover {
    transform: translateY(-20px);
    text-decoration: none; } */
.all-articles__custom .item__article__only:hover button{
    background-color: #595959;
    color: #fff;
}
/* .all-articles__custom .item__article__only:before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 20px;
    width: 0;
    height: 4px;
    background-color: rgba(155, 155, 155, 0.2);
    transition: all .3s ease; }
.all-articles__custom .item__article__only:hover:before {
    width: 75%; } */
.all-articles__custom .item__article__only img {
    display: block;
    margin: 0 auto; 
    width: 100%;
}
.all-articles__custom .item__article__only h1 {
    display: block;
    min-height: 50px;
    text-align: center;
    font-size: 1.8rem;
    margin: 25px 0 20px; }
.all-articles__custom .item__article__only p {
    font-size: 1rem;
    min-height: 76px;
    font-weight: 300;
    margin-bottom: 16px;
}
.all-articles__custom .item__article__only button {
    font-size: 1.375rem;
    position: absolute;
    text-transform: uppercase;
    font-weight: 300;
    background-color: #dfc08c;
    color: #fff;
    border-radius: 0;
    margin: 0 18%;}

@media screen and (max-width: 425px) {

    .all-articles__custom .item__article__only {
        height: 517px;
        padding-bottom: 50px;
    }
    .all-articles__custom .item__article__only h1 {
        font-size: 1.45rem !important;
        margin-top: 13px !important;
    }
    .all-articles__custom .item__article__only time {
        color: #bebebe;
        display: block;
        margin: 12px 0 -4px;
    }
    .all-articles__custom .item__article__only span {
        font-size: 16.5px !important;
    }
    .all-articles__custom .item__article__only button {
        height: unset;
        font-size: 1.125rem;
    }

}