.prodSumRT h1{
    font-size: 1.1428571428571428em;
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e1e1;
}
.prodSumRT{
    margin-top: 30px;
}
.prodAd .col-md-4{
    padding: 10px;
    height: 110px;
}
.relevantImg{
    height: 45px;
    width: 45px;
    display: block;
    margin: 0 auto;
}

/* TOP PART */
.summary-container{
    margin-top: 20px;
}

.summary-container .product_title{
    font-family: "Camila-Semibold", serif;
    text-align: center;
    font-size: 40px;
    line-height: 50px; 
}

.summary-container .product_subtitle{
    text-align: center;
    font-family: "Atlan-Regular", Helvetica, Arial, sans-serif;
}

.summary-container .commerce-product-rating .StarRating{
    text-align: center;
    font-size: 20px;
}

.summary-container .product-excerpt{
    font-size: 17px;
    line-height: 20px;
}

.summary-container .buy-button a{
    display: block;
    background-color: #C3B37B;
    text-align: center;
    font-size: 16px;
    padding: 10px;
    margin-top: 50px;
    color: white;
}

.summary-container .buy-button a:hover{
    background-color: #8F835A;
}

.summary-container .entry-image{
    margin-bottom: 30px;
}

/* INGREDIENTS PART */
.entry-ingredient{
    margin-bottom: 100px;
}
.entry-ingredient h2{
    font-size: 27px;
    line-height: 27px;
    position: relative;
    margin: 0;
}

.howToUse-entry h2, .entry-composition h2{
    font-family: "Camila-SemiboldIt", serif;
    padding-bottom: 54px;
    padding-top: 16px;
    font-size: 27px;
    line-height: 27px;
    position: relative;
    margin: 0;
    background: url(../../assets/images/Theme/ornament-olive.png) no-repeat center bottom;
    background-size: contain;
    text-align: center;
    color: #21552F;
    font-weight: lighter;
}

.howToUse-entry {
    text-align: center;
    font-size: 16px;
    margin-bottom: 100px;
}


.entry-ingredient .product-ingredients-list {
    margin-top: 45px;
    text-align: center;
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-around;
}

.product-ingredients-list-item:first-child{
    margin-top: 0 !important;
}

.product-ingredients-list-item {
    width: 190px;
    min-height: 72px;
}

.product-ingredients-list-item .product-ingredients-list-img {
    display: inline-block;
    max-height: 190px;
    border-radius: 100%;
    margin-bottom: 10px;
}

.product-ingredients-list-title {
    position: relative;
    font-family: "Camila-Bold",serif;
    font-size: 18px;
    line-height: 18px;
    color: #4A4A4A;
}

/* COMPOSITION PART */
.entry-composition .text-composition{
    /*margin-top: 38px;*/
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 40px);
    max-width: 1254px;
    text-align: center;
}

.entry-composition .text-composition h2.o-title {
    margin: 0 35% !important;
}

/* .entry-composition h2 {
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    font-family: "Atlan-Bold",sans-serif;
    margin: 0;
    display: inline-block;
    padding-bottom: 10px;
    border-bottom: 3px solid #A5C03F;
} */

.entry-composition td:first-child{
    text-transform: uppercase;
    font-family: "Atlan-Light",sans-serif;
    font-size: 15px;
    line-height: 24px;
    text-align: right;
    width: 50%;
    padding: 0 10px;
    position: relative;
}

.entry-composition td:last-child{
    font-family: "Atlan-SemiBold",sans-serif;
    text-transform: none;
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    width: 50%;
    padding: 0 10px;
    position: relative;
}

.entry-composition td p{
    margin: 0 !important;
}

.entry-composition td button{
    margin: 0;
    padding: 0;
    cursor: pointer;
    background: none;
    border: none;
    border-radius: 0;
    text-decoration: underline;
    text-transform: uppercase;
    font-family: "Atlan-Light",sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
}

/* Avantages */
.entry-relevantAdvantages{
    margin-bottom: 100px;
}

.entry-relevantAdvantages h2 {
    font-size: 27px;
    line-height: 27px;
}

.entry-relevantAdvantages ul{
    margin-top: 23px;
    display: flex;
    background: #394a27;
    padding: 50px 25px;
    justify-content: space-between;
}

.entry-relevantAdvantages ul li{
    padding: 15px;
    width: calc(95% /4);
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.entry-relevantAdvantages ul li img{
    display: inline-block;
    height: 59px;
    width: auto;
}

.entry-relevantAdvantages ul li .product-results-list-desc{
    margin-top: 15px;
    font-family: "Atlan-Regular",sans-serif;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    color: #4A4A4A;
}

.entry-relevantAdvantages ul li div small{
    font-size: 10px;
    line-height: 12px;
}

/* How to use */
.howToUse-entry ul {
    margin-top: 28px;
    text-align: left !important;
}

.howToUse-entry ul li {
    position: relative;
    width: unset !important;
    /* min-height: 72px; */
    margin: 0 auto;
}

.howToUse-entry ul li p{
    font-family: "Camila-Semibold",serif;
    font-size: 18px;
    line-height: 18px;
    color: #4A4A4A;
    position: relative;
    z-index: 2;
}

.howToUse-entry ul,
.entry-relevantAdvantages ul {
    text-align: center;
    list-style: none;
}

@media screen and (max-width: 768px){
    .entry-relevantAdvantages ul, .entry-ingredient ul {
        flex-direction: column;
    }
    .entry-relevantAdvantages ul li, .entry-ingredient ul li{
        width: 100%;
        margin-bottom: 20px;
    }


}
/* 
@media screen and (max-width: 320px) {

    .product-ingredients-list-item:nth-child(odd) {
        margin: -30px auto 64px 30px;
    }

    .product-ingredients-list-item:nth-child(even) {
        margin: -30px 50px 64px auto;
    }

}

@media screen and (min-width: 321px) and (max-width: 375px) {

    .product-ingredients-list-item:nth-child(odd) {
        margin: 25px auto 72px 50px;
    }

    .product-ingredients-list-item:nth-child(even) {
        margin: -30px 62px 0 auto;
    }

    .product-ingredients-list-item:last-child{
        margin-bottom: 30px;
    }
}

@media screen and (min-width: 376px) and (max-width: 425px) {

    .product-ingredients-list-item:nth-child(odd) {
        margin: 25px auto 72px 76px;
    }

    .product-ingredients-list-item:nth-child(even) {
        margin: -30px 80px 0 auto;
    }

    .product-ingredients-list-item:last-child{
        margin-bottom: 30px;
    }
}

@media screen and (min-width: 426px) and (max-width: 768px) {

    .product-ingredients-list-item:nth-child(odd) {
        margin: 15px auto 42px 104px;
    }

    .product-ingredients-list-item:nth-child(even) {
        margin: -30px 175px 0 auto;
    }

    .product-ingredients-list-item:last-child{
        margin-bottom: 30px;
    }

} */
