/*** special style for Test our products page ***/

.test-our-products h2{
    color: #384A27 !important;
    font-size: calc(25px + 2vw);
    line-height: 1em;
    margin: 0px;
    margin-bottom: 30px;
    font-family: "Camila-Bold",serif;
    text-transform: none;
}

.test-our-products p {
    color: black !important;
    font-size: 18px;
    line-height: 1em;;
}

.test-our-products .btn_custom_oro {
    opacity: 1;
    transform: none;
    left: 0;
    position: initial;
}

.test-our-products .image img{
    width: 100%;
}