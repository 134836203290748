.page-header{
    height: calc(100vh - 226px);
    background-size: cover !important;
}

.overlay {
    height: 100%;
    -webkit-box-shadow: inset 0 -100px 300px -103px rgba(0,0,0,0.75);
    -moz-box-shadow: inset 0 -100px 300px -103px rgba(0,0,0,0.75);
    box-shadow: inset 0 -100px 300px -103px rgba(0,0,0,0.75);
}

.page-header,
.overlay {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.content {
    /*border: 20px solid #394A27;*/
    border-top: 0;
    border-bottom: 0;
    height: 100%;
    width: 100%;
}

.page-title{
    color: #FFF;
    font-family: "Camila-SemiboldIt", serif;
    font-size: 72px;
    line-height: 72px;
    text-shadow: 2px 2px #000;
    text-align: center;
}

.subtitle{
    line-height: 35px;
    font-size: 25px;
    color: #fff;
}

.discoverHead {
    text-align: center;
}

.discover {
    position: absolute;
    bottom: 0;
}

.smooth-scroll {
    color: #FFF;
    display: inline-block;
    text-align: center;
    padding-bottom: 70px;
    background-image: url("../../assets/images/HeaderRollOver/smooth-scroll-arrow.png");
    background-repeat: no-repeat;
    background-position: bottom 20px center;
    font-family: "Atlan-Bold",sans-serif;
    font-size: 18px;
    line-height: 34px;
    cursor: pointer;
    font-weight: 600;
}

/* EFFECTS */
.ops-scroll-animation {
    opacity: 0;
    -moz-transition: all 0.5s ease-out;
    -webkit-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    -moz-transition-delay: 0.2s;
    -webkit-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

.ops-in-view {
    opacity: 1;
    -moz-transform: translate3d(0px,0px,0px);
    -webkit-transform: translate3d(0px,0px,0px);
    -o-transform: translate(0px,0px);
    -ms-transform: translate(0px,0px);
    transform: translate3d(0px,0px,0px);
}

div {
    animation-duration: 0.01s;
    -o-animation-duration: 0.01s;
    -ms-animation-duration: 0.01s;
    -moz-animation-duration: 0.01s;
    -webkit-animation-duration: 0.01s;
    animation-name: nodeInserted;
    -o-animation-name: nodeInserted;
    -ms-animation-name: nodeInserted;
    -moz-animation-name: nodeInserted;
    -webkit-animation-name: nodeInserted;
}

@media (max-width: 1023px) {

    .page-header,
    .overlay,
    .page-title {
        font-size: 36px;
        line-height: 36px;
    }

    .content {
        border-left-width: 10px;
        border-right-width: 10px;
    }

}
