html{
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
}
html,body{
    margin:0px;
    padding:0px;
    font-family:"Atlan-Regular",sans-serif;
    font-size:14px;
    font-weight:normal;
}
a{
    text-decoration:none;
}
.o-hidden{
    display:none;
}
.o-clear{
    clear:both;
}
.node-type-page{
    max-width: 1354px;
    margin: auto;
    opacity: 0;
    transition: all .5s ease-in-out;
}
.node-type-page img{
    height: auto !important;
}
.o-list-modules{
    width:calc(100% - 40px);
    max-width:1354px;
    margin:0 auto;
    display:flex;
    flex-wrap:wrap;
}
@media (max-width:1023px){
    .o-list-modules{
        width:100%;
    }
}
.o-list-modules .o-list-modules-item{
    width:50%;
    padding:70px;
    padding-top:0px;
}
@media (max-width:1439px){
    .o-list-modules .o-list-modules-item{
        padding:40px;
    }
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item{
        width:100%;
        padding:10px;
    }
}
.o-list-modules .o-list-modules-item:last-child{
    padding-bottom:0px;
}
.o-list-modules .o-list-modules-item--bundle-image{
    font-size:0px;
    line-height:0px;
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-image{
        padding:0px;
    }
}
.o-list-modules .o-list-modules-item--bundle-image img{
    display:block;
    margin-left:-70px;
    width:calc(100% + 70px);
}
@media (max-width:1439px){
    .o-list-modules .o-list-modules-item--bundle-image img{
        margin-left:-40px;
        width:calc(100% + 40px);
    }
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-image img{
        margin-left:0px;
        width:100%;
    }
}
.o-list-modules .o-list-modules-item--bundle-image + .o-list-modules-item--bundle-title-text{
    padding-top:70px;
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-image + .o-list-modules-item--bundle-title-text{
        padding-top:25px;
    }
}
.o-list-modules .o-list-modules-item--bundle-title-text{
    padding-bottom:70px;
}
@media (max-width:1439px){
    .o-list-modules .o-list-modules-item--bundle-title-text{
        padding-bottom:40px;
    }
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-title-text{
        padding-bottom:10px;
        padding-top:20px;
    }
}
.o-list-modules .o-list-modules-item--bundle-title-text .o-item-title{
    text-align:center;
    color:#000;
    font-family:"Camila-SemiboldIt",serif;
    font-size:36px;
    line-height:37px;
    margin-right:-34px;
    width:calc(100% + 34px);
    margin-bottom:15px;
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-title-text .o-item-title{
        margin-right:0px;
        width:100%;
    }
}
.o-list-modules .o-list-modules-item--bundle-title-text .o-item-text{
    font-family:"Atlan-Regular",sans-serif;
    font-size:16px;
    line-height:24px;
    margin-right:-34px;
    width:calc(100% + 34px);
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-title-text .o-item-text{
        margin-right:0px;
        width:100%;
    }
}
.o-list-modules .o-list-modules-item--bundle-title-text .o-item-text strong{
    font-family:"Atlan-Bold",sans-serif;
}
.o-list-modules .o-list-modules-item--bundle-title-text .o-item-text p{
    margin-bottom:20px;
}
.o-list-modules .o-list-modules-item--bundle-quote .o-item-quote{
    font-family:"Camila-SemiboldIt",serif;
    font-size:36px;
    line-height:37px;
    text-align:center;
    margin-bottom:20px;
}
@media (max-width:1439px){
    .o-list-modules .o-list-modules-item--bundle-quote .o-item-quote{
        font-size:28px;
        line-height:26px;
    }
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-quote .o-item-quote{
        font-size:24px;
        line-height:26px;
        text-align:left;
    }
}
.o-list-modules .o-list-modules-item--bundle-quote .o-item-legend{
    text-align:center;
    font-family:"Atlan-Bold",sans-serif;
    font-size:16px;
    line-height:24px;
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-quote .o-item-legend{
        text-align:left;
        font-size:14px;
        line-height:24px;
    }
}
.o-list-modules .o-list-modules-item--bundle-group{
    padding-left:0px;
    padding-right:0px;
    width:100%;
    display:flex;
    flex-wrap:wrap;
}
.o-list-modules .o-list-modules-item--bundle-group .o-image{
    width:50%;
    font-size:0px;
    line-height:0px;
    z-index:1;
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-group .o-image{
        width:100%;
    }
}
.o-list-modules .o-list-modules-item--bundle-group .o-image img{
    display:block;
    width:100%;
}
.o-list-modules .o-list-modules-item--bundle-group .o-image .o-item-title{
    display:block;
    text-align:left;
    width:100%;
    font-family:"Camila-SemiboldIt",serif;
    font-size:50px;
    line-height:50px;
    color:#4C5A1A;
    padding:0px 10px;
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-group .o-image .o-item-title{
        font-size:40px;
        line-height:40px;
        padding-bottom:10px;
    }
}
.o-list-modules .o-list-modules-item--bundle-group .o-description{
    width:50%;
    padding-top:132px;
    z-index:10;
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-group .o-description{
        width:100%;
        padding:10px;
    }
}
.o-list-modules .o-list-modules-item--bundle-group .o-description .o-item-title{
    display:block;
    text-align:right;
    font-family:"Camila-SemiboldIt",serif;
    font-size:72px;
    line-height:72px;
    color:#4C5A1A;
    padding-bottom:72px;
    padding-right:24px;
}
@media (max-width:1439px){
    .o-list-modules .o-list-modules-item--bundle-group .o-description .o-item-title{
        font-size:52px;
        line-height:52px;
    }
}
.o-list-modules .o-list-modules-item--bundle-group .o-description .o-item-text{
    font-family:"Atlan-Regular",sans-serif;
    font-size:16px;
    line-height:24px;
    width:100%;
    padding-right:124px;
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-group .o-description .o-item-text{
        padding:0px;
    }
}
.o-list-modules .o-list-modules-item--bundle-group .o-description .o-item-text strong{
    font-family:"Atlan-Bold",sans-serif;
}
.o-list-modules .o-list-modules-item--bundle-group .o-description .o-item-text p{
    margin-bottom:20px;
}
.o-list-modules .o-list-modules-item--bundle-group .o-description .o-item-text iframe{
    max-width:100%;
}
@media (max-width:1439px){
    .o-list-modules .o-list-modules-item--bundle-group .o-description .o-item-text iframe{
        max-height:255px;
    }
}
.o-list-modules .o-list-modules-item--bundle-iframe iframe{
    max-width:576px;
}
@media (max-width:1439px){
    .o-list-modules .o-list-modules-item--bundle-iframe iframe{
        max-width:100%;
        max-height:255px;
    }
}
.o-list-modules .o-list-modules-item--bundle-link{
    text-align:center;
}
.o-list-modules .o-list-modules-item--bundle-link .o-item-title{
    width:100%;
    text-align:center;
    color:#000;
    font-family:"Camila-SemiboldIt",serif;
    font-size:36px;
    line-height:37px;
    margin-bottom:20px;
}
.o-list-modules .o-list-modules-item--bundle-images{
    display:flex;
    align-items:center;
    justify-content:center;
    padding-left:0px;
    padding-right:0px;
}
.o-list-modules .o-list-modules-item--bundle-images .o-images{
    margin:0 auto;
    position:relative;
    font-size:0px;
    line-height:0px;
}
.o-list-modules .o-list-modules-item--bundle-images .o-images img{
    position:absolute;
    max-width:100%;
}
.o-list-modules .o-list-modules-item--bundle-images .o-images.position-9{
    width:100%;
    height:auto;
    text-align:center;
}
.o-list-modules .o-list-modules-item--bundle-images .o-images.position-9 img{
    position:relative;
    width:auto;
}
.o-list-modules .o-list-modules-item--bundle-images .o-images.position-1{
    width:472px;
    height:519px;
}
@media (max-width:1439px){
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-1{
        width:330px;
        height:361px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-1 img:first-child{
        max-width:220px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-1 img:last-child{
        max-width:220px;
    }
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-1{
        width:240px;
        height:264px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-1 img:first-child{
        max-width:161px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-1 img:last-child{
        max-width:161px;
    }
}
.o-list-modules .o-list-modules-item--bundle-images .o-images.position-2{
    width:534px;
    height:508px;
}
@media (max-width:1439px){
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-2{
        width:373px;
        height:356px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-2 img:first-child{
        max-width:274px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-2 img:last-child{
        max-width:220px;
    }
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-2{
        width:240px;
        height:228px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-2 img:first-child{
        max-width:176px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-2 img:last-child{
        max-width:142px;
    }
}
.o-list-modules .o-list-modules-item--bundle-images .o-images.position-3{
    width:603px;
    height:686px;
}
@media (max-width:1439px){
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-3{
        width:422px;
        height:480px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-3 img:first-child{
        max-width:327px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-3 img:last-child{
        max-width:224px;
    }
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-3{
        width:240px;
        height:273px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-3 img:first-child{
        max-width:185px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-3 img:last-child{
        max-width:123px;
    }
}
.o-list-modules .o-list-modules-item--bundle-images .o-images.position-4{
    width:534px;
    height:514px;
}
@media (max-width:1439px){
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-4{
        width:374px;
        height:360px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-4 img:first-child{
        max-width:274px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-4 img:last-child{
        max-width:221px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-4 img:first-child:last-child{
        max-width:100%;
    }
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-4{
        width:241px;
        height:232px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-4 img:first-child{
        max-width:176px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-4 img:last-child{
        max-width:143px;
    }
}
.o-list-modules .o-list-modules-item--bundle-images .o-images.position-5{
    width:534px;
    height:508px;
}
.o-list-modules .o-list-modules-item--bundle-images .o-images.position-6{
    width:603px;
    height:612px;
}
@media (max-width:1439px){
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-6{
        width:420px;
        height:428px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-6 img:first-child{
        max-width:185px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-6 img:last-child{
        max-width:123px;
    }
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-6{
        width:240px;
        height:244px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-6 img:first-child{
        max-width:185px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-6 img:last-child{
        max-width:123px;
    }
}
.o-list-modules .o-list-modules-item--bundle-images .o-images.position-7{
    width:460px;
    height:508px;
}
@media (max-width:1439px){
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-7{
        width:322px;
        height:356px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-7 img:first-child{
        max-width:222px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-7 img:last-child{
        max-width:221px;
    }
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-7{
        width:240px;
        height:278px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-7 img:first-child{
        max-width:176px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-7 img:last-child{
        max-width:142px;
    }
}
.o-list-modules .o-list-modules-item--bundle-images .o-images.position-8{
    width:557px;
    height:643px;
}
@media (max-width:1439px){
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-8{
        width:400px;
        height:463px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-8 img:first-child{
        max-width:269px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-8 img:last-child{
        max-width:319px;
    }
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-8{
        width:240px;
        height:268px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-8 img:first-child{
        max-width:176px;
    }
    .o-list-modules .o-list-modules-item--bundle-images .o-images.position-8 img:last-child{
        max-width:142px;
    }
}
.o-list-modules .o-list-modules-item--bundle-images .o-images.position-1 img:nth-child(1),.o-list-modules .o-list-modules-item--bundle-images .o-images.position-3 img:nth-child(1),.o-list-modules .o-list-modules-item--bundle-images .o-images.position-5 img:nth-child(1),.o-list-modules .o-list-modules-item--bundle-images .o-images.position-6 img:nth-child(1){
    top:0px;
    right:0px;
}
.o-list-modules .o-list-modules-item--bundle-images .o-images.position-1 img:nth-child(2),.o-list-modules .o-list-modules-item--bundle-images .o-images.position-3 img:nth-child(2),.o-list-modules .o-list-modules-item--bundle-images .o-images.position-5 img:nth-child(2),.o-list-modules .o-list-modules-item--bundle-images .o-images.position-6 img:nth-child(2){
    left:0px;
    bottom:0px;
}
.o-list-modules .o-list-modules-item--bundle-images .o-images.position-2 img:nth-child(1),.o-list-modules .o-list-modules-item--bundle-images .o-images.position-7 img:nth-child(1),.o-list-modules .o-list-modules-item--bundle-images .o-images.position-8 img:nth-child(1){
    left:0px;
    bottom:0px;
}
.o-list-modules .o-list-modules-item--bundle-images .o-images.position-2 img:nth-child(2),.o-list-modules .o-list-modules-item--bundle-images .o-images.position-7 img:nth-child(2),.o-list-modules .o-list-modules-item--bundle-images .o-images.position-8 img:nth-child(2){
    top:0px;
    right:0px;
}
.o-list-modules .o-list-modules-item--bundle-images .o-images.position-4 img:nth-child(1){
    top:0px;
    left:0px;
}
.o-list-modules .o-list-modules-item--bundle-images .o-images.position-4 img:nth-child(2){
    right:0px;
    bottom:0px;
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-flag .o-flag-content{
        columns:1;
        column-gap:0px;
    }
}
.o-list-modules .o-list-modules-item--bundle-flag .o-flag-content .o-item-text{
    font-family:"Atlan-Regular",sans-serif;
    font-size:16px;
    line-height:24px;
    text-align:left;
}
.o-list-modules .o-list-modules-item--bundle-flag .o-flag-content .o-item-text strong{
    font-family:"Atlan-Bold",sans-serif;
}
.o-list-modules .o-list-modules-item--bundle-flag .o-flag-content .o-item-text p{
    margin-bottom:20px;
}
.o-list-modules .o-list-modules-item--bundle-flag .o-flag-content .o-item-text p:last-child{
    padding-bottom:220px;
}
@media (max-width:1439px){
    .o-list-modules .o-list-modules-item--bundle-flag .o-flag-content .o-item-text p:last-child{
        padding-bottom:160px;
    }
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-flag .o-flag-content .o-item-text p:last-child{
        padding-bottom:0px;
        margin-bottom:25px;
    }
}
.o-list-modules .o-list-modules-item--bundle-flag .o-flag-content .o-item-title{
    font-family:"Camila-Medium",serif;
    font-size:24px;
    line-height:24px;
    display:block;
    margin-bottom:20px;
    text-transform:uppercase;
}
.o-list-modules .o-list-modules-item--bundle-flag .o-image{
    position:absolute;
    right:135px;
    bottom:-102px;
    width:337px;
}
@media (max-width:1439px){
    .o-list-modules .o-list-modules-item--bundle-flag .o-image{
        right:90px;
        width:270px;
    }
}
@media (max-width:1023px){
    .o-list-modules .o-list-modules-item--bundle-flag .o-image{
        position:relative;
        right:auto;
        bottom:auto;
        width:100%;
        text-align:center;
    }
}
.o-list-modules .o-list-modules-item--bundle-flag .o-image img{
    width:100%;
}
.ops-scroll-animation{
    opacity:0;
    -moz-transition:all 0.5s ease-out;
    -webkit-transition:all 0.5s ease-out;
    -o-transition:all 0.5s ease-out;
    transition:all 0.5s ease-out;
    -moz-transition-delay:0.2s;
    -webkit-transition-delay:0.2s;
    -o-transition-delay:0.2s;
    transition-delay:0.2s;
    -webkit-backface-visibility:hidden;
    -moz-backface-visibility:hidden;
    backface-visibility:hidden;
}
.ops-scroll-animation--from_bottom{
    -moz-transform:translate3d(0px,50px,0px);
    -webkit-transform:translate3d(0px,50px,0px);
    -o-transform:translate(0px,50px);
    -ms-transform:translate(0px,50px);
    transform:translate3d(0px,50px,0px);
}
.ops-scroll-animation--from_top{
    -moz-transform:translate3d(0px,-50px,0px);
    -webkit-transform:translate3d(0px,-50px,0px);
    -o-transform:translate(0px,-50px);
    -ms-transform:translate(0px,-50px);
    transform:translate3d(0px,-50px,0px);
}
.ops-scroll-animation--from_left{
    -moz-transform:translate3d(-50px,0px,0px);
    -webkit-transform:translate3d(-50px,0px,0px);
    -o-transform:translate(-50px,0px);
    -ms-transform:translate(-50px,0px);
    transform:translate3d(-50px,0px,0px);
}
.ops-scroll-animation--from_right{
    -moz-transform:translate3d(50px,0px,0px);
    -webkit-transform:translate3d(50px,0px,0px);
    -o-transform:translate(50px,0px);
    -ms-transform:translate(50px,0px);
    transform:translate3d(50px,0px,0px);
}
.ops-scroll-animation.ops-in-view{
    opacity:1;
    -moz-transform:translate3d(0px,0px,0px);
    -webkit-transform:translate3d(0px,0px,0px);
    -o-transform:translate(0px,0px);
    -ms-transform:translate(0px,0px);
    transform:translate3d(0px,0px,0px);
}
