.masonry-filter.scrollableProductsFilters {
    position: sticky;
    top: 60px;
    z-index: 150;
    background-color: #fff;
}
.masonry-filter.scrollableProductsFilters > .filter-action-center {
    padding-bottom: 0 !important;
}

@media only screen and (max-width: 991px) {
	.masonry-filter.scrollableProductsFilters > .filter-action-center > ul {
        margin-top: 0;
    }
    .masonry-filter.scrollableProductsFilters {
        top: 0;
    }
}
