.entry-image{
  min-height: 450px !important;
}

.entry-summary {
  position: initial !important;
}

.product-thumbnail{
  float: left;
  display: flex;
  flex-direction: column;
  width: 20%;
  align-items: center;
}

.product-thumbnail .thumb{
  margin-bottom: 10px;
  width: 72%;
}

.image-container{
  float: left;
  position: relative;
  /*min-height: 520px;*/
  width: 80%;
  overflow: hidden;
}

.image-container .img{
  position: absolute;
  opacity: 0;
  z-index: 1;
  transition: opacity .3s ease-in-out;
}

.image-container .img.active{
  opacity: 1;
  z-index: 5;
}

@media screen and (max-width: 768px){
  .single-product-images{
    display: flex;
    flex-direction: column-reverse;
  }

  .product-thumbnail{
    margin-top: 10px;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .product-thumbnail .thumb {
    width: 60px;
  }
}