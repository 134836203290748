.margin_Bio {
    margin-bottom: 16px;
}

.social{
    display: inline-block;
    margin: 0 auto 20px;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    height: 14px;
}

.social-item {
    padding: 0 25px;
    display: inline-block;
}

.social-link {
    display: block;
    line-height: 0;
    text-decoration: none;
    color: #000;
    font-size: 24px;
}
