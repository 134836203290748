.RatingReviewsFilter{
    margin-top: 30px;
}

.RatingReviewsFilter select{
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;
    font-size: 16px;
    appearance: menulist;
    padding: 12px;
    font-family: "Camila-Medium", serif;
    max-width: 100%;
    border: 2px solid #EFEBE9;
}

@media (max-width: 665px){
    
    .RatingReviewsFilter select{
        width: 100%;
    }
}
