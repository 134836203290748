/********-------
TYPE 1
*************/
.FeaturedPosts.type-1 .pt-7{
    display: none;
}
.FeaturedPosts.type-1 .container{
    width: 100% !important;
    padding: 0 !important;
    overflow: hidden;
}

.FeaturedPosts.type-1 .item{
    padding: 3px;
    height: 400px;
    overflow: hidden;
    color: white;
}

.FeaturedPosts.type-1 .item .img-ctn{
    background-color: black;
}

.FeaturedPosts.type-1 .item .img-ctn img{
    width: 100% !important;
    opacity: .7;
}

.FeaturedPosts.type-1 .row {
    margin-left: 2px;
    margin-right: 2px;
}

.FeaturedPosts.type-1 h4{
    position: absolute;
    top: 240px;
    left: 30px;
    
}

.FeaturedPosts.type-1 .richtext-ctn{
    position: absolute;
    top: 80px;
    left: 30px;
    font-size: 24px;
    line-height: 24px;
    width: 210px;
    text-align: left;

}

/********-------
TYPE 2
*************/

.FeaturedPosts.type-2 h4{
    font-family: "Camila-Medium", serif;
    font-weight: lighter;
    color: #4A4A4A;
    position: absolute;
    top: 8%;
    width: 80%;
    height: 84%;
    margin: 0;
    background-color: rgba(255,255,255,0.85);
    left: 10%;
    font-size: 36px;
    display: grid;
    align-items: center;
    padding: 10px;
}

.FeaturedPosts.type-2 h4:before{
    content: "";
    width: 103%;
    height: 103%;
    position: absolute;
    border: 1px solid white;
    left: -1.5%;
}

.FeaturedPosts.type-2 a:hover {
    color: inherit
}

.FeaturedPosts.type-2 .img-ctn img{
    transition: all .3s ease-in-out;
    width: 100%;
}

.FeaturedPosts.type-2 a:hover .img-ctn img{
    transform: scale(1.2);
}

.FeaturedPosts.type-2 .item{
    float: left;
    margin-bottom: 15px;
}

.FeaturedPosts.type-2 .img-ctn{
    overflow: hidden;
}

/********-------
TYPE 3
*************/

@media (max-width: 768px){
    .FeaturedPosts.type-3 .item{
        width: 80%;
        display: block;
        margin: 15px auto;
    }
}


.FeaturedPosts.type-3 h4{ 
    position: absolute;
    width: 81%;
    height: 91%;
    top: 4%;
    left: 10%;
    border: 1px solid white;
    margin: 0;
}

.FeaturedPosts.type-3 .img-ctn {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding: 100% 0 0 0;
}

.FeaturedPosts.type-3 .img-ctn img{
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
}


/********-------
TYPE 4
*************/
.FeaturedPosts.type-4 .container{
    padding: 15px 70px;
    background: #f7f2df url(../../assets/images/Theme/engagements_bg.png) no-repeat left top;
}

.FeaturedPosts.type-4 .section-ctn .row{
    display: flex;
    justify-content: center;
}

.FeaturedPosts.type-4 .item{
    border: 1px solid rgba(76,90,26,0.5);
    margin-right: 20px;
    padding-top: 15px;
    padding-bottom: 10px;
}

.FeaturedPosts.type-4 .item:last-child{
    margin-right: 0;
}

.FeaturedPosts.type-4 .title {
    padding-top: 95px;
    background-image: url(../../assets/images/Theme/savon.png);
    background-position: top 18px center;
    background-repeat: no-repeat;
    width: 100%;
    font-family: "Camila-Medium",serif;
    font-size: 24px;
    line-height: 24px;
    display: block;
    margin-bottom: 20px;
    text-transform: uppercase;
    color:#21552F;
    font-weight: lighter;
}

.FeaturedPosts.type-4 h4{
    font-family: 'Camila-Medium', serif;
    font-size: 48px;
    line-height: 48px;
    font-weight: lighter;
}

.FeaturedPosts.type-4 .img-ctn{
    margin-bottom: 15px;
}

@media (max-width: 768px){
    .FeaturedPosts.type-4 .item{
        margin: auto;
        margin-bottom: 15px;
    }
    .FeaturedPosts.type-4 .section-ctn .row{
        flex-direction: column;
    }
}

/********-------
TYPE 5
*************/

.FeaturedPosts.type-5 .section-ctn .container{
    background: #f7f2df url(../../assets/images/Theme/engagements_bg.png) no-repeat left top;
}

.FeaturedPosts.type-5 .section-ctn .row{
    display: flex;
    justify-content: space-around;
    padding: 60px 0;
}

.FeaturedPosts.type-5 a:hover {
    color: inherit;
    cursor: initial;
}

.FeaturedPosts.type-5 .item{
    width: 300px;
    height: 437px;
    border: 1px solid rgba(76,90,26,0.5);
    text-align: center;
    background: #F7F2DF;
    padding: 25px 15px 0;
}

.FeaturedPosts.type-5 .img-ctn{
    height: 75px;
}

.FeaturedPosts.type-5 h4{
    font-family: "Camila-Medium",serif;
    font-size: 24px;
    line-height: 24px;
    color: #000;
    text-transform: uppercase;
    height: 93px;
    font-weight: lighter;
}

.FeaturedPosts.type-5 .richtext-ctn p{
    font-size: 15px;
    margin-bottom: 5px;
}

@media (max-width: 768px){
    .FeaturedPosts.type-5 .item{
        margin: auto;
        margin-bottom: 15px;
    }
    .FeaturedPosts.type-5 .section-ctn .row{
        flex-direction: column;
    }
}