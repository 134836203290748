.custom_row_block{
    margin: 4% 0;
}
.item__row__custom {
    position: relative;
    max-width: 822px !important;
    margin: 0 auto;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
}
.item__row__custom .c-r {
    float: right;
    padding: 10px; }
.item__row__custom .c-r .text-cn > div {
    background-color: #fff; }
.item__row__custom .text-cn {
    top: 0;
    bottom: 0; }
.item__row__custom .text-cn > div {
    height: 100%;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center; }
.item__row__custom .text-cn > div > div {
    padding: 20px; }
.item__row__custom .c-l,
.item__row__custom .c-r {
    padding: 10px; }
.item__row__custom .c-l.text-cn {
    text-align: right; }
.item__row__custom .c-l.text-cn > div {
    background-color: #f8e5ca; }
.item__row__custom h1 {
    margin-top: 0;
    margin-bottom: 24px;
    font-size: 2.225rem;
    line-height: 40px; }
.item__row__custom p {
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 24px;
    font-weight: 300;
    color: #000; }
.item__row__custom img {
    max-width: 100%;
    vertical-align: middle;
    border: 0;
    outline: none;
    height: auto;}
