
.custom__height{
  height: 0;
  text-align: center;
}

.mobile-mode .custom__height{
  height: 70px;
  width: 100%;
}

.menu-hd>li>.sub-menu>li.col-md-5:nth-child(2) li {
  width: 50%;
  display: inline-block;
  padding: 0 10px 0 0;
}
{
  position: relative;
  z-index: 7;
}
.nav-hd{
  margin: 0 auto;
  font-family: "Atlan-Semibold", Helvetica, Arial, sans-serif;
  border-bottom: 1px solid #f0f0f0;
}
.row_custom_small + .row_custom_small {
  margin-top: 0;
}

.nav-hd .slicknav_menu {
  display: none !important;
  top: 0;
  position: absolute;
  width: 100%;
  font-size: 16px;
  padding: 0;
  z-index: 1000;
  transition: all .3s ease; 
}
.nav-hd .slicknav_menu [class*=col-] {
  width: 100% !important;
  float: none; }
.nav-hd .slicknav_menu .child-nav {
  position: relative;
  padding: 12px 30px; }
.nav-hd .slicknav_menu .slicknav_btn {
  margin: 0 0 6px;
  text-decoration: none;
  position: absolute;
  display: block;
  vertical-align: middle;
  padding: 0;
  line-height: 1.125em;
  top: 30px;
  right: 22px;
  z-index: 9; }
.nav-hd .slicknav_menu .slicknav_btn .slicknav_menutxt {
  display: none;
  color: #000;
  font-weight: 700;
  line-height: 1.188em;
  float: left; }
.nav-hd .slicknav_menu .slicknav_btn .slicknav_icon {
  float: right;
  margin: 0; }
.nav-hd .slicknav_menu .slicknav_btn .slicknav_icon .slicknav_icon-bar {
  background-color: #000;
  transition: all .3s ease;
  display: block;
  width: 1.5em;
  height: .18em;
  margin-bottom: 5px; }
.slicknav_btn.slicknav_open .slicknav_icon-bar {
  background-color: #000;
  transform: rotate(-45deg);
  transform-origin: 30% 50%;
  width: 30px;
  margin-top: 5px;
}
.slicknav_btn.slicknav_open .slicknav_icon-bar:first-child {
  transform: rotate(45deg);
}
.slicknav_btn.slicknav_open .slicknav_icon-bar {
  background-color: #000;
  transform: rotate(-45deg);
  transform-origin: 30% 50%;
  width: 30px;
  margin-top: 5px;
}
.slicknav_btn.slicknav_open .slicknav_icon-bar:last-child{
  opacity: 0;
}
.nav-hd .slicknav_menu .slicknav_nav,
.nav-hd .slicknav_menu .slicknav_nav ul {
  list-style: none;
  overflow: hidden; }
.nav-hd .slicknav_menu .slicknav_nav {
  max-height: calc(100vh - 71px);
  overflow: auto;
  font-size: .875em;
  background-color: #F7F2DF;
  padding: 0;
  margin-top: 71px !important;
  clear: both; }
.nav-hd .slicknav_menu .slicknav_nav:first-child {
  border-top: none; }
.nav-hd .slicknav_menu .slicknav_nav .slicknav_arrow {
  font-size: .8em;
  margin: 0 0 0 .4em;
  display: none; }
.nav-hd .slicknav_menu .slicknav_nav ul {
  padding: 0;
  margin: 0; }
.nav-hd .slicknav_menu .slicknav_nav > li {
  text-align: left;
  border-top: 1px solid #c5c5ca;
  display: block;
  height: 100%;}
.nav-hd .slicknav_menu .slicknav_nav > li:hover {
  background-color: #ebebeb; }
.nav-hd .slicknav_menu .slicknav_nav > li > p {
  padding: 14px; }
.nav-hd .slicknav_menu .slicknav_nav > li > p > a {
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  
  padding: 0;
  margin: 0;
  display: inline; }
.nav-hd .slicknav_menu .slicknav_nav > li > p .slicknav_arrow {
  font-size: .8em;
  margin: 0 0 0 .4em;
  display: none; }
.nav-hd .slicknav_menu .slicknav_nav > li.current a,
.nav-hd .slicknav_menu .slicknav_nav > li:hover a {
  background: #F7F2DF;
}
.nav-hd .slicknav_menu .slicknav_nav ul li,
.nav-hd .slicknav_menu .slicknav_nav > li > a,
.nav-hd .slicknav_menu .slicknav_nav > li > p {
  padding: 14px;
  display: block;
}
.nav-hd .slicknav_menu .slicknav_nav .sub-menu {
  background-color: #F7F2DF !important;
  margin-top: unset !important;
}
.nav-hd .slicknav_menu .slicknav_nav .child-nav {
  position: relative;
  padding: 12px 30px; }
.nav-hd .slicknav_menu .slicknav_nav .child-nav:first-child {
  padding-top: 24px;
  border-top: none; }
.slicknav_menu .child-nav>a:before,
.slicknav_nav >a:before {
  content: '\f067';
  margin-right: 8px;
  vertical-align: middle;
  font-family: FontAwesome;
  font-size: 10px;
}
.slicknav_nav a:last-child:before{
  display: none;
}
.slicknav_nav a {
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  position: relative;
}
.nav-hd .nav-cn {
  display: inline-block;
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  z-index: 1;
}
.nav-hd .nav-cn nav {
  display: block; }
.nav-hd .nav-cn .menu-hd {
  line-height: 14px;
  margin-bottom: 0;
  letter-spacing: .02em;
  padding-left: 0;
  text-align: right;
}
.nav-hd .nav-cn .menu-hd .second_level .menu-item:hover a{
  color: #21552F;
}


.nav-hd .nav-cn .menu-hd .main-parent > a, .nav-hd .nav-cn .menu-hd .main-parent > div{
  margin-bottom: 10px;
}

.nav-hd .nav-cn .menu-hd > li.main-parent:hover > a {
  position: relative;
  
  color: #000000;
  font-size: 13px;
  text-transform: uppercase;
}

.nav-hd .nav-cn .menu-hd > li:first-child { 
  margin-left: 0; }
.nav-hd .nav-cn .menu-hd > li.current-menu-ancestor > a,
.nav-hd .nav-cn .menu-hd > li.current-menu-item > a {
  color: #477824 !important; }
.nav-hd .nav-cn .menu-hd > li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 10px 0 0 0 ;
  float: left;
}
.nav-hd .nav-cn .menu-hd > li.align-right{
  float: none;
}

.nav-hd .main-parent:hover > a{
  border-bottom: 5px solid #F7ECC5 !important;
}
.nav-hd .nav-cn .menu-hd > li:hover .sub-menu {
  display: block; }
.nav-hd .nav-cn .menu-hd > li > .sub-menu {
  width: max-content !important;
  display: none;
  position: absolute;
  top: 52px;
  background-color: #F7F2DF;
  z-index: 99;
  text-align: left;
  padding: 20px 15px 10px 20px;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 0.461538em 0.923077em rgba(0, 0, 0, 0.175);
}
.nav-hd .nav-cn .menu-hd > li > .sub-menu > li {
  position: relative;
  list-style-type: none;
  margin-bottom: 15px;
}
.nav-hd .nav-cn .menu-hd > li > .sub-menu > li > a, .nav-hd .nav-cn .menu-hd > li > .sub-menu > li > div {
  display: block;
  position: relative;
  font-size: 13px;
  text-transform: uppercase;
}

.nav-hd .nav-cn .menu-hd > li > .sub-menu > li:last-child:after{
  display: none
}
.nav-hd .nav-cn .menu-hd > li > .sub-menu > li:last-child:after {
  display: none; }

.nav-hd .nav-cn .menu-hd > li > .sub-menu .sub-menu li {
  line-height: 24px;
  list-style-type: none; }
.nav-hd .nav-cn .menu-hd > li:hover:last-child {
  background-color: unset; }
.nav-hd .nav-cn .menu-hd > li > a {
  display: block;
  position: relative;
  padding: 7px 10px;
  
  color: #333;
  font-size: 13px;
  text-transform: uppercase;
}
.nav-hd .nav-cn .menu-hd > li.main-parent > a{
  border-bottom: 5px solid #ffffff;
}
.nav-hd .nav-cn .menu-hd > li .sub-menu {
  display: none; }
.nav-hd .nav-cn .menu-hd a {
  
  text-decoration: none;
  transition: all .3s ease; }

.nav-hd .nav-cn .menu-hd li > .sub-menu > li {
  position: relative;
}
.menu-item-has-children > a:first-child{
  text-transform: uppercase;
}
.nav-hd .nav-cn .menu-hd li > .sub-menu > li > a {
  font-weight: 400;
   }
.nav-hd .nav-cn .menu-hd li:hover > .sub-menu{
  display: block !important;
}
.menu-hd li > .sub-menu .buscar__custom{
  width: 100% !important;
}
.nav-hd .nav-cn .menu-hd li > .sub-menu > li .sub-menu, .nav-hd .nav-cn .menu-hd .second_level {
  /* margin: 10px 0; */
  width: 100% !important;
  padding: 0 10px;
  font-family: "Atlan-Regular", Helvetica, Arial, sans-serif;
}
.nav-hd .nav-cn .menu-hd li > .sub-menu > li .sub-menu li {
  font-weight: 300;
  border-top: 1px solid #d2d2d2;
  line-height: 35px;
  width: 100%;}
  .nav-hd .nav-cn .menu-hd li > .sub-menu > li .sub-menu li:first-child{
    border-top: none;
  }
.nav-hd .nav-cn .menu-hd li > .sub-menu > li .sub-menu li a {
  padding: 4px 0;
  font-size: 15px;
  text-transform: none;
}
.nav-hd .nav-cn .menu-hd li .child-nav {
  min-height: 360px; }
.nav-hd .nav-cn .menu-hd li .no_border__custom:after {
  display: none; }

.nav-hd .nav-cn .menu-hd > li:nth-child(2) > .sub-menu > li{
  width: auto;
}
.nav-hd .nav-cn .menu-hd li:nth-child(2) > .sub-menu {
  max-width: inherit;
}
.nav-hd .nav-cn .menu-hd li:nth-child(2) > .sub-menu li {
  line-height: 24px; }

.nav-hd .nav-cn .menu-hd li:nth-child(3) .child-nav {
  min-height: 170px; }
li.no_border__custom{
  padding-right: 0px;
}
li.no_border__custom a{
  font-size: 15px;
}
li.no_border__custom .sub-menu {
  width: auto !important;
}
.nav-hd > * {
  float: none;
  display: inline-block;
  vertical-align: bottom; }

.nav-hd .logo {
  position: relative;
  z-index: 1001;
  display: inline-block;
  max-width: 150px;
  top: -50px;
  transition: all .3s ease-in-out;
}

.navbar-small .nav-hd .logo{
  width: 110px;
  top: -25px;
}

@media (max-width: 1180px) {
  .nav-hd .logo{
    top: -100px;
    width: 120px;
  }
}
.nav-hd .nav-cn .menu-hd li:nth-child(1) .sub-menu li:nth-child(2) .sub-menu li{
  padding: 0 10px 0 0;
}

.nav-hd .nav-cn .menu-hd > li:nth-child(1) .sub-menu > .col-md-4{
  height: auto;
  min-width: 228px;
}

.mobile-mode .nav-hd .logo{
  width: 90px;
  top: 0;
}

@media (min-width: 350px) {
  .slicknav_menu {
    top: 46px; }
}
@media (min-width: 1005px) {
  .slicknav_menu {
    display: none !important; }
}

.menu-hd li:hover .sub-menu.first_level,
.menu-hd li:hover .sub-menu.first_level{
  display: inline-flex !important;
  flex-direction: column;
  min-width: 200px;
}
li.menu-item.col-md-4.menu-item-has-children:nth-child(n+3)::after{
  display: none !important;
}

.menu-hd li:first-child .sub-menu{
  width: 200px !important;
}

.cat_nav:hover{
  cursor: pointer;
}

.menu-hd>li>.sub-menu>li.col__5:nth-child(2) li {
  width: 50%;
  display: inline-block;
  padding: 0 10px 0 0;
} 