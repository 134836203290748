.navbar-default-container .container {
    width: 100% !important;
}

.header-type-classic .primary-nav>li.menu-item-has-children>a {
    padding: 0 !important;
    letter-spacing: 1px;
    line-height: 23px !important;
    transition: initial;
    border-bottom: 5px solid #ffffff;
}

.header-type-classic .primary-nav li.menu-item-has-children {
    padding: 15px 13px 13px 13px;
}

.header-type-classic .primary-nav li.menu-item-has-children:hover>a {
    border-bottom: 5px solid #F7ECC5
}

.navbar-container .navbar-header{
    width: 100%;
}

.navbar-container .navbar-brand{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -56px;
    transition: all .3s ease-in-out;
    width: 149px;
    z-index: 1050;
}

.primary-nav>li.menu-item-has-children{
    float: left; 
}

.primary-nav>li.menu-item-has-children.align-right{
    float: none;
}

.header-type-classic .navbar-default .primary-navbar-collapse .navbar-nav{
    text-align: right;
}

/*** Overhead ***/
.OverHead {
    text-align: right;
    margin-top: 40px;
    margin-bottom: 38px;
    transition: all .3s ease-in-out;
}

.OverHead .col-md-12{
    float: none;
}

.OverHead .helv_rom{
    margin-right: 15px;
    text-transform: uppercase;
    font-size: 12px;
    color: #9B9B9B;
}

.OverHead .helv_rom i{
    margin-right: 4px;
}

.OverHead .helv_rom i:not(.fa-envelope){
    color: #A5C03F;
}

header.navbar-small .OverHead{
    opacity: 0;
    margin-top: 0;
    margin-bottom: 8px;
}

header.navbar-small .navbar-brand {
    width: 98px;
    top: -12px;
}

.navbar-brand.small-logo{
    width: 98px;
    top: -86px;
}

header.mobile-mode .OverHead{
    display: none;
}

header.mobile-mode  .navbar-brand{
    width: 80px;
    top: 17px;
}

header.mobile-mode ul.dropdown-menu a{
    padding-left: 40px !important;
}