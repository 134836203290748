.SideBySideCarousel{
    font-family: "Camila-Semibold",serif;
    height: calc((100vw / 2) - 40px);
    overflow: hidden;
}
.SideBySideCarousel .slideItem{
    height: calc((100vw / 2) - 40px);
    display: flex;
    flex-direction: row;
}
.SideBySideCarousel .side {
    width: 50%;
}
.SideBySideCarousel .imgContent{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}
.SideBySideCarousel .slideItem .rightPart{
    background-color: #cfddbb;
    text-align: center;
    color: black;
    padding: 4%;
}
.SideBySideCarousel .slideItem .rightPart.pair{
    background-color: #dfcda3;
}
.SideBySideCarousel .containerBorder{
    border: 1px solid white;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
}
.SideBySideCarousel .rightPart img{
    margin-top: 12%;
    max-height: 20%;
    width: auto;
    display: initial;
}
.SideBySideCarousel .rightPart .title{
    font-size: 2vw;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    position: relative;
}
.SideBySideCarousel .rightPart .number{
    content: attr(data-number);
    color: #e8eff2;
    position: absolute;
    top: 10%;
    font-size: 11vw; 
    font-weight: 600;
}
.SideBySideCarousel .rightPart .text{
    font-family: "Atlan-Regular",sans-serif;
    color: #4a4a4a;
    margin-top: 20px;
    font-size: 1vw;
    max-width: 600px;
    text-align: left;
    line-height: 1.5em;
}

.SideBySideCarousel .owl-dots {
    margin-top: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.SideBySideCarousel .owl-dots .owl-dot {
    display: block;
    background: #cfddbb;
    width: 40px;
    height: 40px;
    margin: 10px auto;
    position: relative;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.2);
    font-size: 25px;
}
.SideBySideCarousel .owl-dots .owl-dot.active{
    background: #a5c03f;
    width: 60px;
    height: 60px;
    font-size: 40px;
}

/*** MOBILE ***/

@media (max-width: 889px){
    .SideBySideCarousel{
        height: 420px;
    }
    .SideBySideCarousel .slideItem{
        height: 360px;
    }
    .SideBySideCarousel .leftPart {
        display: none;
    }
    .SideBySideCarousel .side {
        width: 100%;
    }
    .SideBySideCarousel .rightPart .number{
        font-size: 80px;
        top: 7%;
    }
    .SideBySideCarousel .rightPart .title{
        font-size: 22px;
    }
    .SideBySideCarousel .rightPart img{
        margin-top: 2%;
    }
    .SideBySideCarousel .rightPart .text{
        font-size: 12px;
    }
    .SideBySideCarousel .owl-dots {
        position: initial;
        transform: none;
        margin-top: 10px;
    }
    .SideBySideCarousel .owl-dots .owl-dot{
        display: inline-block;
        margin:0 7px;
        width: 30px;
        height: 30px; 
        font-size: 19px;
        box-shadow: none;
    }
    .SideBySideCarousel .owl-dots .owl-dot.active{
        width: 30px;
        height: 30px;
        font-size: 19px;
    }
    .SideBySideCarousel .owl-item{
        /* Hack fix */
        left: 0 !important;
    }
}
