.RandR {
  background: #fff;
}
.title-review {
  text-align: left;
  font-size: 2rem;
  margin: 30px 0 10px;
  width: 100%; }

.title-review,
.comment-reply-title.same__font,
.comment-notes,
#reviews #review_form_wrapper .Legal-form ul li,
.title-review, .comment-reply-title.same__font, .comment-notes, #reviews #review_form_wrapper .Legal-form ul li strong,
.title-review, .comment-reply-title.same__font, .comment-notes, #reviews #review_form_wrapper .Legal-form ul li,
#reviews #review_form_wrapper .Legal-form p,
#reviews #review_form_wrapper .Legal-form ul li a,
#reviews #review_form_wrapper .comment-form .form-submit{
  font-family: "Poppins", Helvetica, Arial, sans-serif;
}
.separate{
  width: 60%;
  margin: 0 auto;
  border-bottom: 1px solid #e1e1e1;
}

.same__font {
  font-size: .875rem; }

.another__font {
  font-size: 1.375rem; }

#reviews {
  margin-top: 34px;}
  #reviews p {
    margin-bottom: 10px; }
  #reviews #comments .Reviews-title {
    display: none;
    font-size: 1.75rem;}
  #reviews #comments .noreviews {
    line-height: 30px; }
  #reviews #review_form_wrapper .comment-reply-title {
    line-height: 22px; }
  #reviews #review_form_wrapper .comment-form {
    line-height: 30px; }
    #reviews #review_form_wrapper .comment-form .comment-form-rating {
      line-height: 22px; }
      #reviews #review_form_wrapper .comment-form .comment-form-rating .stars {
        display: inline-block;
        margin-left: 8px;
        line-height: 30px; }
        #reviews #review_form_wrapper .comment-form .comment-form-rating .stars a {
          padding: 0 4px; }
          #reviews #review_form_wrapper .comment-form .comment-form-rating .stars a:hover {
            text-decoration: none; }
          #reviews #review_form_wrapper .comment-form .comment-form-rating .stars a:focus > i, #reviews #review_form_wrapper .comment-form .comment-form-rating .stars a:active > i {
            color: #000; }
          #reviews #review_form_wrapper .comment-form .comment-form-rating .stars a:before {
            transition: all .3s ease; }
#reviews #review_form_wrapper .comment-form .comment-form-rating .stars a i:hover{
  cursor: pointer;
}
    #reviews #review_form_wrapper .comment-form .comment-form-comment {
      line-height: 30px; }
      #reviews #review_form_wrapper .comment-form .comment-form-comment textarea {
        resize: none;
        width: 100%;
        height: 166px;
        background-color: transparent;
        border: 1px #000 solid;
        padding: 12px 12px 8px;
        font-size: .9375rem;
        color: #000;
        font-family: "HelveticaNeueLTStd",Arial,Verdana;
        outline: 0;
        display: block;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 0;
        -ms-border-radius: 0;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        -khtml-border-radius: 0;
        overflow: auto;
        vertical-align: top;
        margin: 0; }
        #reviews #review_form_wrapper .comment-form .comment-form-comment textarea:hover, #reviews #review_form_wrapper .comment-form .comment-form-comment textarea:focus, #reviews #review_form_wrapper .comment-form .comment-form-comment textarea:active {
          background-color: rgba(0, 0, 0, 0.02);
          border-color: #477824; }
    #reviews #review_form_wrapper .comment-form .input__nombre {
      width: 100%;
      margin: 0 !important; }
      #reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author {
        width: 100%;
        margin-bottom: 0; }
        #reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author input {
          width: 100%;
          background-color: transparent;
          border: 1px #000 solid;
          padding: 3px 10px 3px;
          font-size: 0.873rem;
          color: #000;
          font-family: "HelveticaNeueLTStd",Arial,Verdana;
          outline: 0;
          display: block;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          -o-box-sizing: border-box;
          -ms-box-sizing: border-box;
          box-sizing: border-box;
          border-radius: 0;
          -ms-border-radius: 0;
          -moz-border-radius: 0;
          -webkit-border-radius: 0;
          -khtml-border-radius: 0; }
          #reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author input:hover, #reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author input:focus, #reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author input:active {
            background-color: rgba(0, 0, 0, 0.02);
            border-color: #477824; }
    #reviews #review_form_wrapper .comment-form .pprivacy {
      font-size: .875rem;
      line-height: 22px;
      margin-top: 10px;
      display: inline-flex;}
      #reviews #review_form_wrapper .comment-form .pprivacy .privacyBox {
        position: relative;
        margin-right: 10px;
        vertical-align: middle;
        width: 16px;
        height: 16px; }
      #reviews #review_form_wrapper .comment-form .pprivacy a {
        font-weight: 700;
        color: #000; }
    #reviews #review_form_wrapper .comment-form .form-submit {
      margin-top: 10px; }
      #reviews #review_form_wrapper .comment-form .form-submit .btn_custom_oro {
        display: inline-block;
        margin-bottom: 0;
        text-align: center;
        cursor: pointer;
        background-image: none;
        white-space: nowrap;
        padding: .5714285714285714rem 2rem;
        font-size: 13px;
        line-height: 1.6;
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        border-radius: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        outline: none;
        background: transparent;
        border: 2px solid #333;
        text-transform: uppercase;
        color: #333;
        position: relative;
        font-weight: 600;
        text-transform: uppercase;
        height: 36px;
        vertical-align: top;
        padding-left: 1.5em;
        padding-right: 1.5em;
        color: #fff;
        background-color: #333;
        border-color: #333;
      }
        #reviews #review_form_wrapper .comment-form .form-submit .btn_custom_oro:hover {
          background-color: #595959;
          color: #fff; }
  #reviews #review_form_wrapper .Legal-form {
    width: 100%;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 28px; }
    #reviews #review_form_wrapper .Legal-form p {
      margin: unset;
      font-size: 12px; }
    #reviews #review_form_wrapper .Legal-form ul {
      padding: 0; }
      #reviews #review_form_wrapper .Legal-form ul li {
        list-style: none;
        font-size: .75rem;
        line-height: 22px;
      }
        #reviews #review_form_wrapper .Legal-form ul li a {
          color: #000;
          text-decoration: underline; }
#reviews .comment-notes{
  font-size: 0.675em;
}
#reviews .alert-success{
  margin-top: 12px;
}

/*# sourceMappingURL=ratings.css.map */

/* COMMENTS */
#comments li{
  padding-bottom: 20px;
  border-bottom: 1px solid #979797;
  border: 0;
  vertical-align: baseline;
  list-style: none;
  list-style-image: none;
}

#comments li .meta {
  overflow: hidden;
  margin-bottom: 10px;
}

#comments li .meta strong{
  font-size: .875rem;
  font-weight: 400;
}

#comments li .meta time{
  float: right;
  font-size: .875rem;
  font-weight: 300;
}

#comments .comment_container{
  padding: 20px;
  margin-top: 20px;
  background: #F8F8F8;
}

#comments .commentlist{
  padding: 0 18px;
}

#comments .StarRating {
  display: inline-block;
  margin-right: 15px;
}

#comments li p{
  line-height: 22px;
  font-size: 0.975rem;
  font-weight: 300;
  font-family: unset;
  margin-bottom: 0;
}

#comments .description{
  font-weight: 300;
  font-family: "Camila-SemiboldIt",serif;
}

#comments .description p{
  font-size: 17px;
  line-height: 25px;

}

#comments .review__author span{
  font-size: 18px;
  line-height: 20px;
  color: #C3B37B;
}

#comments .star-rating{
  font-size: .75rem;
  margin-top: -10px;
}

#comments .star-rating i{
  margin-right: 5px;
}

#comments i.fa, #comments i.far{
  font-size: 0.875em;
}

.comments-area .form-allowed-tags {
  display: none
}

.comments-area .commentst-title {
  margin-bottom: 1em
}

.comments-area .comment-list {
  margin-top: 30px;
  list-style: none
}

.comments-area .comment-list>li.comment {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e1e1e1
}

.comments-area .comment-list>li.comment .children .comment-wrap {
  position: relative;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #e1e1e1
}

.comments-area .comment-paginate .paginate_links {
  float: left
}

.comments-area .comment-paginate .paginate_links .page-numbers {
  padding: 0.1em 0.7em;
  font-size: 85%
}

.comments-area .comment-paginate .paginate_links .next,
.comments-area .comment-paginate .paginate_links .prev,
.comments-area .comment-paginate .paginate_links .next:hover,
.comments-area .comment-paginate .paginate_links .prev:hover {
  border-color: transparent
}

.comment-list,
.comment-list .children {
  margin: 0;
  padding: 0;
  list-style: none
}

@media (min-width:768px) {
  .comment-list .children {
    padding-left: 3.2142857142857144em;
    position: relative
  }
}

li.comment {
  margin: 0;
  padding: 0
}

.comment-img {
  float: left
}

.comment-block {
  padding: 0;
  margin: 0 0 0 96px
}

.comment-header {
  padding-bottom: 30px
}

.comment-header span.pull-right {
  margin-top: 6px
}

.comment-content {
  position: relative
}

.comment-author {
  color: #333;
  font-weight: 600;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 1em
}

.comment-author a:hover {
  color: #dfcda3
}

.ispostauthor {
  background: #8C8C8C;
  color: #fff;
  font-size: 85%;
  padding: .14285714285714285em .5714285714285714em;
  -webkit-border-radius: 3px;
  border-radius: 3px
}

.comment-meta {
  position: relative;
  margin-top: 5px;
  font-size: 85%;
  color: #777;
  display: block
}

.comment-meta .comment-edit-link {
  text-decoration: underline
}

.comment-pending {
  padding: .5714285714285714rem 1rem;
  text-align: center;
  color: #333;
  background-color: #aaa;
  -webkit-border-radius: 0;
  border-radius: 0
}

.comment-reply-link {
  color: #777;
  text-transform: uppercase;
  font-size: 85%;
  font-weight: 600
}

.comment-reply-link:before {
  content: "\f112";
  position: relative;
  display: inline-block;
  font-family: "FontAwesome";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none
}

.comment-reply-link:before {
  margin-right: 5px
}

.comment-reply-link:hover {
  color: #dfcda3
}

#respond {
  margin-bottom: 2.571429em;
}

#respond .required {
  position: relative;
  color: #444
}

.commentst-title h3 {
  margin-bottom: 1.2em
}

.comment-respond .comment-reply-title {
  margin-top: 2em;
  font-size: 1.56em
}

#commentform {
  margin-bottom: 0
}

.comment-notes,
.logged-in-as {
  margin-bottom: 2em
}

.comment-notes a,
.logged-in-as a {
  font-weight: 600
}

.comment-form-author input,
.comment-form-email input,
.comment-form-url input,
.comment-form-comment input,
.comment-form-author textarea,
.comment-form-email textarea,
.comment-form-url textarea,
.comment-form-comment textarea {
  display: block;
  width: 100%;
  height: 40px;
  padding: .5714285714285714rem 1rem;
  line-height: 1.6;
  color: #444;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e1e1e1;
  -webkit-border-radius: 0;
  border-radius: 0;
  outline: none;
  -webkit-transition: border-color 0.3s, box-shadow 0.3s;
  -o-transition: border-color 0.3s, box-shadow 0.3s;
  transition: border-color 0.3s, box-shadow 0.3s
}

.comment-form-author input:focus,
.comment-form-email input:focus,
.comment-form-url input:focus,
.comment-form-comment input:focus,
.comment-form-author textarea:focus,
.comment-form-email textarea:focus,
.comment-form-url textarea:focus,
.comment-form-comment textarea:focus {
  border-color: #333;
  outline: none
}

.comment-form-author input[disabled],
.comment-form-email input[disabled],
.comment-form-url input[disabled],
.comment-form-comment input[disabled],
.comment-form-author textarea[disabled],
.comment-form-email textarea[disabled],
.comment-form-url textarea[disabled],
.comment-form-comment textarea[disabled],
.comment-form-author input[readonly],
.comment-form-email input[readonly],
.comment-form-url input[readonly],
.comment-form-comment input[readonly],
.comment-form-author textarea[readonly],
.comment-form-email textarea[readonly],
.comment-form-url textarea[readonly],
.comment-form-comment textarea[readonly],
fieldset[disabled] .comment-form-author input,
fieldset[disabled] .comment-form-email input,
fieldset[disabled] .comment-form-url input,
fieldset[disabled] .comment-form-comment input,
fieldset[disabled] .comment-form-author textarea,
fieldset[disabled] .comment-form-email textarea,
fieldset[disabled] .comment-form-url textarea,
fieldset[disabled] .comment-form-comment textarea {
  cursor: not-allowed;
  background-color: #aaa;
  opacity: 1;
  filter: alpha(opacity=100)
}

textarea.comment-form-author input,
textarea.comment-form-email input,
textarea.comment-form-url input,
textarea.comment-form-comment input,
textarea.comment-form-author textarea,
textarea.comment-form-email textarea,
textarea.comment-form-url textarea,
textarea.comment-form-comment textarea {
  height: auto
}

.comment-form-author label,
.comment-form-email label,
.comment-form-url label,
.comment-form-comment label {
  font-weight: 300;
  text-transform: uppercase
}

.comment-form-author,
.comment-form-email,
.comment-form-url,
.comment-form-rating,
.comment-form-comment {
  margin: 0
}

.comment-form-author label,
.comment-form-email label,
.comment-form-url label,
.comment-form-rating label,
.comment-form-comment label {
  font-size: 13px
}

.comment-form-author .required,
.comment-form-email .required,
.comment-form-url .required,
.comment-form-rating .required,
.comment-form-comment .required {
  color: #fe252c;
}

p.comment-form-author,
p.comment-form-email,
p.comment-form-url,
p.comment-form-rating {
  margin-bottom: 15px
}

.comment-form-comment {
  margin-bottom: 10px
}

.comment-form-comment textarea {
  height: 11.428571428571429em;
  resize: none;
  width: 100%;
  margin-top: 0em
}

#review_form .form-submit,
.comment-form .form-submit {
  margin: 0
}

#review_form .form-submit input[type="submit"],
.comment-form .form-submit input[type="submit"] {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: .5714285714285714rem 2rem;
  font-size: 13px;
  line-height: 1.6;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  text-decoration: none;
  background: transparent;
  border: 2px solid #333;
  text-transform: uppercase;
  color: #333;
  position: relative;
  font-weight: 600
}

#review_form .form-submit input[type="submit"] i,
.comment-form .form-submit input[type="submit"] i,
#review_form .form-submit input[type="submit"] span,
.comment-form .form-submit input[type="submit"] span {
  z-index: 2;
  position: relative
}

#review_form .form-submit input[type="submit"].btn-icon-left i,
.comment-form .form-submit input[type="submit"].btn-icon-left i {
  margin-right: 5px
}

#review_form .form-submit input[type="submit"].btn-icon-right i,
.comment-form .form-submit input[type="submit"].btn-icon-right i {
  margin-left: 5px
}

#review_form .form-submit input[type="submit"].btn-icon-right.btn-icon-slide-in,
.comment-form .form-submit input[type="submit"].btn-icon-right.btn-icon-slide-in {
  padding-left: 40px;
  padding-right: 40px;
  position: relative
}

#review_form .form-submit input[type="submit"].btn-icon-right.btn-icon-slide-in span,
.comment-form .form-submit input[type="submit"].btn-icon-right.btn-icon-slide-in span,
#review_form .form-submit input[type="submit"].btn-icon-right.btn-icon-slide-in i,
.comment-form .form-submit input[type="submit"].btn-icon-right.btn-icon-slide-in i {
  -webkit-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out
}

#review_form .form-submit input[type="submit"].btn-icon-right.btn-icon-slide-in i,
.comment-form .form-submit input[type="submit"].btn-icon-right.btn-icon-slide-in i {
  position: absolute;
  right: 40px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  opacity: 0;
  filter: alpha(opacity=0)
}

#review_form .form-submit input[type="submit"].btn-icon-right.btn-icon-slide-in span,
.comment-form .form-submit input[type="submit"].btn-icon-right.btn-icon-slide-in span {
  position: relative;
  left: 0
}

#review_form .form-submit input[type="submit"].btn-icon-right.btn-icon-slide-in:hover span,
.comment-form .form-submit input[type="submit"].btn-icon-right.btn-icon-slide-in:hover span {
  left: -15px
}

#review_form .form-submit input[type="submit"].btn-icon-right.btn-icon-slide-in:hover i,
.comment-form .form-submit input[type="submit"].btn-icon-right.btn-icon-slide-in:hover i {
  opacity: 1;
  filter: alpha(opacity=100);
  right: 25px
}

#review_form .form-submit input[type="submit"].btn-round,
.comment-form .form-submit input[type="submit"].btn-round {
  -webkit-border-radius: 40px;
  border-radius: 40px
}

#review_form .form-submit input[type="submit"]:hover,
.comment-form .form-submit input[type="submit"]:hover,
#review_form .form-submit input[type="submit"]:focus,
.comment-form .form-submit input[type="submit"]:focus {
  color: #fff;
  border-color: #dfcda3;
  background: #dfcda3
}


/*** REVIEWS SUMMARY ***/
.reviewsSummary h3{
  font-family: "Atlan-Semibold", sans-serif;
  font-size: 18px;
}
.reviewsSummary .globalScore .rank {
  font-size: 65px;
  font-weight: 600;
  color: #8f835a;
}

.reviewsSummary .globalScore .rank span {
  font-size: .6em;
  color: #c3b37b;
}

.reviewsSummary .scoreRanking .starsContainer {
  display: inline-block;
  width: 80px;
  font-size: 16px;
  line-height: 16px;
}

.reviewsSummary .scoreRanking .line {
  margin-bottom: 7px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}

.reviewsSummary .scoreRanking .rankBarContainer {
  width: 65%;
  height: 15px;
  margin-top: 4px;
  background-color: #faf5e1;
  display: inline-block;
}

.reviewsSummary .scoreRanking .rankBar {
  position: relative;
  height: 15px;
  background-color: #dfcda3;
}

.reviewsSummary .giveAdvice p {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}

.reviewsSummary .giveAdvice .btn{
  background-color: #dfcda3;
  font-size: 19px;
  display: block;
  color: white;
  padding: 8px 0;
}

.reviewsSummary .giveAdvice .btn:hover{
  background-color: #c3b37b;
}

/*** fix anchor whith fixed nav ***/
#review_form_wrapper{
  padding-top: 100px;
  margin-top: -100px;;
}

.commentlist{
  max-height: 600px;
  overflow:auto;
}

@media (max-width: 665px) {
  .reviewsSummary {
    padding: 0 !important;
  }
  .reviewsSummary .scoreRanking .rankBarContainer {
    width: 50%;
  }
  .reviewsSummary .scoreRanking .line{
    font-size: 12px;
  }

  .reviewsSummary .scoreRanking .starsContainer {
    width: 68px;
    font-size: 13px;
  }
  .commentlist{
    height: auto;
  }
}
