
#portfolio > h2 {
  font-size: 2.25rem;
  line-height: 2rem;
}

@media (min-width: 992px) {
  #portfolio > h2 {
    font-size: 3rem;
    line-height: 2.5rem;
  }
}

.blog-header-logo{
  position: absolute;
  left: 10%;
}

.contain-menu{
  margin-bottom: 12px;
  width: 100%;
  z-index: 2;
}

.menu-search{
  width: 12%;
  border: 0px;
  text-align: right;
  padding: 0px 3px 0px 3px;
}

.contain-menu i{
  color: #454545;
}

.contain-menu i:hover{
  opacity: .7;
}

#mainNav {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 700;
  font-family: 'Montserrat';
  height: 100px;
  background: #fff !important;
  font-family: 'FuturaStd-Heavy' ,Helvetica, Arial, Lucida, sans-serif !important;

  padding: .5rem 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.menu-mobile,
.searchMobile {
  display: none;
}

#mainNav .navbar-brand {
  color: #fff;
}

#mainNav .navbar-nav {
  margin-top: 1rem;
  letter-spacing: 0.0625rem;
}

.menu-pad a{
  color: #454545;
  font-size: 19px;
  font-weight: 700;
  font-style: normal;
  font-family: 'FuturaStd-CondensedBold';
}
 
.menu-pad a:hover{
  color: #454545 !important;
  opacity: .7 !important;
  text-decoration-line: underline;
} 

.menu-pad{
  margin-bottom: -14px;
  padding-top: 15px;
}

#mainNav .navbar-nav li.nav-item a.nav-link {
  color: #454545;
  font-size: 19px;
  font-weight: 700;
  font-style: normal;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 1px;
}

#mainNav .navbar-nav li.nav-item{
  width: auto;
  text-align: center;
  padding-bottom: 5px;
  padding: 0 16px;
}

.fixed-top{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.noHov{
  font-size: 17px !important;
}

.noHov:hover{
  text-decoration: unset !important;
}

#mainNav .navbar-nav li.nav-item a.nav-link:active, 
#mainNav .navbar-nav li.nav-item a.nav-link:focus {
  color: #454545;
}

#mainNav .navbar-nav li a:active,
#mainNav .navbar-nav li a:hover{
  opacity: .7;
}

#mainNav .navbar-nav{
  margin: unset !important;
}

#mainNav .navbar-toggler {
  font-size: 80%;
  padding: 0.8rem;
}

.greenUs{
  color: #6EAA00 !important;
}

.header-perso{
  height: calc(100vh - 270px)!important;
  font-family: 'FuturaStd-Heavy',Helvetica,Arial,Lucida,sans-serif !important;
}

.header-perso.masthead {
  padding: unset !important;
  /* margin-top: 100px; */
  overflow: hidden;
}

.header-perso.masthead h1 {
  font-size: 3rem;
  line-height: 3rem;
}

.header-perso.masthead h2 {
  font-size: 1.3rem;
}

.discovery{
  padding: 15px;
  text-align: center;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-discover{
  border: unset;
}

.container-perso{
  padding-right: 30%!important;
  padding-left: 30%!important;
  margin-right: auto;
  margin-left: auto;
}

.portfolio{
  padding: 6rem 0;
  font-family: 'FuturaStd-Heavy',Helvetica,Arial,Lucida,sans-serif !important;
}

.portfolio-perso {
  margin-bottom: -15px;
}

.portfolio .portfolio-item {
  position: relative;
  display: block;
  max-width: 25rem;
  margin-bottom: 15px;
  overflow: hidden;
}

.portfolio-item-caption .my-auto {
  position: absolute;
  margin-top: 2% !important;
  padding: 0 80px;
  text-align: center;
  z-index: 1;
}

.portfolio .portfolio-item .portfolio-item-caption .portfolio-item-caption-content h2{
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  color: #454545 !important;
  text-transform: uppercase;
  font-size: 18px;
}

.portfolio-item-subtitle{
  text-decoration: unset !important;
  color: #454545;
}

.hoverZoom img {
  width: 100%;
  height: 100%;
}

#footer-perso{
  padding: 3% 0%;
  background: #d7d7d7;
  color: #fff;
  font-family: 'FuturaStd-Heavy',Helvetica,Arial,Lucida,sans-serif !important;
}

.footerUl{
  padding: unset;
  list-style: none;
}

.sepCol h4{
  color: #fff !important;
  font-size: 20px;
  font-weight: bold;
}

.footerUl a{
  color: #000;
  font-size: 16px;
  line-height: 30px;
}

.img-footer{
  padding-top: 30%;
  width: 80%;
}

.footerUl a:hover{
  color: #737171;
  text-decoration: none;
}

  /********************************
    FOOTER 
  /********************************
  /* Ajoute les bordures a gauche des colonnes du footer */
  .sepCol {
    border-left: 1px solid #969696;
  }
  /* Change la couleur des titres du footer en noir */
  .sepCol > h4{
    color: #000 !important;
  }
  /* Change la couleur des sous titres dans le footer en gris */
  .footerUl a{
    color: #969696;
  }

@media(max-width: 425px){
  #mainNav{
    height: 100px;
    padding-bottom: unset;
  }
  .menu-mobile{
    left: 12%;
    padding: unset;
    background-color: #d7d7d7;
    top: 6%;
    position: fixed;
  }
  .searchMobile{
    left: 78%;
    padding: unset;
    background-color: #d7d7d7;
    top: 6%;
    position: fixed;

    color: rgba(255,255,255,.5);
    border-color: rgba(255,255,255,.1);
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
  }
  .noSearchMobile{
    display: none;
  }
  .logo_container{
    width: 93%;
    height: 48px;
  }
  .contain-menu{
    width: 80%;
  }
  #navbarResponsive{
    padding: 0 20px;
    text-align: left;
    background: #fff;
    margin-top: 36%;
    border-top: 2px solid #454545;
    padding: 0;
  }
  #search{
    margin-top: 30%;
    display: none;
  }
  .navbar-toggler-icon{
    color: #454545;
  }
  .btn-discover{
    margin-top: 15%;
  }
  .portfolio .portfolio-item {
    margin-bottom: 30px;
    height: 356px;
    max-height: 356px;
  }
  .portfolio-item.last-item{
    margin-bottom: unset !important;
  }
  .img-footer{
    padding-top: 3%;
    padding-bottom: 6%;
  }
  .footerUl{
    width: 60%;
    margin: auto;
  }
  .footerUl > li{
    padding: 0 10px;
    display: inline-block;
  }
  .sepCol{
    border: unset;
    text-align: center;
    margin: 8px 72px;
    border-bottom: 1px solid #fff;
    padding-bottom: 12px;
  }
  .sepCol:last-child{
    border: unset;
  }
  .sepCol > h4{
    text-decoration: underline;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  #mainNav{
    height: 100px;
    padding-bottom: unset;
  }
  .menu-mobile{
    left: 12%;
    padding: unset;
    background-color: #d7d7d7;
    top: 6%;
    position: fixed;
  }
  .searchMobile{
    left: 85%;
    padding: unset;
    background-color: #d7d7d7;
    top: 6%;
    position: fixed;
  }
  #navbarResponsive{
    padding: 0 20px;
    text-align: left;
    background: #fff;
    margin-top: 114px;
    border-top: 2px solid #454545;
    padding: 0;
  }
  #search{
    margin-top: 16% !important;
    width: 58%;
    margin: 0 auto;
  }
  .portfolio {
    margin-bottom: -30px;
  }
  .portfolio .portfolio-item {
    margin-bottom: 30px;
    height: 356px;
    max-height: 356px;
  }
  .logo_container{
    margin-left: 42%;
  }
  .btn-discover{
    margin-top: 5%;
  }
  .sepCol{
    border: unset;
    text-align: center;
    border-top: 1px solid #fff;
    padding: 10px 0px;
  }
  .img-footer{
    padding-top: 3%;
    padding-bottom: 6%;
  }
  .footerUl{
    display: inline-flex;
  }
  .footerUl > li{
    padding: 0 10px;
  }
  .noSearchMobile{
    display: none;
  }
  .sepCol{
    border: unset;
    text-align: center;
    margin: 8px 0px;
    border-bottom: 1px solid #fff;
    padding-bottom: 12px;
  }
  .sepCol:last-child{
    border: unset;
  }
}

@media (min-width: 769px) {
  #mainNav {
    padding-top: 1.5rem;
  }
  #mainNav .navbar-brand {
    font-size: 2em;
  }
  #mainNav .navbar-nav {
    margin-top: 0;
    margin: auto !important;
    margin-right: unset !important;
  }
  #mainNav .navbar-nav > li.nav-item > a.nav-link.active,
  #mainNav .navbar-nav > li.nav-item > a.nav-link:focus,
  #mainNav .navbar-nav > li.nav-item > a.nav-link:hover{
    text-decoration: underline;
    color: #454545;
  }
  #mainNav.navbar-shrink {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  #mainNav.navbar-shrink .navbar-brand {
    font-size: 1.5em;
  }

  .portfolio .portfolio-item {
    margin-bottom: 20px;
    height: 356px;
    max-height: 356px;
    margin: 0 !important;
  }

  .goRight{
    float: right;
  }

  #portfolio .col-xl-3{
    flex: 0 0 50% !important;
    max-width: 48%;
    padding: 3px;
    overflow: hidden !important;
  }

  .header-perso.masthead {
    padding-top: calc(6rem + 106px);
    padding-bottom: 6rem;
    height: 195px !important;
    overflow: hidden;
  }
  .header-perso.masthead img{
    width: 100%;
    background-position: top center;
    background-size: cover;
  }
  .header-perso.masthead h1 {
    font-size: 32px;
    line-height: 4rem;
  }
  .header-perso.masthead h2 {
    font-size: 32px;
    margin-top: -13px;
    font-family: 'FuturaStd-Medium';
  }
  .footerUl{
    display: block;
  }
  .sepCol{
    border-top: unset;
    border-left: 1px solid;
  }
}

@media (min-width: 1025px){
  .logo_container{
    margin-left: 6%;
  }
}

@media (min-width: 1800px){
  .logo_container{
    left: 7%;
  }
}


/********************************
    Header 
/********************************/

/**************** STEP1 ****************/
/***** Repositionnement du header ******/
/***************************************/
.blog-header-logo{ position: initial !important; left: initial !important; }
.py-3 { padding-bottom: 2px !important; padding-top: 2px !important; }
.contain-menu-custom{ width: 100%; padding-right: 15px;  padding-left: 15px; margin-right: auto; margin-left: auto; }
.contain-menu-custom .py-1 { padding-bottom: .25rem!important; }
.contain-menu-custom .mb-2 { margin: unset !important; }
.contain-menu-custom .justify-content-between{ justify-content: center !important; }
.blog-header-logo img{ margin: 12px 0px -17px 0 !important;}
.d-flex{ display: flex !important; }
.discovery{ text-align: left; margin: 0px 0px 0px 38px !important; left: 34%; top: 26%;}
.btn-discover{background: #fff;}
.header-perso.masthead{height: 667px !important;}
.portfolio-item-caption-content h2{ border-bottom: 3px solid #fff; padding-bottom: 4px;}
.header-perso.masthead h2{ text-decoration-line: underline; color: #fff !important; }
.header-perso.masthead h3{ margin-bottom: 10px !important; color: #fff !important; }

/**************** STEP2 ****************/
/********** Design des blocs ***********/
/***************************************/
#portfolio .col-xl-3{ flex: 0 0 25% !important; padding: 0 8px !important; padding: initial; }
.container-perso{ padding: 0 10% !important; }
.d-flex { display: -ms-flexbox!important; display: flex!important; }
.portfolio-item-caption{ display: flex!important; position: absolute!important; width: 100%; height: 100%; }

/**************** STEP3 ******************/
/* Design des descriptions + Effet hover */
/*****************************************/
.portfolio-item-caption .my-auto { margin-top: 74% !important; text-align: left; padding: 0 30px; }
.portfolio .portfolio-item .portfolio-item-caption .portfolio-item-caption-content h2,
.portfolio .portfolio-item-subtitle{ color: #fff !important; }
.hoverZoom img{ width: 100%; height: 100%; -webkit-transform: scale(1); 
    transform: scale(1); -webkit-transition: .3s ease-in-out; transition: .3s ease-in-out; }
.hoverZoom:hover img{ -webkit-transform: scale(1.3); transform: scale(1.3); }
.portfolio-item-caption-content h2{border-width: 0 0 3px; border-style: solid;}
