/* .row_custom_small.box-1{
    margin: 4% 0;
} */

.row_custom_small.box-1 .row_custom_small.box-1 + .row_custom_small.box-1,
.box-project .row_custom_small.box-1 + .row_custom_small.box-1 {
    margin-top: unset !important;
}
.row_custom_small.box-1 .row_custom_small,
.box-project .row_custom_small {
    padding: unset !important;
    margin-bottom: 34px;
}
.row_custom_small.box-1 .row_custom_small .pad,
.box-project .row_custom_small .pad {
    padding: unset;
    margin-top: unset !important;
    margin-bottom: unset !important;
}
.row_custom_small.box-1 .row_custom_small .pad h1,
.box-project .row_custom_small .pad h1 {
    font-size: 2.325rem;
    margin: 12px 0 17px;
    text-align: center;
    display: none;
}
.row_custom_small.box-1 .row_custom_small .pad img,
.box-project .row_custom_small .pad img{
    width: 100% !important;
    height: 30vw;
    object-fit: cover;
    object-position: center;
}

.row_custom_small.box-1 .row_custom_small .pad blockquote,
.box-project .row_custom_small .pad blockquote {
    margin: unset !important;
    max-width: 100%;
    text-align: center;
    font-size: 1.275rem;
    font-weight: 300;
    padding: 2% 8%;
    border-left: unset;
    line-height: 1.2em;
}
.row_custom_small.box-1 .row_custom_small .pad blockquote p,
.box-project .row_custom_small .pad blockquote p {
    margin-bottom: unset;
}
.row_custom_small.box-1 .row_custom_small .pad .home__img_marg,
.box-project .row_custom_small .pad .home__img_marg {
    margin-top: unset !important;
}

.row_custom_small.box-1 {
    background: #80808021;
    padding: 0 0;
    margin-bottom: 30px;
}
.row_custom_small.box-1 .row_custom_small.section3 {
    margin-bottom: unset !important;
    width: 100%;
}

.row_custom_small.box-1.featured_posts_custom {
    background: initial;
}

.row_custom_small.box-1 + .row_custom_small.box-1 {
    margin-top: unset !important;
}

@media screen and (min-width: 1px) and (max-width: 425px) {


    .row_custom_small.box-1 .pad blockquote {
        font-size: 1rem;
    }

    .row_custom_small.box-1 .pad blockquote p {
        font-size: 1rem;
    }

    .custom_list h1,
    .row_custom_small.box-1 h1 {
        font-size: 1.875rem !important;
    }
}
